import React from "react"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import formatWithLocale from "../../util/dateFormatter"
import { Collapsible } from "@flow/components"
import DownloadFileButton from "./DownloadFileButton"

const RequestedDocArchive = ({ documentations = [], t }) => {
  return (
    <ContentWrapper>
      {documentations?.map((doc) => {
        return (
          <CommentWrapper>
            <CommentColumn>
              <p>{doc.caseworker} etterspurte:</p>
              <p>{doc.commentToCustomer}</p>
            </CommentColumn>

            <CommentColumn>
              <p>{formatWithLocale(doc?.commentTime)}</p>
            </CommentColumn>

            <CaseComment>
              {doc?.files ? (
                <>
                  <p>Respons fra kunde:</p>
                  <p>{doc.commentFromCustomer}</p>
                  <Collapsible header={t("files-uploaded-from-customer")}>
                    {doc?.files?.map((x, index) => {
                      return (
                        <DownloadFileButton
                          title={x.filename}
                          fileId={x.storeID}
                          key={index}
                        />
                      )
                    })}
                  </Collapsible>
                </>
              ) : (
                <>
                  <p>{t("no-response-from-customer")}:</p>
                  <p>{t("customer-not-uploaded")}</p>
                </>
              )}
            </CaseComment>
          </CommentWrapper>
        )
      })}

      {documentations.length === 0 && <div>{t("no-documentations")}</div>}
    </ContentWrapper>
  )
}
export default RequestedDocArchive

const CaseComment = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 2;
  grid-row-end: 2;
  padding: 1em;
  background-color: #f9fbff;

  p {
    &:first-child {
      font-weight: bold;
    }

    text-align: start;
    ${FontStyles.Medium}
    white-space: pre-wrap;
  }
`

const ContentWrapper = styled.div`
  color: ${Colors.Grey1};
  padding: 0px 25px;
`

const CommentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: [row1-start] auto 1fr;
  background-color: ${Colors.SeaLighter};
  border: 1px solid ${Colors.Grey4};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  align-items: start;

  div:nth-child(2) {
    text-align: end;
  }
`
const CommentColumn = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 1;
  grid-row-end: 1;
  padding: 1em;

  &:first-child {
    p {
      &:first-child {
        font-weight: bold;
      }
    }
  }

  &:nth-child(2) {
    color: ${Colors.Sea};
  }
`
