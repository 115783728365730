import styled from "styled-components"
import { Colors } from "@flow/style"
import React, { useState } from "react"
import TextButton from "../common/TextButton"
import SpecialTerm from "./SpecialTerm"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import lodash from "lodash"

/**
 * Creates a component for editing a delivery.
 * @param {*} delivery The delivery to be used
 * @param {*} onChange Function called whenever a delivery compentent is changed
 * @param {*} t Translation
 * @returns The delivery view
 */
const DeliveryComponent = ({
  delivery,
  onChange,
  t,
  specialTermTags,
  deliveryId,
  editingId = null,
  setEditingId = null,
  readOnly = false,
  isMaintenance,
}) => {
  /**
   * When clicking on the "show-special-terms-button-text"-button (+ vis særvilkår)
   */

  const { userAdded, mandatory, specialTermsInEdit } = delivery.specialTerms
  const onAddSpecialTerm = () => {
    // Adding a term to be filled in by the SpecialTerm component
    const newTerm = {
      termText: "",
      disabled: false,
    }
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.specialTermsInEdit = newTerm
    onChange({ deliveryId, data: newDev })

    setEditingId(deliveryId)
  }

  const addEditTerm = (finishedSpecialTerm) => {
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.userAdded.push(finishedSpecialTerm)
    delete newDev.specialTerms.specialTermsInEdit
    onChange({ deliveryId, data: newDev })

    setEditingId(null)
  }

  const onDelete = (termToRemove) => {
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.userAdded = delivery.specialTerms.userAdded.filter(
      (term) => term !== termToRemove
    )
    onChange({ deliveryId, data: newDev })
  }

  const onCancel = () => {
    const newDev = lodash.cloneDeep(delivery)
    newDev.specialTerms.specialTermsInEdit = null
    onChange({ deliveryId, data: newDev })

    setEditingId(null)
  }

  return (
    <>
      <Box>
        <ProductTitle>
          {t(delivery.productName)}{" "}
          {isMaintenance ? "" : `kr ${addThousandSeparator(delivery.amount)}`}
        </ProductTitle>
        {mandatory.map((term, i) => {
          return (
            <SpecialTerm
              key={i}
              specialTerm={term}
              disabled={true}
              deletable={false}
              categories={specialTermTags}
              t={t}
            />
          )
        })}
        {userAdded.map((term, i) => {
          return (
            <SpecialTerm
              key={i}
              specialTerm={term}
              onDelete={onDelete}
              disabled={true}
              deletable={!editingId && !readOnly}
              categories={specialTermTags}
              t={t}
            />
          )
        })}

        {specialTermsInEdit && (
          <SpecialTerm
            key={"S"}
            specialTerm={specialTermsInEdit}
            onDelete={onDelete}
            onAdd={addEditTerm}
            onCancel={onCancel}
            categories={specialTermTags}
            t={t}
          />
        )}
      </Box>
      {!specialTermsInEdit && !readOnly && !editingId && (
        <TextButton onClick={onAddSpecialTerm}>
          {t("show-special-terms-button-text")}
        </TextButton>
      )}
    </>
  )
}

export default DeliveryComponent

const Box = styled.div`
  color: black;
  background-color: ${Colors.Grey4};
  padding: 10px;
  border-radius: 5px;
`
const ProductTitle = styled.div`
  color: black;
  font-size: 15px;
`
