import { useCallback, useState } from "react"

const LANGUAGE_KEY = "STACC_FLOW_SELECTED_LANGUAGE"

const languageLocaleMap = {
  no: "nb-NO",
  en: "en-UK",
}

export const useLanguage = (i18nInstance, t) => {
  const [language, languageSet] = useState(
    () => localStorage.getItem(LANGUAGE_KEY) ?? "no"
  )

  const handleSetLanguage = useCallback(
    (lang, updateStorage = true) => {
      const l = lang ?? "no"
      languageSet(l)
      if (updateStorage) {
        localStorage.setItem(LANGUAGE_KEY, l)
      }

      if (i18nInstance) {
        if (i18nInstance.language !== l) {
          i18nInstance.changeLanguage(l)
          // hack since CaseWorker no longer uses the proper i18next instance and does not sense side effects in props
          setTimeout(() => {
            window.location.reload()
          }, 1)
        }
      }
    },
    [i18nInstance]
  )

  const locale = languageLocaleMap[language] ?? languageLocaleMap.no

  return { language, setLanguage: handleSetLanguage, locale }
}
