import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import React, { useCallback, useEffect, useState } from "react"
import Heading from "../components/common/Heading"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import GuarantorCard from "../components/bail/GuarantorCard"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import StyledBox from "../components/common/StyledBox"
import { RadioButtons } from "@flow/forms"
import TextButton from "../components/common/TextButton"
import NewGuarantor from "../components/bail/NewGuarantor"
import ErrorText from "../components/common/ErrorText"
import FileInTabButton from "../components/common/FileInTabButton"
import { Spinner } from "@flow/icons"
import axios from "axios"
import { returnFirstArgWithValue } from "../util/returnValue"
import Tabs from "../components/common/Tabs"
import AssessmentComments from "../components/common/AssessmentComments"
import BlueDot from "../components/common/BlueDot"
import formatWithLocale from "../util/dateFormatter"
const { v4: uuidv4 } = require("uuid")

const EvaluateGuarantor = ({ guarantor, id, t, onChange, children }) => {
  const change = (value) => {
    const updateGuarantor = { ...guarantor }
    updateGuarantor.valid = value
    onChange(id, updateGuarantor)
  }

  return (
    <StyledBox title="Vurdering" padding="0px 0 10px 0" large>
      <Padding padding="5px 0px">
        {guarantor.type === "person"
          ? t("should-person-be-used-as-guarantor")
          : t("should-company-be-used-as-guarantor")}
      </Padding>
      <div>
        <RadioButtons
          onChange={(e, value) => change(value)}
          checkedValue={guarantor.valid}
          name={`guarantor-evaluation-${guarantor.id}`}
          horizontal
          options={[
            {
              label: t("yes-guarantor"),
              value: "yes",
            },
            {
              label: t("no"),
              value: "no",
            },
          ]}
        />
      </div>
      {children}
    </StyledBox>
  )
}

const LoadingCreditCheck = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  div {
    margin-left: 10px;
  }
`

const CreditCheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10;
  margin-bottom: 10px;
  align-items: center;
`

const CreditCheckText = styled.div`
  margin-right: 10px;
`

const sortByDatePred = (a, b) => {
  if (a?.error) return -1
  if (b?.error) return 1
  return new Date(b.date) - new Date(a.date)
}

const GuarantorCreditChecks = ({ guarantor, t }) => {
  if (
    guarantor.type === "organization" &&
    guarantor.scope === "international"
  ) {
    return <></>
  }

  if (guarantor.creditChecking) {
    return (
      <StyledBox title={t("credit-evaluations")} padding="0" large>
        <LoadingCreditCheck>
          <Spinner size={20} />
          <div>Vent mens kredittsjekk pågår</div>
        </LoadingCreditCheck>
      </StyledBox>
    )
  }

  return (
    <StyledBox title={t("credit-evaluations")} padding="0" large>
      {guarantor?.creditChecks?.sort(sortByDatePred)?.map((x, i) => {
        if (x?.error) {
          return (
            <CreditCheckContainer key={i}>
              <CreditCheckText>
                {formatWithLocale(x.date, "dd.MM.yyyy")}:
              </CreditCheckText>
              <div>{t(x.error)}</div>
            </CreditCheckContainer>
          )
        }
        return (
          <CreditCheckContainer key={i}>
            <CreditCheckText>
              Kredittsjekk fra {formatWithLocale(x.date, "dd.MM.yyyy")}:
            </CreditCheckText>
            <FileInTabButton
              storeId={x?.file?.storeID}
              title={t("open-credit-check-in-new-window")}
            />
          </CreditCheckContainer>
        )
      }) || <div>{t("no-credit-check-could-be-made")}</div>}
    </StyledBox>
  )
}

const EvaluateGuarantors = ({
  task,
  t,
  flow,
  schema,
  onSave,
  updateCase,
  save,
  complete,
}) => {
  const { applicationSummary, stateData } = task?.context

  const initialData = returnFirstArgWithValue(task.data, stateData)

  const [taskData, setTaskData] = useState(initialData)
  const [isProcessing, setProcessing] = useState(false)
  const [isEditing, setEditing] = useState(false)
  const [isCreditChecking, setCreditChecking] = useState(false)
  const [validate, setValidate] = useState(false)

  const partialSave = useCallback(
    (data) => {
      setProcessing(true)
      onSave(
        task.taskId,
        data,
        () => {
          updateCase()
          setProcessing(false)
        },
        () => {
          console.error("Could not save task")
          setProcessing(false)
        }
      )
    },
    [onSave, task.taskId, updateCase]
  )

  useEffect(() => {
    const isEditing = taskData.guarantors?.some((x) => x.new || x.editing)
    const creditChecking = taskData.guarantors?.some((x) => x.creditChecking)
    setCreditChecking(creditChecking)
    setEditing(isEditing)
  }, [taskData])

  //Hente ut pending task slik at vi kan hente taskId på message-tasken som skal trigges
  const getTaskId = async (flowId) => {
    const res = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending`
    )
    const tasks = res?.data
    if (tasks.length > 0) {
      const pendingTask =
        tasks.find(
          (x) =>
            x.taskType === "fetch-new-guarantor-credit-check" &&
            x.taskCategory === "message-task"
        ) || []
      return pendingTask.taskId
    }
  }
  const performCreditCheck = useCallback(async (id, guarantor) => {
    try {
      const flowId = flow?.flowId
      const taskId = await getTaskId(flowId)
      await axios.post(`/api/flow/tasks/${taskId}/trigger`, {
        guarantor: guarantor,
      })

      const creditCheckFinished = async () => {
        const res = await axios.get(`/api/flow/flows/${flowId}`)
        const flowData = res?.data?.data
        if (!updateCreditChecks(flowData, guarantor, id)) {
          setTimeout(creditCheckFinished, 3000)
        }
      }
      setTimeout(creditCheckFinished, 3000)
    } catch (error) {
      console.error("Failed to trigger new credit check", error)
    }
  }, [])

  const onChangeGuarantor = (id, guarantor) => {
    const newTaskdata = { ...taskData }
    newTaskdata.guarantors[id] = guarantor
    if (guarantor.creditCheck && !guarantor.new) {
      performCreditCheck(id, guarantor)
      guarantor.creditCheck = false
      guarantor.creditChecking = true
      partialSave(newTaskdata)
    }
    setTaskData(newTaskdata)
  }

  const updateCreditChecks = (flowData, guarantor, id) => {
    const stateGuarantor = flowData.analysis?.initialGuarantors[guarantor.id]
    const pendingCreditCheck = flowData?.pendingCreditCheck
    const currentGuarantor = taskData?.guarantors[id]
    const creditChecks = stateGuarantor?.creditChecks

    const newGuarantor = { ...currentGuarantor }
    newGuarantor.editing = false

    // If trying to credit check and there is no pending check, stop checking
    if (newGuarantor.creditChecking && !pendingCreditCheck) {
      newGuarantor.creditChecking = false
      newGuarantor.creditChecks = creditChecks?.map((x) => ({
        date: x.date,
        file: x.file,
      }))
      newGuarantor.name = stateGuarantor.info?.name
      newGuarantor.address = stateGuarantor.info?.address

      onChangeGuarantor(id, newGuarantor)
      return true
    }
    onChangeGuarantor(id, newGuarantor)
    return false
  }

  const taskGuarantors = taskData.guarantors || []
  useEffect(() => {
    taskGuarantors.map((guarantor, i) => {
      if (!guarantor.creditChecking) return i
      const creditCheckFinished = async () => {
        const flowId = flow?.flowId
        const res = await axios.get(`/api/flow/flows/${flowId}`)
        const flowData = res?.data?.data
        if (!updateCreditChecks(flowData, guarantor, i)) {
          setTimeout(creditCheckFinished, 3000)
        }
      }
      setTimeout(creditCheckFinished, 3000)
      return i
    })
  }, [])

  const handleSave = (values) => {
    setProcessing(true)
    save(
      taskData,
      () => {},
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setValidate(false)
    if (
      taskData.guarantors.some(
        (x) => !x.valid || (!(x.amount > 0) && x.valid === "yes")
      )
    ) {
      setValidate(true)
      return
    }

    const finalData = {
      ...taskData,
      guarantors: taskData.guarantors.map((tGuarantor) => {
        const guarantor = JSON.parse(JSON.stringify(tGuarantor))
        const creditChecks = guarantor.creditChecks || []
        const valid = guarantor.valid
        const amount = Number(guarantor.amount || "0")
        delete guarantor.creditChecks
        delete guarantor.editing
        delete guarantor.creditCheck
        delete guarantor.creditChecking
        delete guarantor.valid
        delete guarantor.amount
        return {
          info: guarantor,
          valid,
          amount,
          creditChecks,
        }
      }),
    }

    setProcessing(true)
    complete(
      finalData,
      () => {},
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const addNewGuarantor = () => {
    const newTaskdata = { ...taskData }
    newTaskdata.guarantors.unshift({
      new: true,
      id: uuidv4(),
      type: "person",
    })
    setTaskData(newTaskdata)
  }

  const commitGuarantor = (index, guarantor) => {
    const newTaskdata = { ...taskData }
    newTaskdata.guarantors[index] = guarantor
    delete guarantor.new
    if (guarantor.type === "person" || guarantor.scope === "domestic") {
      guarantor.creditCheck = false
      partialSave(newTaskdata)
      guarantor.creditChecking = true
      performCreditCheck(index, guarantor)
    }
    setTaskData(newTaskdata)
  }

  const removeGuarantor = (index) => {
    const newTaskdata = { ...taskData }
    newTaskdata.guarantors.splice(index, 1)
    setTaskData(newTaskdata)
  }

  const canAddGuarantor = taskData.guarantors?.every((x) => !x.new)

  const newSchema = { ...schema }
  // Note: Manually handled outside of reactForm, but we want it in the schema for validation purposes
  delete newSchema.properties.guarantors

  const decisionCount = flow?.data?.assessmentComments?.length ?? 0

  const options = [
    {
      id: "guarantors",
      title: "Vurder kausjonist",
      component: (
        <Content>
          <SplitLine>
            <Heading>{t("guarantors")}</Heading>
            {canAddGuarantor && (
              <TextButton onClick={() => addNewGuarantor()}>
                <AddGuarantorText>+ {t("add-guarantor")}</AddGuarantorText>
              </TextButton>
            )}
          </SplitLine>
          <GuarantorContainer>
            {taskData.guarantors?.map((x, i) => {
              if (!x.new) {
                return (
                  <GuarantorCard
                    t={t}
                    guarantor={x}
                    key={i}
                    hasEdit
                    onChange={(guarantor) => onChangeGuarantor(i, guarantor)}
                  >
                    <EvaluateGuarantor
                      guarantor={x}
                      id={i}
                      key={i}
                      t={t}
                      onChange={onChangeGuarantor}
                    >
                      {validate && !x.valid && (
                        <ErrorText error="Påkrevd"></ErrorText>
                      )}
                      {validate && x.valid === "yes" && !(x.amount > 0) && (
                        <ErrorText error="bail-amount-required"></ErrorText>
                      )}
                    </EvaluateGuarantor>
                    <GuarantorCreditChecks guarantor={x} t={t} />
                  </GuarantorCard>
                )
              }

              return (
                <NewGuarantor
                  key={i}
                  guarantor={x}
                  t={t}
                  onChange={(guarantor) => onChangeGuarantor(i, guarantor)}
                  onCancel={(guarantor) => removeGuarantor(i)}
                  onAdd={(guarantor) => commitGuarantor(i, guarantor)}
                />
              )
            })}
          </GuarantorContainer>
        </Content>
      ),
    },
    {
      id: "decisions",
      title: "Vurderinger",
      children: decisionCount ? <BlueDot>{decisionCount}</BlueDot> : undefined,
      component: (
        <AssessmentComments
          comments={flow?.data?.assessmentComments}
          t={t}
          assignee={flow?.assignee?.name}
        />
      ),
    },
  ]

  return (
    <Layout forceHeight>
      <MainContainer>
        <Tabs defaultTab="guarantors" options={options} />
      </MainContainer>
      <Context context={applicationSummary} flow={flow}>
        <ReactForm
          schema={newSchema}
          formData={taskData}
          disabled={isProcessing}
          onChange={(values) => setTaskData(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              disabled={isProcessing || isEditing || isCreditChecking}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isProcessing || isEditing}
              onClick={() => handleSave(taskData)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}

const AddGuarantorText = styled.div`
  ${FontStyles.Large};
  color: ${Colors.Sea};
`

const SplitLine = styled.div`
  display: flex;
  justify-content: space-between;
`

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  padding: 10px 30px;
  padding-bottom: 30px;
`

const GuarantorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

const Padding = styled.div`
  padding: ${({ padding }) => padding};
`

export default withTranslation()(EvaluateGuarantors)
