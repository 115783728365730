import React from "react"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"

const GridContent = ({
  title,
  data,
  titleProps = {},
  contentProps = {},
  children,
}) => {
  let DataComponent = data
  if (typeof data !== "function") {
    DataComponent = () => <>{data}</>
  }
  return (
    <BoxStyled>
      <Title {...titleProps}>{title}</Title>
      <Content {...contentProps}>
        <DataComponent />
      </Content>
    </BoxStyled>
  )
}
export default GridContent

const Title = styled.h3`
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  font-size: ${({ fontSize }) => fontSize || FontStyles.Small};
  color: ${({ color }) => color || "inherit"};
`

const Content = styled.div`
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  font-size: ${({ fontSize }) => fontSize || FontStyles.Normal};
  color: ${({ color }) => color || Colors.Coal};
`

const BoxStyled = styled.div``
