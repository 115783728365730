import React, { useState } from "react"
import Dropdown from "../common/inputs/Dropdown"
import Notice from "../common/Notice"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import { addThousandSeparator } from "../../util/addThousandSeparator"

const SecurityRiskAssessment = ({
  t,
  deliveries,
  securityRiskData,
  setSecurityRiskData,
  isMaintenance,
}) => {
  const options = [
    { name: "Velg", value: "default" },
    { name: "1: 0%", value: "1" },
    { name: "2: 1-10%", value: "2" },
    { name: "3: 11-25%", value: "3" },
    { name: "4: 26-50%", value: "4" },
    { name: "5: 51-75%", value: "5" },
    { name: "6: 76-100%", value: "6" },
    { name: "7: 100%", value: "7" },
  ]

  const handleChange = (e, i) => {
    const { deliveryId } = securityRiskData[i]
    const tempData = [...securityRiskData]

    tempData[i] = {
      deliveryId,
      safetyRisk: e.target.value,
    }

    setSecurityRiskData(tempData)
  }
  return (
    <>
      {securityRiskData?.map((riskData, i) => {
        const delivery = deliveries[riskData.deliveryId]

        return (
          <NoticeWrapper key={i}>
            <Notice
              backgroundColor={Colors.SeaLighter}
              border={{ size: "1px", style: "solid", color: Colors.Grey2 }}
            >
              {!isMaintenance && (
                <DeliveryTitle>{`${delivery.loanPurposeCode}-${
                  delivery.productName
                } kr ${addThousandSeparator(delivery.amount)}`}</DeliveryTitle>
              )}
              <Dropdown width={"100%"}>
                <SelectWrapper>
                  <p>{t("riskAssessment")}</p>
                  <select
                    id={i}
                    onChange={(e) => handleChange(e, i)}
                    value={riskData.safetyRisk}
                  >
                    {options.map((optionItem) => {
                      return (
                        <option key={optionItem.name} value={optionItem?.value}>
                          {optionItem?.name}
                        </option>
                      )
                    })}
                  </select>
                </SelectWrapper>
              </Dropdown>
            </Notice>
          </NoticeWrapper>
        )
      })}
    </>
  )
}

const NoticeWrapper = styled.div`
  width: 100%;
  padding: 10px 50px;
`

const SelectWrapper = styled.div`
  margin: 1em;
`
const DeliveryTitle = styled.p`
  ${FontStyles.Normal};
`

export default SecurityRiskAssessment
