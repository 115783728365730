import React from "react"
import styled from "styled-components"
import { TextArea } from "@flow/forms"
import { Colors } from "@flow/style"
import { TransparentButton } from "@flow/buttons"
import PayoutDetailsForm from "./PayoutDetailsForm"
import _ from "lodash"
import { v4 as uuidv4 } from "uuid"

/**
 * @typedef {Object} Props
 * @property {string} flowId - The id of the flow
 * @property {string} karCheckMessage - The message from the kar check
 * @property {PayoutRequestReceived} payoutRequestReceived - The payout request received
 * @property {Delivery} currentDelivery - The current delivery
 * @property {Flow} flow - The flow
 * @property {function} t - The translation function
 * @property {StateData} stateData - The state data
 * @property {function} setStateData - The state data setter
 * @property {boolean} readonly - Is the form readonly
 * @property {boolean} partialSave - Is the form partially saved
 * @property {Object} errorMsg - The error messages
 * @property {string} deliveryId
 * @property {boolean} isPerformFinancialCheck - Is the form in perform financial check
 */

/**
 * @typedef {Object} StateData
 * @property {Array<Payment>} payments - The payments
 * @property {string} payoutDefinition - The payout definition
 * @property {number} payoutAmount - The payout amount
 * @property {number} remainingAmount - The remaining amount
 * @property {string} comment - The comment
 * @property {string} payoutDefinition - The payout definition
 * @property {number} totalPayoutAmount - The total payout amount
 */

/**
 * @param {Props} props
 * @returns {JSX.Element}
 */
const PayoutDetails = ({
  flowId,
  karCheckMessage,
  payoutRequestReceived,
  currentDelivery,
  flow,
  t,
  stateData,
  setStateData,
  readonly,
  partialSave,
  errorMsg,
  deliveryId,
  isPerformFinancialCheck,
}) => {
  const customerAccount = flow?.data.raw?.accountMaster?.customerAccount || {}
  const applicantName = customerAccount?.name
  const addPayoutDeets = () => {
    const existingPayments = stateData.payments
    existingPayments.push({
      id: uuidv4(),
      payoutAmount: null,
      payoutName: applicantName,
      payoutOrgNumber: flow.data.raw.accountMaster.customerAccount.publicId,
      customerAccountNumber: payoutRequestReceived?.customerAccountNumber,
      payoutApplicantOrThirdPerson: "applicant",
    })
    setStateData({ ...stateData, payments: existingPayments })
  }

  const onDelete = (index) => {
    const existingPayments = stateData.payments
    existingPayments.splice(index, 1)
    setStateData({ ...stateData, payments: existingPayments })
  }

  const change = (e, key) => {
    if (readonly) return
    if (key === "payoutDefinition") {
      const value = e.target.value
      const newStateData = { ...stateData }
      newStateData[key] = value
      setStateData(newStateData)
      return
    }
  }

  /**
   * @param {Payment} payment
   * @param {number} index
   *
   * @returns {void}
   */
  const onChange = (payment, index) => {
    const existingPayments = stateData.payments
    existingPayments[index] = payment
    const payoutAmount = payment.payoutAmount
    setStateData({ ...stateData, payments: existingPayments, payoutAmount })
  }

  return (
    <>
      {stateData?.payments?.map((x, i) => {
        return (
          <PayoutDetailsForm
            currentDelivery={currentDelivery}
            karCheckMessage={
              i === 0 ? karCheckMessage : "Ingen kar sjekk utført"
            }
            flowId={flowId}
            key={x.id}
            payment={x}
            payoutRequestReceived={payoutRequestReceived}
            readonly={readonly}
            stateData={stateData}
            t={t}
            partialSave={partialSave}
            setStateData={setStateData}
            errorMsg={errorMsg}
            flow={flow}
            deliveryId={deliveryId}
            onDelete={() => onDelete(i)}
            onChange={(payment) => onChange(payment, i)}
            index={i}
            isPerformFinancialCheck={isPerformFinancialCheck}
          />
        )
      })}
      <Row>
        {!readonly && (
          <StyledTransparentButton
            onClick={() => addPayoutDeets()}
            disabled={readonly}
          >
            {t("addpayments")}
          </StyledTransparentButton>
        )}
      </Row>
      <Row>
        <TextAreaContainer>
          <TextAreaBox
            label={t("details-payout-explanation")}
            help={t("payout-details-help")}
            value={stateData?.payoutDefinition}
            disabled={readonly}
            onChange={(e) => change(e, "payoutDefinition")}
            name=""
            placeholder={readonly ? "" : t("payout-details-placeholder")}
          />
          {errorMsg?.payoutDefinition && (
            <ErrorField> {errorMsg?.payoutDefinition}</ErrorField>
          )}
        </TextAreaContainer>
      </Row>
    </>
  )
}
export default PayoutDetails

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: 2.5rem;
`

const ErrorField = styled.span`
  color: ${Colors.Ferrari};
`

const TextAreaBox = styled(TextArea)`
  width: 100%;
  flex-basis: 100%;
  height: 150px;
`

const TextAreaContainer = styled.div`
  flex-basis: 100%;
`
const StyledTransparentButton = styled(TransparentButton)`
  color: blue;
`
/**
 * @typedef {Object} Payment
 * @property {string} customerAccountNumber - The customer account number
 * @property {string} payoutName - The name of the payout
 * @property {string} id - The id of the payout
 * @property {string} payoutOrgNumber - The org number of the payout
 * @property {boolean} isPerson - Is the payout a person
 * @property {string} payoutApplicantOrThirdPerson - Is the payout the applicant or a third person
 * @property {number} payoutAmount - The amount of the payout
 */
