import styled from "styled-components"
import { Colors } from "@flow/style"
import { TextArea } from "@flow/forms"
import IddBasis from "../common/IddBasis"
import React from "react"

const MyAssessment = ({ flow, isTeam, isValid, taskData, setTaskData, t }) => {
  const updateText = (e) => {
    if (isTeam) return
    const clone = { ...taskData }
    clone.assessment = e
    setTaskData(clone)
  }

  const decideIddRequirements =
    flow.data?.decisionTables?.decideIddRequirements?.raw?.result?.data
  const selfRisk =
    decideIddRequirements["self-report"]?.at(0)?.selfDeclarationRisk
  const industryRisk = decideIddRequirements?.industry?.at(0)?.industryRisk
  const companyRisk = decideIddRequirements?.company?.at(0)?.companyRisk
  const manualAssessment = flow.data?.integrityDueDilligence?.manualAssessment

  const validation =
    !isTeam && !taskData.assessment && t("caseworker-evaluation-validation")
  return (
    <MyAssessmentWrapper>
      <IddBasisWrapper>
        <IddBasis
          title={"basis-for-idd"}
          selfRisk={selfRisk}
          industryRisk={industryRisk}
          companyRisk={companyRisk}
          manualAssessment={manualAssessment}
          t={t}
        />
      </IddBasisWrapper>
      <div>
        <b>{t("caseworkers-assessment")}</b>
        {isTeam && <StyledText>{taskData.assessment}</StyledText>}
        {!isTeam && (
          <>
            {!isValid && (
              <>
                <br />
                <ValidationError>{validation}</ValidationError>{" "}
              </>
            )}
            <StyledTextArea
              name={"caseworker-evaluation"}
              defaultValue={taskData.assessment}
              required={true}
              placeholder={t("write-your-assessment")}
              onChange={(e, text) => updateText(text)}
            />
          </>
        )}
      </div>
    </MyAssessmentWrapper>
  )
}

export default MyAssessment

const MyAssessmentWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 5fr;
  grid-row-gap: 1rem;
`

const StyledTextArea = styled(TextArea)`
  width: 100%;
  height: 600px;
  margin-top: 10px;
  border-radius: 0;
  resize: none;
`

const StyledText = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 10px;
  border-radius: 0;
  white-space: pre-wrap;
`

const ValidationError = styled.span`
  color: ${Colors.Ferrari};
`

const IddBasisWrapper = styled.div`
  width: calc(100% - 30px);
  height: auto;
  background-color: ${Colors.SeaLightest};
  border: 1px solid ${Colors.SeaLighter};
  padding: 15px;
`
