import styled from "styled-components"
import { useState, useEffect } from "react"
import { Icons } from "@flow/icons"
import { Colors } from "@flow/style"
import IconButton from "../../../common/IconButton"
import { Checkbox } from "@flow/forms"
import useClickOutside from "../../../../hooks/useClickOutside"

/**
 * This is a replacement/alternative to the current MultiSelect we have the project, this follows the more default styling pattern of inputs, making it a better fit for general purpose.
 * This also has the ability to have sections within the dropdown, which is a feature that is missing in the current MultiSelect.
 * I don't believe this will work with react-hook-forms, as it uses none of the default HTML elements, and I haven't tested support.
 */

// Definition of how the options prop should be structured, this structure is to allow for sections within the select.
// type Option = {
//   group: string; // Name displayed for the section
//   options: [
//     {
//       label: string;
//       value: string;
//     }
//   ]
// }

function MultiSelect({
  options: groupedOptions,
  onChange,
  isOpen = false,
  minWidth,
  collapseOnMany = true,
  collapseCount = 4,
  showRemoveButton = true,
  labelFormatting = undefined,
}) {
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(isOpen)
  const ref = useClickOutside(() => setOpen(false))

  function removeEntry(value) {
    setSelected((prev) => prev.filter((item) => item.value !== value))
  }

  function addSelected(option) {
    if (!selected.find((item) => item.value === option.value)) {
      setSelected((prev) => [...prev, option])
    } else {
      setSelected((prev) => prev.filter((item) => item.value !== option.value))
    }
  }

  useEffect(() => {
    onChange(selected)
  }, [selected])

  return (
    <Container ref={ref} minWidth={minWidth}>
      <SelectedView open={open} onClick={() => setOpen(!open)}>
        {collapseOnMany && selected.length >= collapseCount ? (
          <SelectedOptionsContainer>
            <SelectedItem onClick={(event) => event.stopPropagation()}>
              <p>{selected.length} selected</p>
            </SelectedItem>
          </SelectedOptionsContainer>
        ) : (
          <SelectedOptionsContainer>
            {selected.map((item) => (
              <SelectedItem
                key={item.value}
                onClick={(event) => event.stopPropagation()}
              >
                <p>{labelFormatting ? labelFormatting : item.label}</p>
                {showRemoveButton && (
                  <IconButton
                    icon={"Remove"}
                    color={"gray"}
                    onClick={() => removeEntry(item.value)}
                  />
                )}
              </SelectedItem>
            ))}
          </SelectedOptionsContainer>
        )}
        <Icons.ChevronDown color={"gray"} />
      </SelectedView>
      {open && (
        <OptionMenu>
          {groupedOptions.map((group) => (
            <OptionGroup key={group.group}>
              <p>{group.group}</p>
              {group.options.map((option) => (
                <OptionItem key={option.value}>
                  <Checkbox
                    name={option.value}
                    id={option.value}
                    onChange={() => addSelected(option)}
                    label={option.label}
                    defaultChecked={selected.includes(option)}
                  />
                </OptionItem>
              ))}
            </OptionGroup>
          ))}
        </OptionMenu>
      )}
    </Container>
  )
}

const Container = styled.div`
  min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
  max-width: 500px;
  position: relative;
`

const SelectedView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 3px 5px 10px;
  border: ${(props) =>
    props.open ? "1px solid #2969FF" : "1px solid #d7d7d5"}};
  border-radius: 5px;
  background-color: white;
  color: black;
  justify-content: space-between;
  cursor: pointer;
  overflow-x: hidden;

  svg {
    flex-shrink: 0;
    margin: 0px 5px;
  }

  box-shadow: ${(props) => (props.open ? "0 0 0 3px #BBDEFB" : "none")};
`

const SelectedOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  overflow-x: hidden;
`

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #d9d9d9;
  padding: 2px 10px;
  border-radius: 15px;
  max-width: 150px;
  gap: 5px;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  svg:hover {
    background-color: ${Colors.FerrariLight};
    border-radius: 100%;
  }
`

const OptionMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: max-content;
  background-color: white;
  border: 1px solid #d7d7d5;
  border-radius: 5px;
  z-index: 100;
  max-height: 500px;
  overflow-y: scroll;
`

const OptionGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ededef80;
  p {
    font-weight: 600;
    padding: 10px 0px;
    color: #495057;
    padding: 10px 10px;
  }
`

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px;
  background-color: white;

  label {
    display: flex;
    flex-direction: row;
    input {
      flex-shrink: 0;
      width: 20px;
      height: 20px;
    }
    p {
      font-weight: normal;
    }
  }
`

export default MultiSelect
