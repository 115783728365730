import React, { useCallback, useEffect, useState } from "react"
import Heading from "../../common/Heading"
import StakeholderCard from "./StakeholderCard"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import TextButton from "../../common/TextButton"
import axios from "axios"
import { ProjectColors } from "../../common/style/ProjectColors"
import { stakeholderDefaultValues } from "./Stakeholder.validationSchema"
import { mapStakeholderForInsight } from "./mapStakeholderForInsight"
import { Spinner } from "@flow/icons"
import { formatStakeholderFromState } from "./formatStakeholderFromState"
import { hasData } from "../../../util/returnValue"

const { v4: uuidv4 } = require("uuid")

const Stakeholders = ({
  t,
  flow,
  partialSave,
  setEditing,
  setCreditChecking,
  formData,
  setFormData,
  setInsightStakeholder,
  isCreditChecking,
  agreementsDataState,
}) => {
  const [stakeholders, setStakeholders] = useState(formData.guarantors)
  const [addStakeholderError, setAddStakeholderError] = useState(false)

  const updateStates = (formData, stakeholderData) => {
    setFormData(formData)
    setStakeholders(stakeholderData)
    setInsightStakeholder(mapStakeholderForInsight(stakeholderData))
  }

  useEffect(() => {
    const isEditing = stakeholders.some((x) => x.new || x.editing)
    const creditChecking = stakeholders.some((x) => x.creditChecking)
    setCreditChecking(creditChecking)
    setEditing(isEditing)
  }, [stakeholders, setCreditChecking, setEditing, formData])

  //Hente ut pending task slik at vi kan hente taskId på message-tasken som skal trigges
  const getTaskId = async (flowId) => {
    const res = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending`
    )
    const tasks = res?.data
    if (tasks.length > 0) {
      const pendingTask =
        tasks.find(
          (x) =>
            x.taskType === "fetch-new-stakeholder-credit-check" &&
            x.taskCategory === "message-task"
        ) || []
      return pendingTask.taskId
    }
  }
  const performCreditCheck = useCallback(
    async (id, stakeholder, ambita, bisnode, populationRegistry) => {
      try {
        if (ambita || bisnode || populationRegistry) {
          const flowId = flow?.flowId
          const taskId = await getTaskId(flowId)
          await axios.post(`/api/flow/tasks/${taskId}/trigger`, {
            guarantor: stakeholder,
            needsCreditCheck: bisnode,
            needsAmbitaLookup: ambita,
            needsPopulationRegistryLookup: populationRegistry,
          })
        }

        const creditCheckFinished = async () => {
          const flowId = flow?.flowId
          const res = await axios.get(`/api/flow/flows/${flowId}`)
          const flowData = res?.data?.data
          if (!updateCreditChecks(flowData, stakeholder, id)) {
            setTimeout(creditCheckFinished, 3000)
          }
        }
        setTimeout(creditCheckFinished, 3000)
      } catch (error) {
        console.error("Failed to trigger new credit check", error)
      }
    },
    []
  )

  const onChangeStakeholder = (id, stakeholder) => {
    const newFormData = { ...formData }
    newFormData.guarantors[id] = stakeholder
    if (
      stakeholder.fetchDataFromBisnode ||
      stakeholder.fetchDataFromAmbita ||
      stakeholder.fetchDataFromPopulationRegistry
    ) {
      performCreditCheck(
        id,
        stakeholder,
        stakeholder.fetchDataFromAmbita,
        stakeholder.fetchDataFromBisnode,
        stakeholder.fetchDataFromPopulationRegistry
      )
      stakeholder.fetchDataFromAmbita = false
      stakeholder.fetchDataFromBisnode = false
      stakeholder.fetchDataFromPopulationRegistry = false
      stakeholder.creditChecking = true
    }
    partialSave(newFormData)
    updateStates(newFormData, newFormData.guarantors)
  }

  const updateCreditChecks = (flowData, stakeholder, id) => {
    const formattedStakeholderFromState = formatStakeholderFromState(
      flowData.analysis?.initialGuarantors[stakeholder.id],
      stakeholder.id,
      false,
      false,
      false
    )
    const currentStakeholder = stakeholders[id]

    const pendingCreditCheckAmbita = flowData?.pendingCreditCheck?.ambita
    const pendingCreditCheckBisnode = flowData?.pendingCreditCheck?.bisnode
    const pendingCreditCheckNationalPopulationRegistry =
      flowData?.pendingCreditCheck?.populationRegistry

    const isCreditChecking = currentStakeholder.creditChecking

    let newStakeholder = { ...currentStakeholder, editing: false }

    // If trying to credit check and there is no pending check, stop checking
    if (
      isCreditChecking &&
      !pendingCreditCheckBisnode &&
      !pendingCreditCheckAmbita &&
      !pendingCreditCheckNationalPopulationRegistry
    ) {
      newStakeholder = {
        ...newStakeholder,
        ...formattedStakeholderFromState,
        name: formattedStakeholderFromState?.name || newStakeholder.name,
        address:
          formattedStakeholderFromState?.address || newStakeholder.address,
        legalName: formattedStakeholderFromState?.legalName || {},
        dateOfBirth: formattedStakeholderFromState?.dateOfBirth || {},
        ownedCadastres: formattedStakeholderFromState?.ownedCadastres,
      }
      onChangeStakeholder(id, newStakeholder)
      return true
    }
    onChangeStakeholder(id, newStakeholder)
    return false
  }

  useEffect(() => {
    stakeholders.map((stakeholder, i) => {
      if (!stakeholder.creditChecking) return i
      const creditCheckFinished = async () => {
        const flowId = flow?.flowId
        const res = await axios.get(`/api/flow/flows/${flowId}`)
        const flowData = res?.data?.data
        if (!updateCreditChecks(flowData, stakeholder, i)) {
          setTimeout(creditCheckFinished, 3000)
        }
      }
      setTimeout(creditCheckFinished, 3000)
      return i
    })
  }, [])

  const addNewStakeholder = () => {
    const newFormData = { ...formData }
    newFormData.guarantors.unshift({
      ...stakeholderDefaultValues,
      new: true,
      id: uuidv4(),
      type: "person",
      fetchDataFromPopulationRegistry: true,
    })
    updateStates(newFormData, newFormData.guarantors)
  }

  const commitStakeholder = (index, stakeholder) => {
    let hasExistingStakeholder = true
    // If data is not complete, the stakeholder was just added
    // and we should not check for existing stakeholders
    if (!stakeholder.ssn && !stakeholder.organizationNumber) {
      hasExistingStakeholder = false
    } else {
      const existingStakeholder = stakeholders
        .filter((x) => !x.new)
        .find((x) => {
          if (x.type === "person") {
            return x.ssn === stakeholder.ssn
          }
          if (x.type === "organization") {
            return x.organizationNumber === stakeholder.organizationNumber
          }
          return true
        })
      hasExistingStakeholder = hasData(existingStakeholder)
    }

    const ambita = stakeholder.fetchDataFromAmbita
    const bisnode = stakeholder.fetchDataFromBisnode
    const populationRegistry = stakeholder.fetchDataFromPopulationRegistry
    const newFormData = { ...formData }

    if (hasExistingStakeholder) {
      setAddStakeholderError(true)
      return
    }
    newFormData.guarantors[index] = stakeholder
    partialSave(newFormData)
    delete stakeholder.new
    if (
      (stakeholder.type === "person" || stakeholder.scope === "domestic") &&
      (ambita || bisnode || populationRegistry)
    ) {
      stakeholder.fetchDataFromAmbita = false
      stakeholder.fetchDataFromBisnode = false
      stakeholder.fetchDataFromPopulationRegistry = false
      stakeholder.creditChecking = true
      performCreditCheck(
        index,
        stakeholder,
        ambita,
        bisnode,
        populationRegistry
      )
    }
    updateStates(newFormData, newFormData.guarantors)
    setAddStakeholderError(false)
  }

  const removeStakeholder = (index) => {
    const newFormData = { ...formData }
    newFormData.guarantors.splice(index, 1)
    updateStates(newFormData, newFormData.guarantors)
    setAddStakeholderError(false)
  }
  const canAddStakeholder = stakeholders.every((x) => !x.new)

  const existsOnCollateralAgreementOrObject = (id, agreementData) => {
    const { collateralAgreements, collateralObjects } = agreementData
    const existsOnAgreement = collateralAgreements.some((agreement) =>
      (agreement?.basicCollateralAgreementInformation?.mortgagors || []).some(
        (mortgagor) => mortgagor?.internalId === id
      )
    )

    const existsOnObject = collateralObjects.some((object) =>
      (object?.basicCollateralObjectInformation?.owners || []).some(
        (owner) => owner?.ownerId === id
      )
    )

    return existsOnAgreement || existsOnObject
  }

  return (
    <Content>
      <SplitLine>
        <Heading>{t("stakeholders")}</Heading>
        {canAddStakeholder && (
          <TextButton onClick={() => !isCreditChecking && addNewStakeholder()}>
            <AddStakeholderText isCreditChecking={isCreditChecking}>
              {isCreditChecking ? (
                <>
                  <Spinner size={20} color={Colors.Grey2} />
                  {" " + t("cannot-add-new-stakeholder-while-credit-checking")}
                </>
              ) : (
                "+ " + t("add-stakeholder")
              )}
            </AddStakeholderText>
          </TextButton>
        )}
      </SplitLine>
      <StakeholderContainer>
        {stakeholders.map((x, i) => {
          const allowDeletion =
            agreementsDataState &&
            x?.editing &&
            x?.cancelDisabled &&
            !existsOnCollateralAgreementOrObject(
              x.id,
              agreementsDataState?.collateral
            )
          return (
            <StakeholderCard
              t={t}
              stakeholder={x}
              key={x.id}
              hasEdit
              onChange={(stakeholder) => onChangeStakeholder(i, stakeholder)}
              color={ProjectColors.Sea97}
              onAdd={(stakeholder) => commitStakeholder(i, stakeholder)}
              onCancel={() => removeStakeholder(i)}
              isCreditChecking={isCreditChecking}
              maritalStatus={
                flow.data.analysis.maritalStatusOnApplicant?.maritalStatus
              }
              allowDeletion={allowDeletion}
              addStakeholderError={x?.new ? addStakeholderError : false}
            />
          )
        })}
      </StakeholderContainer>
    </Content>
  )
}

const AddStakeholderText = styled.div`
  ${FontStyles.Large};
  color: ${({ isCreditChecking }) =>
    isCreditChecking ? Colors.Grey2 : Colors.Sea};
`

const SplitLine = styled.div`
  display: flex;
  justify-content: space-between;
`

const Content = styled.div`
  height: 100%;
  width: 90%;
  padding: 10px 10px 10px 50px;
  padding-bottom: 30px;
`

const StakeholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

export default Stakeholders
