import React from "react"
import ErrorContext from "../../common/Error"
import Select from "react-select"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"
import { Colors } from "@flow/style"

const MultiSelect = ({
  onChange,
  onBlur,
  name,
  value,
  errors,
  t,
  selectOptions,
  styling,
  ...rest
}) => {
  const error = errors[name]?.message
  return (
    <>
      <StyledSelect
        styling={styling}
        onBlur={onBlur}
        id={name}
        name={name}
        key={name}
        placeholder={t("select-placeholder")}
        isMulti
        value={value}
        options={selectOptions}
        onChange={onChange}
        isSearchable={false}
        menuPlacement={"top"}
      />
      <ErrorContext t={t} error={error} />
    </>
  )
}

export default MultiSelect

const StyledSelect = styled(Select)`
  padding-top: ${(props) =>
    props?.styling?.conditionallyRendered ? "1em" : "0"};
  min-width: 18.5em;
  max-width: 18em;
  font-size: small;

  span {
    padding: 0;
    margin: 3px 2px 3px 0;
    font-size: small;
  }

  svg {
    max-height: 70%;
    max-width: 70%;
    padding: 0em 0.2em 0 0.2em;
    margin: 0;
    color: ${Colors.Grey1};
  }

  div[class*="control"] {
    border-radius: 5px;

    border: solid 1px;
    border-color: ${ProjectColors.InputBorder};
    outline: none;
    box-shadow: none;
    color: inherit;
    min-height: 1.5em;
    padding: 1px 0 0 4px;
    margin: 0;

    div {
      min-height: inherit;
      max-height: inherit;
      padding: 0;
      margin: 0;
    }

    &:hover,
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: inherit;
    }
  }

  div[class*="ValueContainer"] {
    text-transform: none;
    border: none;
    font-size: medium;
    display: flex;

    div[class*="placeholder"] {
      display: flex;
      padding-left: 0.5em;
      font-size: small;
    }

    div[class*="multiValue"] {
      margin: 0.1em;
      background-color: ${Colors.Grey3};
      display: flex;
      align-items: center;
      div:last-of-type {
        height: inherit;
        min-width: 20px;
        max-width: 20px;
        :hover {
          background-color: ${Colors.Grey2};
        }

        svg {
          color: ${Colors.Grey1};
          width: inherit;
          height: inherit;
        }
      }
    }
  }

  div[class*="menu"] {
    max-height: 400px;
  }
`
