import React from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import StyledBox from "../../components/common/StyledBox"
import Notice from "../../components/common/Notice"
import { FontStyles } from "@flow/style"
import DisplayPayouts from "./DisplayPayouts"
import { TextArea } from "@flow/forms"

const explanationLookup = (annulmentTrigger, delivery, t) => {
  const delayPayout = delivery?.delayPayout || {}
  if (delayPayout?.delayPayout === "NO") {
    return delivery?.delayPayout.assessmentComment
  }
  return annulmentTrigger === "annulment-requested"
    ? delivery?.annulment?.annulmentRequested?.comment
    : delivery?.annulment?.companyBankruptcy?.comment || t("no-comment")
}

const DisplayAnnulment = ({ stateData, setStateData, delivery, t }) => {
  let type =
    t("manual-handling") + " - " + t(delivery?.annulment?.annulmentTrigger)

  if (!delivery?.annulment?.annulmentTrigger?.length) {
    type = t("auto-handling") + " - " + t("last-possible-payout-date")
  }

  const payoutList = delivery?.payOuts || []

  const commitment = delivery?.annulment?.commitment?.accountByAccountName
  const annulmentTrigger = delivery?.annulment?.annulmentTrigger
  const information = [
    {
      title: t("amount-available"),
      value: commitment?.availableAmount,
    },
    {
      title: t("amount-approved"),
      value: delivery?.amount,
    },
    {
      title: t("amount-paid"),
      value: commitment?.payoutAmount,
    },
  ]

  return (
    <div>
      <ReasonWrapper>
        <LabelSpan>{t("cause")}</LabelSpan>
        <ContentStyle>
          <StyledBox title={t("type")} data={type} />
          <StyledBox
            title={t("explanation")}
            data={explanationLookup(annulmentTrigger, delivery, t)}
          />
        </ContentStyle>
      </ReasonWrapper>
      <Wrapper>
        <LabelSpan>{t("delivery-details")}</LabelSpan>
        <Content>
          {information.map((x, i) => (
            <StyledBox key={i} title={x.title} data={x.value} />
          ))}
        </Content>
      </Wrapper>
      <Wrapper>
        <LabelSpan>{t("payouts")}</LabelSpan>
        <DisplayPayouts payouts={payoutList} t={t} />
      </Wrapper>
      <TextWrapper>
        <LabelSpan>{t("internal-comment")}</LabelSpan>
        <TextAreaBox
          label={t("comment")}
          help={t("internal-comment-help")}
          value={stateData?.comment}
          onChange={(e) =>
            setStateData({ ...stateData, comment: e.target.value })
          }
          name="internal-comment"
        />
      </TextWrapper>
    </div>
  )
}
export default withTranslation()(DisplayAnnulment)

const TextAreaBox = styled(TextArea)`
  width: 100%;
  height: 150px;
`

const TextWrapper = styled.div`
  margin-top: 1.5em;
  max-width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;
`

const ReasonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const LabelSpan = styled.div`
  ${FontStyles.Normal};
  margin-bottom: 5px;
`
const ContentStyle = styled(Notice)`
  display: flex;
  flex-direction: column;
`

const Content = styled(Notice)`
  display: flex;
`
