import React from "react"
import { GeneratedForm } from "@flow/form-generator"
import styled from "styled-components"

const ReactForm = (props) => {
  const children = props.children
  const formsProps = { ...props, children: children ? <></> : null }
  return (
    <GeneratedFormStyle>
      <GeneratedForm key={props.rerenderId} {...formsProps}>
        {children}
      </GeneratedForm>
    </GeneratedFormStyle>
  )
}

const GeneratedFormStyle = styled.div`
  #root {
    padding: 0 !important;
    margin-left: -1px;
  }

  textarea {
    width: 100%;
  }

  select {
    width: 100%;
  }

  input[type="checkbox"] {
    min-width: 15px;
  }
`

export default ReactForm
