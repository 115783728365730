import React, { useState } from "react"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import { withTranslation } from "react-i18next"
import Tabs from "../components/common/Tabs"
import { Colors } from "@flow/style"
import ReactForm from "../components/common/ReactForm"
import ErrorText from "../components/common/ErrorText"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import DownloadFileButton from "../components/common/DownloadFileButton"
import AssessmentComments from "../components/common/AssessmentComments"
import { parseCommentsForAssessment } from "../components/utils/mapMaintenance"

const DownloadDecisionLetter = ({ t, decisionText }) => {
  return (
    <Item>
      <p>{t("download-decision-letter")}</p>
      <StyledDownload>
        <DownloadFileButton
          title={t(decisionText?.filename)}
          fileId={decisionText?.storeID}
          key={decisionText?.storeID}
        />
      </StyledDownload>
    </Item>
  )
}

const DoCustomerFollowUp = ({
  task,
  flow,
  t,
  schema,
  save,
  complete,
  user,
}) => {
  const { data, context } = task

  const [formData, setFormData] = useState(data || {})
  const [isProcessing, setProcessing] = useState(false)
  const [error, setError] = useState()

  const decisionPdf = flow.data.files.filter(
    (x) => x.documentType === "Vedtaksbrev" && x.documentGroup === "Archive"
  )

  const handleComplete = () => {
    setError()
    setProcessing(true)
    complete(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const parsedComments = parseCommentsForAssessment(context, t)
  const comments = [
    ...(flow?.data?.assessmentComments ?? []),
    ...parsedComments,
  ]

  const options = [
    {
      id: "decisiontexts",
      title: t("decisionTexts"),
      component: (
        <>
          {decisionPdf.length > 0 ? (
            decisionPdf?.map((x) => {
              return <DownloadDecisionLetter t={t} decisionText={x} />
            })
          ) : (
            <p>Ingen vedtakstekst dokumenter i arkiv</p>
          )}
        </>
      ),
    },
    {
      id: "comments",
      title: t("assessment-history"),
      component: (
        <AssessmentComments
          comments={comments}
          t={t}
          assignee={flow?.assignee?.name}
        />
      ),
    },
  ]

  return (
    <Layout forceHeight>
      <MainContent>
        <Tabs defaultTab="decisiontexts" options={options} />
      </MainContent>
      <Context context={context.applicationSummary} flow={flow}>
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing}
            onChange={(data) => setFormData(data)}
            onSubmit={handleComplete}
          >
            {error && <ErrorText error={error} t={t} />}
            <ButtonContainer>
              <PrimaryButton type="submit" isLoading={isProcessing}>
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={() => handleSave()}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}

const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`

const StyledDownload = styled.div`
  color: ${Colors.Grey1};
  padding: 0.1em 0.1em 0.1em 0;
  font-size: small;
  line-height: 0.8em;
  width: 100%;

  button {
    white-space: pre-wrap;
    width: 100%;
  }
  svg {
    display: none;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
  padding-top: 1.5em;
  overflow-x: hidden;
`

export default withTranslation("maintenance")(DoCustomerFollowUp)
