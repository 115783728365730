import React, { useState, useEffect, useCallback, useMemo } from "react"
import styled from "styled-components"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import Layout, { Context } from "../components/common/Layout"
import lodash from "lodash"
import { returnFirstArgWithValue } from "../util/returnValue"
import ErrorText from "../components/common/ErrorText"
import Tabs from "../components/common/Tabs"
import DecisionTextsList, {
  DownloadDecisionLetter,
} from "../components/maintenance/DecisionTextsList"
import { FormProvider, useForm } from "react-hook-form"
import SpecialTermsMaintenance from "../components/maintenance/SpecialTermsMaintenance"
import AssessAcceptanceComponent from "../components/maintenance/AssessAcceptanceComponent"
import DisplaySecurityText from "../components/TermsAndSecurity/DisplaySecurityText"
import { mapSpecialTerms } from "../components/utils/mapSpecialTerms"

const AssessAcceptance = ({ task, flow, t, schema, save, complete, user }) => {
  const { context } = task
  const [isProcessing, setIsProcessing] = useState(false)
  const [validations, setValidations] = useState([])
  const [signers, setSigners] = useState(
    returnFirstArgWithValue(task.data?.signers, context.stateData?.signers) ||
      []
  )
  const [activeTab, setActiveTab] = useState("assessacceptance")
  const [needsAcceptance, setNeedsAcceptance] = useState(
    task.data?.needsAcceptance !== undefined
      ? task.data.needsAcceptance
      : context.stateData.needsAcceptance
  )

  const decisionTexts = useMemo(
    () => flow?.data?.deliveries?.vedlikehold?.changes,
    [flow?.data?.deliveries?.vedlikehold?.changes]
  )

  const methods = useForm({ defaultValues: task?.data })

  const specialTerms = useMemo(() => {
    return mapSpecialTerms(context.stateData?.specialTerms)
  }, [context.stateData])

  const getTaskId = async (flowId) => {
    const res = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending`
    )
    const tasks = res?.data
    if (tasks.length > 0) {
      const pendingTask = tasks.find(
        (x) =>
          x.taskType === "fetch-signer-details" &&
          x.taskCategory === "message-task"
      )
      return pendingTask?.taskId
    }
  }

  const updateSignerChecked = (flowData, originalSigner) => {
    // This can either be a new signer, an updated signer or undefined, it's undefined if the message task failed because of invalid data.
    const signer = flowData.analysis.maintenance.signers.find(
      (s) => s.id === originalSigner.id
    )

    const pendingSignerCheck = flowData?.pendingSignerCheck

    if (!pendingSignerCheck && signer) {
      const updatedSigners = lodash.cloneDeep(signers)
      let signerIndex = updatedSigners.findIndex(
        (s) => s.id === originalSigner.id
      )
      if (signerIndex === -1) {
        // New signer has been added
        updatedSigners.push(signer)
      } else {
        // Signer has been edited
        updatedSigners[signerIndex] = {
          ...updatedSigners[signerIndex],
          ...signer,
        }
      }
      setSigners(updatedSigners)
      save(
        {
          signers: updatedSigners,
        },
        undefined,
        undefined,
        false // Don't close the modal when saving
      )
      setIsProcessing(false)
      return true
    }

    if (!signer && !pendingSignerCheck) {
      // Cleanup local state, signer was added previously for UX, but the message task failed because of invalid data
      const updatedSigners = signers.filter(
        (signer) => signer.id !== originalSigner.id
      )
      setIsProcessing(false)
      setSigners(updatedSigners)
      return true
    }
    setIsProcessing(false)
    return false
  }

  const fetchSignerDetails = useCallback(
    async (signer) => {
      try {
        const flowId = flow?.flowId
        const taskId = await getTaskId(flowId)
        await axios.post(`/api/flow/tasks/${taskId}/trigger`, {
          signer: signer,
          isPerson: signer.type === "person",
        })

        const signerCheckFinished = async () => {
          const flowId = flow?.flowId
          const res = await axios.get(`/api/flow/flows/${flowId}`)
          const flowData = res?.data?.data
          // If fetching data is not done, wait 3 seconds and try again
          if (!updateSignerChecked(flowData, signer)) {
            setTimeout(signerCheckFinished, 3000)
          }
        }
        setTimeout(signerCheckFinished, 3000)
      } catch (error) {
        console.error("Failed to trigger fetching signer details", error)
      }
    },
    [signers]
  )

  const handleSave = () => {
    setIsProcessing(true)
    save(
      {
        signers: signers,
        needsAcceptance: needsAcceptance,
      },
      () => {
        setIsProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setIsProcessing(false)
      }
    )
  }

  const handleComplete = () => {
    setIsProcessing(true)
    // Check every signer of type person has email
    const hasMissingData = signers.some(
      (signer) => signer.type === "person" && !signer.email
    )
    const validations = []
    if (hasMissingData) {
      validations.push("signers-missing-email")
    }

    if (validations.length > 0) {
      setValidations(validations)
      setIsProcessing(false)
      return
    }

    complete(
      {
        signers: signers,
        needsAcceptance: needsAcceptance,
      },
      () => {
        setIsProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setIsProcessing(false)
      }
    )
  }

  const handleEdit = (editedSigner) => {
    setIsProcessing(true)
    const updatedSigners = signers.map((signer) => {
      if (signer.id === editedSigner.id) {
        return editedSigner
      }
      return signer
    })
    setSigners(updatedSigners)

    if (editedSigner.isFetchingSignerDetails) {
      fetchSignerDetails(editedSigner)
    } else {
      setIsProcessing(false)
    }
  }

  const handleAdd = (addedSigner) => {
    setIsProcessing(true)
    if (signers.find((signer) => signer.id === addedSigner.id)) return

    // fetchSignerDetails also adds the signer to the list of signers, but we do it here as well, in order to give better UX (Spinner when fetching data)
    const updatedSigners = [...signers, addedSigner]
    setSigners(updatedSigners)

    if (addedSigner.isFetchingSignerDetails) {
      fetchSignerDetails(addedSigner)
    } else {
      setIsProcessing(false)
    }
  }

  const handleDelete = (deletedSigner) => {
    const updatedSigners = signers.filter(
      (signer) => signer.id !== deletedSigner.id
    )
    setSigners(updatedSigners)
  }

  const currentUser = user.profile?.name
  const options = [
    {
      id: "assessacceptance",
      title: t("assessacceptance"),
      component: (
        <AssessAcceptanceComponent
          needsAcceptance={needsAcceptance}
          setNeedsAcceptance={setNeedsAcceptance}
          context={context}
          signers={signers}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          t={t}
        />
      ),
    },
    {
      id: "decisiontexts",
      title: t("decisionTexts"),
      component: <DecisionTextsList t={t} changes={decisionTexts} />,
    },
    {
      id: "specialterms",
      title: t("specialTerms"),
      component: (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleComplete)}>
            <SpecialTermsWrapper>
              <SpecialTermsMaintenance
                data={specialTerms}
                t={t}
                currentUser={currentUser}
                readOnly={true}
              />
            </SpecialTermsWrapper>
          </form>
        </FormProvider>
      ),
    },
    {
      id: "securityTexts",
      title: "Sikkerhetstekster",
      component: (
        <SecurityWrapper>
          {flow?.data?.deliveries["vedlikehold"]?.securityTexts?.length > 0 ? (
            flow?.data?.deliveries["vedlikehold"]?.securityTexts?.map(
              (securityText, securityTextIndex) => {
                return (
                  <DisplaySecurityText
                    t={t}
                    key={securityTextIndex}
                    isEmpty={!securityText?.subtitle?.length}
                    isFirst={securityTextIndex === 0}
                    securityText={securityText}
                    userGenerated={securityText?.userGenerated}
                    isReadOnly={true}
                  />
                )
              }
            )
          ) : (
            <p>{t("no-security-text")}</p>
          )}
        </SecurityWrapper>
      ),
    },
  ]

  return (
    <Layout forceHeight>
      <MainContent>
        <Tabs
          defaultTab="assessacceptance"
          options={options}
          onChange={(tab) => setActiveTab(tab)}
        />
      </MainContent>

      <Context context={context.applicationSummary} flow={flow}>
        {validations &&
          validations.map((x, i) => (
            <ErrorTextStyle key={i}>
              <ErrorText error={x} t={t}></ErrorText>
            </ErrorTextStyle>
          ))}
        <ButtonContainer>
          <PrimaryButton
            onClick={() => handleComplete()}
            type="submit"
            isLoading={isProcessing}
            disabled={isProcessing}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            disabled={isProcessing}
            onClick={() => handleSave()}
          >
            {t("save")}
          </SecondaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  gap: 10px;
`

const ErrorTextStyle = styled.div`
  margin-bottom: 1.5em;
`

const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow: scroll;
`
const SpecialTermsWrapper = styled.div`
  margin-top: 1.5em;
  max-width: 100%;
`
const SecurityWrapper = styled.div`
  margin-top: 1.5em;
  width: 95%;
`
export default withTranslation("maintenance")(AssessAcceptance)
