const searchFieldsVekstgaranti = [
  {
    name: "applying-bank",
    value: "data.application.applyingBank.name",
    queryOperator: "contains",
    process: ["vekstgaranti"],
  },
  {
    name: "guarantee-type",
    value: "data.application.applyingCustomer.transactionType",
    queryOperator: "contains",
    process: ["vekstgaranti"],
  },
  {
    name: "guarantee-amount-applied-for",
    value: "data.mapped.application.amountString",
    process: ["vekstgaranti"],
  },
  {
    name: "start-up-cost-and-guarantee-provision-total",
    value:
      "data.mapped.application.startUpCostAndGuaranteeProvisionTotalString",
    process: ["vekstgaranti"],
  },
]

const searchResultFieldVekstgaranti = [
  {
    name: "applying-bank",
    value: "data.application.applyingBank.name",
    process: ["vekstgaranti"],
  },
  {
    name: "guarantee-type",
    value: "data.application.applyingCustomer.transactionType",
    process: ["vekstgaranti"],
  },
  {
    name: "guarantee-amount-applied-for",
    value: "data.application.transactionDetails.amount",
    process: ["vekstgaranti"],
  },
  {
    name: "start-up-cost-and-guarantee-provision-total",
    value: "data.mapped.application.startUpCostAndGuaranteeProvisionTotal",
    process: ["vekstgaranti"],
  },
]

const getCategoriesForSearch = (type = "application") => {
  if (type === "maintenance") {
    return {
      MAINTENANCE: "maintenance",
    }
  }

  //Disse kategoriene tilhører "søknad-prosesen"
  //Vekstgaranti er ikke inkludert fordi det har en egen prosess
  return {
    EXTRAORDINARY_FINANCING: "extraordinaryfinancing",
    STARTUP: "startup",
    STARTUPMATIL: "startupmatil",
    AGRICULTURE: "agriculture",
    EXPORTPROMOTION: "exportpromotion",
    AGRICULTURE_ADDITIONAL_BUSINESS: "agricultureadditionalbusiness",
    BIOENERGY: "bioenergy",
    CLUSTER: "cluster",
    DSF: "shippingandfishery",
    DNI: "businessdevelopmentandinnovation",
    HIP: "humanitarianinnovationproject",
  }
}

const categoriesConstantsApplication = getCategoriesForSearch("application")
const categoriesConstantsMaintenance = getCategoriesForSearch("maintenance")
// Generate categories with names
const createLabelAndValue = (object) => {
  return Object.values(object).map((value) => ({
    label: value,
    value: value,
  }))
}
const categoriesApplication = createLabelAndValue(
  categoriesConstantsApplication
)
const categoriesMaintenance = createLabelAndValue(
  categoriesConstantsMaintenance
)

const searchFieldsApplication = [
  {
    name: "organization-number",
    value: "data.raw.accountMaster.customerAccount.publicId",
    process: ["application", "maintenance"],
  },
  {
    name: "application-name",
    value: "data.raw.applicationMaster.currentApplication.projectName",
    queryOperator: "contains",
    process: ["application", "maintenance"],
  },
  {
    name: "applicant-email",
    value: "data.raw.accountMaster.customerContactApplication.email",
    queryOperator: "contains",
    process: ["application", "maintenance"],
  },
  {
    name: "applicant-phone",
    value: "data.raw.accountMaster.customerContactApplication.mobilePhone",
    queryOperator: "contains",
    process: ["application", "maintenance"],
  },
  {
    name: "category",
    value: "data.application.category",
    type: "select",
    options: categoriesApplication,
    process: ["application"],
  },
  {
    name: "category-maintenance",
    value: "data.application.category",
    type: "select",
    options: categoriesMaintenance,
    process: ["maintenance"],
  },
]

const searchResultFieldsApplication = [
  {
    name: "application-name",
    value: "data.raw.applicationMaster.currentApplication.projectName",
    process: ["application", "maintenance"],
  },
  {
    name: "organization-number",
    value: "data.raw.accountMaster.customerAccount.publicId",
    process: ["application", "maintenance"],
  },
  {
    name: "applicant-email",
    value: "data.raw.accountMaster.customerContactApplication.email",
    process: ["application", "maintenance"],
  },
  {
    name: "applicant-phone",
    value: "data.raw.accountMaster.customerContactApplication.mobilePhone",
    process: ["application", "maintenance"],
  },
  {
    name: "category",
    value: "data.application.category",
    process: ["application"],
  },
  {
    name: "category-maintenance",
    value: "data.application.category",
    process: ["maintenance"],
  },
]

const searchResultFieldsPayoutControl = [
  {
    name: "payouts-from",
    value: "data.searchDate",
    process: ["payout-control"],
  },
]

export const searchFields = [
  ...searchFieldsVekstgaranti,
  ...searchFieldsApplication,
  {
    name: "applying-customer",
    value: "data.raw.accountMaster.customerAccount.name",
    queryOperator: "contains",
    process: ["vekstgaranti", "application", "maintenance"],
  },
]
export const searchResultFields = [
  {
    name: "applying-customer",
    value: "data.raw.accountMaster.customerAccount.name",
    process: ["application", "vekstgaranti", "maintenance"],
  },
  ...searchResultFieldVekstgaranti,
  ...searchResultFieldsApplication,
  ...searchResultFieldsPayoutControl,
  {
    name: "created-at",
    value: "createdAt",
    process: ["application", "vekstgaranti", "payout-control", "maintenance"],
  },
  {
    name: "application-status",
    value: "data.businessStatus",
    process: ["application", "vekstgaranti", "maintenance"],
  },
]
