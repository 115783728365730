import React from "react"
import styled from "styled-components"
import { Tabs } from "@flow/components"
import MyAssessment from "./MyAssessment"
import BisnodeKeyInformation from "./BisnodeKeyInformation"
import BisnodeAMLLookup from "./BisnodeAMLLookup"
import CaseCommentThreads from "./CaseCommentThread"
import { useState } from "react"
import BlueDot from "../common/BlueDot"
import AssessmentComments from "../common/AssessmentComments"

const IddTabs = ({ taskData, setTaskData, flow, t, isTeam, isValid }) => {
  const [activeTab, setActiveTab] = useState("my-assessment")
  const decisionCount = flow?.data?.assessmentComments?.length ?? 0
  const tabOptions = [
    {
      id: "my-assessment",
      title: t("my-assessment"),
      component: (
        <MyAssessment
          isValid={isValid}
          isTeam={isTeam}
          taskData={taskData}
          setTaskData={setTaskData}
          flow={flow}
          t={t}
        />
      ),
    },
    {
      id: "bisnode-aml-data",
      title: t("bisnode-aml-data"),
      component: (
        <BisnodeKeyInformation
          taskData={taskData}
          setTaskData={setTaskData}
          flow={flow}
          t={t}
        />
      ),
    },
    {
      id: "bisnode-aml-lookup",
      title: t("bisnode-aml-lookup"),
      component: (
        <BisnodeAMLLookup
          data={flow.data?.raw?.integrityDueDilligence?.performPepScreening}
          t={t}
        />
      ),
    },
    {
      id: "comments",
      title: t("case-comments"),
      component: (
        <CaseCommentThreads
          isTeam={isTeam}
          taskData={taskData}
          setTaskData={setTaskData}
          flow={flow}
          t={t}
        />
      ),
    },
    {
      id: "decisions",
      title: t("assessment-history"),
      children: decisionCount ? <BlueDot>{decisionCount}</BlueDot> : undefined,
      component: (
        <AssessmentComments
          comments={flow?.data?.assessmentComments}
          t={t}
          assignee={flow?.assignee?.name}
        />
      ),
    },
  ]
  const tab = tabOptions.find((x) => x.id === activeTab)

  return (
    <Content>
      <Tabs options={tabOptions} onChange={(e) => setActiveTab(e)} />
      <TabContainer>{tab.component}</TabContainer>
    </Content>
  )
}

export default IddTabs

const Content = styled.div`
  flex: 1;
  padding: 10px 40px;
  overflow-y: auto;
`
const TabContainer = styled.div`
  margin: 20px 0;
  flex: 1;
`
