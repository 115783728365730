const decisionLevels = {
  CREDIT_SECTION: "Seksjon",
  CREDIT_LEVEL_1: "DK - Nivå1",
  CREDIT_LEVEL_2: "DK - Nivå2",
  CREDIT_DIVISION: "Divisjon",
  MAIN_CREDIT: "IN Hovedkredittutvalg",
  BOARD: "IN Hovedstyre",
}

export { decisionLevels }
