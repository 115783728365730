import styled from "styled-components"
import { Colors } from "@flow/style"

const Notice = styled.div`
  background-color: ${(props) => props.backgroundColor || Colors.SeaLightest};
  padding: 10px;
  border-radius: 15px;
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  border: ${(props) =>
    `${props.border?.size} ${props.border?.style} ${props.border?.color}` ||
    props.backgroundColor ||
    Colors.SeaLightest};
`

export default Notice
