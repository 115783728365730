import React from "react"
import { SecondaryButton } from "@flow/buttons"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import axios from "axios"
import TaskContext from "../common/TaskContext"
import { Colors } from "@flow/style"

const MaintenanceTaskContext = ({ context, t, children }) => {
  return (
    <>
      <TaskContext data={context} />
      <ContextComponent>{children}</ContextComponent>
    </>
  )
}

const ContextComponent = styled.div`
  width: 100%;
`

export default withTranslation()(MaintenanceTaskContext)
