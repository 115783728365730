import styled from "styled-components"
import { Colors } from "@flow/style"

const BisnodeKeyInformation = ({ flow, t }) => {
  const { screenedPersons, screenedCompanies, summary } =
    flow.data?.integrityDueDilligence?.screeningSummary || {}

  const persons = [
    ...(screenedPersons?.personShareholders || []),
    ...(screenedPersons?.ownersAndBoardMembers || []),
  ]
  const companies = [
    screenedCompanies?.applyingCompany,
    ...(screenedCompanies?.companyShareholders || []),
    ...(screenedCompanies?.subsidiaries || []),
  ]

  return (
    <Layout>
      <Section>
        <Full>
          <Flat>
            <Title>{t("summary", "Oppsummering")}</Title>
          </Flat>
          <StyledList>
            <li key={"hits-pep"}>
              <label>{t("hits-pep")}</label>
              <label>
                <p>{summary?.noOfPepMatches}</p>
              </label>
            </li>
            <li key={"hits-blacklist-surveillance-list"}>
              <label>{t("hits-blacklist-surveillance-list")}</label>
              <label>
                <p>{summary?.noOfWlblMatches}</p>
              </label>
            </li>
            <li key={"hits-sanction"}>
              <label>{t("hits-sanction")}</label>
              <label>
                <p>{summary?.noOfSanctionMatches}</p>
              </label>
            </li>
          </StyledList>
        </Full>
      </Section>
      <TopSection>
        <Section>
          <Flat>
            <Title>{t("screened-persons")}</Title>
          </Flat>
          {persons?.map((person, i) => {
            // Prefix key with P/C to make sure it is unique
            return (
              <ScreenedContainer key={`P${i}`}>
                <ScreenedColumn>
                  <p>{person?.name}</p>
                  <p>{person?.ssn}</p>
                </ScreenedColumn>
                <ScreenedColumn>
                  <p>{t(person?.role)}</p>
                  <HitContainer>
                    {person?.pepResults
                      ? `${person?.pepResults} ${t("pep-match")}`
                      : ""}
                  </HitContainer>
                </ScreenedColumn>
              </ScreenedContainer>
            )
          })}
        </Section>
        <Section>
          <Flat>
            <Title>{t("screened-companies")}</Title>
          </Flat>
          {companies?.map((company, i) => {
            // Prefix key with P/C to make sure it is unique
            return (
              <ScreenedContainer key={`C${i}`}>
                <ScreenedColumn>
                  <p>{company?.name}</p>
                  <p>{company?.regNo}</p>
                </ScreenedColumn>
                <ScreenedColumn>
                  <p>{t(company?.role)}</p>
                  <HitContainer>
                    {company?.hasSanctionMatch
                      ? `${company?.hasSanctionMatch} ${t("sanction-match")}`
                      : ""}
                  </HitContainer>
                </ScreenedColumn>
              </ScreenedContainer>
            )
          })}
        </Section>
      </TopSection>
    </Layout>
  )
}

const Layout = styled.div`
  color: ${Colors.Grey1};
`
const Full = styled.div`
  width: 100%;

  > * {
    margin-bottom: 16px;
  }
`

const Flat = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`
const Title = styled.label`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 10px;
`
const StyledList = styled.ol`
  padding-left: 0;

  li {
    height: 38px;
    border-bottom: 1px solid #d2d7d5;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    align-items: center;

    label:last-child {
      display: grid;
      grid-template-columns: 10fr 0.5fr;
      grid-gap: 2px;
      align-items: center;
      text-align: end;
    }
  }
`
const ScreenedContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  background-color: ${Colors.Grey4};
  border: 1px solid ${Colors.SeaLighter};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  align-items: center;
  min-height: 50px;

  div:last-child {
    text-align: end;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
`
const TopSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  align-items: start;
`
const ScreenedColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    p {
      &:first-child {
        font-weight: bold;
      }
    }
  }
`

const HitContainer = styled.p`
  color: ${Colors.Ferrari};
`

export default BisnodeKeyInformation
