import {
  useFieldArray,
  useForm,
  useFormContext,
  FormProvider,
  Controller,
} from "react-hook-form"
import React from "react"
import styled from "styled-components"
import { ProjectColors } from "../common/style/ProjectColors"

import formatWithLocale from "../../util/dateFormatter"
import { SecondaryButton } from "@flow/buttons"
import { Colors, FontStyles } from "@flow/style"
import { Text, TextArea } from "@flow/forms"

export const EditableInputCell = ({ row, column, name }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      key={name}
      render={({ field }) => {
        return <StyledTextArea {...field} />
      }}
    />
  )
}

export const CheckBoxCell = ({ row, column, t, name }) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, key, onChange, value, onBlur, ...rest } }) => {
        return (
          <input
            id={key}
            onChange={(val) => onChange(val)}
            key={Math.random()}
            type={"checkbox"}
            checked={value}
          />
        )
      }}
    />
  )
}

export const GenerateNameCheckboxCell = ({
  row,
  column,
  currentUser,
  name,
  isMiniTable,
  t,
  agreementId,
  dataPath,
}) => {
  const { setValue, control, getValues } = useFormContext()

  const data = `${formatWithLocale(new Date())}, ${currentUser}`
  const generateNameInAnotherCell = (index) => {
    if (isMiniTable) {
      const controlledStatus = getValues(`${agreementId}.checklistControlled`)
      if (controlledStatus === true) {
        setValue(`${agreementId}.checklistControlledBy`, data)
      } else {
        setValue(`${agreementId}.checklistControlledBy`, "")
      }
    } else {
      if (getValues(name) === true) {
        setValue(`${dataPath}.${index}.controlledBy`, data)
      } else {
        setValue(`${dataPath}.${index}.controlledBy`, "")
      }
    }
  }
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({
          field: { name, key, onChange, value, onBlur, ...rest },
        }) => {
          return (
            <input
              id={key}
              onChange={(val) => {
                onChange(val)
                generateNameInAnotherCell(row)
              }}
              key={Math.random()}
              type={"checkbox"}
              checked={value}
            />
          )
        }}
      />
    </>
  )
}

export const MiniTable = ({
  t,
  currentUser,
  agreementId,
  checkedColor,
  isMaintenance,
}) => {
  const { watch } = useFormContext()

  const user = watch(`${agreementId}.checklistControlledBy`)
  const commonHeaders = [t("comment"), t("controlled"), t("controlledBy")]
  const maintenanceHeaders = [t("controlled"), t("controlledBy")]

  const headers = isMaintenance ? maintenanceHeaders : commonHeaders
  return (
    <MiniTableStyled>
      <tbody>
        <TRMiniTable backgroundColor={checkedColor}>
          {headers.map((header) => {
            return (
              <THMiniTable backgroundColor={checkedColor}>{header}</THMiniTable>
            )
          })}
        </TRMiniTable>

        <TRMiniTable backgroundColor={checkedColor}>
          {!isMaintenance && (
            <TDMiniTable backgroundColor={checkedColor}>
              <EditableInputCell
                row={0}
                column={0}
                name={`${agreementId}.checklistComment`}
              />
            </TDMiniTable>
          )}
          <TDMiniTable backgroundColor={checkedColor}>
            <GenerateNameCheckboxCell
              row={0}
              column={1}
              currentUser={currentUser}
              name={`${agreementId}.checklistControlled`}
              isMiniTable={true}
              t={t}
              agreementId={agreementId}
            />
          </TDMiniTable>
          <TDMiniTable backgroundColor={checkedColor}>{user}</TDMiniTable>
        </TRMiniTable>
      </tbody>
    </MiniTableStyled>
  )
}

export const CheckListTable = ({ dataPath, t, currentUser, methods }) => {
  const { watch } = useFormContext()
  const { append } = useFieldArray({ name: dataPath })

  const values = watch(dataPath)

  const addRow = () => {
    append({
      documentation: "",
      received: false,
      controlled: false,
      controlledBy: null,
    })
  }

  const headers = [
    t("documentation"),
    t("received"),
    t("controlled"),
    t("controlledBy"),
  ]

  return (
    <>
      <CustomTable>
        <tbody>
          <Heading>{t("checklist-documents")}</Heading>
          <TR>
            {headers?.map((header) => {
              return <TH>{header}</TH>
            })}
          </TR>

          {values?.map((row, rowId) => {
            return (
              <TR>
                {Object.values(row).map((cellValue, columnId) => {
                  if (columnId === 0) {
                    return (
                      <TD>
                        <EditableInputCell
                          row={rowId}
                          column={columnId}
                          name={`${dataPath}.${rowId}.documentation`}
                        />
                      </TD>
                    )
                  }
                  if (columnId === 1) {
                    return (
                      <TD>
                        <CheckBoxCell
                          fieldName={"received"}
                          row={rowId}
                          column={columnId}
                          t={t}
                          name={`${dataPath}.${rowId}.received`}
                        />
                      </TD>
                    )
                  }
                  if (columnId === 2) {
                    return (
                      <TD>
                        <GenerateNameCheckboxCell
                          row={rowId}
                          column={columnId}
                          currentUser={currentUser}
                          name={`${dataPath}.${rowId}.controlled`}
                          dataPath={dataPath}
                          t={t}
                        />
                      </TD>
                    )
                  }
                  if (columnId === 3) {
                    return (
                      <TD>
                        <p>{cellValue}</p>
                      </TD>
                    )
                  }
                })}
              </TR>
            )
          })}
        </tbody>
      </CustomTable>
      <ButtonContainer>
        <SecondaryButton type="button" onClick={addRow}>
          {t("addRow")}
        </SecondaryButton>
      </ButtonContainer>
    </>
  )
}

const CustomTable = styled.table`
  width: 100%;
  font-size: small;
  border-collapse: collapse;
  margin: 0 0 1em 0;
`
const MiniTableStyled = styled.table`
  width: 100%;
  font-size: small;
  border-collapse: collapse;
  margin: 0 0 1em 1em;
`

const TR = styled.tr`
  &:nth-child(odd) {
    background-color: ${ProjectColors.Slate90};
  }
`

const TRMiniTable = styled.tr`
  background-color: ${(props) => props.backgroundColor || Colors.SeaLighter};
`

const TDMiniTable = styled.th`
  font-weight: normal;
  width: 100px;
  background-color: ${(props) => props.backgroundColor || Colors.SeaLighter};

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(3) {
    text-align: center;
  }
`
const TH = styled.th`
  font-weight: normal;
  padding: 12px;

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(3) {
    text-align: center;
  }
`
const THMiniTable = styled.th`
  font-weight: normal;
  background-color: ${(props) => props.backgroundColor || Colors.SeaLighter};
  max-width: 100px;

  &:nth-child(1) {
    text-align: left;
  }

  &:nth-child(3) {
    text-align: center;
  }
`
const TD = styled.td`
  font-weight: normal;
  padding: 12px;
  width: 100px;

  &:nth-child(2) {
    text-align: center;
  }

  &:nth-child(3) {
    text-align: center;
  }

  &:nth-child(4) {
    text-align: center;
  }
`

const Heading = styled.p`
  font-size: ${FontStyles.Normal};
  color: black;
  padding-bottom: 0.5em;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`
const StyledTextArea = styled(TextArea)`
  resize: none;
  width: 100%;
  border-radius: 5px;
  background: white;
`
