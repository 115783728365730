import React from "react"
import styled from "styled-components"
import { hasData } from "../../../util/returnValue"
import Item from "./Item"
import { Colors, FontStyles } from "@flow/style"

const DisplayCadastreInfo = ({ t, ownedCadastres, displayNone }) => {
  if (displayNone) return <></>
  return (
    <DisplayCadastreInfoWrapper>
      <Item
        className="top-section"
        data={
          hasData(ownedCadastres)
            ? t("properties-in-norway")
            : t("no-information-about-properties-in-norway-to-show")
        }
      />
      {hasData(ownedCadastres) && (
        <>
          {Object.values(ownedCadastres || {}).map((ownedCadastre, index) => (
            <React.Fragment key={index}>
              <div className="grid">
                {index === 0 && (
                  <div className="content">
                    <Item
                      data={t("cadastre")}
                      dataStyling={{
                        color: Colors.Grey1,
                        fontSize: FontStyles.Small,
                      }}
                    />
                    <Item
                      data={t("ownershipFraction")}
                      dataStyling={{
                        color: Colors.Grey1,
                        fontSize: FontStyles.Small,
                      }}
                    />
                    <Item
                      data={t("registerCode")}
                      dataStyling={{
                        color: Colors.Grey1,
                        fontSize: FontStyles.Small,
                      }}
                    />
                  </div>
                )}
                {!ownedCadastre.error ? (
                  <div className="content">
                    <Item
                      data={`${ownedCadastre.cadastre.municipalityNumber}-${ownedCadastre.cadastre.cadastralUnitNumber}/${ownedCadastre.cadastre.unitNumber}/${ownedCadastre.cadastre.leaseHoldUnitNumber}/${ownedCadastre.cadastre.sectionNumber}`}
                    />
                    <Item
                      data={`${ownedCadastre.ownershipFraction.numerator}/${ownedCadastre.ownershipFraction.denominator}`}
                    />
                    <Item
                      data={`${ownedCadastre.cadastreLevel.registerCode} - ${ownedCadastre.cadastreLevel.text}`}
                    />
                  </div>
                ) : (
                  <div className="contentError">
                    <Item data={t(ownedCadastre.error)} />
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
        </>
      )}
    </DisplayCadastreInfoWrapper>
  )
}
export default DisplayCadastreInfo

const DisplayCadastreInfoWrapper = styled.div`
  .grid {
    max-width: 37em;
    display: grid;
    grid-template-columns: 1fr;
  }

  .content {
    display: grid;
    grid-template-columns: 0.7fr 0.4fr 1fr;
  }
  .contentError {
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 0.7em;
  }

  .top-section {
    display: flex;
    flex-direction: row;
    margin: 10px 10px;
  }

  .edit-card {
    width: 100%;
    padding: 10px 10px;
  }

  .content-item {
    min-width: 200px;
  }

  .full-width {
    width: 100%;
  }

  .edit-form {
    margin-top: 10px;
    display: flex;
    width: 650px;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .edit-form > * {
    width: calc(50% - 20px);
  }

  .options {
    margin-top: 10px;
  }

  .options > div {
    cursor: pointer;
  }

  .icon {
    padding-top: 10px;
  }

  .person-content {
    display: grid;
    padding: 10px 10px;
    grid-template-columns: minmax(200px, auto) 180px minmax(200px, auto) 200px;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }

  .edit-content {
    display: grid;
    grid-template-columns: 200px minmax(200px, auto);
    align-items: center;
    gap: 10px;
  }

  .organization-content {
    display: grid;
    padding: 10px 10px;
    grid-template-columns: minmax(200px, auto) 200px;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    gap: 10px;
  }

  .stakeholder-card-content {
    margin-left: 65px;
    margin-bottom: 10px;
  }
`
