import MainLogo from "./branding/MainLogo"
import AuthenticationIcon from "./branding/AuthenticationIcon"
import Branding from "./branding/Branding"
import CaseSummary from "./CaseSummary"
import QueueItem from "./QueueItem"
import { searchResultFields, searchFields } from "./SearchConfig"
import FileTab from "./tabs/FileTab"
import { taskLists } from "./taskList/customTaskLists"
import {
  queueNames,
  queueStatusCurrentCase,
} from "../constants/queueStatusCurrentCase"
import taskComponents from "./task-components"
import { countiesConfiguration } from "../constants/countiesConfiguration"
import { hasData } from "./util/returnValue"

const allMainProcesses = [
  "application",
  "vekstgaranti",
  "maintenance",
  "depot-end-control",
]

const baseQueue = {
  queueSort: "sort=createdAt&dir=-1",
  limit: 15,
  pollingInterval: 60_000,
}
const depotEndControlQueue = {
  queueSort: "sort=data.input.followUpDate&dir=1",
  limit: 15,
  pollingInterval: 60_000,
  name: "depot-end-control",
  queueFilter: `?status=active&flowDefinitionId=depot-end-control`,
}

const generateFlowDefinitionIdQuery = (processes) =>
  processes.reduce((acc, curr) => `${acc}&flowDefinitionId=${curr}`, "")

/* 
Søknader som blir sendt inn til IN blir besluttet enten sentralt eller regionalt.
Det som bestemmer dette er fylkesnummer som blir sendt inn med søknaden og kategori.
Denne categories-listen er pre-definerte kategorier som utgjør hvilke som skal besluttes regionalt.
Denne funksjonen returnerer en streng som brukes som en del av spørringen til å hente ut saker i RK-køene på topp-nivå
*/
const generateRegionalCategories = (
  categories = [
    "startup",
    "agriculture",
    "agricultureadditionalbusiness",
    "businessdevelopmentandinnovation",
    "shippingandfishery",
    "maintenance",
  ]
) =>
  categories.reduce(
    (acc, curr) => `${acc}&data.application.category=${curr}`,
    ""
  )

/*
IN har bestilt at RK-køene skal være tilgjengelig som underkøer i hovedkøen "Økonomisk kontroll"
Det som skal vises i disse RK-køene er kun saker på steget "Utfør økonomisk kontroll" og som skal besluttes regionalt
med UNNTAK av skipsfart og fiske. Ref IN-2267
Denne funksjonen returnerer en streng som brukes som en del av spørringen til å hente ut saker i RK-køene i "Økonomisk kontroll"
*/
const generatePayoutControlRegionalCategories = (
  categories = [
    "startup",
    "agriculture",
    "agricultureadditionalbusiness",
    "businessdevelopmentandinnovation",
  ]
) =>
  categories.reduce(
    (acc, curr) => `${acc}&data.application.category=${curr}`,
    ""
  )

const generateCountyNumbersQuery = (counties = []) => {
  if (!counties.length) {
    return ""
  }
  return `&data.mapped.caseOverview.municipality.code=^(${counties.join("|")})`
}

const createTaskQueue = (name, status, processes = ["application"]) => ({
  name,
  queueFilter: `?status=active&data.queueStatusCurrentCase=${status}&view=queueView${generateFlowDefinitionIdQuery(
    processes
  )}`,
  ...baseQueue,
})

const createNamedTaskQueue = (
  name,
  queueName,
  processes = ["application", "maintenance"]
) => ({
  name,
  queueFilter: `?status=active&data.queueStatuses.${queueName}.0=&view=queueView${generateFlowDefinitionIdQuery(
    processes
  )}`,
  ...baseQueue,
})

const createAdvancedTaskQueue = (name, sub, config) => {
  const { processes, isQueueList, filter } = sub
  const { countyNumber, category } = config
  let queueFilter = ""
  if (filter) {
    queueFilter = isQueueList
      ? `&data.queueStatuses.${filter}.0=`
      : `&data.queueStatusCurrentCase=${filter}`
  }
  const queue = {
    name,
    queueFilter: `?status=active${queueFilter}&view=queueView${generateFlowDefinitionIdQuery(
      processes
    )}${generateRegionalCategories(category)}${generateCountyNumbersQuery(
      countyNumber
    )}`,
    ...baseQueue,
  }

  // Allow for specifying taskFilter and countTaskFilter
  if (sub.taskFilter) queue.taskFilter = sub.taskFilter
  if (sub.countTaskFilter) queue.countTaskFilter = sub.countTaskFilter

  return queue
}

const createPayoutControlCustomQueue = (
  queueName,
  counties,
  isNonRegional,
  category
) => {
  const categories = generatePayoutControlRegionalCategories()
  const countyList = generateCountyNumbersQuery(counties)
  const base = createNamedTaskQueue(
    queueName,
    queueNames.PERFORM_FINANCIAL_CHECK
  )
  let queueFilter

  const categoriesPath = category
    ?.map((cat) => `data.application.category=${cat}`)
    .join("&")

  if (isNonRegional) {
    queueFilter = `${base.queueFilter}&${categoriesPath}`
  } else {
    queueFilter = `${base.queueFilter}${categories}${countyList}`
  }

  return {
    queueSort: "sort=data.input.followUpDate&dir=1",
    limit: 15,
    pollingInterval: 60_000,
    name: queueName,
    queueFilter,
    taskFilter: "/?status=pending&taskCategory=user-task",
  }
}

const payoutControlCountyQueues = countiesConfiguration.reduce((acc, curr) => {
  const queueName = `${curr.name}-queue`
  acc[queueName] = createPayoutControlCustomQueue(queueName, curr.countyNumber)
  return acc
}, {})

const createAllCasesQueue = (name, category, processes) => ({
  name,
  queueFilter: `?status=active&data.application.category=${category}&view=queueView${generateFlowDefinitionIdQuery(
    processes
  )}`,
  ...baseQueue,
})

const createQueueQueue = (name, category, processes) => ({
  name,
  queueFilter: `?status=active&data.application.category=${category}&assigneeExists=false&view=queueView${generateFlowDefinitionIdQuery(
    processes
  )}`,
  taskFilter: "/?status=pending&taskCategory=user-task",
  countTaskFilter: "/?status=pending&taskCategory=user-task",
  ...baseQueue,
})

const baseQueueItemsSet = [
  {
    name: "registered-section",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_CREDIT_SECTION,
    processes: ["application", "maintenance"],
    isQueueList: false,
  },
  {
    name: "registered-rk1",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_CREDIT_LEVEL_1,
    processes: ["application", "maintenance"],
    isQueueList: false,
  },
  {
    name: "registered-rk2",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_CREDIT_LEVEL_2,
    processes: ["application", "maintenance"],
    isQueueList: false,
  },
  {
    name: "direct-payout",
    filter: queueNames.DIRECT_PAYOUT,
    processes: ["application", "maintenance"],
    isQueueList: true,
  },
  {
    name: "active-cases",
    filter: null,
    processes: ["application", "maintenance"],
    isQueueList: false,
  },
]

const allCasesSubQueues = [
  {
    name: "registered-division",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_CREDIT_DIVISION,
    processes: allMainProcesses,
    queueType: "queueStatusCurrentCase",
  },
  {
    name: "registered-board",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_BOARD,
    processes: allMainProcesses,
    queueType: "queueStatusCurrentCase",
  },
  {
    name: "registrered-main-credit",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_MAIN_CREDIT,
    processes: allMainProcesses,
    queueType: "queueStatusCurrentCase",
  },
]

const maintenanceCaseQueues = [
  {
    name: "active-cases",
    filter: null,
    processes: ["maintenance"],
    isQueueList: false,
  },
  {
    name: "registered-section",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_CREDIT_SECTION,
    processes: ["maintenance"],
    queueType: "queueStatusCurrentCase",
  },
  {
    name: "registered-rk1",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_CREDIT_LEVEL_1,
    processes: ["maintenance"],
    isQueueList: false,
  },
  {
    name: "registered-rk2",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_CREDIT_LEVEL_2,
    processes: ["maintenance"],
    isQueueList: false,
  },
  {
    name: "registered-board",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_BOARD,
    processes: ["maintenance"],
    queueType: "queueStatusCurrentCase",
  },
  {
    name: "registered-division",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_CREDIT_DIVISION,
    processes: ["maintenance"],
    queueType: "queueStatusCurrentCase",
  },
  {
    name: "registered-main-credit",
    filter: queueStatusCurrentCase.ASSESS_APPLICATION_MAIN_CREDIT,
    processes: ["maintenance"],
    queueType: "queueStatusCurrentCase",
  },
]

const maintenanceCaseQueuesAssignee = [
  {
    name: "todos-before-decision",
  },
  {
    name: "todos-after-decision",
  },
]

const roleControlledQueueItems = {
  depot: {
    "select-signers-queue": createNamedTaskQueue(
      "select-signers-queue",
      queueNames.SELECT_SIGNERS,
      ["application"]
    ),
    "print-and-send-to-sign-queue": createNamedTaskQueue(
      "print-and-send-to-sign-queue",
      queueNames.PRINT_AND_SEND_TO_SIGN,
      ["application"]
    ),
    "register-bail-documents-queue": createNamedTaskQueue(
      "register-bail-documents-queue",
      queueNames.REGISTER_BAIL_DOCUMENTS,
      ["application"]
    ),
    "tinglysing-manual-queue": createNamedTaskQueue(
      "tinglysing-manual-queue",
      queueNames.TINGLYSING_MANUAL,
      ["application"]
    ),
    "control-priority-queue": createNamedTaskQueue(
      "control-priority-queue",
      queueNames.CONTROL_PRIORITY,
      ["application"]
    ),
    "control-collateral-depot": createNamedTaskQueue(
      "control-collateral-depot",
      queueNames.CONTROL_COLLATERAL_DEPOT,
      ["application"]
    ),
    "depot-end-control": depotEndControlQueue,
    "select-signers-queue-maintenance": createNamedTaskQueue(
      "select-signers-queue-maintenance",
      queueNames.SELECT_SIGNERS,
      ["maintenance"]
    ),
    "print-and-send-to-sign-queue-maintenance": createNamedTaskQueue(
      "print-and-send-to-sign-queue-maintenance",
      queueNames.PRINT_AND_SEND_TO_SIGN,
      ["maintenance"]
    ),
    "register-bail-documents-queue-maintenance": createNamedTaskQueue(
      "register-bail-documents-queue-maintenance",
      queueNames.REGISTER_BAIL_DOCUMENTS,
      ["maintenance"]
    ),
    "tinglysing-manual-queue-maintenance": createNamedTaskQueue(
      "tinglysing-manual-queue-maintenance",
      queueNames.TINGLYSING_MANUAL,
      ["maintenance"]
    ),
    "control-priority-queue-maintenance": createNamedTaskQueue(
      "control-priority-queue-maintenance",
      queueNames.CONTROL_PRIORITY,
      ["maintenance"]
    ),
    "control-collateral-depot-maintenance": createNamedTaskQueue(
      "control-collateral-depot-maintenance",
      queueNames.CONTROL_COLLATERAL_DEPOT,
      ["maintenance"]
    ),
  },
  juri: {
    "juri-queue": createNamedTaskQueue("juri-queue", queueNames.JURI_CONTROL),
  },
  payoutcontrol: {
    "economic-control-queue": createNamedTaskQueue(
      "economic-control-queue",
      queueNames.PERFORM_FINANCIAL_CHECK
    ),
    "extraordinary-financing-queue": createPayoutControlCustomQueue(
      "extraordinary-financing-queue",
      [],
      true,
      ["extraordinaryfinancing", "exportpromotion"]
    ),
    "growth-and-innovation-center-queue": createPayoutControlCustomQueue(
      "growth-and-innovation-center-queue",
      [],
      true,
      ["startupmatil"]
    ),
    "bioenergy-queue": createPayoutControlCustomQueue(
      "bioenergy-queue",
      [],
      true,
      ["bioenergy"]
    ),
    ...payoutControlCountyQueues,
  },
  idd: {
    "idd-team-queue": createTaskQueue(
      "idd-team-queue",
      queueStatusCurrentCase.PERFORM_IDD_CHECK_IDD_TEAM
    ),
  },
  reskontro: {
    "begin-payout-queue": createNamedTaskQueue(
      "begin-payout-queue",
      queueNames.BEGIN_PAYOUT,
      ["application"]
    ),
    "advance-received": createNamedTaskQueue(
      "advance-received",
      queueNames.WAIT_FOR_ADVANCE_RECEIVED,
      ["vekstgaranti"]
    ),
    "cancel-account-in-coreview-manual": createNamedTaskQueue(
      "cancel-account-in-coreview-manual",
      queueNames.CANCEL_ACCOUNT_IN_COREVIEW_MANUAL,
      allMainProcesses
    ),
    "manually-update-core": createNamedTaskQueue(
      "manually-update-core",
      queueNames.MANUALLY_UPDATE_CORE,
      ["maintenance"]
    ),
  },
}
const createRoleQueueItems = (role) => {
  if (role in roleControlledQueueItems) {
    return roleControlledQueueItems[role]
  }
  return {}
}

const roleControlledQueues = [
  {
    allowedRole: "depot",
    name: "depot-queue",
    elems: [
      "select-signers-queue",
      "print-and-send-to-sign-queue",
      "register-bail-documents-queue",
      "control-priority-queue",
      "tinglysing-manual-queue",
      "control-collateral-depot",
      "depot-end-control",
    ],
  },
  {
    allowedRole: "depot",
    name: "depot-maintenance-queue",
    elems: [
      "select-signers-queue-maintenance",
      "print-and-send-to-sign-queue-maintenance",
      "register-bail-documents-queue-maintenance",
      "control-priority-queue-maintenance",
      "tinglysing-manual-queue-maintenance",
      "control-collateral-depot-maintenance",
    ],
  },
  {
    allowedRole: "juri",
    name: "juri-queue",
  },
  {
    allowedRole: "payoutcontrol",
    name: "economic-control-queue",
    elems: [
      "extraordinary-financing-queue",
      "growth-and-innovation-center-queue",
      "bioenergy-queue",
      ...countiesConfiguration.map(({ name }) => `${name}-queue`),
    ],
  },
  {
    allowedRole: "idd",
    name: "idd-team-queue",
  },
  {
    allowedRole: "reskontro",
    name: "reskontro-queue",
    elems: [
      "begin-payout-queue",
      "advance-received",
      "cancel-account-in-coreview-manual",
      "manually-update-core",
    ],
  },
]

// These are the names of the queues IN wants to have a ToDo sub-queue
const todoQueueNames = [
  "extraordinary-financing",
  "growth-and-innovation-center",
  ...countiesConfiguration.map(({ name }) => name),
]

const createRoleQueues = (roles = []) => {
  return roleControlledQueues.filter((elem) => roles.includes(elem.allowedRole))
}

/**
 * Creates the menu structure for a given top level queue
 * The menu structure just decides where and which queues are used
 * To define how the queues actually work, use the queueDefinition
 * @param {object} config Top level queue object
 * @returns Menu structure object
 */
const createAdvancedMenuItems = (config) => {
  let queuesToMake = baseQueueItemsSet
  if (config.name === "all-cases")
    queuesToMake = [...queuesToMake, ...allCasesSubQueues]

  if (config.name === "maintenance")
    queuesToMake = [...maintenanceCaseQueues, ...maintenanceCaseQueuesAssignee]

  if (todoQueueNames.includes(config.name)) {
    // We only need name here, since this is just the menu structure and not the queue definition
    queuesToMake = [{ name: "todos" }, ...queuesToMake]
  }

  return {
    name: `${config.name}-queue`,
    elems: queuesToMake.map((sub) => `${config.name}-${sub.name}`),
  }
}

/**
 * Creates queue definitions for sub-queues
 * @param {object} config - The queue configuration
 * @returns Array of queue definitions
 */
const createBaseQueueItemSet = (config) => {
  let queuesToMake = baseQueueItemsSet
  if (config.name === "all-cases") {
    queuesToMake = [...queuesToMake, ...allCasesSubQueues]

    // We want maintenance in most of these queues, but including
    // them in all-cases could make a mess, and then there wouldn't
    // be a need for a dedicated queue for maintenance.
    queuesToMake = queuesToMake.map((queue) => ({
      ...queue,
      processes: queue.processes.filter((process) => process !== "maintenance"),
    }))
  }

  if (config.name === "maintenance") queuesToMake = [...maintenanceCaseQueues]

  if (todoQueueNames.includes(config.name)) {
    // Instead of making assumptions on what processes to use, just copy whatever the active-cases queue does.
    const activeCasesQueue = queuesToMake.find((e) => e.name === "active-cases")
    // If for some reason we couldn't find active-cases (shouldn't happen), just default to only "application",
    // which is what's defined by default in baseQueueItemsSet anyway.
    const processes = activeCasesQueue?.processes || ["application"]

    queuesToMake = [
      {
        name: "todos",
        filter: queueNames.BELONGS_TO_CASEWORKER,
        processes,
        isQueueList: true,
        taskFilter: "/?status=pending&taskCategory=user-task",
        countTaskFilter: "/?status=pending&taskCategory=user-task",
      },
      ...queuesToMake,
    ]
  }

  return queuesToMake.reduce((prev, sub) => {
    const queueName = config.name + "-" + sub.name
    prev.push(createAdvancedTaskQueue(queueName, sub, config))
    return prev
  }, [])
}

/**
 * Hardcoded queue definitions - we add dynamic ones to this object later in getCustomComponents().
 */
const queueDefinition = {
  "queue-vekstgaranti": createQueueQueue(
    "queue-vekstgaranti",
    "counterguarantee",
    ["vekstgaranti"]
  ),
  "all-cases-vekstgaranti": createAllCasesQueue(
    "all-cases-vekstgaranti",
    "counterguarantee",
    ["vekstgaranti"]
  ),
  "my-cases": {
    name: "my-cases",
    queueType: "assignee",
    queueFilter: `?status=active&view=queueView${generateFlowDefinitionIdQuery(
      allMainProcesses
    )}`,
    ...baseQueue,
  },
  "my-cases-with-active-user-task": {
    name: "my-cases-with-active-user-task",
    queueType: "assignee",
    queueFilter: `?status=active&data.queueStatuses.${
      queueNames.BELONGS_TO_CASEWORKER
    }.0&view=queueView${generateFlowDefinitionIdQuery(allMainProcesses)}`,
    taskFilter: "/?status=pending&taskCategory=user-task",
    countTaskFilter: "/?status=pending&taskCategory=user-task",
    ...baseQueue,
  },
  "maintenance-todos-before-decision": {
    name: "maintenance-todos-before-decision",
    queueType: "assignee",
    queueFilter: `?status=active&data.queueStatuses.${
      queueNames.BELONGS_TO_CASEWORKER
    }.0&data.queueStatuses.${
      queueNames.MAINTENANCE_BEFORE_DECISION
    }.0&view=queueView${generateFlowDefinitionIdQuery(["maintenance"])}`,
    ...baseQueue,
  },
  "maintenance-todos-after-decision": {
    name: "maintenance-todos-after-decision",
    queueType: "assignee",
    queueFilter: `?status=active&data.queueStatuses.${
      queueNames.BELONGS_TO_CASEWORKER
    }.0&data.queueStatuses.${
      queueNames.MAINTENANCE_AFTER_DECISION
    }.0&view=queueView${generateFlowDefinitionIdQuery(["maintenance"])}`,
    ...baseQueue,
  },
  "failed-cases-queue": {
    name: "failed-cases-queue",
    queueFilter: `?status=active&hasErrors=true&view=queueView${generateFlowDefinitionIdQuery(
      allMainProcesses
    )}`,
    ...baseQueue,
  },
}

const extraordinaryCategories = ["extraordinaryfinancing", "exportpromotion"]

const menuItemsConfig = [
  {
    name: "extraordinary-financing",
    countyNumber: [],
    category: [...extraordinaryCategories],
  },
  {
    name: "humanitarian-innovation-project",
    countyNumber: [],
    category: ["humanitarianinnovationproject"],
  },
  {
    name: "growth-and-innovation-center",
    countyNumber: [],
    category: ["startupmatil"],
  },
  {
    name: "bioenergy",
    countyNumber: [],
    category: ["bioenergy"],
  },
  {
    name: "maintenance",
    countyNumber: [],
    category: [],
  },
]

const allCasesConfig = {
  name: "all-cases",
  countyNumber: [],
  category: [],
}

const CustomComponents = {
  case: {
    caseSummary: {
      component: CaseSummary,
    },
    businessStatus: {
      map: (flow) => flow.data.businessStatus,
    },
    caseDetails: undefined,
    tabs: [
      {
        id: "files",
        title: "files",
        component: FileTab,
        hide: (flow) => (flow.data?.files?.length ?? 0) <= 0,
        badge: (flow) => flow.data.files.length,
      },
    ],
    tasks: {
      taskLists,
      taskComponents,
    },
    caseHistoryTasksLimit: 1000,
  },
  queue: {
    queueItem: QueueItem,
  },
  menu: {
    menuStructure: [],
  },
  queueDefinition,
}

const getCustomComponents = (roles = []) => {
  // map queues with base structure
  menuItemsConfig.forEach((menuItem) => {
    const countyQueueItems = createBaseQueueItemSet(menuItem)
    for (const item of countyQueueItems) {
      queueDefinition[item.name] = item
    }
  })

  const allCasesQueueItem = createBaseQueueItemSet(allCasesConfig)
  for (const item of allCasesQueueItem) {
    queueDefinition[item.name] = item
  }

  // RK-queues is now available for everyone
  const countiesForUser = countiesConfiguration

  // generate queue items for counties for user
  countiesForUser.forEach((county) => {
    const countyQueueItems = createBaseQueueItemSet(county)
    for (const item of countyQueueItems) {
      queueDefinition[item.name] = item
    }
  })

  // generate queue items for roles for user
  roles.forEach((role) => {
    const config = createRoleQueueItems(role)
    if (hasData(config)) {
      Object.keys(config).forEach((e) => (queueDefinition[e] = config[e]))
    }
  })

  // Generate menu structure based on generated queues
  const menuStructure = [
    {
      name: "assigned-to-caseworker-queue",
      elems: ["my-cases", "my-cases-with-active-user-task"],
    },
    createAdvancedMenuItems(allCasesConfig),
    ...countiesForUser.map(createAdvancedMenuItems),
    {
      name: "vekstgaranti-queue",
      elems: ["queue-vekstgaranti", "all-cases-vekstgaranti"],
    },
    ...menuItemsConfig.map(createAdvancedMenuItems),
    ...createRoleQueues(roles),
    {
      name: "failed-cases-queue",
    },
  ].filter((x) => x.featureToggle === undefined || x.featureToggle)

  return {
    ...CustomComponents,
    queueDefinition,
    menu: { menuStructure },
  }
}

const StartableProcesses = ["payout-control", "depot-end-control"]
const SearchFields = [
  "referenceId",
  "data.raw.accountMaster.customerAccount.name",
  "data.input.companyName",
]

export {
  Branding,
  MainLogo,
  CustomComponents,
  SearchFields,
  StartableProcesses,
  searchResultFields,
  searchFields,
  AuthenticationIcon,
  getCustomComponents,
}
