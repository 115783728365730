import React, { useState } from "react"
import styled from "styled-components"
import { CenteredSpinner, Icons } from "@flow/icons"
import { Colors, FontStyles } from "@flow/style"
import { TransparentButton, SecondaryButton } from "@flow/buttons"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import {
  createSecurityTextDefaultValues,
  createSecurityTextValidationSchema,
} from "./CreateSecurityText.validationSchema"
import { TextBoxInput, TextAreaInput, SelectInput } from "./InputComponents"
import {
  getCategories,
  getSubcategories,
  getDetails,
  TitleCategoryString,
} from "../../../util/securityTermsRequest"

const formatText = (text) => {
  let newText = text
  // Remove quotes around text, if it exists
  if (newText.at(0) === '"' && newText.at(-1) === '"') {
    newText = newText.slice(1, -1)
  }
  // Remove the preceding dash, if it exists
  if (newText.startsWith("- ")) {
    newText = newText.substring(2)
  }
  return newText
}

const TitleControls = ({ t, titleCategories, watch, setValue }) => {
  const [hint, setHint] = useState("")
  const [titles, setTitles] = useState([])

  const selectedCategoryId = watch("titleCategory")
  const selectedSubcategoryId = watch("titleSubCategory")
  const filteredTitles = titles.filter(
    (t) => t.subCategoryId === selectedCategoryId
  )

  React.useEffect(() => {
    if (selectedCategoryId) {
      async function fetchDetails() {
        const newData = await getDetails(selectedCategoryId)
        setTitles(newData)
      }
      fetchDetails()
    }
  }, [selectedCategoryId])

  const onChangeSubCategory = (e) => {
    const newId = e?.target?.value
    const selectedSubcategory = filteredTitles.find((s) => s.id === newId)
    if (selectedSubcategory) {
      setValue("title", formatText(selectedSubcategory.description.terms))
      setHint(selectedSubcategory.description.userInstructions)
    }
  }

  const Icon = Icons["Help"]
  return (
    <>
      <AddTextSectionTitle>{t("choose-title")}</AddTextSectionTitle>
      <FlexRow>
        <StyledSelect key={"titleCategory"}>
          <SelectInput
            fieldName={"titleCategory"}
            t={t}
            options={titleCategories.map((x) => ({
              value: x.id,
              name: x.title,
            }))}
            disabled={titleCategories.length === 0}
          />
        </StyledSelect>
        <Divider />
        <StyledSelect key={"titleSubCategory"}>
          <SelectInput
            fieldName={"titleSubCategory"}
            t={t}
            options={filteredTitles.map((x) => ({
              value: x.id,
              name: x.description?.definition,
            }))}
            onChanged={(e) => onChangeSubCategory(e)}
            disabled={filteredTitles.length === 0}
          />
        </StyledSelect>
      </FlexRow>
      {hint?.length > 0 && (
        <>
          <Divider />
          <FlexRow>
            <StyledHelpIcon>
              <Icon color={Colors.Grey1} size={20} />
            </StyledHelpIcon>
            <UserInstructions>{hint}</UserInstructions>
          </FlexRow>
        </>
      )}
      <StyledText>
        <TextBoxInput
          fieldName="title"
          t={t}
          disabled={!selectedCategoryId || !selectedSubcategoryId}
        />
      </StyledText>
    </>
  )
}

const ContentControls = ({ t, contentCategories, watch, setValue }) => {
  const [hint, setHint] = useState("")
  const [subcategories, setSubcategories] = useState([])
  const [details, setDetails] = useState([])

  const selectedCategoryId = watch("contentCategory")
  const selectedSubcategoryId = watch("contentSubCategory")
  const selectedTextId = watch("contentText")

  const filteredSubcategories = subcategories.filter(
    (sub) => sub.categoryId === selectedCategoryId
  )
  const filteredDetails = details.filter(
    (detail) => detail.subCategoryId === selectedSubcategoryId
  )

  React.useEffect(() => {
    if (selectedCategoryId) {
      async function fetchSubcategories() {
        const newData = await getSubcategories(selectedCategoryId)
        setSubcategories(newData)
      }
      fetchSubcategories()
    }
  }, [selectedCategoryId])

  React.useEffect(() => {
    if (selectedCategoryId && selectedSubcategoryId) {
      async function fetchDetails() {
        const newData = await getDetails(selectedSubcategoryId)
        setDetails(newData)
      }
      fetchDetails()
    }
  }, [selectedCategoryId, selectedSubcategoryId])

  const onChangeCategory = (e) => {
    const newId = e?.target?.value
    const selectedCategory = contentCategories.find((c) => c.id === newId)
    if (selectedCategory) {
      setValue("subtitle", selectedCategory.title)
    }
  }

  const onChangeText = (e) => {
    const newId = e?.target?.value
    const selectedDetail = filteredDetails.find((d) => d.id === newId)
    if (selectedDetail) {
      setValue("text", formatText(selectedDetail.description.terms))
      setHint(selectedDetail.description.userInstructions)
    }
  }

  const Icon = Icons["Help"]
  return (
    <>
      <AddTextSectionTitle>{t("choose-content")}</AddTextSectionTitle>
      <FlexRow>
        <StyledSelect key={"contentCategory"}>
          <SelectInput
            fieldName={"contentCategory"}
            t={t}
            options={contentCategories.map((x) => ({
              value: x.id,
              name: x.title,
            }))}
            onChanged={(e) => onChangeCategory(e)}
            disabled={contentCategories.length === 0}
          />
        </StyledSelect>
        <Divider />
        <StyledSelect key={"contentSubCategory"}>
          <SelectInput
            fieldName={"contentSubCategory"}
            t={t}
            options={filteredSubcategories.map((x) => ({
              value: x.id,
              name: x.title,
            }))}
            disabled={filteredSubcategories.length === 0}
          />
        </StyledSelect>
        <Divider />
        <StyledSelect key={"contentText"}>
          <SelectInput
            fieldName={"contentText"}
            t={t}
            options={filteredDetails.map((x) => ({
              value: x.id,
              name: x.description?.definition,
            }))}
            onChanged={(e) => onChangeText(e)}
            disabled={filteredDetails.length === 0}
          />
        </StyledSelect>
      </FlexRow>
      {hint?.length > 0 && (
        <>
          <Divider />
          <FlexRow>
            <StyledHelpIcon>
              <Icon color={Colors.Grey1} size={20} />
            </StyledHelpIcon>
            <UserInstructions>{hint}</UserInstructions>
          </FlexRow>
        </>
      )}
      <TextAreaInput
        fieldName="text"
        t={t}
        disabled={
          !selectedCategoryId || !selectedSubcategoryId || !selectedTextId
        }
        placeholder={t("security-text-placeholder")}
      />
      <Divider />
    </>
  )
}

const CreateSecurityText = ({ t, onAddText, onCancelEdit, item }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    register,
    ...rest
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createSecurityTextValidationSchema),
    defaultValues: createSecurityTextDefaultValues,
  })
  const [lookupCategories, setLookupCategories] = useState([])
  const [titleCategories, setTitleCategories] = useState([])

  const lookupCategoryId = lookupCategories.find(
    (x) => x.title === TitleCategoryString
  )?.id

  const contentCategories = lookupCategories.filter(
    (x) => x.id !== lookupCategoryId
  )

  React.useEffect(() => {
    async function fetchData() {
      let categories = lookupCategories || []
      if (categories.length === 0) {
        categories = await getCategories()
        setLookupCategories(categories)
      }

      let lookupId =
        lookupCategoryId ||
        categories.find((x) => x.title === TitleCategoryString)?.id

      let titles = titleCategories || []
      if (titles.length === 0) {
        const subcategories = await getSubcategories(lookupId)
        const titleCategories = subcategories.filter(
          (x) => x.categoryId === lookupId
        )

        setTitleCategories(titleCategories)
      }
    }
    fetchData()
  }, [lookupCategoryId])

  const canSubmitForm = () => {
    if (!lookupCategoryId) return false
    if (lookupCategoryId && titleCategories.length === 0) return false

    const title = watch("title")
    if (!title) return false

    const subtitle = watch("subtitle")
    if (!subtitle) return false

    const text = watch("text")
    if (!text) return false

    return true
  }

  const onSubmit = (data) => {
    onAddText(data, item)
  }

  const hasTitleCategories = titleCategories?.length > 0

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      control={control}
      errors={errors}
      watch={watch}
      setValue={setValue}
      getValues={getValues}
      {...rest}
    >
      <form id="addSecurityTextForm" onSubmit={handleSubmit(onSubmit)}>
        {hasTitleCategories ? (
          <EditingSection>
            <TitleControls
              {...{
                t,
                titleCategories,
                watch,
                setValue,
              }}
            />
            <ContentControls
              {...{
                t,
                contentCategories,
                watch,
                setValue,
              }}
            />
            <Divider />
            <>
              <FlexRow>
                <SecondaryButton
                  form="addSecurityTextForm"
                  type="submit"
                  disabled={!canSubmitForm()}
                >
                  {t("add-special-terms-button-text")}
                </SecondaryButton>
                <TransparentButton onClick={() => onCancelEdit()}>
                  {t("cancel-special-terms-button-text")}
                </TransparentButton>
              </FlexRow>
            </>
          </EditingSection>
        ) : (
          <CenteredSpinner />
        )}
      </form>
    </FormProvider>
  )
}

const EditingSection = styled.div`
  color: black;
  background-color: ${Colors.Grey4};
  padding: 15px;
  border-radius: 5px;
`

const StyledSelect = styled.div`
  min-width: 355px;
`

const StyledText = styled.div`
  margin: 10px 0;
`

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 5px;
`

const Divider = styled.div`
  margin: 15px;
`

const UserInstructions = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.Grey1};
  font-size: 13px;
`

const StyledHelpIcon = styled.div`
  margin-right: 5px;
`

const AddTextSectionTitle = styled.h3`
  ${FontStyles.Large}
  padding: 5px 0;
`

export default CreateSecurityText
