import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import FinancialCheckTab from "../components/performFinancialCheck/FinancialCheckTab"
import validate from "../components/performFinancialCheck/PayoutDetailsComponents.validationSchema"
import { Colors } from "@flow/style"
import { v4 as uuidv4 } from "uuid"
import { checkForDepotPayoutAuthority } from "../components/utils/checkForAuthority"
import { hasData } from "../util/returnValue"

const getInitialState = (data, defaults, variables, flow) => {
  let currentStateData =
    flow?.data?.deliveries[variables.delivery.id]?.currentPayout
      ?.performFinancialCheck

  let dataFromPayoutRequestTask =
    flow?.data?.deliveries[variables.delivery.id]?.currentPayout
      ?.payoutRequestReceived

  //This is to reset after coming back from "Be om mer dokumentasjon"
  if (currentStateData) {
    currentStateData.commentToCustomer = null
    currentStateData.payoutDecision = null
  }

  return data || currentStateData || dataFromPayoutRequestTask || defaults
}

const PerformFinancialCheck = ({
  task,
  flow,
  t,
  schema,
  save,
  complete,
  onSave,
  updateCase,
  user,
}) => {
  const { data, defaults, variables } = task
  const initialState = getInitialState(
    data?.stateData,
    defaults,
    variables,
    flow
  )
  const deliveryId = task?.variables.delivery.id
  const currentDelivery = flow?.data?.deliveries[deliveryId]
  const { payoutRequestReceived } = currentDelivery.currentPayout || {}
  const [errors, setErrors] = useState(false)
  const customerAccount = flow?.data.raw?.accountMaster?.customerAccount || {}
  const applicantName = customerAccount?.name
  initialState.payments = initialState.payments || [
    {
      customerAccountNumber: payoutRequestReceived?.customerAccountNumber,
      payoutName: applicantName,
      id: uuidv4(),
      payoutOrgNumber: flow.data.raw.accountMaster.customerAccount.publicId,
      isPerson:
        flow.data.raw.accountMaster.customerAccount.accountType === "Person",
      payoutApplicantOrThirdPerson: "applicant",
      payoutAmount: payoutRequestReceived?.content?.payoutAmount,
    },
  ]
  const isPerformFinancialCheck = true

  const [stateData, setStateData] = useState(initialState)
  const [formData, setFormData] = useState(data?.formData || {})
  const [isProcessing, setProcessing] = useState(false)
  const [errorMsg, setErrorMsg] = useState([])
  const { applicationSummary, insightComponentData } = task.context
  initialState.totalPayoutAmount = stateData.totalPayoutAmount || 0
  initialState.remainingAmount = stateData.remainingAmount || 0

  const [allowedToDecide, setAllowedToDecide] = useState(undefined)
  const [allowedToDecideReason, setAllowedToDecideReason] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      const data = await checkForDepotPayoutAuthority(
        user,
        task.taskType,
        flow.flowId,
        flow.data.application.category
      )
      setAllowedToDecide(data.allowed)
      setAllowedToDecideReason(data.reason)
    }

    fetchData()
  }, [user])

  useEffect(() => {
    if (stateData.payoutType === "finalPayout") {
      setErrors(false)
    } else {
      hasErrors(stateData)
    }
  }, [stateData])

  //Valideringssjekk
  const hasErrors = (stateData) => {
    const payments = stateData.payments.filter((x) => !!x) || []
    if (!hasData(payments)) {
      setErrors(true)
      return true
    }
    const errors =
      payments.filter(
        (payment) =>
          Object.keys(payment).filter((x) => {
            if (x === "isPerson" && payment[x] !== null) return false
            return !hasData(payment[x]) || payment[x] === 0
          }).length > 0
      ).length > 0
    setErrors(errors)
  }
  stateData.whoDidIt = user?.profile?.email
  const handlePartialSave = (newData) => {
    setProcessing(true)

    onSave(
      task.taskId,
      {
        ...initialState,
        customerAccountNumber: newData.customerAccountNumber,
        karErrorMessage: newData.karErrorMessage,
      },
      () => {
        updateCase()
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = () => {
    const data = { stateData, formData }
    setProcessing(true)
    save(
      data,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const handleComplete = async () => {
    const values = { ...stateData, ...formData }
    const completeData = formatValues(values)
    setProcessing(true)

    if (
      completeData.payoutDecision !== "REJECT" &&
      completeData.payoutDecision !== "MOREINFO"
    ) {
      const isValid = await validateSchema(completeData, t)
      if (!isValid || errors) {
        /*
                                                          Mulig svar får kall til kar-sjekk:
                                                          "01": "Konto finnes og eies av angitt kunde.",
                                                          "02": "Konto er omnummerert og ny konto eies av angitt kunde.",
                                                          "03": "Konto finnes, men eies ikke av angitt kunde.",
                                                          "04": "Konto finnes ikke.",
                                                          "05": "Angitt konto er omnummerert, men ny konto finnes ikke.",
                                                          "06": "Angitt konto er omnummerert. Ny konto finnes, men eies ikke av angitt kunde.",
                                                          "09": "Ukjent registernummer i angitt konto.",
                                                          "10": "Angitt konto er ikke CDV-gyldig.",
                                                          "11": "Angitt kundenummer er ikke gyldig.",
                                                          "13": "Bankens data er sperret for oppslag fra andre banker.",
                                                          "15": "Konto er omnummerert til ny konto, og ny konto eies av kunden med ugyldig kundenummer."
                                                        Vi bør på sikt (etter avtale med IN) ikkje la dei fullføre oppgaven dersom man har fått ein av desse svara tilbake:
                                                        04 09 10 15
                                                        Då må me sjekke under på feltet resultCode i responsen frå KAR
                                                        stateData.resultCode !== ein av kodane over
                                                        */

        setProcessing(false)
        return
      }
    }
    complete(
      completeData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const formatValues = (values) => {
    // remove everything except data for the option chosen to avoid data filled in for other options to bleed over

    if (values?.payoutDecision === "OK") {
      delete values?.commentToCustomer
    }
    if (values?.payoutDecision === "REJECT") {
      delete values?.commentToCustomer
    }
    return values
  }

  const validateSchema = async (data, t) => {
    const validation = await validate(data, t)
    setErrorMsg(validation.errors)
    return validation?.isValidSchema
  }

  const manualValidationOfCommentToCustomer = (commentToCustomer) => {
    if (!commentToCustomer) return t("comment-to-customer-required")
  }

  const getFlowSchema = () => {
    const oneOf = schema?.dependencies?.payoutDecision?.oneOf
    const fields = [
      "customerAccountNumber",
      "payoutType",
      "payoutAmount",
      "isCreditCheckOutdated",
      "payoutDefinition",
      "specialConditions",
      "navRegistered",
      "projectAccounting",
      "progressReport",
      "comment",
      "karErrorMessage",
    ]

    if (stateData.mustCheckSigners) {
      fields.push("checkSigners")
    }

    oneOf?.forEach((e) => {
      const properties = e?.properties || {}
      Object.keys(properties).forEach((key) => {
        if (fields.includes(key)) {
          delete properties[key]
        }
      })
      const required = e?.required || []
      e.required = required.filter((e) => fields.includes(e))
    })
    return schema
  }

  const notificationMsg =
    stateData.payoutType === "finalPayout" && stateData.payoutAmount === 0

  return (
    <Layout forceHeight>
      <MainContent>
        <FinancialCheckTab
          deliveryId={deliveryId}
          flow={flow}
          t={t}
          stateData={stateData}
          partialSave={handlePartialSave}
          setStateData={setStateData}
          errorMsg={errorMsg}
          task={task}
          commonData={insightComponentData?.commonData}
          isPerformFinancialCheck={isPerformFinancialCheck}
        />
      </MainContent>
      <Context flow={flow} context={applicationSummary}>
        {!allowedToDecide && (
          <p style={{ color: Colors.Ferrari, paddingBottom: "5px" }}>
            {t(allowedToDecideReason)}
          </p>
        )}
        <StyledForm>
          <ReactForm
            schema={getFlowSchema()}
            formData={formData}
            disabled={isProcessing || !allowedToDecide}
            onChange={(values) => {
              setFormData({
                ...formData,
                payoutDecision: values.payoutDecision,
                commentToCustomer: values.commentToCustomer,
              })
            }}
          >
            <></>
          </ReactForm>
        </StyledForm>
        <ErrorDiv>
          {errorMsg.payoutDecision && (
            <ErrorDiv> {t("choose-payout-decision")}</ErrorDiv>
          )}
        </ErrorDiv>
        <ErrorDiv>
          {stateData.payoutDecision === "MOREINFO" &&
            manualValidationOfCommentToCustomer(stateData.commentToCustomer)}
        </ErrorDiv>
        {errors && <ErrorDiv> {t("fix-fields-please")}</ErrorDiv>}
        {notificationMsg && (
          <ErrorDiv> {t("final-payout-notification")}</ErrorDiv>
        )}
        <ButtonContainer>
          <PrimaryButton
            type="submit"
            isLoading={isProcessing}
            disabled={isProcessing || !allowedToDecide}
            onClick={() => handleComplete()}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            disabled={isProcessing}
            onClick={() => handleSave()}
          >
            {t("save")}
          </SecondaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}
export default withTranslation()(PerformFinancialCheck)
const ErrorDiv = styled.div`
  color: ${Colors.Ferrari};
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`
const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  overflow-y: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
