/*
For å kunne beslutte en sak eller melde opp en sak til en høyere beslutningsnivå eller kunne endre en sak som er oppmeldt
  - OK Bruker må være medlem av role.inflow.prod.decisionmaker
  - OK Bruker må ha aktiv fullmakt i IN Fullmaktsdatabase
  - OK Bruker kan ikke melde opp og beslutte samme sak
  - UTGÅR Beslutning utføres normalt på regionskontoret som har ansvaret for aktuelle prosjektkommune. 
    Men det er ingen krav om at prosjektets kommune må tilhøre beslutters avdeling.
    Dvs at en beslutter som er knyttet til Innlandet kan beslutte en søknad som er knyttet til Trøndelag.
    En beslutter behøver ikke se saker utenfor for sitt fylke, men må kunne velges som beslutter av en som jobber i "riktig" fylke
*/
import axios from "axios"
import { FLOWNS_IS_DEV_ENV_VAR_IS_DEV } from "../ONLY_RENDER_IN_DEVELOPMENT"

export const checkForAuthority = (
  user,
  signedUpForAssessmentBy,
  decisionLevelNeeded = undefined,
  checkRole = false
) => {
  if (user) {
    const authority = user?.profile?.authority
    // Check first if the user has authority in the claims, this means that the user exists in the Fullmaktdatabase with IN
    if (authority) {
      const isActive = authority
        .find((el) => el.includes("isActive"))
        .split(":")
        .pop()
      //if env is dev, and environment variable in local storage is set to dev, let same person complete two tasks
      const samePerson = FLOWNS_IS_DEV_ENV_VAR_IS_DEV()
        ? false
        : user?.profile?.name === signedUpForAssessmentBy

      // Check if the person is the same person who sent the case to decision
      if (samePerson) {
        return {
          allowed: false,
          reason: "You cannot access a case you yourself have rejected",
        }
      }

      // Casehandlers can not complete unless they also are decision makers AND they are not the one who sent the case to Vurder innstilling
      if (
        checkRole &&
        !user.profile.role.some(
          (r) =>
            r === "role.inflow.test.decisionmaker" ||
            r === "role.inflow.prod.decisionmaker"
        )
      ) {
        return {
          allowed: false,
          reason:
            "You are missing the role 'decisionmaker' in AD needed to decide this case",
        }
      }

      // Check if the fullmakt is active and if the user has the correct decisionLevel to make this decision
      if (isActive === "Ja") {
        const usersDecisionLevel = authority
          .find((el) => el.includes("decisionLevel"))
          .split(":")
          .pop()
        return checkIfUserIsAllowedToCompleteTask(
          decisionLevelNeeded,
          usersDecisionLevel
        )
      }
    }
  }
  return {
    allowed: false,
    reason:
      "Something went wrong when validating your access to decide on this case",
  }
}

//dersom man har rolle Seksjon kan man fullføre første task på 1 nivå
const rolesAllowedToCompleteOnLevelSeksjon = [
  "Seksjon",
  "DK - Nivå1",
  "DK - Nivå2",
  "Divisjon",
  "IN Hovedkredittutvalg",
  "IN Hovedstyre",
]
//dersom man har rolle DK - Nivå kan man fullføre første task på 2 nivå
const rolesAllowedToCompleteOnLevelDKNiva1 = [
  "DK - Nivå1",
  "DK - Nivå2",
  "Divisjon",
  "IN Hovedkredittutvalg",
  "IN Hovedstyre",
]
//dersom man har rolle DK - Nivå2 kan man fullføre første task på alle 3 nivå
const rolesAllowedToCompleteOnLevelDKNiva2 = [
  "DK - Nivå2",
  "Divisjon",
  "IN Hovedkredittutvalg",
  "IN Hovedstyre",
]
//dersom man har rolle Divisjon kan man fullføre første task på 1 nivå
const rolesAllowedToCompleteOnLevelDivision = [
  "Divisjon",
  "IN Hovedkredittutvalg",
  "IN Hovedstyre",
]
//dersom man har rolle IN Hovedkredittutvalg kan man fullføre dei to første
const rolesAllowedToCompleteOnLevelHovedkredittutvalg = [
  "IN Hovedkredittutvalg",
  "IN Hovedstyre",
]
//dersom man har rolle IN Hovedstyr kan man fullføre alle saker
const rolesAllowedToCompleteOnLevelHovedstyre = ["IN Hovedstyre"]

const allowedResponse = {
  allowed: true,
  reason: "",
}
const notAllowedResponse = {
  allowed: false,
  reason: "You do not have the rights to access this case",
}

function checkIfUserIsAllowedToCompleteTask(
  decisionLevelNeeded,
  usersDecisionLevel
) {
  let response
  switch (decisionLevelNeeded) {
    case "Seksjon":
      rolesAllowedToCompleteOnLevelSeksjon.includes(usersDecisionLevel)
        ? (response = allowedResponse)
        : (response = notAllowedResponse)
      break
    case "DK - Nivå1":
      rolesAllowedToCompleteOnLevelDKNiva1.includes(usersDecisionLevel)
        ? (response = allowedResponse)
        : (response = notAllowedResponse)
      break
    case "DK - Nivå2":
      rolesAllowedToCompleteOnLevelDKNiva2.includes(usersDecisionLevel)
        ? (response = allowedResponse)
        : (response = notAllowedResponse)
      break
    case "Divisjon":
      rolesAllowedToCompleteOnLevelDivision.includes(usersDecisionLevel)
        ? (response = allowedResponse)
        : (response = notAllowedResponse)
      break
    case "IN Hovedkredittutvalg":
      rolesAllowedToCompleteOnLevelHovedkredittutvalg.includes(
        usersDecisionLevel
      )
        ? (response = allowedResponse)
        : (response = notAllowedResponse)
      break
    case "IN Hovedstyre":
      rolesAllowedToCompleteOnLevelHovedstyre.includes(usersDecisionLevel)
        ? (response = allowedResponse)
        : (response = notAllowedResponse)
      break
    default:
      response = notAllowedResponse
      break
  }
  return response
}

export const checkForDepotPayoutAuthority = async (
  user,
  task,
  flowId,
  category
) => {
  const allowedRoles = [
    "role.inflow.test.depot",
    "role.inflow.prod.depot",
    "role.inflow.test.payoutcontrol",
    "role.inflow.prod.payoutcontrol",
    "role.inflow.test.reskontro",
    "role.inflow.prod.reskontro",
    "role.inflow.test.payoutdecision",
    "role.inflow.prod.payoutdecision",
  ]

  //IN har bestemt at for kategorien skipsfart og fiske skal saksbehandler kunne utføre økonomisk kontroll
  if (category === "shippingandfishery") {
    allowedRoles.push(
      ...["role.inflow.test.casehandler", "role.inflow.prod.casehandler"]
    )
  }

  if (user) {
    const currentUser = user?.profile?.name

    const depotTask = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=completed&taskType=control-priority`
    )
    const performFinancialTasks =
      (await axios.get(
        `/api/flow/tasks?flowId=${flowId}&status=completed&taskType=perform-financial-check`
      )) || []

    const userWhoControlledLoanDepot =
      depotTask?.data?.at(0)?.data?.stateData?.controlLoanAmountUser
    const userWhoCompletedDepotTask = depotTask?.data?.at(0)?.user?.name

    //A check whether the process went to Tinglysing
    let samePerson
    if (depotTask.data.length > 0) {
      if (task === "perform-financial-check") {
        //Check if they are the same person
        // Also check who controlled the loan, if the loan was above 10 million.
        if (userWhoControlledLoanDepot) {
          samePerson =
            currentUser === userWhoCompletedDepotTask ||
            currentUser === userWhoControlledLoanDepot
        } else {
          samePerson = currentUser === userWhoCompletedDepotTask
        }
      }

      if (task === "direct-payout") {
        const userThatCompletedTheLatestPerformFinancialTask =
          performFinancialTasks.data?.pop()

        if (userWhoControlledLoanDepot) {
          samePerson =
            currentUser === userWhoCompletedDepotTask ||
            currentUser ===
              userThatCompletedTheLatestPerformFinancialTask.user?.name ||
            currentUser === userWhoControlledLoanDepot
        } else {
          samePerson =
            currentUser === userWhoCompletedDepotTask ||
            currentUser ===
              userThatCompletedTheLatestPerformFinancialTask.user?.name
        }
      }

      if (task === "begin-payout") {
        const userThatCompletedTheLatestPerformFinancialTask =
          performFinancialTasks.data?.pop()

        // Check direct-payout also
        const directPayoutTask = await axios.get(
          `/api/flow/tasks?flowId=${flowId}&status=completed&taskType=direct-payout`
        )
        const directPayoutCaseworker = directPayoutTask?.data?.at(0)?.user?.name

        if (userWhoControlledLoanDepot) {
          samePerson =
            currentUser === userWhoCompletedDepotTask ||
            currentUser === userWhoControlledLoanDepot ||
            currentUser ===
              userThatCompletedTheLatestPerformFinancialTask.user?.name ||
            currentUser === directPayoutCaseworker
        } else {
          samePerson =
            currentUser === userWhoCompletedDepotTask ||
            currentUser ===
              userThatCompletedTheLatestPerformFinancialTask.user?.name ||
            currentUser === directPayoutCaseworker
        }
      }
    } else {
      if (task === "begin-payout") {
        const userThatCompletedTheLatestPerformFinancialTask =
          performFinancialTasks.data?.pop()

        // Check direct-payout also
        const directPayoutTask = await axios.get(
          `/api/flow/tasks?flowId=${flowId}&status=completed&taskType=direct-payout`
        )
        const directPayoutCaseworker = directPayoutTask.data.at(0).user.name

        samePerson =
          currentUser ===
            userThatCompletedTheLatestPerformFinancialTask?.user?.name ||
          currentUser === directPayoutCaseworker
      } else if (task === "direct-payout") {
        // Check if the person is the same person who completed tasks [perform-financial-check, control-priority]
        const userThatCompletedTheLatestPerformFinancialTask =
          performFinancialTasks.data?.pop()

        samePerson =
          currentUser === userWhoCompletedDepotTask ||
          currentUser ===
            userThatCompletedTheLatestPerformFinancialTask?.user?.name
      }
    }

    if (samePerson)
      // Check if the person is the same person who completed the task
      return notAllowedResponse

    return allowedRoles.some((x) => user.profile?.role.includes(x))
      ? allowedResponse
      : notAllowedResponse
  }
  return notAllowedResponse
}
