import React from "react"
import StyledBox from "../../common/StyledBox"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import GridContent from "./GridContent"

const RemainingAmount = ({
  commitment,
  t,
  stateData,
  payoutRequestReceived,
  remainingAmount,
}) => {
  return (
    <GridContent
      title={t("remainingAmount")}
      data={
        (remainingAmount
          ? addThousandSeparator(remainingAmount.toString())
          : "0") + " NOK"
      }
    />
  )
}

export default RemainingAmount
