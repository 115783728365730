import React from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"

const TextButton = ({
  children,
  onClick,
  disabled = false,
  padding = true,
  paddingConfiguration,
}) => {
  return (
    <TextButtonWrapper
      padding={padding}
      type={"button"}
      onClick={onClick}
      disabled={disabled}
      paddingConfiguration={paddingConfiguration}
    >
      {children}
    </TextButtonWrapper>
  )
}
export default TextButton

const TextButtonWrapper = styled.button`
  display: flex;
  color: ${({ disabled }) => (disabled ? Colors.Grey2 : Colors.Sea)};
  background-color: inherit;
  border: none;
  padding: ${(props) => (props.padding ? "10px 0px 10px 20px" : "0")};
  padding: ${({ paddingConfiguration }) => paddingConfiguration};
  cursor: pointer;
  ${({ disabled }) => (disabled ? "" : "cursor: pointer;")};
`
