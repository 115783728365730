import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"

const BaseLayout = ({ task, t, flow, save, complete, trigger, schema }) => {
  const { data, defaults } = task

  const stateData = {}
  const taskHasData = data && Object.keys(data).length > 0
  const [formData, setFormData] = useState(
    taskHasData ? data : stateData ? stateData : defaults
  )
  const [isProcessing, setProcessing] = useState(false)

  const onFormChange = (values) => {
    setFormData(values)
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      formData,
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    const completeTask =
      task.taskCategory === "message-task" ? trigger : complete
    setProcessing(true)
    completeTask(
      formData,
      () => setProcessing(false),
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <MainContainer>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton type="submit" isLoading={isProcessing}>
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
        </ReactForm>
      </MainContainer>
      <Context context={task.context?.applicationSummary} flow={flow}></Context>
    </Layout>
  )
}

const MainContainer = styled.div`
  padding: 15px;
  width: 100%;
  overflow-y: auto;
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

export default withTranslation()(BaseLayout)
