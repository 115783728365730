import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import EvaluateBasisForSupportCommon from "../components/evaluateBasisForSupport"
import EvaluateBasisForSupportProduct from "../components/evaluateBasisForSupport/extraordinaryFinancing"
import axios from "axios"
import { CenteredSpinner } from "@flow/icons"

/*
Retrieve all products that are relevant for currenet category, used for validation and dropdown in frontend
 */

const mapProductConfiguration = (productRegistry, category) =>
  Object.keys(productRegistry)?.reduce((acc, key) => {
    if ("productType" in productRegistry[key]) {
      const current = productRegistry[key]
      if (!current.category.includes(category)) return acc
      acc[key] = {
        ...current,
        displayName: current.displayNameShortVmCode,
        ...current.measurement,
      }
    }
    return acc
  }, {})

const EvaluateBasisForSupport = (props) => {
  const { task } = props
  const {
    extraordinaryFinancingProduct,
    extraordinaryFinancingCategory,
    category,
  } = task.context

  /**
   * Due to getContext not being async do we need to fetch the products from
   * the product registry when loading the component.
   */
  const [products, setProducts] = useState()
  useEffect(() => {
    const callback = async () => {
      const { data } = await axios("/api/products?productCategory=" + category)
      const productRegistry = data.reduce((acc, product) => {
        acc[product.productId] = product
        return acc
      }, {})

      setProducts(
        mapProductConfiguration(productRegistry, task.context.category)
      )
    }
    callback()
  }, [])

  if (!products) return <CenteredSpinner />

  task.context.productConfiguration = products

  return category === extraordinaryFinancingCategory ? (
    <EvaluateBasisForSupportProduct
      extraordinaryFinancingCategory={extraordinaryFinancingCategory}
      extraordinaryFinancingProduct={extraordinaryFinancingProduct}
      {...props}
    />
  ) : (
    <EvaluateBasisForSupportCommon {...props} />
  )
}

export default withTranslation("EvaluateBasisForSupport")(
  EvaluateBasisForSupport
)
