import React from "react"
import styled from "styled-components"
import { FontStyles } from "@flow/style"

const BoxStyled = styled.div`
  display: flex;
  padding: ${({ padding }) => padding || "10px 30px 10px 10px"};
  flex-direction: column;

  .title,
  .data {
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .title {
    padding-right: 5px;
    ${({ titleSize, large }) =>
      large ? FontStyles.Normal : titleSize ? titleSize : FontStyles.Small};
    ${({ titleStyling }) => `color: ${titleStyling?.color}`};
  }

  .data {
    ${({ large }) => (large ? FontStyles.Large : FontStyles.Normal)};
    ${({ dataStyling }) => dataStyling?.fontSize && dataStyling?.fontSize};

    margin-top: 3px;
    color: ${(props) =>
      props.dataStyling?.color ? props.dataStyling.color : "black"};
    width: ${({ width }) => width || "unset"};
  }
`

const Whitespace = styled.div`
  white-space: ${({ whiteSpace }) => (whiteSpace ? "pre-wrap" : "inherit")};
`

const StyledBox = ({
  title,
  whiteSpace,
  padding,
  data,
  large,
  children,
  titleSize,
  width,
  dataStyling = {},
  titleStyling = {},
}) => {
  return (
    <BoxStyled
      width={width}
      padding={padding}
      large={large}
      titleSize={titleSize}
      dataStyling={dataStyling}
      titleStyling={titleStyling}
    >
      <div className="title">
        <div>{title}</div>
      </div>
      <div className="data">
        <Whitespace whiteSpace={whiteSpace}>{data}</Whitespace>
        <div>{children}</div>
      </div>
    </BoxStyled>
  )
}
export default StyledBox
