import { withTranslation } from "react-i18next"
import styled from "styled-components"
import React, { useState, useCallback } from "react"
import Heading from "../../components/common/Heading"
import ReactForm from "../../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import GuarantorCard from "../../components/bail/GuarantorCard"
import { personFirstPred } from "../../util/guarantorUtils"
import DownloadFileButton from "../../components/common/DownloadFileButton"
import Tabs from "../../components/common/Tabs"
import InsightModule from "../../components/insight/InsightModule"
import { useInsightAppInitialized } from "../../components/insight/useInsightAppInitialized"
import { Context } from "../../components/common/Layout"
import SpecialTermsTab from "../AnalyzeApplication/SpecialTermsTab"
import DisplaySecurityText from "../TermsAndSecurity/DisplaySecurityText"
import DecisionTextsList from "../maintenance/DecisionTextsList"
import {
  getNewAndExistingEngagements,
  mapLoansWithAgreementData,
} from "../utils/mapAgreements"

const DocumentPacket = ({ guarantors = [], relevantDocuments = [], t }) => {
  const isSigningWithCollateral = guarantors.some(
    (x) => x.isSigningWithCollateral
  )
  const signDocs = relevantDocuments?.filter(
    (x) => !!x && x.signType === "sign"
  )
  const viewDocs = relevantDocuments?.filter(
    (x) => !!x && x.signType === "view"
  )

  return (
    <Content>
      <GuarantorContainer>
        {guarantors.sort(personFirstPred).map((x, i) => (
          <GuarantorCard
            readonly
            t={t}
            guarantor={x}
            key={i}
            isSigningWithCollateral={isSigningWithCollateral}
          ></GuarantorCard>
        ))}
      </GuarantorContainer>
      <div>
        <Heading>{t("relevant-documents")}</Heading>
        <Row>
          {signDocs.length > 0 ? (
            signDocs?.map((x, i) => (
              <DownloadFileButton
                title={t(x?.title)}
                fileId={x?.storeID}
                key={i}
              />
            ))
          ) : (
            <p>Ingen dokumenter</p>
          )}
        </Row>
      </div>
      <div>
        <Heading>{t("documents-to-view")}</Heading>
        <Row>
          {viewDocs.length > 0 ? (
            viewDocs?.map((x, i) => (
              <DownloadFileButton
                title={t(x?.title)}
                fileId={x?.storeID}
                key={i}
              />
            ))
          ) : (
            <p>Ingen dokumenter</p>
          )}
        </Row>
      </div>
    </Content>
  )
}

const PrintAndSendToSignNew = ({
  t,
  guarantors,
  task,
  schema,
  save,
  complete,
  flow,
  isMaintenance,
}) => {
  const { variables } = task
  const { insightComponentData } = task.defaults
  const [taskData, setTaskData] = useState(task.data || task.defaults)
  const [isProcessing, setProcessing] = useState(false)

  const engagements = flow.data.mapped.engagement.loans.map((loan) => {
    return {
      ...loan,
      riskGroupCode:
        task.data?.loans?.find(
          (taskLoan) => loan.accountNumber === taskLoan.accountNumber
        )?.riskGroupCode ?? loan.riskGroupCode,
    }
  })
  const securityRiskData =
    flow.data.analysis?.termsAndSecurity?.input?.securityRiskData ?? []

  const newAndExistingCollateralEngagements = getNewAndExistingEngagements(
    flow.data.deliveries,
    flow.data.mapped?.collateralEngagements || []
  )
  const mappedLoansWithCollateralAgreements = mapLoansWithAgreementData(
    flow.data.mapped.agreementData || [],
    newAndExistingCollateralEngagements,
    engagements,
    securityRiskData
  )

  const handleSave = (values) => {
    setProcessing(true)

    save(
      taskData,
      () => {},
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    delete taskData.relevantDocuments
    delete taskData.relevantMortgagors

    setProcessing(true)
    complete(
      taskData,
      () => {},
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const relevantDocuments = task.defaults.relevantDocuments
    ?.sort((a, b) => a.fileName.localeCompare(b.fileName))
    ?.map((x) => ({
      title: x.fileName,
      storeID: x.storeID,
      signType: x.signType,
    }))

  const [activeApp, setActiveApp] = useState(null)
  const [isLoading] = useInsightAppInitialized(activeApp)

  const onAppChange = useCallback(
    (app) => {
      setActiveApp(app)
    },
    [setActiveApp]
  )

  const options = [
    {
      id: "documentpacket",
      title: "Dokumentpakke",
      component: (
        <DocumentPacket
          relevantDocuments={relevantDocuments}
          t={t}
          guarantors={guarantors}
        />
      ),
    },
    {
      id: "agreementRegisterSummary",
      title: "Sikkerhet- og avtaleoppsett",
      component: (
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          onAppChange={onAppChange}
          commonData={insightComponentData}
          data={{
            agreementData: mappedLoansWithCollateralAgreements,
          }}
          options={{
            context: "agreementregistersummaryNoHeader",
          }}
        />
      ),
    },
    {
      id: "specialconditions",
      title: "Særvilkår",
      component: (
        <SpecialTermsTab
          deliveries={flow?.data?.deliveries}
          onChange={() => {}}
          t={t}
          readOnly={true}
        />
      ),
    },
    {
      id: "securityTexts",
      title: "Sikkerhetstekster",
      component: (
        <SecurityTextContainer>
          {flow?.data?.deliveries[variables?.delivery?.id]?.securityTexts?.map(
            (securityText, securityTextIndex) => {
              return (
                <DisplaySecurityText
                  t={t}
                  key={securityTextIndex}
                  isEmpty={!securityText?.subtitle?.length}
                  isFirst={securityTextIndex === 0}
                  securityText={securityText}
                  userGenerated={securityText?.userGenerated}
                  isReadOnly={true}
                />
              )
            }
          )}
        </SecurityTextContainer>
      ),
    },
  ]

  //Legge til tabber dersom det er vedlikehold
  if (isMaintenance) {
    const decisionTexts = flow?.data?.deliveries?.vedlikehold?.changes
    options.push({
      id: "decisiontexts",
      title: t("decisionTexts"),
      component: (
        <DecisionStyle>
          <DecisionTextsList t={t} changes={decisionTexts} />{" "}
        </DecisionStyle>
      ),
    })
  }

  return (
    <>
      <MainContainer>
        <Tabs loading={isLoading} options={options} />
      </MainContainer>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <ReactForm
          schema={schema}
          formData={taskData}
          disabled={isProcessing}
          onChange={(values) => setTaskData(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isLoading}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave(taskData)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </>
  )
}

const MainContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`

const GuarantorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

const Row = styled.div`
  display: flex;
  gap: 15px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

const Content = styled.div`
  padding: 20px 40px;
`

const SecurityTextContainer = styled.div`
  padding: 23px;
`

const DecisionStyle = styled.div`
  padding: 20px;
`

export default withTranslation()(PrintAndSendToSignNew)
