import React, { useState } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import DisplayAnnulment from "../components/Annulment/DisplayAnnulment"

const AnnulmentCancellationFee = ({
  task,
  flow,
  t,
  schema,
  save,
  complete,
  user,
}) => {
  const { data, defaults, variables } = task
  const [stateData, setStateData] = useState(data || defaults)
  const [isProcessing, setProcessing] = useState(false)
  const delivery = flow?.data?.deliveries[variables.delivery.id]

  const handleComplete = (stateData) => {
    setProcessing(true)
    complete(
      stateData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  return (
    <Layout forceHeight>
      <MainContent>
        <DisplayAnnulment
          stateData={stateData}
          setStateData={setStateData}
          delivery={delivery}
          t={t}
        />
      </MainContent>
      <Context flow={flow} context={task.context.applicationSummary}>
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={stateData}
            disabled={isProcessing}
            onChange={(values) => setStateData({ ...stateData, values })}
          >
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                isLoading={isProcessing}
                onClick={() => handleComplete(stateData)}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={() => save()}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledForm>
      </Context>
    </Layout>
  )
}
export default withTranslation()(AnnulmentCancellationFee)

const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow-y: auto;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`
