import React, { useState } from "react"
import styled from "styled-components"
import AccountForPayout from "./AccountForPayout/AccountForPayout"
import { Colors, FontStyles } from "@flow/style"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import { Input } from "@staccx/bento"
import WarningInfoBox from "../../common/WarningInfoBox"
import GridContent from "./GridContent"
import { Spinner } from "@flow/icons"
import { PersonOrThirdRadio } from "./personOrThirdRadio"
import IconButton from "../../common/IconButton"
import axios from "axios"
import { hasData } from "../../../util/returnValue"

/**
 * @typedef {Object} PayoutDetailsFormProps
 * @property {Object} commitment - Description for commitment
 * @property {Object} stateData - Description for stateData
 * @property {Function} setStateData - Description for setStateData
 * @property {Function} onDelete - Description for onDelete
 * @property {Function} onChange - (payment) => onChange(payment)
 * @property {Payment} payment - Description for payment
 * @property {PayoutRequestReceived} payoutRequestReceived - Description for payoutRequestReceived
 * @property {boolean} isPerformFinancialCheck - Description for isPerformFinancialCheck
 */

/**
 * @param {PayoutDetailsFormProps} props
 */
const PayoutDetailsForm = ({
  flowId,
  karCheckMessage,
  commitment,
  flow,
  t,
  stateData,
  setStateData,
  readonly,
  partialSave,
  errorMsg,
  deliveryId,
  onDelete,
  onChange,
  payment,
  index,
  payoutRequestReceived,
}) => {
  /**
   * @param {Event} e
   * @param {"payoutType" | "payoutAmount" | "payoutName" | "payoutOrgNumber" | "payoutApplicantOrThirdPerson"} key
   * @returns {void}
   */
  const change = async (e, key) => {
    if (readonly) return
    if (key === "payoutAmount") {
      const value = parseInt(e.rawValue)
      payment[key] = value
      onChange(payment)
      return
    }

    if (key === "payoutType") {
      const value = e.target.value
      payment[key] = value
      onChange(payment)
      return
    }
    if (key === "payoutName") {
      const value = e.target.value
      payment[key] = value
      onChange(payment)
      return
    }
    if (key === "payoutOrgNumber") {
      const value = e.target.value
      payment[key] = value
      onChange(payment)

      const isPerson = value?.length === 11
      payment["isPerson"] = isPerson

      if (value?.length === 11 || value?.length === 9) {
        payment["payoutName"] = await checkDaName(value)
        onChange(payment)
      }
      return
    }
    if (key === "payoutApplicantOrThirdPerson") {
      const value = e.target.value
      payment[key] = value
      onChange(payment)
      return
    }
  }

  const checkDaName = async (searchString) => {
    if (searchString.length === 11) {
      const response = await axios.get(
        `/api/applicantsName?applicantSSN=${searchString}`
      )
      return response?.data?.name
    }
    if (searchString.length === 9) {
      const response = await axios.get(
        `/api/organizationName?orgNumber=${searchString}`
      )
      return response?.data
    }
    return ""
  }
  const customerAccount = flow?.data.raw?.accountMaster?.customerAccount || {}
  const applicantName = customerAccount?.name

  const checkValid = (error, t) => {
    return (
      error === "Konto finnes og eies av angitt kunde." ||
      error === t("wait-karcheck-message")
    )
  }
  const [KARMessage, setKARMessage] = useState(
    payment.karErrorMessage || karCheckMessage
  )

  const [current, setCurrent] = useState(
    payment?.payoutApplicantOrThirdPerson || "applicant"
  )
  const handleChange = (value) => {
    if (value.target.value === "thirdParty") {
      payment = {
        ...payment,
        payoutName: null,
        payoutOrgNumber: null,
        customerAccountNumber: null,
        payoutApplicantOrThirdPerson: "thirdParty",
        karErrorMessage: null,
      }
      setKARMessage(t("no-karcheck"))
      onChange(payment)
    } else {
      payment = {
        ...payment,
        payoutName: applicantName,
        payoutOrgNumber: flow.data.raw.accountMaster.customerAccount.publicId,
        customerAccountNumber: payoutRequestReceived?.customerAccountNumber,
        payoutApplicantOrThirdPerson: "applicant",
        karCheckMessage: null,
      }
      setKARMessage(t("no-karcheck"))
      onChange(payment)
    }
    setCurrent(value.target.value)
  }
  const isThirdParty = current === "thirdParty"

  const isBirthNumber = payment?.payoutOrgNumber?.length === 11
  const isOrgNumber = payment?.payoutOrgNumber?.length === 9

  const payoutAmount = payment?.payoutAmount
    ? payment?.payoutAmount
    : stateData?.payoutAmount

  const [mode, setMode] = useState("none")
  const isApplicant = current === "applicant"
  let payoutField = 0
  if (!!payment?.payoutAmount) {
    payoutField = payment?.payoutAmount
  } else {
    payoutField = index === 0 && isApplicant ? payoutAmount : 0
  }

  const isKarChecked =
    mode === "none" && payment?.customerAccountNumber?.length >= 11
  const isNameFilled = hasData(payment?.payoutName)
  return (
    <>
      <Content>
        <FlexLine>
          <PersonOrThirdRadio
            t={t}
            handleChange={(e) => handleChange(e)}
            current={current}
            value={payment?.payoutApplicantOrThirdPerson}
            readonly={readonly}
          />
          <IconButton
            onClick={() => onDelete()}
            icon={"Trashcan"}
            disabled={readonly}
          />
        </FlexLine>
        <GridLine>
          {!readonly && isThirdParty ? (
            <InputStyled
              name="payout-orgnumber"
              id="payout-orgnumber"
              inputMode="numeric"
              label={t("payout-identifier")}
              disabled={readonly || isKarChecked}
              value={payment?.payoutOrgNumber}
              onChange={(e) => change(e, "payoutOrgNumber")}
            />
          ) : (
            <GridContent
              title={t("payout-identifier")}
              data={payment?.payoutOrgNumber}
              contentProps={{ color: Colors.Coal }}
            />
          )}
          <AccountForPayout
            key={payment?.payoutApplicantOrThirdPerson}
            class="account"
            flowId={flowId}
            readonly={readonly}
            data={payment?.customerAccountNumber}
            partialSave={partialSave}
            stateData={stateData}
            setStateData={setStateData}
            setErrorMessage={setKARMessage}
            deliveryId={deliveryId}
            t={t}
            payment={payment}
            index={index}
            mode={mode}
            setMode={setMode}
          />

          <GridContent
            title={t("account-validation")}
            contentProps={{
              color: !checkValid(KARMessage, t) && Colors.Ferrari,
            }}
            data={() => (
              <KARContainer>
                {KARMessage === t("wait-karcheck-message") && (
                  <>
                    <Spinner size={24} />
                    <Margin right="5px" display="inline" />
                  </>
                )}
                {KARMessage}
              </KARContainer>
            )}
          ></GridContent>
        </GridLine>
        <GridLine>
          <div>
            {!readonly && isThirdParty ? (
              <InputStyled
                name="payout-name"
                id="payout-name"
                inputMode="numeric"
                type="text"
                label={t("payout-name")}
                disabled={
                  readonly ||
                  (isBirthNumber && isNameFilled) ||
                  (isOrgNumber && isNameFilled) ||
                  isKarChecked
                }
                value={payment?.payoutName}
                onChange={(e) => change(e, "payoutName")}
              />
            ) : (
              <GridContent
                title={t("payout-name")}
                data={payment?.payoutName}
                contentProps={{ color: Colors.Coal }}
              />
            )}
          </div>
          <div>
            {!readonly ? (
              <InputStyled
                name="payout-amount"
                id="payout-amount"
                mode="currency"
                inputMode="numeric"
                pattern={/[0-9]*/}
                type="text"
                label={t("payout-amount")}
                disabled={readonly}
                value={addThousandSeparator(payoutField)}
                onChange={(e) => change(e, "payoutAmount")}
              />
            ) : (
              <GridContent
                title={t("payout-amount")}
                data={addThousandSeparator(payoutField)}
                contentProps={{ color: Colors.Coal }}
              />
            )}
            {commitment?.accountByAccountName.openFee > 0 && (
              <WarningInfoBox
                padding="15px"
                width="182px"
                margin="0.5em 0em 0em 0em"
                text={t("establishment-fee-text")}
                fontSize={FontStyles.Small}
              />
            )}
            {errorMsg?.payoutAmount && (
              <ErrorField> {errorMsg?.payoutAmount}</ErrorField>
            )}
          </div>
        </GridLine>
      </Content>
    </>
  )
}
export default PayoutDetailsForm

const Content = styled.div`
  background-color: ${Colors.SeaLighter};
  margin-top: 1rem;
  padding: 1.5rem;
  border-radius: 8px;
`
const GridLine = styled.div`
  padding: 0.5em 2em 1em 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
  grid-gap: 5em;
  align-items: center;
`
const FlexLine = styled.div`
  padding: 0.5em 2em 1em 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const InputStyled = styled(Input)`
  input {
    margin: 0;
    border: solid 1px ${Colors.Grey2};
    width: 200px;
    border-radius: 0.4em;
    padding: 0.5em;
  }

  label {
    font-size: 12px;
    line-height: 1.4em;
    letter-spacing: 0.01em;
    padding: 0.2em;
  }
`

const KARContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Margin = styled.div`
  display: ${({ display }) => display || "block"};
  margin-left: ${({ left }) => left || "0px"};
  margin-top: ${({ top }) => top || "0px"};
  margin-right: ${({ right }) => right || "0px"};
  margin-bottom: ${({ bottom }) => bottom || "0px"};
`

const ErrorField = styled.span`
  color: ${Colors.Ferrari};
`

/**
 * @typedef {Object} Payment
 * @property {string} customerAccountNumber - The customer account number
 * @property {string} payoutName - The name of the payout
 * @property {string} id - The id of the payout
 * @property {string} payoutOrgNumber - The org number of the payout
 * @property {boolean} isPerson - Is the payout a person
 * @property {string} payoutApplicantOrThirdPerson - Is the payout the applicant or a third person
 * @property {number} payoutAmount - The amount of the payout
 */

/**
 * @typedef {Object} PayoutRequestReceived - The payout request received
 * @property {string} customerAccountNumber - The customer account number
 * @property {string} payoutType - The payout type
 * @property {number} payoutAmount - The payout amount
 * @property {boolean} mustCheckSigners - Must check signers
 * @property {boolean} isCreditCheckOutdated - Is the credit check outdated
 * @property {Array} attachments - The attachments
 * @property {Array} relevantGuarantorIds - The relevant guarantor ids
 * @property {boolean} debtorHasOutdatedCreditCheck - Does the debtor have an outdated credit check
 * @property {Array} expiredCreditcheckGuarantors - The expired credit check guarantors
 * @property {Object} content - The content
 * @property {string} content.projectStarted - Has the project started
 * @property {string} content.paymentDetails - The payment details
 * @property {string} content.customerName - The customer name
 * @property {string} content.referenceNumber - The reference number
 * @property {string} content.bankaccountName - The bank account name
 * @property {string} content.instrumentName - The instrument name
 * @property {string} content.approveDate - The approve date
 * @property {string} content.availableAmount - The available amount
 * @property {string} content.payoutAmount - The payout amount
 * @property {string} content.remainingAmount - The remaining amount
 * @property {string} content.payoutBankaccountNumber - The payout bank account number
 * @property {string} content.payoutType - The payout type
 * @property {string} content.attachmentsOfferLetter - The attachments offer letter
 * @property {string} content.feedback - The feedback
 * @property {Object} submittedForm - The submitted form
 * @property {string} submittedForm.title - The title
 * @property {Array} submittedForm.formValues - The form values
 * @property {Object} submittedForm.submitter - The submitter
 * @property {string} submittedForm.submitter.name - The submitter name
 * @property {string} submittedForm.submitter.roleNames - The submitter role names
 * @property {string} submittedForm.submitter.email - The submitter email
 * @property {string} submittedForm.submitter.phone - The submitter phone
 * @property {Array} submittedForm.confirmations - The confirmations
 * @property {string} submittedForm.confirmations[0] - The first confirmation
 * @property {string} submittedForm.confirmations[1] - The second confirmation
 * @property {string} submittedForm.confirmations[2] - The third confirmation
 * @property {string} submittedForm.confirmations[3] - The fourth confirmation
 */
