import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { Icons } from "@flow/icons"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"

import { TaskContainer, TaskDescription } from "./TaskBase"

const PendingTask = ({
  title,
  actionTitle,
  path,
  disabled,
  isPrimaryAction,
}) => (
  <TaskContainer>
    {isPrimaryAction ? (
      <TaskDescription>
        <Icons.ActiveCircle />
        <p>{title}</p>
      </TaskDescription>
    ) : (
      <TaskDescription>
        <p style={{ paddingLeft: "5px" }}>{title}</p>
      </TaskDescription>
    )}
    {disabled ? (
      isPrimaryAction ? (
        <PrimaryButton disabled>{actionTitle}</PrimaryButton>
      ) : (
        <SecondaryButton disabled>{actionTitle}</SecondaryButton>
      )
    ) : (
      <Link to={path}>
        {isPrimaryAction ? (
          <PrimaryButton>{actionTitle}</PrimaryButton>
        ) : (
          <SecondaryButton>{actionTitle}</SecondaryButton>
        )}
      </Link>
    )}
  </TaskContainer>
)

PendingTask.propTypes = {
  title: PropTypes.string.isRequired,
  actionTitle: PropTypes.string,
  path: PropTypes.string,
  disabled: PropTypes.bool,
}

PendingTask.defaultProps = {
  path: "",
  actionTitle: "open",
  disabled: false,
}

export default PendingTask
