/**
 * Copied from /setup/process/_common/constants/categories.js
 * Remember to keep these files in sync whenever a
 */
export const categoriesConstants = {
  EXTRAORDINARY_FINANCING: "extraordinaryfinancing",
  STARTUP: "startup",
  STARTUPMATIL: "startupmatil",
  COUNTER_GUARANTEE: "counterguarantee",
  AGRICULTURE: "agriculture",
  EXPORTPROMOTION: "exportpromotion",
  AGRICULTURE_ADDITIONAL_BUSINESS: "agricultureadditionalbusiness",
  BIOENERGY: "bioenergy",
  CLUSTER: "cluster",
  MAINTENANCE: "maintenance",
  DSF: "shippingandfishery",
  DNI: "businessdevelopmentandinnovation",
  HIP: "humanitarianinnovationproject",
}

/**
 * Shared function to map flow category to insight context.
 * @param {string} category The category of this flow
 * @param {boolean} isDecision If this is a "decision" context (basically means read-only versions of the cards)
 * @param {boolean | undefined} needAnalysis Determines if matil context should be used. If not undefined and false (needAnalysis === false), then context is matil. Relevant for specific cases like CreateCreditMemo/AssessDecision.
 * @returns {string} Insight context string
 */
export default function getInsightContextForCategory(
  category,
  isDecision = false,
  needAnalysis = undefined
) {
  if (!category) {
    throw new Error(
      "getInsightContextForCategory() called without a category specified."
    )
  }

  if (!Object.values(categoriesConstants).includes(category)) {
    throw new Error(
      `getInsightContextForCategory() called with unknown category "${category}"`
    )
  }

  const isExtraordinaryFinancing =
    category === categoriesConstants.EXTRAORDINARY_FINANCING
  const isAgriculture = category === categoriesConstants.AGRICULTURE
  const isBioEnergy = category === categoriesConstants.BIOENERGY
  const isMass = category === categoriesConstants.EXPORTPROMOTION
  const isDtil =
    category === categoriesConstants.AGRICULTURE_ADDITIONAL_BUSINESS
  const isMaintenance = category === categoriesConstants.MAINTENANCE
  const isDsf = category === categoriesConstants.DSF
  const isDni = category === categoriesConstants.DNI
  const isHip = category === categoriesConstants.HIP
  if (isDecision) {
    if (isMass) return "massDecision"
    if (needAnalysis === false) return "decisionMatil"
    if (isExtraordinaryFinancing) return "decisionUtInntBortfall"
    if (isAgriculture) return "agricultureDecision"
    if (isBioEnergy) return "bioEnergyDecision"
    if (isDtil) return "dtilDecision"
    if (isMaintenance) return "maintenanceDecision"
    if (isDsf) return "dsfDecision"
    if (isDni) return "dniDecision"
    if (isHip) return "decisionHip"

    return "decision"
  }
  if (isMass) return "mass"
  if (needAnalysis === false) return "proposalMatil"
  if (isExtraordinaryFinancing) return "proposalUtInntBortfall"
  if (isAgriculture) return "proposalAgriculture"
  if (isBioEnergy) return "proposalBioEnergy"
  if (isDtil) return "proposalDtil"
  if (isMaintenance) return "proposalMaintenance"
  if (isDsf) return "proposalDsf"
  if (isDni) return "proposalDni"
  if (isHip) return "proposalHip"

  return "proposal"
}
