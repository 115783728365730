/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from "react"
import { withTranslation } from "react-i18next"
import Summary from "./components/caseSummary/Summary"
import DepotControlSummary from "./components/caseSummary/DepotControlSummary"

const CaseSummary = ({ caseData, t }) => {
  const { data } = caseData || {}
  const flowDefinition = caseData.flowDefinitionId
  const category = data?.application?.category
  const subcategories = data?.application?.subcategories
  const applyingBank = data?.application?.applyingBank
  const applyingCustomer = data?.application?.applyingCustomer
  const customerData = data.raw?.accountMaster || {}
  const customerAccount = customerData?.customerAccount || {}
  const customerContact = customerData?.customerContactApplication || []
  const application = data?.raw?.applicationMaster?.currentApplication || {}
  const projectName = data?.mapped?.caseOverview?.projectName
  const impartiability =
    data?.raw?.impartiability?.length > 0
      ? t("hits-impartiability-check")
      : t("no-hits-impartiability-check")

  let decideIddRequirements =
    data?.decisionTables?.decideIddRequirements?.output?.performIdd
  const manualAssessment = data?.integrityDueDilligence?.manualAssessment
  const urls = data?.application?.urls || []
  const urlForServiceDirectoryIN =
    "https://innovationnorway.sharepoint.com/sites/InBusiness365_Dev/SitePages/Søk-i-tjenester.aspx"

  if (flowDefinition === "depot-end-control") {
    const applicant = data.input.applicant
    const followUpDate = data.input.followUpDate
    const caseNumber = data.input.caseNumber
    const companyName = data.input.companyName

    return (
      <DepotControlSummary
        applicant={applicant}
        followUpDate={followUpDate}
        caseNumber={caseNumber}
        companyName={companyName}
        t={t}
      />
    )
  }

  return flowDefinition === "payout-control" ? (
    ""
  ) : (
    <Summary
      category={category}
      subcategories={subcategories}
      customerAccount={customerAccount}
      customerContact={customerContact}
      application={application}
      impartiability={impartiability}
      decideIddRequirements={decideIddRequirements}
      manualAssessment={manualAssessment}
      urls={urls}
      urlForServiceDirectoryIN={urlForServiceDirectoryIN}
      t={t}
      applyingCustomer={applyingCustomer}
      applyingBank={applyingBank}
      projectName={projectName}
    />
  )
}

export default withTranslation()(CaseSummary)
