import StyledBox from "../../common/StyledBox"
import { Spinner } from "@flow/icons"
import formatWithLocale from "../../../util/dateFormatter"
import FileInTabButton from "../../common/FileInTabButton"
import styled from "styled-components"
import React from "react"

const DisplayComponent = ({ title, elements, t, padding }) => {
  return (
    <StyledBox
      title={t(title)}
      padding={padding}
      large
      titleStyling={{ color: "black" }}
    >
      {elements?.map((x, i) => {
        if (x?.error || !x?.file?.storeID) {
          return (
            <CreditCheckContainer key={i}>
              <CreditCheckText>
                {formatWithLocale(x.date, "dd.MM.yyyy")}:
              </CreditCheckText>
              <div>{t(x.error) || t("could-not-fetch-credit-check")}</div>
            </CreditCheckContainer>
          )
        }
        return (
          <CreditCheckContainer key={i}>
            <FileInTabButton
              buttonHeight={"30px"}
              storeId={x?.file?.storeID}
              title={t("open-credit-check-in-new-window-with-date").replaceAll(
                "XXXXXX",
                formatWithLocale(x?.date, "dd.MM.yyyy")
              )}
            />
          </CreditCheckContainer>
        )
      })}
    </StyledBox>
  )
}
const DisplayCreditCheckStakeholder = ({
  stakeholder,
  t,
  padding = "0 10px",
}) => {
  const filterChecks = (condition, checks) =>
    checks?.filter((check) => check.provider === condition)
  const sortedChecks = stakeholder?.creditChecks?.sort(sortByDatePred)
  const checksAmbita = filterChecks("ambita", sortedChecks) || []
  const checksBisnode = filterChecks("bisnode", sortedChecks) || []
  const hasChecksWithoutProvider =
    (checksAmbita.length || 0) + (checksBisnode.length || 0) <
      stakeholder?.creditChecks?.length || 0
  if (
    stakeholder.type === "organization" &&
    stakeholder.scope === "international"
  ) {
    return <></>
  }

  if (stakeholder.creditChecking) {
    return (
      <StyledBox title={t("credit-evaluations")} padding="0" large>
        <LoadingCreditCheck>
          <Spinner size={20} />
          <div>{t("wait-while-credit-checking")}</div>
        </LoadingCreditCheck>
      </StyledBox>
    )
  }

  return (
    <>
      {!hasChecksWithoutProvider ? (
        <>
          {checksAmbita.length > 0 && (
            <DisplayComponent
              title={"credit-evaluations-ambita"}
              elements={checksAmbita}
              t={t}
              padding={padding}
            />
          )}
          {checksBisnode.length > 0 && (
            <DisplayComponent
              title={"credit-evaluations-bisnode"}
              elements={checksBisnode}
              t={t}
              padding={padding}
            />
          )}
        </>
      ) : (
        <DisplayComponent
          title={"credit-evaluations"}
          elements={sortedChecks}
          t={t}
          padding={padding}
        />
      )}
      {!checksBisnode.length > 0 &&
        !checksAmbita.length > 0 &&
        !hasChecksWithoutProvider && (
          <StyledBox padding={padding}>
            <div style={{ color: "inherit" }}>
              {t("no-credit-check-could-be-made")}
            </div>
          </StyledBox>
        )}
    </>
  )
}
export default DisplayCreditCheckStakeholder

const LoadingCreditCheck = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

  div {
    margin-left: 10px;
  }
`

const CreditCheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10;
  margin-bottom: 10px;
  align-items: left;
`

const CreditCheckText = styled.div`
  margin-right: 10px;
`

const sortByDatePred = (a, b) => {
  if (a?.error) return -1
  if (b?.error) return 1
  return new Date(b.date) - new Date(a.date)
}
