import * as yup from "yup"
import { object } from "yup"

export const signerValidationSchema = object({
  email: yup
    .string()
    .email("email-must-be-valid-email-error")
    .required("required-error"),
  phone: yup.string().required("required-error"),
  ssn: yup.string().when("type", {
    is: "person",
    then: yup
      .string()
      .length(11, "ssn-must-be-11-digits-error")
      .required("required-error"),
  }),
  organizationNumber: yup.string().when("type", {
    is: "organization",
    then: yup
      .string()
      .length(9, "organization-number-must-be-9-digits-error")
      .required("required-error"),
  }),
  type: yup.string().required("required-error"),
  isFetchingSignerDetails: yup.boolean().notRequired(),
})
