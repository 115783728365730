import React from "react"
import styled from "styled-components"
import Notice from "../../common/Notice"
import GridContent from "./GridContent"
import { Colors, FontStyles } from "@flow/style"
import { ProjectColors } from "../../common/style/ProjectColors"

const InformationLine = ({
  data,
  padding = "1.5em",
  margin,
  gridColumnGap,
  min = "12em",
  max = "1fr",
  backgroundColor,
}) => {
  return (
    <Content
      padding={padding}
      margin={margin}
      gridColumnGap={gridColumnGap}
      min={min}
      max={max}
      backgroundColor={backgroundColor}
    >
      {data.map((x, i) => (
        <GridContent
          key={i}
          title={x.title}
          data={x.value}
          contentSize={FontStyles.Normal}
        />
      ))}
    </Content>
  )
}
export default InformationLine

const Content = styled(Notice)`
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};

  display: grid;
  grid-template-columns: ${({ min, max }) =>
    `repeat(auto-fill, minmax(${min}, ${max}))`};
  grid-gap: 1em;
  grid-column-gap: ${({ gridColumnGap }) => gridColumnGap || "1em"};

  background-color: ${(props) => props.backgroundColor || Colors.SeaLighter};
`
