import styled from "styled-components"
import { FormProvider, useForm } from "react-hook-form"
import {
  CheckboxComponent,
  InputComponent,
} from "../TermsAndSecurity/stakeholders/InputComponents"
import { SecondaryButton, TransparentButton } from "@flow/buttons"
import { ProjectColors } from "../common/style/ProjectColors"
import { signerValidationSchema } from "./schemas/Signer.schema"
import { yupResolver } from "@hookform/resolvers/yup"

const EditSigner = ({ t, signer, toggleEditing, handleEdit }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
    getValues,
    register,
    ...rest
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    // resolver: yupResolver(signerValidationSchema),
    defaultValues: {
      ...signer,
      isFetchingSignerDetails: false,
    },
  })

  function onSubmit(data) {
    handleEdit(data)
    toggleEditing()
  }

  function canSubmitForm() {
    const email = watch("email")
    if (!email) return false

    return true
  }

  return (
    <Container>
      <FormProvider
        handleSubmit={handleSubmit}
        control={control}
        watch={watch}
        errors={errors}
        setValue={setValue}
        getValues={getValues}
        {...rest}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputContainer>
            {signer.type === "organization" ? (
              <InputComponent
                fieldName="organizationNumber"
                disabled={true}
                t={t}
                id={signer.id}
              />
            ) : (
              <InputComponent
                fieldName="ssn"
                disabled={true}
                t={t}
                id={signer.id}
              />
            )}
            <InputComponent
              fieldName="name"
              disabled={true}
              t={t}
              id={signer.id}
            />
          </InputContainer>
          <InputContainer>
            <InputComponent fieldName="email" t={t} id={signer.id} />
            <InputComponent fieldName="phone" t={t} id={signer.id} />
          </InputContainer>
          <InputContainer>
            <CheckboxComponent
              fieldName="isFetchingSignerDetails"
              t={t}
              id={signer.id}
            />
          </InputContainer>

          <ButtonContainer>
            <SecondaryButton disabled={!canSubmitForm()} type="submit">
              {t("add")}
            </SecondaryButton>
            <TransparentButton onClick={() => toggleEditing()}>
              {t("cancel")}
            </TransparentButton>
          </ButtonContainer>
        </form>
      </FormProvider>
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color ${ProjectColors.Sea97};
    padding: 15px;
    border-radius: 8px;
`

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 0;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 10px;
`

export default EditSigner
