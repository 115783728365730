import React from "react"
import styled from "styled-components"
import { TransparentButton, SecondaryButton } from "@flow/buttons"
import { FlexRow } from "../ControlComponents"
import { FormProvider, useForm } from "react-hook-form"
import { TextAreaInput } from "../securityTexts/InputComponents"

const EditDecisionText = ({ t, onEditDecision, onCancelEdit, decision }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
    getValues,
    register,
    ...rest
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...decision.decision,
      decision,
    },
  })

  const canSubmitForm = () => {
    const title = watch("title")
    if (!title) return false

    const text = watch("text")
    if (!text) return false

    return true
  }

  return (
    <FormProvider
      handleSubmit={handleSubmit}
      control={control}
      errors={errors}
      watch={watch}
      setValue={setValue}
      getValues={getValues}
      {...rest}
    >
      <form id="editSecurityTextForm" onSubmit={handleSubmit(onEditDecision)}>
        <StyledTextAreaInput fieldName="text" t={t} disabled={false} />
        <>
          <FlexRow>
            <SecondaryButton
              form="editSecurityTextForm"
              type="submit"
              disabled={!canSubmitForm()}
            >
              {t("save-security-text-edit")}
            </SecondaryButton>
            <TransparentButton onClick={() => onCancelEdit()}>
              {t("cancel-special-terms-button-text")}
            </TransparentButton>
          </FlexRow>
        </>
      </form>
    </FormProvider>
  )
}

const StyledTextAreaInput = styled(TextAreaInput)`
  div {
    margin: 0px;
  }
  TextArea {
    min-height: 140px;
  }

  label > span {
    font-size: 14px;
    margin-top: 10px;
    text-transform: uppercase;
  }
`

export default EditDecisionText
