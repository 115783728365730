import React, { useState } from "react"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import { shouldDeliveryHaveSecurity } from "../../util/securityText"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import lodash from "lodash"
import TextButton from "../common/TextButton"
import CreateSecurityText from "./securityTexts/CreateSecurityText"
import DisplaySecurityText from "./DisplaySecurityText"
import { v4 as uuidv4 } from "uuid"

const SecurityTexts = ({
  t,
  deliveries,
  getSecurityTexts,
  mappedSecurityTexts,
  setMappedSecurityTexts,
  setProcessing,
  isMaintenance,
}) => {
  const sortSecurityTexts = (securityTexts) => {
    return securityTexts.sort((a, b) => {
      return a.deliveryId.localeCompare(b.deliveryId)
    })
  }

  const [editingId, setEditingId] = useState(null)

  const onAddText = (values, item) => {
    setProcessing(true)
    setMappedSecurityTexts((oldMappedSecurityTexts) => {
      const currentDelivery = oldMappedSecurityTexts.find(
        (s) => s.deliveryId === item.deliveryId
      )
      const newMappedSecurityTexts = [
        ...oldMappedSecurityTexts.filter(
          (x) => x.deliveryId !== item.deliveryId
        ),
        {
          ...currentDelivery,
          securityTexts: [
            ...currentDelivery.securityTexts,
            {
              title: values.title,
              subtitle: values.subtitle,
              text: values.text,
              userGenerated: true,
              id: uuidv4(),
            },
          ],
        },
      ]
      // Sort newMappedSecurityTexts by deliveryId
      sortSecurityTexts(newMappedSecurityTexts)

      return newMappedSecurityTexts
    })
    setProcessing(false)
    setEditingId(null)
  }

  const onEditText = (deliveryIndex, index, newText) => {
    setMappedSecurityTexts((old) => {
      const updated = lodash.cloneDeep(old)
      updated[deliveryIndex].securityTexts[index] = {
        ...updated[deliveryIndex].securityTexts[index],
        ...newText,
      }
      return updated
    })
  }

  const onDeleteText = (deliveryIndex, index) => {
    setMappedSecurityTexts((old) => {
      const updated = lodash.cloneDeep(old)
      updated[deliveryIndex].securityTexts.splice(index, 1)
      return updated
    })
  }

  const onCancelEdit = () => {
    setEditingId(null)
  }

  const generateTextsFor = (item) => {
    const generatedTexts = getSecurityTexts(item.deliveryId)
    const currentDelivery = mappedSecurityTexts.find(
      (s) => s.deliveryId === item.deliveryId
    )
    const newMappedSecurityTexts = [
      ...mappedSecurityTexts.filter((x) => x.deliveryId !== item.deliveryId),
      {
        ...currentDelivery,
        securityTexts: [
          ...generatedTexts,
          ...currentDelivery.securityTexts.filter((x) => x.userGenerated),
        ],
      },
    ]

    setMappedSecurityTexts(sortSecurityTexts(newMappedSecurityTexts))
  }

  return (
    <Content>
      {!isMaintenance && <LabelSpan>{t("collateral-deliveries")}</LabelSpan>}
      {mappedSecurityTexts?.map((item, deliveryIndex) => {
        const { deliveryId, securityTexts } = item
        const delivery = deliveries[deliveryId]
        if (!shouldDeliveryHaveSecurity(delivery)) return null

        const { productName, loanPurposeCode, amount } = delivery

        return (
          <React.Fragment key={`delivery-${deliveryId}`}>
            {!isMaintenance && (
              <DeliveryWrapper>
                <span>
                  {loanPurposeCode}-{productName} kr{" "}
                  {addThousandSeparator(amount)}
                </span>
              </DeliveryWrapper>
            )}
            {securityTexts?.map((securityText, securityTextIndex) => (
              <DisplaySecurityText
                t={t}
                key={securityTextIndex}
                isFirst={securityTextIndex === 0}
                securityText={securityText}
                onEdit={(newText) =>
                  onEditText(deliveryIndex, securityTextIndex, newText)
                }
                onDelete={() => onDeleteText(deliveryIndex, securityTextIndex)}
                isMaintenance={isMaintenance}
              />
            ))}
            <SecurityIndent>
              <EditingWrapper isMaintenance={isMaintenance}>
                {editingId === deliveryId && (
                  <CreateSecurityText
                    {...{
                      t,
                      onAddText,
                      item,
                      onCancelEdit,
                    }}
                  />
                )}
                <>
                  <TextButton
                    padding={false}
                    disabled={editingId !== null}
                    onClick={() => generateTextsFor(item)}
                  >
                    {t("generate-security-text-button-text")}
                  </TextButton>
                  <br />
                  <TextButton
                    padding={false}
                    disabled={editingId !== null}
                    onClick={() => setEditingId(deliveryId)}
                  >
                    {t("add-security-text-button-text")}
                  </TextButton>
                </>
              </EditingWrapper>
            </SecurityIndent>
          </React.Fragment>
        )
      })}
    </Content>
  )
}

const LabelSpan = styled.div`
  margin-bottom: 1rem;
  text-transform: uppercase;
  margin-left: 1rem;
`

const Content = styled.div`
  margin: 23px;
`

const DeliveryWrapper = styled.div`
  box-sizing: border-box;
  background: ${({ background }) => background || Colors.SeaLighter};
  border: 1px solid ${Colors.Grey2};
  border-radius: 10px;
  min-height: 50px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
`

const SecurityIndent = styled.div`
  padding-left: 1rem;
  width: 100%;
`

const EditingWrapper = styled.div`
  ${({ isMaintenance }) => (isMaintenance ? "" : "padding-left: 40px")};
  padding-bottom: 1rem;
`

export default SecurityTexts
