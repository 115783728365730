import inbox from "./inbox.json"
import search from "./search.json"
import tasks from "./tasks.json"
import details from "./details.json"
import queues from "./queues.json"
import auditlog from "./auditlog.json"
import taskComponents from "./taskComponents.json"
import evaluateBasisForSupport from "./evaluateBasisForSupport.json"
import tabs from "./tabs.json"
import vekstgaranti from "./vekstgaranti.json"
import oldTaskTranslations from "./oldTaskTranslations.json"
import maintenance from "./maintenance.json"
import settings from "./settings.json"

const no = {
  inbox,
  search,
  tasks,
  details,
  queues,
  auditlog,
  taskComponents,
  evaluateBasisForSupport,
  tabs,
  vekstgaranti,
  oldTaskTranslations,
  maintenance,
  settings,
}

export default no
