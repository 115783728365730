import React from "react"
import styled from "styled-components"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { TextArea } from "@flow/forms"
import { Colors } from "@flow/style"

const Sidebar = ({
  commentHeading,
  commentHelp,
  commentValue,
  commentOnChange,
  commentPlaceholder,
  primaryButtonProps,
  primaryButtonName,
  secondaryButtonProps,
  secondaryButtonName,
  children,
}) => {
  return (
    <div id="evaluateBasisForSupportSidebar">
      <StyledTextArea
        name={"case-comment"}
        label={commentHeading}
        help={commentHelp}
        defaultValue={commentValue}
        required
        placeholder={commentPlaceholder}
        onChange={(e, text) => commentOnChange(text)}
      />
      {children}
      <ButtonContainer>
        <PrimaryButton {...primaryButtonProps}>
          {primaryButtonName}
        </PrimaryButton>
        <SecondaryButton {...secondaryButtonProps}>
          {secondaryButtonName}
        </SecondaryButton>
      </ButtonContainer>
    </div>
  )
}
export default Sidebar

const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 200px;

  ::placeholder {
    color: ${Colors.Grey2};
  }
`
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  width: 100%;
  overflow: hidden;
  margin-top: 15px;
`
