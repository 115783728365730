import React, { useState } from "react"
import styled from "styled-components"
import { FlexRow } from "../ControlComponents"
import { TextArea } from "@flow/forms"
import { Colors } from "@flow/style"
import IconButton from "../../common/IconButton"
import EditDecisionText from "./EditDecisionText"
import lodash from "lodash"
import { removeDecision } from "../../utils/mapMaintenance"

const DecisionText = ({
  t,
  decision,
  delivery,
  deliveryId,
  change,
  onChange,
  isGenerated = false,
}) => {
  const [editing, setEditing] = useState(false)

  const onCancelEdit = () => {
    setEditing(false)
  }

  const onEditDecision = (data) => {
    setEditing(false)
    const isModified = data.decision.isModified
    const editedDelivery = lodash.cloneDeep(delivery)
    const deliveryChange = editedDelivery.changes.find(
      (c) => c.id === change.id
    )
    let decisionIndex = -1
    if (isModified) {
      decisionIndex = deliveryChange.decisionTexts.generated.findIndex(
        (decision) => decision.id === data.decision.id
      )
    } else {
      decisionIndex = deliveryChange.decisionTexts.userAdded.findIndex(
        (decision) => decision.id === data.decision.id
      )
    }

    delete data.decision // Delete decision on data, as it's only used to get the decisionIndex from delivery.

    if (isModified) {
      deliveryChange.decisionTexts.generated[decisionIndex].decision = data
    } else {
      deliveryChange.decisionTexts.userAdded[decisionIndex].decision = data
    }

    onChange({ deliveryId, data: editedDelivery })
  }

  const onRemoveDecision = (decisionId) => {
    const newDelivery = removeDecision(decisionId, delivery, change)
    onChange({ deliveryId, data: newDelivery })
  }

  return (
    <DecisionWrapper>
      {editing ? (
        <EditDecisionText
          t={t}
          onEditDecision={onEditDecision}
          onCancelEdit={onCancelEdit}
          decision={decision}
        />
      ) : (
        <>
          <FlexRow>
            {decision.agreements?.length > 0 &&
              decision.agreements.map((agreement) => (
                <FlexRow>
                  <TextWrapper>
                    <GreyText>{t("agreement")}</GreyText>
                  </TextWrapper>
                </FlexRow>
              ))}
            {!isGenerated && decision.category?.title && (
              <FlexRow>
                <TextWrapper>
                  <GreyText>{t("category")}</GreyText>
                  <BlackText>{decision.category?.title}</BlackText>
                </TextWrapper>
              </FlexRow>
            )}
            {!isGenerated && decision.subCategory?.title && (
              <FlexRow>
                <TextWrapper>
                  <GreyText>{t("subcategory")}</GreyText>
                  <BlackText>{decision.subCategory?.title}</BlackText>
                </TextWrapper>
              </FlexRow>
            )}
            {!isGenerated && decision.subSubCategory?.title && (
              <FlexRow>
                <TextWrapper>
                  <GreyText>{t("decisionType")}</GreyText>
                  <BlackText>{decision.subSubCategory?.title}</BlackText>
                </TextWrapper>
              </FlexRow>
            )}
            {!isGenerated && decision.decision?.title && (
              <FlexRow>
                <TextWrapper>
                  <GreyText>{t("text")}</GreyText>
                  <BlackText>{decision.decision?.title}</BlackText>
                </TextWrapper>
              </FlexRow>
            )}
            <FlexRow style={{ marginLeft: "auto" }}>
              {(decision.category || decision.isModified) && (
                <StyledIconButton
                  onClick={() => setEditing(!editing)}
                  icon={"Edit"}
                />
              )}
              <StyledIconButton
                onClick={() => onRemoveDecision(decision.id)}
                icon={"Trashcan"}
              />
            </FlexRow>
          </FlexRow>
          <StyledTextArea
            name={"termTextArea"}
            value={decision.decision.text}
            placeholder={t("term-text-placeholder")}
            disabled={true}
            onChange={() => null} // Disable code warning
          />
        </>
      )}
    </DecisionWrapper>
  )
}

const StyledTextArea = styled(TextArea)`
  width: 100%;
  min-height: 140px;
  border-radius: 5px;
  background: white;
`

const BlackText = styled.div`
  color: black;
  white-space: nowrap;
`
const GreyText = styled.div`
  color: ${Colors.Grey1};
  text-transform: uppercase;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  margin: 20px 10px 20px 0px;
`

const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  padding: 5px 0px;
`

export const DecisionWrapper = styled.div`
  color: black;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #e5edff;
  &:last-child {
    border-bottom: none;
  }
`

export default DecisionText
