import React from "react"
import styled from "styled-components"
import AssessmentComments from "../common/AssessmentComments"
import BlueDot from "../common/BlueDot"
import RequestedDocArchive from "../common/RequestedDocArchive"
import InsightModule from "../insight/InsightModule"
import ApplicationHistory from "./ApplicationHistory"

const getIntroductoryEvaluationOptions = ({
  order,
  context,
  taskData,
  onAppChange,
  t,
  municipalityLookup,
  flow,
}) => {
  const options = [
    {
      id: "analyze",
      title: t("overview"),
      component: (
        <InsightModule
          name={"@stacc/vue-initialassessment"}
          data={taskData}
          commonData={taskData?.commonData}
          onAppChange={onAppChange}
          options={{
            context,
            municipalityLookup,
          }}
        />
      ),
    },
    {
      id: "company",
      title: t("about-company"),
      component: (
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          commonData={taskData?.commonData}
          onAppChange={onAppChange}
          options={{
            context: "company",
          }}
        />
      ),
    },
    {
      id: "applicationHistory",
      title: t("application-history"),
      component: (
        <ApplicationHistory
          applications={
            flow.data?.raw?.applicationMaster?.previousCases?.applications
          }
        />
      ),
    },
    {
      id: "comments",
      title: t("requested-documentation"),
      component: (
        <AssessmentCommentsWrapper>
          <RequestedDocArchive
            documentations={flow?.data?.introductoryEvaluation?.documentation}
            t={t}
          />
        </AssessmentCommentsWrapper>
      ),
    },
  ]

  //only add tab if data
  const comments = flow?.data?.assessmentComments
  flow?.data?.rejection?.assessDecision &&
    options.push({
      id: "assessments",
      title: t("assessment-history"),
      children: comments?.length ? (
        <BlueDot>{comments?.length}</BlueDot>
      ) : undefined,
      component: (
        <AssessmentCommentsWrapper>
          <AssessmentComments comments={flow?.data?.assessmentComments} t={t} />
        </AssessmentCommentsWrapper>
      ),
    })
  return (order || [])
    .map((x) => options.find((y) => y.id === x))
    .filter((x) => !!x)
}

const AssessmentCommentsWrapper = styled.div`
  padding: 10px 40px;
  overflow-y: auto;
`

export default getIntroductoryEvaluationOptions
