/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

const ApplyingBankInformation = ({ applyingBank, t }) => {
  const fullName = `${applyingBank?.contact?.firstName} ${applyingBank?.contact?.lastName}`
  return (
    <>
      <ItemWrapper>
        <Title>{t("bank")}</Title>
        <ContextContent>{`${applyingBank?.name} (${applyingBank?.orgNumber})`}</ContextContent>
        <ContextContent>{applyingBank?.address?.street}</ContextContent>
        <ContextContent>{`${applyingBank?.address?.postalCode} ${applyingBank?.address?.city}`}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("contact-person-bank")}</Title>
        <ContextContent>{fullName}</ContextContent>
        <ContextContent>{applyingBank?.contact?.phone}</ContextContent>
        <ContextContent>{applyingBank?.contact?.email}</ContextContent>
      </ItemWrapper>
    </>
  )
}

const MapLinks = ({ urls, t }) => {
  return urls.map((link, id) => (
    <ContextContent key={id}>
      <a href={link.url} target="_blank" rel="noopener noreferrer">
        {t(link.system)}
      </a>
    </ContextContent>
  ))
}

const Summary = ({
  category,
  subcategories,
  customerAccount,
  customerContact,
  application,
  impartiability,
  decideIddRequirements,
  manualAssessment,
  urls,
  urlForServiceDirectoryIN,
  t,
  applyingBank,
  applyingCustomer,
  projectName,
}) => {
  const isCounterGuarantee = category === "counterguarantee"
  const isMaintenance = category === "maintenance"
  const urlsWithoutSparring = urls.filter((url) => url.system !== "Sparring")

  return (
    <Content>
      <ItemWrapper>
        <Title>{isCounterGuarantee ? t("loanApplicant") : t("company")}</Title>
        <ContextContent>{customerAccount?.publicId}</ContextContent>
        <ContextContent>{customerAccount?.name}</ContextContent>
      </ItemWrapper>
      {(isCounterGuarantee && (
        <ItemWrapper>
          <Title>{t("contact-person")}</Title>
          <ContextContent>
            {applyingCustomer?.contact?.firstName}{" "}
            {applyingCustomer?.contact?.lastName}
          </ContextContent>
          <ContextContent>{applyingCustomer?.contact?.phone}</ContextContent>
          <ContextContent>{applyingCustomer?.contact?.email}</ContextContent>
        </ItemWrapper>
      )) || (
        <ItemWrapper>
          <Title>{t("contact-person")}</Title>
          <ContextContent>{customerContact?.fullName}</ContextContent>
          <ContextContent>{customerContact?.mobilePhone}</ContextContent>
          <ContextContent>{customerContact?.email}</ContextContent>
        </ItemWrapper>
      )}
      {application?.projectName && (
        <ItemWrapper>
          <Title>{t("application-name")}</Title>
          <ContextContent>{projectName}</ContextContent>
        </ItemWrapper>
      )}
      <ItemWrapper>
        <Title>{t("category", "Kategori")}</Title>
        <ContextContent>{t(category)}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("customer-contact-in")}</Title>
        <ContextContent>{customerAccount?.manager}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("impartiability-check")}</Title>
        <ContextContent>{impartiability}</ContextContent>
      </ItemWrapper>
      <ItemWrapper>
        <Title>{t("hyper-links")}</Title>
        <MapLinks
          urls={
            isCounterGuarantee || isMaintenance ? urlsWithoutSparring : urls
          }
          t={t}
        ></MapLinks>
        <ContextContent>
          <a
            href={urlForServiceDirectoryIN}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("service-directory")}
          </a>
        </ContextContent>
      </ItemWrapper>

      {isMaintenance && (
        <ItemWrapper>
          <Title>{t("deliveries")}</Title>
          <FlexColumn>
            {subcategories?.map((subcategory) => (
              <React.Fragment key={subcategory}>
                <p>{t(subcategory)}</p>
              </React.Fragment>
            ))}
          </FlexColumn>
        </ItemWrapper>
      )}

      {!isCounterGuarantee && !isMaintenance && (
        <ItemWrapper>
          <Title>{t("idd-results")}</Title>
          <SubTable>
            <p>{t("hits-criteria-manual-idd")}:</p>
            <p>{t(decideIddRequirements) || ""}</p>
          </SubTable>
          <SubTable>
            <p>{t("manual-idd-by-caseworker")}:</p>
            <p>{t(manualAssessment) || ""}</p>
          </SubTable>
        </ItemWrapper>
      )}
      {isCounterGuarantee && (
        <ApplyingBankInformation
          applyingBank={applyingBank}
          t={t}
        ></ApplyingBankInformation>
      )}
    </Content>
  )
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const SubTable = styled.div`
  display: flex;
  p:last-child {
    padding-left: 0.5rem;
  }
`

const Content = styled.div`
  display: grid;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns ? `repeat(${props.columns}, 1fr)` : "repeat(3, 1fr)"};
  gap: 10px;
`

const Title = styled.h3`
  font-weight: 300;

  ::first-letter {
    text-transform: capitalize;
  }

  color: #8b8a8d;
  font-size: 12pt;
  margin-bottom: 8px;
`

const ItemWrapper = styled.div`
  ::first-letter {
    text-transform: capitalize;
  }
`
const ContextContent = styled.div`
  a {
    text-decoration: none;
  }
`

export default withTranslation()(Summary)
