import { useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import { ProjectColors } from "../../common/style/ProjectColors"
import AdditionalProductLine from "../extraordinaryFinancing/AdditionalProductLine"
import ActivityTypeLine from "../lineItems/ActivityTypeLine"
import LineList from "../LineList"
import ProposedAmountBox from "../ProposedAmountBox"
import BankInformation from "./BankInformation"
import OtherEngagements from "./OtherEngagements"

const VekstgarantiProduct = ({
  productConfiguration,
  options,
  bankInformation,
  otherEngagements,
  amountAppliedFor,
  currency,
}) => {
  const { t } = useTranslation("EvaluateBasisForSupport")

  const { watch } = useFormContext()
  const vekstgarantiProduct = watch("product")

  return (
    <>
      <ProposedAmountBoxWrapper>
        <ProposedAmountBox
          title={t("proposed-guarantee").toUpperCase()}
          currency={currency}
          amount={addThousandSeparator(vekstgarantiProduct?.amount)}
          appliedForAmount={addThousandSeparator(amountAppliedFor?.guarantee)}
          appliedForText={t("applied-for")}
        />
      </ProposedAmountBoxWrapper>
      <ProductContainer>
        <ActivityTypeLine
          activityTypeText={t("activity-type-heading")?.toUpperCase()}
          supportRegimeText={t("choose-support-regime")?.toUpperCase()}
          t={t}
          isGrowthGuaranteeProduct={true}
        />
        <LineList>
          <StyledProduct>
            <StyledAdditionalProductLine
              key={`productHeader`}
              product={t("product")?.toUpperCase()}
              amount={t("amount")?.toUpperCase()}
              fundingIntensity={t("funding-intensity")?.toUpperCase()}
              productType={t("product-type")?.toUpperCase()}
              subsidyEffect={t("subsidy-effect")?.toUpperCase()}
              header={true}
              options={options}
            />

            <StyledAdditionalProductLine
              {...vekstgarantiProduct}
              t={t}
              options={options}
              readonly={true}
            />
          </StyledProduct>
        </LineList>
        <BankInformation
          bankInformation={bankInformation}
          productConfiguration={productConfiguration}
        />

        <OtherEngagements otherEngagements={otherEngagements} />
      </ProductContainer>
    </>
  )
}

export default VekstgarantiProduct

const StyledProduct = styled.div`
  padding-bottom: 1em;
`

const ProposedAmountBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  div {
    min-width: 10em;
  }
`

const StyledAdditionalProductLine = styled(AdditionalProductLine)`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`

const ProductContainer = styled.div`
  padding: 2em;
  border: solid 1px ${ProjectColors.SlateBoxShadow};
  border-radius: 5px;
  margin-top: 1em;
`
