import useInsightThemes from "../../hooks/useInsightThemes"
import InsightModule from "./insight/InsightModule"

// Used to get Insight notes app inside flow
// Handles importing themes as well as the note app
export default function InsightNote({
  variant = "footer",
  // needs to be unique, f.ex "esgSurvey" for notes under ESG survey card
  context,
  // comes from state.mapped.commonData
  commonData,
}) {
  // Init themes
  useInsightThemes()

  return (
    <InsightModule
      name="@stacc/vue-note-app"
      options={{
        caseId: commonData.externalId,
        variant,
        context,
      }}
      commonData={commonData}
    />
  )
}
