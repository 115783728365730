import React, { useState, useEffect } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import Layout from "../components/common/Layout"
import RequestedDocArchive from "../components/common/RequestedDocArchive"
import { returnFirstArgWithValue } from "../util/returnValue"
import { removeEmpty } from "../util/removeEmptyFieldsInObject"
import { Colors } from "@flow/style"
import AssessmentComments from "../components/common/AssessmentComments"
import InsightModule from "../components/insight/InsightModule"
import { pollForInitializedApp } from "../components/utils/pollInsightApp"
import { useInsightAppInitialized } from "../components/insight/useInsightAppInitialized"
import Tabs from "../components/common/Tabs"
import Notice from "../components/common/Notice"
import BlueDot from "../components/common/BlueDot"
import useMunicipalityLookup from "../components/common/useMunicipalityLookup"
import _ from "lodash"
import calculateMultipleSubsidyEffects, {
  getSubsidyEffectParamsForDelivery,
} from "../util/calculateSubsidyEffectNew"
import { trimOperationalRisk } from "../util/trimOperationalRisk"
import { formatDate } from "../components/utils/formatDate"
import { usePrevious } from "../components/utils/hooks"
import getInsightContextForCategory from "../util/getInsightContextForCategory"
import {
  getNewAndExistingEngagements,
  mapLoansWithAgreementData,
} from "../components/utils/mapAgreements"

const removeAdditionalProperties = (values) => {
  // remove everything except data for the option chosen to avoid data filled in for other options to bleed over
  const {
    decision,
    decisionLevel,
    rejectionTemplate,
    rejectionText,
    comment,
    needsJuridicalInspection,
    needsTinglysing,
    needsUpdateInCore,
  } = values || {}

  let legalComment
  if (needsJuridicalInspection) {
    legalComment = values.legalComment
  }

  if (decision === "OK") {
    values = {
      decision,
      decisionLevel,
      needsJuridicalInspection,
      legalComment,
      needsTinglysing,
      needsUpdateInCore,
    }
  }
  if (decision === "BACK_TO_SUPPORT") {
    values = {
      decision,
    }
  }
  if (decision === "REJECT") {
    values = {
      decision,
      decisionLevel,
      rejectionTemplate,
      rejectionText,
      comment,
      legalComment,
      needsJuridicalInspection,
    }
  }
  return values
}

const formatPayload = (
  formData,
  financePlan,
  conclusion,
  company,
  caseOverview,
  trlAndBrl,
  context,
  isExtraordinaryFinancing,
  internalInterest,
  enkData,
  agreementData,
  engagement,
  caseMemo
) => {
  const formattedConclusion = {
    comment: conclusion?.conclusion?.content?.ops
      ?.map((e) => e?.insert)
      ?.filter((elem) => typeof elem === "string")
      ?.join(""),
    createdBy: conclusion?.conclusion?.createdBy,
    timestamp: conclusion?.conclusion?.updated,
    updatedBy: conclusion?.conclusion?.updatedBy,
  }

  const formattedData = removeAdditionalProperties(formData)
  const data = {
    ...formattedData,
    financePlan,
    conclusion: formattedConclusion,
    company,
    caseOverview,
    trlAndBrl,
    context,
    enkData,
    internalInterest,
    agreementData,
    engagement,
    caseMemo,
  }
  if (isExtraordinaryFinancing) delete data.trlAndBrl
  return data
}

const CreateCreditMemo = (props) => {
  const { task, t, schema, flow, updateCase } = props
  const { data } = task
  const {
    templates = {},
    insightComponentData,
    documentation,
    creditMemoState,
    needAnalysis,
    isExtraordinaryFinancing,
    isAgriculture,
    isBioEnergy,
    isMass,
    isDtil,
    isMaintenance,
    isDsf,
    isDni,
  } = task?.context
  delete creditMemoState?.legalComment

  const caseMemoOptions = {
    context: getInsightContextForCategory(
      flow.data.application.category,
      false,
      needAnalysis
    ),
  }

  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data?.formData, creditMemoState) || {}
  )

  const [rerenderId, setRerenderId] = useState(0)
  const [previousTemplate, setPreviousTemplate] = useState(
    formData.rejectionTemplate
  )
  const [isProcessing, setProcessing] = useState(false)
  const [activeTab, setActiveTab] = useState("creditMemo")
  const [schemaValid, setSchemaValid] = useState([])

  const getDefaultOperationalRisk = (currentState = null) => {
    if (currentState?.caseOverview?.selectedOperationalRisk) {
      return trimOperationalRisk(
        currentState?.caseOverview?.selectedOperationalRisk?.title
      )
    }
    const { data: state } = flow
    return trimOperationalRisk(
      state?.caseOverview?.selectedOperationalRisk?.title ||
        state?.mapped?.caseOverview?.selectedOperationalRisk?.title
    )
  }

  const [selectedOperationalRisk, setSelectedOperationalRisk] = useState(
    getDefaultOperationalRisk()
  )
  const [subsidyEffectData, setSubsidyEffectData] = useState([])

  const [time, setTime] = useState(Date.now())
  const [showReload, setShowReload] = useState(false)

  const isRefetchingEngagements = flow.data?.tmp?.creditGroup?.isRefetching
  const prevIsRefetching = usePrevious(isRefetchingEngagements)

  useEffect(() => {
    if (prevIsRefetching && !isRefetchingEngagements) {
      setShowReload(true)
    }
  }, [isRefetchingEngagements])

  useEffect(() => {
    if (isRefetchingEngagements) {
      const interval = setInterval(() => {
        updateCase()
        setTime(Date.now())
      }, 5000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [isRefetchingEngagements])

  const mapCreateCreditMemoData = (contextData, taskData) => {
    const updateWithSubsidyEffect = (contextDataToUpdate) => {
      // We also want to update contextDataToUpdate?.caseMemo?.mappedStateAid?.activities, and add the subsidyEffect to each activity for each loan
      if (contextDataToUpdate?.caseMemo?.mappedStateAid?.activities) {
        const activities =
          contextDataToUpdate?.caseMemo?.mappedStateAid?.activities
        const updatedActivities = activities?.map((activity) => {
          // Each activity contains products where we want to update the subsidyEffect
          if (
            activity.supportRegime.trim() === "exemptFromSupportBasisRules" ||
            activity.supportRegime.trim() === "article22" ||
            activity.supportRegime.trim() === "startUps"
          ) {
            return activity
          }

          const updatedProducts = activity?.products?.map((product) => {
            if (product.productType !== "loan-with-risk") {
              return product
            }

            const delivery = flow?.data?.deliveries[product.product]
            delivery.deliveryId = product.product
            const subsidyEffectParams = getSubsidyEffectParamsForDelivery(
              delivery,
              contextDataToUpdate?.caseOverview,
              flow?.data?.analysis?.subsidyEffectData,
              flow?.data?.analysis?.termsAndSecurity?.input?.securityRiskData,
              selectedOperationalRisk
            )
            const calculatedSubsidyEffect = calculateMultipleSubsidyEffects([
              {
                loanAmount: +product.amount,
                subsidyEffectParams: {
                  ...subsidyEffectParams,
                  deliveryId: product.product,
                },
              },
            ])

            return {
              ...product,
              subsidyEffect: calculatedSubsidyEffect?.[0]?.subsidyEffect,
            }
          })
          return {
            ...activity,
            products: updatedProducts,
          }
        })
        contextDataToUpdate.caseMemo.mappedStateAid.activities =
          updatedActivities
      }

      if (contextDataToUpdate?.caseMemo?.mappedStateAid?.products) {
        const products = contextDataToUpdate?.caseMemo?.mappedStateAid?.products
        const updatedProducts = products?.map((product) => {
          const relevantSubsidyEffectData = subsidyEffectData?.find(
            (subsidyEffect) => subsidyEffect?.deliveryId === product?.productId
          )
          const { subsidyEffect } = relevantSubsidyEffectData || {}

          // May need to update product.rows as well, but this seems to be done automatically somewhere (wtf)
          return {
            ...product,
            calculatedFields: {
              ...product?.calculatedFields,
              subsidyEffect,
            },
          }
        })

        contextDataToUpdate.caseMemo.mappedStateAid.products = updatedProducts
      }

      return contextDataToUpdate
    }
    if (taskData) {
      const contextDataCopy = { ...contextData }
      const parsedTaskData = removeEmpty(taskData)
      contextDataCopy.caseOverview = {
        ...contextDataCopy?.caseOverview,
        ...parsedTaskData?.caseOverview,
      }
      contextDataCopy.company = {
        ...contextDataCopy?.company,
        ...parsedTaskData?.company,
      }
      contextDataCopy.financePlan = {
        ...contextDataCopy?.financePlan,
        ...parsedTaskData?.financePlan,
      }
      contextDataCopy.trlAndBrl = {
        ...contextDataCopy?.trlAndBrl,
        ...parsedTaskData?.trlAndBrl,
      }
      contextDataCopy.internalInterest = {
        ...contextDataCopy?.internalInterest,
        ...parsedTaskData?.internalInterest,
      }
      contextDataCopy.enkData = {
        ...contextDataCopy?.enkData,
        ...parsedTaskData?.enkData,
      }
      contextDataCopy.engagement = _.merge(
        {},
        flow.data.mapped?.engagement ?? {},
        {
          ...contextDataCopy?.engagement,
          ...parsedTaskData?.engagement,
        }
      )
      contextDataCopy.creditGroup = flow.data.mapped?.creditGroup

      return updateWithSubsidyEffect(contextDataCopy)
    }
    return updateWithSubsidyEffect(contextData)
  }

  const caseMemoData = mapCreateCreditMemoData(insightComponentData, data)

  const engagements = flow.data.mapped.engagement.loans.map((loan) => {
    return {
      ...loan,
      riskGroupCode:
        task.data?.loans?.find(
          (taskLoan) => loan.accountNumber === taskLoan.accountNumber
        )?.riskGroupCode ?? loan.riskGroupCode,
    }
  })
  const securityRiskData =
    flow.data.analysis?.termsAndSecurity?.input?.securityRiskData ?? []

  const newAndExistingCollateralEngagements = getNewAndExistingEngagements(
    flow.data.deliveries,
    flow.data.mapped?.collateralEngagements || []
  )
  const mappedLoansWithCollateralAgreements = mapLoansWithAgreementData(
    flow.data.mapped.agreementData || [],
    newAndExistingCollateralEngagements,
    engagements,
    securityRiskData
  )

  caseMemoData.shouldDisplayPrognose = true
  caseMemoData.isAdvicePanelStarted =
    flow.data?.advicePanel?.manuallyTriggered ||
    flow.data?.advicePanel?.triggered
  caseMemoData.hasAdvicePanelContent =
    flow.data?.advicePanel?.notesContent?.advicePanelHasContent ?? false
  caseMemoData.hasStatementFromOthersContent =
    flow.data?.advicePanel?.notesContent?.statemenFromOthersHasContent ?? false
  const isTaxonomyRelevant =
    flow.data.analysis?.setDecision?.isTaxonomyRelevant ?? false
  caseMemoData.showTaxonomy = isTaxonomyRelevant
  caseMemoData.agreementData = mappedLoansWithCollateralAgreements ?? []

  const [appState, setAppstate] = useState({})

  const [app, setApp] = useState(null)
  const [isLoading, isErrored] = useInsightAppInitialized(app)
  let municipality =
    formData?.caseOverview?.municipality ||
    insightComponentData.caseOverview?.municipality
  const [municipalityName, municipalityLookup] = useMunicipalityLookup(
    municipality?.code
  )

  useEffect(() => {
    if (municipality && municipalityName) {
      municipality.name = municipalityName
      setFormData({ ...formData })
    }
  }, [municipalityName])

  useEffect(() => {
    if (
      formData.rejectionTemplate !== previousTemplate &&
      formData.rejectionTemplate
    ) {
      const newData = {
        ...formData,
        rejectionText: templates[formData.rejectionTemplate],
      }
      setPreviousTemplate(formData.rejectionTemplate)
      setFormData(newData)
      setRerenderId(rerenderId + 1)
    }
  }, [formData])

  const getUpdatedInsightState = () => {
    return { ...appState, ...app?.getCurrentState() }
  }

  const handleSave = async () => {
    const state = getUpdatedInsightState()
    if (await pollForInitializedApp(500, 30, app, setSchemaValid)) {
      setProcessing(true)
      props.save(
        { formData, ...state },
        () => setProcessing(false),
        () => {
          console.error("Could not save task")
          setProcessing(false)
        }
      )
    }
  }

  const handleComplete = async (values) => {
    const currentState = getUpdatedInsightState()
    setSchemaValid([])
    let validations = currentState?.validations
    let isSchemaValid = currentState?.isSchemaValid
    if (isExtraordinaryFinancing) {
      validations = currentState?.validationsUtInntBortfall
      isSchemaValid = currentState?.isSchemaValidUtInntBortfall
    }

    if (isAgriculture || isBioEnergy || isMass) {
      validations = currentState?.validationsAgriculture
      isSchemaValid = currentState?.isSchemaValidAgriculture
    }
    if (isMaintenance) {
      validations = currentState?.validationsMaintenance
      isSchemaValid = currentState?.isSchemaValidMaintenance
    }
    if (isDtil || isDsf || isDni) {
      validations = currentState?.validationsDtil
      isSchemaValid = currentState?.isSchemaValidDtil
    }

    if (!currentState?.caseOverview?.selectedOperationalRisk) {
      validations.customOperationalRiskNull =
        !!currentState?.caseOverview?.selectedOperationalRisk
      isSchemaValid = false
    }

    if (!isSchemaValid && values.decision === "OK") {
      setSchemaValid(
        Object.keys(validations || {}).filter((key) => !validations[key])
      )
    }
    // if no engagements answered, then we validate 🧖🏼‍♀️
    const validationsForReject = currentState?.isEngagementAnsweredReject
    if (!validationsForReject && values.decision === "REJECT") {
      validations = currentState?.validationsRejected
      setSchemaValid(
        Object.keys(validations || {}).filter((key) => !validations[key])
      )
      setProcessing(false)
      return
    }
    // Complete if valid schema OR decision is not OK
    if (isSchemaValid || values?.decision !== "OK") {
      setProcessing(true)
      const {
        financePlan,
        trlAndBrl,
        conclusion,
        company,
        caseOverview,
        internalInterest,
        enkData,
        agreementData,
        engagement,
      } = getUpdatedInsightState() || {}

      const caseMemo = mapCreateCreditMemoData(caseMemoData, appState).caseMemo

      const data = formatPayload(
        formData,
        financePlan,
        conclusion,
        company,
        caseOverview,
        trlAndBrl,
        internalInterest,
        enkData,
        agreementData,
        caseMemoOptions.context,
        isExtraordinaryFinancing,
        engagement,
        caseMemo
      )
      props.complete(
        data,
        () => setProcessing(false),
        () => {
          console.error("Could not complete task")
          setProcessing(false)
        }
      )
    }
  }

  // This function calculates the subsidy effect based on the passed in loan.
  /**
   * Calculates the subsidyeffect for the loan.
   *
   * @param {object} deliveries - The deliveries.
   * @param {object} caseOverview - The caseOverview.
   * @param {string} selectedOperationalRisk - The selected operational risk for delivery.
   * @param {object} subsidyEffectData - The subsidy effect data.
   * @param {object} securityRiskData - The security risk data.
   *
   * @returns {array} - [{loanAmount, subsidyEffectParams}] The subsidy effect data to be passed in to api-gateway.
   * @property {number} loanAmount - The amount of the loan.
   * @property {object} subsidyEffectParams - The subsidy effect params.
   */
  const parseLoans = (
    deliveries,
    caseOverview,
    selectedOperationalRisk = null,
    subsidyEffectData = {},
    securityRiskData
  ) => {
    if (!deliveries) {
      return []
    }
    const mappedDeliveries = Object.entries(deliveries)
      .map(([key, value]) => ({
        deliveryId: key,
        ...value,
      }))
      .filter(({ productClass }) => productClass === "Loan")
    return (
      mappedDeliveries?.map((delivery) => ({
        loanAmount: delivery.amount,
        subsidyEffectParams: getSubsidyEffectParamsForDelivery(
          delivery,
          caseOverview,
          subsidyEffectData,
          securityRiskData,
          selectedOperationalRisk
        ),
      })) ?? []
    )
  }

  const handleAppChange = (app) => {
    setApp(app)
  }
  const handleTabChange = (tab) => {
    const insightState = app.getCurrentState() || {}
    setAppstate({ ...appState, ...insightState })
    setActiveTab(tab)
  }

  const subsidyEffectCalculate = (value) => {
    // This function is called twice but don't try to change this (vue moment), you'll break it
    // If selected operational risk is the same as the one we already have, we don't need to recalculate

    const trimmedOperationalRisk = trimOperationalRisk(value.title)
    setSelectedOperationalRisk(trimmedOperationalRisk)

    const loans = parseLoans(
      flow?.data?.deliveries,
      flow?.data?.caseOverview,
      trimmedOperationalRisk,
      flow?.data?.analysis?.subsidyEffectData,
      flow?.data?.analysis?.termsAndSecurity?.input?.securityRiskData
    )

    const subsidyEffectResult = calculateMultipleSubsidyEffects(loans)
    setSubsidyEffectData(subsidyEffectResult)

    return subsidyEffectResult
  }

  const decisionCount = flow?.data?.assessmentComments?.length ?? 0
  const options = [
    {
      id: "creditMemo",
      title: t("create-credit-memo"),
      component: (
        <InsightModule
          name={"@stacc/vue-casememo"}
          data={caseMemoData}
          onAppChange={handleAppChange}
          commonData={insightComponentData?.commonData}
          options={{
            ...caseMemoOptions,
            municipalityLookup,
            subsidyEffectCalculate,
          }}
        />
      ),
    },
    {
      id: "documentations",
      title: t("requested-documentation"),
      component: (
        <AssessmentCommentsWrapper>
          <RequestedDocArchive documentations={documentation} t={t} />
        </AssessmentCommentsWrapper>
      ),
    },
  ]

  //only add tab if data
  flow?.data?.assessmentComments &&
    options.push({
      id: "assessments",
      title: t("assessment-history"),
      children: decisionCount ? <BlueDot>{decisionCount}</BlueDot> : undefined,
      component: (
        <AssessmentCommentsWrapper>
          <AssessmentComments comments={flow?.data?.assessmentComments} t={t} />
        </AssessmentCommentsWrapper>
      ),
    })

  const getFlowSchema = () => {
    const oneOf = schema?.dependencies?.decision?.oneOf
    const mainSchema = schema?.properties
    delete mainSchema?.context
    oneOf?.forEach((e) => {
      delete e?.properties?.caseOverview
      delete e?.properties?.company
      delete e?.properties?.trlAndBrl
      delete e?.properties?.conclusion
      delete e?.properties?.financePlan
      delete e?.if
      delete e?.then
      delete e?.else

      e.required = e.required.filter(
        (e) =>
          e !== "caseOverview" &&
          e !== "company" &&
          e !== "trlAndBrl" &&
          e !== "conclusion" &&
          e !== "financePlan"
      )
    })
    return schema
  }

  const overrideTranslations = (e) => {
    if (e === "allQuestionsAnswered") return t("allQuestionsAnswered")
    else return t(e)
  }

  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <Tabs
          defaultTab="creditMemo"
          loading={isLoading || isRefetchingEngagements}
          options={options}
          onChange={(e) => handleTabChange(e)}
          showReloadButton={showReload}
        />
      </BMAnalyzeContent>

      <Context flow={flow} context={task.context?.applicationSummary}>
        <ReactForm
          schema={getFlowSchema()}
          formData={formData}
          key={rerenderId}
          disabled={isProcessing}
          onChange={(values) => {
            setFormData(values)
            setSchemaValid([])
          }}
          onSubmit={(values) => handleComplete(values)}
        >
          {schemaValid?.map((e) => {
            return (
              <ErrorText key={e}>
                {isAgriculture ? overrideTranslations(e) : t(e)}
              </ErrorText>
            )
          })}
          <LoadingNotice hidden={!isRefetchingEngagements}>
            {`${t(
              "update-engagements-and-creditgroup-in-progress"
            )}  ${formatDate(time, "HH:mm:ss")}`}
          </LoadingNotice>
          <LoadingNotice hidden={!showReload}>
            {t("update-engagements-and-creditgroup-completed")}
          </LoadingNotice>
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              disabled={
                isProcessing ||
                isErrored ||
                isLoading ||
                isRefetchingEngagements ||
                showReload
              }
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={
                isProcessing ||
                isLoading ||
                isRefetchingEngagements ||
                showReload
              }
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
          {isErrored && (
            <StyledNotice backgroundColor={Colors.FerrariLighter}>
              {isErrored && <ErrorText>{t(`failed-to-load-task`)}</ErrorText>}
            </StyledNotice>
          )}
        </ReactForm>
      </Context>
    </Layout>
  )
}

const LoadingNotice = styled(Notice)`
  margin-top: 10px;
  color: ${Colors.Coal};
  background-color: ${Colors.OrangeLighter};
  border-radius: 10px;
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`
const ErrorText = styled.p`
  color: ${Colors.Ferrari};
`
const StyledNotice = styled(Notice)`
  color: ${Colors.Ferrari};
  border-radius: 0;
  margin-top: 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  justify-content: flex-start;
  margin-right: 1.9em;
`

const BMAnalyzeContent = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
`
const AssessmentCommentsWrapper = styled.div`
  padding: 10px 40px;
  overflow-y: auto;
`
export default withTranslation()(CreateCreditMemo)
