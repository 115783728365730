import { FontStyles } from "@flow/style"
import React from "react"
import styled from "styled-components"

const Heading = ({ children }) => {
  return <HeadingWrapper>{children}</HeadingWrapper>
}
export default Heading

const HeadingWrapper = styled.h1`
  ${FontStyles.Large}
`
