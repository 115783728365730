import axios from "axios"
import { Text } from "@flow/forms"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import { TransparentButton } from "@flow/buttons"
import { Icons } from "@flow/icons"
import { useState } from "react"

const EditAccount = ({
  title,
  data,
  setMode,
  setEditData,
  partialSave,
  flowId,
  stateData,
  setLoading,
  setStateData,
  errorMessage,
  setErrorMessage,
  deliveryId,
  t,
  index,
  payment,
}) => {
  //Hente ut pending task slik at vi kan hente taskId på message-tasken som skal trigges
  const getTaskId = async (flowId) => {
    const res = await axios.get(
      `/api/flow/tasks?flowId=${flowId}&status=pending`
    )
    const tasks = res?.data
    if (tasks.length > 0) {
      const pendingTask =
        tasks.find(
          (x) => x.taskType === "kar-check" && x.taskCategory === "message-task"
        ) || []
      return pendingTask.taskId
    }
  }
  const triggerKARCheck = async (flowId, newAccountNumber) => {
    setErrorMessage(t("wait-karcheck-message"))
    payment.customerAccountNumber = newAccountNumber

    setStateData({ ...stateData, payments: stateData.payments })

    partialSave({ customerAccountNumber: newAccountNumber })
    try {
      setMode("none")
      setLoading(true)
      const taskId = await getTaskId(flowId)

      const startTime = new Date()
      await axios.post(`/api/flow/tasks/${taskId}/trigger`, {
        customerAccountNumber: newAccountNumber,
        organizationNumber: stateData.payments[index].payoutOrgNumber,
        isPerson: stateData.payments[index].isPerson,
      })

      let counter = 0

      const interval = setInterval(async () => {
        const res = await axios.get(`/api/flow/flows/${flowId}`)
        const flowData = res?.data?.data
        const tasks = res?.data?.tasks

        if (tasks.length > 0) {
          const karchecks =
            tasks.filter(
              (x) =>
                x.taskType === "kar-check-on-message-task" &&
                x.taskCategory === "service-task"
            ) || []
          const pendingKarCheck =
            karchecks.filter((x) => new Date(x.createdAt) > startTime).pop() ||
            {}

          if (pendingKarCheck.status === "completed") {
            setLoading(false)
            const errorText =
              flowData?.deliveries[deliveryId]?.currentPayout?.messagetask
                ?.messagetask?.karCheck?.resultText ||
              flowData?.deliveries[deliveryId]?.currentPayout?.messagetask
                ?.karCheck?.resultText

            setErrorMessage(errorText || t("karcheck-failed-message"))

            payment.customerAccountNumber = newAccountNumber

            payment.karErrorMessage = errorText || t("karcheck-failed-message")
            payment.karCheckMessage = errorText || t("karcheck-failed-message")

            setStateData({ ...stateData, payments: stateData.payments })

            partialSave({
              payments: stateData.payments,
              customerAccountNumber: newAccountNumber,
              karErrorMessage: payment.karErrorMessage,
              karCheckMessage: payment.karCheckMessage,
            })

            clearInterval(interval)
          }
        }

        if (counter >= 5) {
          clearInterval(interval)
          setLoading(false)
          setErrorMessage(t("karcheck-failed-message"))
        }
        counter++
      }, 2000)
    } catch (error) {
      console.error("Failed to trigger message-task Kar-check", error)
    }
  }
  const [input, setInput] = useState(data)

  const handleCancel = () => {
    setEditData(data)
    setMode("none")
  }

  const handleSubmit = () => {
    // Strip input of non-numeric characters
    const strippedInput = input.replace(/\D/g, "")

    setEditData(strippedInput)
    return triggerKARCheck(flowId, strippedInput)
  }

  return (
    <div>
      <Line>
        <p>{title}</p>
      </Line>
      <CustomStyledBox>
        <Line>
          <Text
            defaultValue={data}
            onChange={(e) => setInput(e.target.value)}
            name="account"
          />
          {errorMessage}
          <ButtonContainer>
            <Button type="button" onClick={handleSubmit}>
              <Icons.Check color={Colors.GrassDark} />
            </Button>
            <Button type="button" onClick={handleCancel}>
              <Icons.Remove color={Colors.Ferrari} />
            </Button>
          </ButtonContainer>
        </Line>
      </CustomStyledBox>
    </div>
  )
}

export default EditAccount

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Button = styled(TransparentButton)`
  padding: 0;
  margin: 0;
  height: 18px;
`

const CustomStyledBox = styled.div`
  display: flex;
  background-color: inherit;
  border-radius: 5px;
  flex-direction: column;
  input {
    border: solid 1px ${Colors.Grey2};
  }
  :first-child {
    margin: 0;
  }
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  p {
    ${FontStyles.Small}
    padding-right: 5px;
  }

  &:nth-child(2) {
    p {
      ${FontStyles.Normal}
      color: black;
    }
  }
`
