import React, { useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import InsightModule from "../components/insight/InsightModule"

const UploadAccountData = (props) => {
  const { task, t, schema, flow } = props
  const { data, defaults } = task
  const taskHasData = data && Object.keys(data).length > 0
  const [formData, setFormData] = useState(taskHasData ? data : defaults)
  const [isProcessing, setProcessing] = useState(false)
  const { applicationSummary, insightComponentData } = task?.context

  const onFormChange = (values) => {
    setFormData(values)
  }

  const handleSave = (values) => {
    setProcessing(true)

    props.save(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setProcessing(true)

    props.complete(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  return (
    <Layout forceHeight>
      <MainContent>
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          commonData={insightComponentData?.commonData}
          options={{
            context: "accounting",
            startupModal: "ADD_ACCOUNTING_DATA",
          }}
        />
      </MainContent>
      <Context flow={flow} context={applicationSummary}>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton type="submit" isLoading={isProcessing}>
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}
export default UploadAccountData

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  overflow-y: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  margin: 10px 0;
  height: 30px;
`
