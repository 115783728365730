import React from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"

const ProposedAmountBox = ({
  title,
  currency,
  amount,
  appliedForAmount,
  appliedForText,
  className,
}) => {
  return (
    <StyledBox className={className}>
      <Line>
        <p>{title}</p>
      </Line>
      <Line>
        <p>{amount}</p>
        <p>{currency}</p>
      </Line>
      <Line>
        <p>{appliedForText}: </p>
        <p>{appliedForAmount}</p>
        <p>{currency}</p>
      </Line>
    </StyledBox>
  )
}
export default ProposedAmountBox

const StyledBox = styled.div`
  display: flex;
  background-color: ${Colors.SeaLightest};
  padding: 10px 30px 10px 10px;
  border-radius: 5px;
  flex-direction: column;
  margin-left: 15px;
  :first-child {
    margin: 0;
  }
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-bottom: 5px;
  p {
    padding-right: 5px;
  }
  &:nth-child(1) {
    font-size: small;
  }
  &:nth-child(2) {
    font-size: large;
    p {
      &:first-child {
        color: ${Colors.SeaDark};
      }
      &:last-child {
        color: ${Colors.Sea};
      }
    }
  }
`
