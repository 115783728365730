import React from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"

const WarningInfoBox = ({
  text,
  padding = "30px",
  width = "inherit",
  margin = "0",
  color = Colors.OrangeLighter,
  fontSize = "inherit",
  border = "none",
  textColor = "inherit",
  className,
  children,
}) => {
  return (
    <StyledBox
      className={className}
      width={width}
      padding={padding}
      color={color}
      margin={margin}
      fontSize={fontSize}
      border={border}
      textColor={textColor}
    >
      {text ? <p>{text}</p> : children}
    </StyledBox>
  )
}
export default WarningInfoBox
const StyledBox = styled.div`
  display: flex;
  background-color: ${(props) => props?.color};
  width: ${(props) => props?.width};
  padding: ${(props) => props?.padding};
  margin: ${(props) => props?.margin};
  font-size: ${(props) => props?.fontSize};
  border: ${(props) => props?.border};
  color: ${(props) => props?.textColor};

  border-radius: 5px;
  height: fit-content;
`
