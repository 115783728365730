import { isValid } from "date-fns"
import _ from "lodash"
import oldProductIdMapping from "../../../constants/oldProductIdMapping"
import formatWithLocale from "../../../customer/util/dateFormatter"
import { DropDownValues } from "../common/tempData/DropDownValues"

const isValidDate = (date) => {
  return isValid(new Date(date))
}

const compareDates = (dateA, dateB) => {
  return isValidDate(dateA) ? dateA > dateB : false
}

const getDate = (dateA, dateB, getLatest) => {
  const firstDate = new Date(dateA)
  const secondDate = new Date(dateB)
  return getLatest
    ? compareDates(firstDate, secondDate)
      ? firstDate
      : secondDate
    : compareDates(firstDate, secondDate)
    ? secondDate
    : firstDate
}

const swapDate = (compare, current, getLatest) => {
  return !current && isValidDate(compare)
    ? new Date(compare)
    : getDate(compare, current, getLatest)
}

export const formatInputDate = (date) => {
  return date && isValidDate(date)
    ? formatWithLocale(new Date(date), "yyyy-MM-dd")
    : "yyyy-MM-dd"
}

export const computeCosts = (costs = []) => {
  return costs.reduce((acc, curr) => acc + parseFloat(curr.approved), 0)
}

const pushToActivityAndSubsidyRegime = (
  activityAndSubsidyRegime,
  currentIndex,
  subsidyRegime
) => {
  if (
    !activityAndSubsidyRegime
      ?.map((e) => e.activity === currentIndex + 1)
      ?.includes(true)
  ) {
    activityAndSubsidyRegime.push({
      activity: currentIndex,
      subsidyRegime,
    })
  }
}
/*
Go through list of current subproducts
if current subproduct in previous, splice and update amount, add subproduct back in
if not in previous, add
 */

const pushToSubproducts = (
  productName,
  previousSubproducts,
  currentSubproducts
) => {
  let clone = _.cloneDeep(previousSubproducts)
  if (currentSubproducts?.length > 0) {
    currentSubproducts.forEach((sub) => {
      const currentSubIndex = clone.findIndex(
        (elem) => elem.variable === sub.variable
      )
      if (currentSubIndex >= 0) {
        const subProductToChange = clone.splice(currentSubIndex, 1)[0]
        subProductToChange.amount =
          parseFloat(subProductToChange.amount) + parseFloat(sub.amount)
        clone = [...clone, subProductToChange]
      } else {
        clone = [...clone, sub]
      }
    })
  }
  return clone
}

const mapDeliveries = (activity, currentIndex, acc, productConfiguration) => {
  //add new product to object or add to existing object
  return activity?.products?.forEach((curr) => {
    const { productType, amount, subproducts } = curr
    const productId = oldProductIdMapping[curr.product] || curr.product
    curr.product = productId
    const productName =
      productConfiguration[productId]?.displayNameShort || productId
    if (acc && productId in acc) {
      //replace date if larger/smaller
      acc[productId].deliveryStart = getDate(
        activity?.activityStart,
        acc[productId]?.deliveryStart,
        false
      )
      acc[productId].deliveryEnd = getDate(
        activity?.activityEnd,
        acc[productId]?.deliveryEnd,
        true
      )
      //increment amount
      acc[productId].amount += parseFloat(amount)

      pushToActivityAndSubsidyRegime(
        acc[productId]?.activityAndSubsidyRegime,
        currentIndex,
        activity?.supportRegime
      )
      acc[productId].subproducts = pushToSubproducts(
        productId,
        acc[productId].subproducts,
        subproducts
      )
    } else {
      acc[productId] = {
        productName,
        deliveryStart:
          isValidDate(activity?.activityStart) &&
          new Date(activity?.activityStart),
        deliveryEnd:
          isValidDate(activity?.activityEnd) && new Date(activity?.activityEnd),
        amount: parseFloat(amount),
        subproducts,
        productType,
        activityAndSubsidyRegime: [
          {
            activity: currentIndex,
            subsidyRegime: activity?.supportRegime,
          },
        ],
      }
    }
    return acc
  }, {})
}

export const mapCaseInput = (caseInput, productConfiguration) => {
  if (!caseInput) {
    return { activityTypes: [], products: {}, startDate: null, endDate: null }
  }
  const mapped = caseInput?.activities?.reduce(
    (acc, activity, currentIndex) => {
      acc.startDate = swapDate(activity?.activityStart, acc?.startDate, false)
      acc.endDate = swapDate(activity?.activityEnd, acc?.endDate, true)
      mapDeliveries(activity, currentIndex, acc.products, productConfiguration)
      if (!acc?.activityTypes?.includes(activity?.activityType)) {
        acc.activityTypes.push(activity?.activityType)
      }
      return acc
    },
    { activityTypes: [], products: {}, startDate: null, endDate: null }
  )

  return mapped
}

export const mapTotalProposed = (mappedInputProducts, productConfiguration) => {
  if (!mappedInputProducts) return { loan: 0, grant: 0, guarantee: 0 }
  return Object.keys(mappedInputProducts).reduce(
    (acc, curr) => {
      if (curr !== "noOption") {
        const productClass = productConfiguration[curr].productClass
        const productType = productConfiguration[curr].productType
        if (productClass === "Loan") {
          acc.loan += mappedInputProducts[curr].amount || 0
          if (productType === "loan-with-risk") {
            acc.loanTotalHighRisk += mappedInputProducts[curr].amount || 0
          }
          if (productType === "low-risk-loan") {
            acc.loanTotalLowRisk += mappedInputProducts[curr].amount || 0
          }
        }
        if (productClass === "Grant") {
          acc.grant += mappedInputProducts[curr].amount || 0
        }
        if (productClass === "Guarantee") {
          acc.guarantee += mappedInputProducts[curr].amount || 0
        }
      }
      return acc
    },
    {
      loan: 0,
      grant: 0,
      guarantee: 0,
      loanTotalHighRisk: 0,
      loanTotalLowRisk: 0,
    }
  )
}

export const mapCostOverview = (caseInput) => {
  const allCosts = caseInput.map((input) => {
    return input.costs.reduce((acc, curr) => {
      acc.push({
        name: curr.name,
        appliedFor: parseFloat(curr.appliedFor),
        approved: parseFloat(curr.approved),
      })
      return acc
    }, [])
  })

  return Object.values(
    allCosts.reduce((acc, costList) => {
      costList.forEach((cost) => {
        if (acc[cost.name]) {
          acc[cost.name] = {
            name: cost.name,
            appliedFor: acc[cost.name].appliedFor + cost.appliedFor,
            approved: acc[cost.name].approved + cost.approved,
          }
        } else {
          acc[cost.name] = cost
        }
      })

      return acc
    }, {})
  )
}

// Mapping of supportRegime from DropDownValues.jsx to eosArticle for the product registry
const supportRegimeMapping = {
  trivialSupport: "artBAG",
  regionalInvestment: "art14",
  smbInvestment: "art17",
  smbConsultantSupport: "art18",
  smbConferenceParticipation: "art19",
  smbEtcProjects: "art20",
  riskFinancingSupport: "art21",
  startUps: "art22",
  researchAndDevelopment: "art25",
  researchInfrastructure: "art26",
  clusterSupport: "art27",
  smbInnovationSupport: "art28",
  processAndInnovation: "art29",
  fouFishingAndAquaCulture: "art30",
  training: "art31",
  increaseProtectionOfEnvironment: "art36",
  earlyAdaptation: "art37",
  energySaving: "art38",
  highEfficientCogenerationPlant: "art40",
  renewableEnergy: "art41",
  revertPolutedAreas: "art45",
  sufficientDistrictHeatingAndCooling: "art46",
  recyclingAndReusing: "art47",
  energyInfrastructure: "art48",
  studiesOfEnvironment: "art49",
  cultureAndCulturalHeritage: "art53",
  audioVisualWorks: "art54",
  sportAndRecreationalInfrastructure: "art55",
  localInfrastructure: "art56",
  exemptFromSupportBasisRules: "artNOTEØS",
  article14: "art14",
  article17: "art17",
  article18: "art18",
  article19: "art19",
  article20: "art20",
  article21: "art21",
  article22: "art22",
  article25: "art25",
  article26: "art26",
  article27: "art27",
  article28: "art28",
  article29: "art29",
  article30: "art30",
  article31: "art31",
  article36: "art36",
  article37: "art37",
  article38: "art38",
  article40: "art40",
  article41: "art41",
  article45: "art45",
  article46: "art46",
  article47: "art47",
  article48: "art48",
  article49: "art49",
  article53: "art53",
  article54: "art54",
  article55: "art55",
  article56: "art56",
  article561: "art561",
  articleNOTEØS: "artNOTEØS",
  articleTF: "artTF",
}

export const getNewSupportRegime = (supportRegime) => {
  return supportRegimeMapping[supportRegime] || supportRegime
}

export const getOldSupportRegime = (supportRegime) => {
  return Object.keys(supportRegimeMapping).find(
    (key) => supportRegimeMapping[key] === supportRegime
  )
}

export const getSupportRegimesFromProducts = (productConfiguration) => {
  // For each product, fetch supportRegime from product.eosArticles and push to array with type {name: supportRegime, value: supportRegime}
  const supportRegimesFromProducts = Object.keys(productConfiguration).reduce(
    (acc, curr) => {
      const { eosArticles } = productConfiguration[curr]
      if (eosArticles?.length > 0) {
        const supportRegimes = eosArticles.map((eosArticle) =>
          getOldSupportRegime(eosArticle)
        )
        acc.push(...supportRegimes)
      }

      // Get the unique supportRegimes from the array
      const uniqueSupportRegimes = [
        ...new Set(acc.map((supportRegime) => supportRegime)),
      ]
      return uniqueSupportRegimes
    },
    []
  )
  const mappedSupportRegimes = supportRegimesFromProducts.map(
    (supportRegime) => {
      return {
        name: supportRegime,
        value: supportRegime,
      }
    }
  )
  const mappedSupportRegimesWithNames = mappedSupportRegimes
    .map(({ value, name }) => {
      // Find the corresponding object in the SupportRegime array
      const mergedDropdownValues = [
        ...DropDownValues.SupportRegime,
        ...DropDownValues.SupportRegimeExtraordinaryFinancing,
        ...DropDownValues.SupportRegimeBioEnergi,
        ...DropDownValues.SupportRegimeDtil,
        ...DropDownValues.SupportRegimeExportPromotion,
        ...DropDownValues.SupportRegimeDSF,
        ...DropDownValues.NotMappedSupportRegimes,
      ]
      const regime = mergedDropdownValues.find((item) => item.value === value)
      // If the object is found, return the name, otherwise return the original value
      return {
        value: regime?.value ?? value,
        name: regime?.name ?? name,
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))
  return mappedSupportRegimesWithNames
}

/**
 * @typedef {Object} Product
 * @property {String} product // The product id
 * @property {String[]} eosArticles // The eosArticles for the product
 */

/**
 * @typedef {Object} Activity
 * @property {String} activityType
 * @property {String} supportRegime
 * @property {Product[]} products
 */

/**
 * Validates the products in the activities based on the productsFromRegistry.
 *
 * @param {Activity[]} data - The activities to validate.
 * @param {ProductsFromRegistry} productsFromRegistry - The registry of products.
 * @returns {Object} - Returns products that are invalid for the given supportRegime.
 */
export const validateAndShowInvalidProducts = (data, productsFromRegistry) => {
  const invalidProducts = []
  for (const activity of data) {
    for (const product of activity.products) {
      // Find the corresponding product in the productsFromRegistry
      /**
       * @type {ProductFromRegistry}
       */
      const correspondingProduct = productsFromRegistry[product.product]
      // If the product was not found or the supportRegime is not included in the eosArticles, return false
      if (
        !correspondingProduct ||
        !correspondingProduct?.eosArticles?.includes(
          getNewSupportRegime(activity.supportRegime)
        )
      ) {
        invalidProducts.push({
          ...product,
          displayName: correspondingProduct?.displayName ?? "noOption",
        })
      }
    }
  }
  // If all products passed the validation, return true
  return invalidProducts
}

export const shouldValidateProducts = (hasEosArticles, isDynamicCostBased) => {
  return hasEosArticles && isDynamicCostBased
}

export const mapTotalProjectCostAndBasisForSupportForCostOverview = (costs) => {
  return costs.reduce(
    (acc, curr) => {
      const appliedForTotal = acc.projectCost + curr.appliedFor
      const approvedTotal = acc.basisForSupport + curr.approved

      acc.projectCost = appliedForTotal
      acc.basisForSupport = approvedTotal
      return acc
    },
    { projectCost: 0, basisForSupport: 0 }
  )
}

/**
 * @typedef {Object.<string, ProductFromRegistry>} ProductsFromRegistry
 *
 * @typedef {Object} ProductFromRegistry
 * @property {Boolean} isActive
 * @property {String[]} category - eg ["humanitarianinnovationproject"]
 * @property {String} productId
 * @property {String} displayName
 * @property {"grant"|"loan"} productType
 * @property {String} productClass - eg "Grant" or "Loan"
 * @property {String[]} eosArticles
 * @property {String} displayNameShort
 */
