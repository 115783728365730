import { Text } from "@flow/forms"
const TextField = ({
  label,
  onChange,
  value,
  field: { id, key, name, onBlur },
}) => {
  return (
    <Text
      name={name}
      id={id}
      label={label}
      key={key}
      onChange={(event) => onChange(event.target.value)}
      value={value}
    />
  )
}

export default TextField
