const collateralObjectTypes = {
  realEstate: "REAL_ESTATE",
  shareInHousingCooperation: "SHARE_IN_HOUSING_COOPERATION",
  housingShares: "HOUSING_SHARES",
  vehicle: "VEHICLE",
  ship: "SHIP",
  listedSecurities: "LISTED_SECURITIES",
  unlistedSecurities: "UNLISTED_SECURITIES",
  movables: "MOVABLES",
  shares: "SHARES",
  aquaculture: "AQUACULTURE",
  other: "OTHER",
}

const collateralAgreementTypes = {
  mortgageDeed: "MORTGAGE_DEED",
  suretyship: "SURETYSHIP",
  depositOwnBank: "DEPOSIT_OWN_BANK",
  depositOtherBank: "DEPOSIT_OTHER_BANK",
  lifeInsurance: "LIFE_INSURANCE",
  transport: "TRANSPORT",
  other: "OTHER",
  coordination: "OTHER_COLLATERAL_AGREEMENT",
}

export { collateralObjectTypes, collateralAgreementTypes }
