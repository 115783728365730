import IconButton from "../common/IconButton"
import { Colors, FontStyles } from "@flow/style"
import styled from "styled-components"
import React, { useState } from "react"
import EditSecurityText from "./securityTexts/EditSecurityText"

const DisplaySecurityTextComponent = ({
  t,
  isFirst,
  securityText,
  onEdit,
  onDelete,
  isReadOnly = false,
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const onEditSubmit = (data) => {
    const updatedSecurityText = {
      ...securityText,
      ...data,
    }
    onEdit(updatedSecurityText)
    setIsEditing(false)
  }

  const getSourceLabel = () => {
    if (securityText.failed) return t("securityTexts.failed")
    if (securityText.userGenerated) return t("securityTexts.userGenerated")

    return t("securityTexts.autoGenerated")
  }

  return (
    <>
      <SecurityIndent>
        <SecurityTextWrapper>
          {!isReadOnly && isEditing ? (
            <EditSecurityText
              t={t}
              onSubmit={onEditSubmit}
              onCancelEdit={() => setIsEditing(false)}
              securityText={securityText}
            />
          ) : (
            <>
              <TextSource>{getSourceLabel()}</TextSource>
              <SecurityTitleContainer>
                <SecurityTitleHeading>
                  {securityText.title}
                </SecurityTitleHeading>
                {!isReadOnly && (
                  <IconButtonWrapper>
                    {securityText.userGenerated && (
                      <StyledIconButton>
                        <IconButton
                          icon="Edit"
                          onClick={() => setIsEditing(true)}
                        />
                      </StyledIconButton>
                    )}
                    <StyledIconButton>
                      <IconButton icon="Trashcan" onClick={() => onDelete()} />
                    </StyledIconButton>
                  </IconButtonWrapper>
                )}
              </SecurityTitleContainer>
              {securityText.subtitle && (
                <SecuritySubtitle>{securityText.subtitle}</SecuritySubtitle>
              )}
              {securityText.text && (
                <SecurityLineList>
                  <SecurityLineListItem>
                    {t(securityText.text)}
                  </SecurityLineListItem>
                </SecurityLineList>
              )}
            </>
          )}
        </SecurityTextWrapper>
      </SecurityIndent>
      <br />
    </>
  )
}

const DisplaySecurityText = (props) => {
  const { isMaintenance, isFirst } = props
  if (!isMaintenance) {
    return (
      <IndentWrapper>
        <IndentArrow>{isFirst && "⮑"}</IndentArrow>
        <DisplaySecurityTextComponent {...props} />
      </IndentWrapper>
    )
  }

  return <DisplaySecurityTextComponent {...props} />
}

export default DisplaySecurityText

const IndentArrow = styled.div`
  margin-left: 1rem;
  margin-top: 1rem;
`

const IndentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 40px auto;
`

const SecurityTextWrapper = styled.div`
  background-color: ${Colors.FerrariLightest};
  border-radius: 10px;
  padding: 0.5rem 2rem;
`

const SecurityIndent = styled.div`
  padding-left: 1rem;
  width: 100%;
`

const SecurityLineList = styled.ul`
  padding-left: 0;
  margin-left: 0;
`

const SecurityTitleHeading = styled.h3`
  font-weight: 600;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin: 0;
`

const SecurityTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`

const SecuritySubtitle = styled.div`
  ${FontStyles.Normal}
  margin-bottom: 0.5rem;
`

const SecurityLineListItem = styled.li`
  margin-bottom: 0.5rem;
`

const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledIconButton = styled.div`
  margin-left: 0.5rem;
`

const TextSource = styled.div`
  margin: 0;
  padding: 0;
  margin-top: 0.5rem;
  font-size: 12px;
`
