import React from "react"
import styled from "styled-components"

export const PersonOrThirdRadio = ({
  t,
  handleChange,
  current,
  value,
  readonly,
}) => {
  return (
    <InputField>
      <label>{t("payout-to")}</label>
      <RadioGroup onChange={(e) => handleChange(e)}>
        <StyledRadio key={`applicant`}>
          <input
            type={"radio"}
            value={"applicant"}
            checked={current === "applicant"}
            onChange={(e) => handleChange(e)}
            disabled={readonly}
          />
          <p> {t("applicant")}</p>
        </StyledRadio>
        <StyledRadio key={`thirdParty`}>
          <input
            type={"radio"}
            value={"thirdParty"}
            checked={current === "thirdParty"}
            onChange={(e) => handleChange(e)}
            disabled={readonly}
          />
          <p>{t("thirdParty")}</p>
        </StyledRadio>
      </RadioGroup>
    </InputField>
  )
}

const InputField = styled.div`
  grid-column: ${(props) => props.fullLine && "1 / span 2"};
  padding: ${(props) => props.fullLine && "0 0 1em 0"};
  margin-right: 50px;
  margin-bottom: 10px;
`

const StyledRadio = styled.label`
  display: flex;
  flex-direction: row;

  p {
    padding-left: 5px;
  }

  &:last-child {
    padding-left: 10px;
  }
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
`
