import { FontStyles } from "@flow/style"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { ProjectColors } from "../../common/style/ProjectColors"
import BankInformationItem from "./BankInformationItem"

const BankInformation = ({ bankInformation, productConfiguration }) => {
  const { t } = useTranslation("EvaluateBasisForSupport")

  return (
    <div>
      <StyledHeading>{t("bank-information")}</StyledHeading>
      <BankInformationContainer>
        {Object.keys(bankInformation).map((key) => (
          <BankInformationItem
            key={key}
            item={bankInformation[key]}
            field={key}
            productConfiguration={productConfiguration}
          />
        ))}
      </BankInformationContainer>
    </div>
  )
}

export default BankInformation

const BankInformationContainer = styled.div`
  display: grid;
  border-radius: 5px;
  background-color: ${ProjectColors.Slate95};
  margin: 4px;
  padding: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  font-size: 14px;
`

const StyledHeading = styled.h1`
  margin: 4px;
  padding: 10px;
  ${FontStyles.Normal};
  font-size: 14px;

  font-weight: 400;
  line-height: 15px;
  text-transform: uppercase;
`
