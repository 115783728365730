import React, { useState } from "react"
import ReactForm from "../components/common/ReactForm"
import StatisticalData from "../components/uploadStatisticalData"
import Layout, { Context } from "../components/common/Layout"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import {
  uploadStatisticalDataDefaultValues,
  uploadStatisticalDataValidationSchemaAgriculture,
  uploadStatisticalDataValidationSchema,
} from "../components/uploadStatisticalData/UploadStatisticalData.validationSchema"
import ErrorContext from "../components/common/Error"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import styled from "styled-components"
import {
  handleComplete,
  handleSave,
} from "../components/BaseComponent/utils/mutations"
import BlueDot from "../components/common/BlueDot"
import AssessmentComments from "../components/common/AssessmentComments"
import Tabs from "../components/common/Tabs"
import { mapRelevantFields } from "../components/uploadStatisticalData/productionDataFields"
import { returnFirstArgWithValue } from "../util/returnValue"
import { FLOWNS_IS_DEV_ENV_VAR_IS_DEV } from "../components/ONLY_RENDER_IN_DEVELOPMENT"
import dummyStatisticalData from "../util/dummyStatisticalData"

const UploadStatisticalData = (props) => {
  const { task, t, flow, save, complete, schema } = props
  const { data, defaults } = task

  const stateData = flow?.data?.analysis?.uploadStatisticalData

  const applicationContent = flow?.data?.application?.applicationContent
  const context = flow?.data?.application.category
  const isAgriculture = context === "agriculture"
  const relevantAgricultureFields = !isAgriculture
    ? []
    : mapRelevantFields(applicationContent?.selectMainProduction)

  const productionFields = relevantAgricultureFields.reduce((acc, field) => {
    const { beforeKey, afterKey } = field
    if (data && data[beforeKey] && data[afterKey]) {
      return {}
    }
    acc[beforeKey] = `${applicationContent[beforeKey] || 0}`
    acc[afterKey] = `${applicationContent[afterKey] || 0}`
    return acc
  }, {})

  const defaultValues = Object.assign(
    uploadStatisticalDataDefaultValues,
    productionFields
  )

  const existingValues = returnFirstArgWithValue(data, stateData, defaultValues)

  const [isProcessing, setProcessing] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    reset,
    watch,
    setValue,
    register,
    onBlur,
    onChange,
    setError,
    ...rest
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(
      isAgriculture
        ? uploadStatisticalDataValidationSchemaAgriculture
        : uploadStatisticalDataValidationSchema
    ),
    defaultValues: existingValues,
  })

  const onFormChange = (value) => {
    setValue("comment", value?.comment)
  }

  const hasFemaleInitiative = watch("femaleInitiativeRadio")
  const industry = watch("industry")

  const onComplete = (values) => {
    handleComplete(complete, values, setProcessing)
  }

  const onSave = () => {
    const values = getValues()
    handleSave(save, values, setProcessing)
  }

  const [activeTab, setActiveTab] = useState("upload-statistical-data")
  const decisionCount = flow?.data?.assessmentComments?.length ?? 0
  const options = [
    {
      id: "upload-statistical-data",
      title: "Legg til statistikkdata",
      component: (
        <StatisticalData
          t={t}
          hasFemaleInitiative={hasFemaleInitiative}
          industry={industry}
          errors={errors}
          isAgriculture={isAgriculture}
          applicationContent={applicationContent}
        />
      ),
    },
    {
      id: "decisions",
      title: "Vurderinger",
      children: decisionCount ? <BlueDot>{decisionCount}</BlueDot> : undefined,
      component: (
        <AssessmentComments
          comments={flow?.data?.assessmentComments}
          t={t}
          assignee={flow?.assignee?.name}
        />
      ),
    },
  ]
  const tab = options.find((x) => x.id === activeTab)

  return (
    <Layout forceHeight>
      <MainContainer>
        <TabWrapper>
          <Tabs options={options} onChange={(e) => setActiveTab(e)}>
            <FormProvider
              handleSubmit={handleSubmit}
              control={control}
              errors={errors}
              setError={setError}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              onChange={onChange}
              onBlur={onBlur}
              {...rest}
            >
              <form
                onSubmit={handleSubmit(onComplete)}
                id={"statistical-data"}
                noValidate
              >
                {tab.component}
              </form>
            </FormProvider>
          </Tabs>
        </TabWrapper>
      </MainContainer>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <ReactForm
          schema={schema}
          formData={existingValues}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
          onSubmit={(value) => onComplete(value)}
        >
          <ErrorContext t={t} error={errors?.comment?.message} />
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              form="statistical-data"
              isLoading={isProcessing}
              disabled={isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing}
              onClick={() => onSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
          {FLOWNS_IS_DEV_ENV_VAR_IS_DEV() ? (
            <ButtonContainer>
              <SecondaryButtonModified
                type="button"
                onClick={() => reset(dummyStatisticalData)}
              >
                FYLL UT
              </SecondaryButtonModified>
            </ButtonContainer>
          ) : null}
        </ReactForm>
      </Context>
    </Layout>
  )
}
export default UploadStatisticalData

const TabWrapper = styled.div`
  margin-top: 10px;
`

const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  min-width: 60em;
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`
