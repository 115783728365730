import React from "react"
import styled from "styled-components"
import { TextArea } from "@flow/forms"
import { ProjectColors } from "../../components/common/style/ProjectColors"
import IconButton from "../common/IconButton"
import { useFormContext } from "react-hook-form"
import formatWithLocale from "../../util/dateFormatter"

const CustomCheckListFormListItem = ({
  index,
  currentUser,
  readOnly = false,
  formId,
}) => {
  const { register, watch, setValue } = useFormContext()
  const isChecked = watch(`${formId}[${index}].checklistControlled`)
  const isNotRelevant = watch(`${formId}[${index}].checklistNotRelevant`)
  const controlledTime = watch(`${formId}[${index}].checklistTimeControlled`)
  const text = watch(`${formId}[${index}].text`)

  const allChecklistItems = watch(`${formId}`)

  // When modified add controlledBy username and date
  React.useEffect(() => {
    setValue(`${formId}[${index}].checklistControlledBy`, currentUser)
    setValue(
      `${formId}[${index}].checklistTimeControlled`,
      formatWithLocale(new Date(), "dd.MM.yy")
    )
  }, [isChecked, isNotRelevant])

  const onChangeText = (e) => {
    setValue(`${formId}[${index}].text`, e.target.value)
  }

  return (
    <TR>
      <TD style={{ width: "50%" }}>
        {readOnly ? (
          <Text>{text}</Text>
        ) : (
          <StyledTextArea
            disabled={readOnly}
            {...register(`${formId}[${index}].text`)}
            onChange={onChangeText}
            value={text}
          />
        )}
      </TD>

      <TD style={{ width: "80px" }}>
        <label htmlFor="checklistControlled"></label>
        <input
          type="checkbox"
          disabled={isNotRelevant}
          {...register(`${formId}[${index}].checklistControlled`)}
        />
      </TD>
      <TD style={{ width: "80px" }}>
        <label htmlFor="checklistNotRelevant"></label>
        <input
          type="checkbox"
          disabled={isChecked}
          {...register(`${formId}[${index}].checklistNotRelevant`)}
        />
      </TD>
      <TD>
        {(isChecked || isNotRelevant) && (
          <>
            {currentUser}
            <br />
            {controlledTime}
          </>
        )}
      </TD>

      {!readOnly && (
        <TD>
          <CenteredContent>
            <IconButton
              icon={"Trashcan"}
              onClick={() => {
                const filteredChecklistItems = allChecklistItems.filter(
                  (item, i) => i !== index
                )
                setValue(`${formId}`, filteredChecklistItems)
              }}
            />
          </CenteredContent>
        </TD>
      )}
    </TR>
  )
}

const Text = styled.p`
  text-align: left;
  width: 100%;
`

const TR = styled.tr`
  &:nth-child(odd) {
    background-color: ${ProjectColors.Slate90};
  }
`

const TD = styled.td`
  font-weight: normal;
  padding: 12px;
  text-align: center;
`

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
`

const StyledTextArea = styled(TextArea)`
  resize: none;
  width: 100%;
  border-radius: 5px;
  background: white;
`

export default CustomCheckListFormListItem
