import styled from "styled-components"
import { Colors } from "@flow/style"
import { ProjectColors } from "./style/ProjectColors"

const CommentColumnComponent = ({ data, title }) => {
  return (
    <div>
      <CommentColumn>{title}</CommentColumn>
      <CommentColumn>
        <CaseWorkerComment>{data}</CaseWorkerComment>
      </CommentColumn>
    </div>
  )
}
export default CommentColumnComponent

const CaseWorkerComment = styled.div`
  text-align: start;
  background-color: ${ProjectColors.CommentColor};
  font-size: 12px;
  border-radius: 1em;
  padding: 1em;
  white-space: pre-wrap;
  margin-top: 0.5em;
  margin-bottom: 1em;
`

const CommentColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    p {
      &:first-child {
        font-weight: bold;
      }
    }
  }

  &:nth-child(2) {
    p {
      text-align: end;
      color: ${Colors.Sea};
    }
  }
`
