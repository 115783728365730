import PendingTask from "./PendingTask"
import { v4 as uuidv4 } from "uuid"

const taskCanBeOpened = (task) => {
  const { actions, permissions, taskCategory } = task

  if (actions) {
    const hasAvailableActions =
      actions.includes("save") ||
      actions.includes("complete") ||
      actions.includes("trigger") ||
      actions.includes("retry")
    return hasAvailableActions
  }

  if (!permissions || Object.keys(permissions).length <= 0) {
    return false
  }

  // Fallback to permissions (without "retry" for service-tasks which not available before actions are available)
  const hasPermissions =
    permissions.save || permissions.complete || permissions.trigger
  const isNotServiceTask = taskCategory !== "service-task"
  return !!hasPermissions && isNotServiceTask
}

const taskWithPermissionRuling = (task) => {
  const key = task.taskId || uuidv4()
  const disabled = !taskCanBeOpened(task)
  const defaultTaskProps = {
    title: task.title,
    actionTitle: task.actionTitle,
    disabled,
    path: task.path,
    isPrimaryAction: task.isPrimaryAction,
  }

  return (
    <div key={key}>
      <PendingTask {...defaultTaskProps} />
    </div>
  )
}

const filteredTasks = [
  "get-frame-budget",
  "kar-check",
  "fetch-new-guarantor-credit-check",
  "fetch-new-stakeholder-credit-check",
  "get-lookup-cadastre-ambita",
  "start-lookup-cadastrepdf",
  "fetch-signer-details",
]

export const taskLists = [
  // Failed tasks without deliveryId
  {
    id: "custom-failed-task-list-without-delivery",
    title: "failed-task-title",
    query: "status=failed",
    sort: (taskA, taskB) => taskA.title.localeCompare(taskB.title),
    filter: (task, index, self) => {
      if (
        !task.variables?.delivery?.id &&
        !filteredTasks.includes(task.taskType)
      ) {
        return task
      }
      return null
    },
  },
  // Failed tasks with deliveryId
  {
    id: "custom-failed-task-list-with-delivery",
    title: "failed-task-title",
    query: "status=failed",
    groupBy: (task) =>
      task?.variables?.delivery?.name ||
      task?.variables?.delivery?.id ||
      "general-tasks",
    sort: (taskA, taskB) => taskA.title.localeCompare(taskB.title),
    filter: (task, index, self) => {
      if (
        task.variables?.delivery?.id &&
        !filteredTasks.includes(task.taskType)
      ) {
        return task
      }
      return null
    },
  },
  // tasks without deliveryId
  {
    id: "custom-task-list-user-task-without-delivery",
    title: "task-title",
    query: "status=pending&taskCategory=user-task",
    sort: (taskA, taskB) => taskA.title.localeCompare(taskB.title),
    pendingTask: taskWithPermissionRuling,
    filter: (task, index, self) => {
      if (
        !task.variables?.delivery?.id &&
        !filteredTasks.includes(task.taskType)
      ) {
        return task
      }
      return null
    },
    map: (task, t) => {
      if (
        task.taskType === "fill-in-insurance-movables" ||
        task.taskType === "fill-in-insurance-vehicle"
      ) {
        return {
          title: [t(task.taskType), task.variables.documentClaim?.documentTitle]
            .filter((x) => !!x)
            .join(" - "),
          actionTitle: t("open"),
          disabled: false,
        }
      }
      return {
        title: t(task.taskType),
        actionTitle: t("open"),
        disabled: false,
      }
    },
  },
  // tasks with deliveryId
  {
    id: "custom-task-list-user-task-with-delivery",
    title: "task-title",
    query: "status=pending&taskCategory=user-task",
    groupBy: (task) =>
      task?.variables?.delivery?.name ||
      task.variables?.delivery?.id ||
      "general-tasks",
    sort: (taskA, taskB) => taskA.title.localeCompare(taskB.title),
    pendingTask: taskWithPermissionRuling,
    filter: (task, index, self) => {
      if (
        task.variables?.delivery?.id &&
        !filteredTasks.includes(task.taskType)
      ) {
        return task
      }
      return null
    },
    map: (task, t) => {
      if (task.taskType === "sign-document") {
        return {
          title: [
            t(task.taskType),
            task.variables.signingTask?.orgName,
            task.variables.signingTask?.signerName,
            task.variables.signingTask?.documents?.replaceAll(";", ","),
          ]
            .filter((x) => !!x)
            .join(" - "),
          actionTitle: t("open"),
          disabled: false,
        }
      }
      if (task.taskType === "register-bail-documents") {
        return {
          title: [
            t(task.taskType),
            task.variables.manualSigningDocumentPacket?.securityType,
            task.variables.manualSigningDocumentPacket?.amount
              ? `${task.variables.manualSigningDocumentPacket?.amount} NOK`
              : "",
          ]
            .filter((x) => !!x)
            .join(" - "),
          actionTitle: t("open"),
          disabled: false,
        }
      }
      return {
        title: t(task.taskType),
        actionTitle: t("open"),
        disabled: false,
      }
    },
  },
  // optional tasks without deliveryId
  {
    id: "custom-task-list-message-task-without-delivery",
    title: "optional-tasks-title",
    isPrimaryActionList: false,
    query: "status=pending&taskCategory=message-task",
    sort: (taskA, taskB) => taskA.title.localeCompare(taskB.title),
    pendingTask: taskWithPermissionRuling,
    filter: (task, index, self) => {
      if (
        !task.variables?.delivery?.id &&
        !filteredTasks.includes(task.taskType)
      ) {
        return task
      }
      return null
    },
    map: (task, t) => {
      if (
        task.taskType === "insurance-movables-not-necessary" ||
        task.taskType === "insurance-vehicle-not-necessary"
      ) {
        return {
          title: [t(task.taskType), task.variables.documentClaim?.documentTitle]
            .filter((x) => !!x)
            .join(" - "),
          actionTitle: t("open"),
          disabled: false,
        }
      }
      return {
        title: t(task.taskType),
        actionTitle: t("open"),
        disabled: false,
      }
    },
  },
  // optional tasks with deliveryId
  {
    id: "custom-task-list-message-task-with-delivery",
    title: "optional-tasks-title",
    isPrimaryActionList: false,
    query: "status=pending&taskCategory=message-task",
    groupBy: (task) =>
      task?.variables?.delivery?.name ||
      task?.variables?.delivery?.id ||
      "general-tasks",
    sort: (taskA, taskB) => taskA.title.localeCompare(taskB.title),
    pendingTask: taskWithPermissionRuling,
    filter: (task, index, self) => {
      if (
        task.variables?.delivery?.id &&
        !filteredTasks.includes(task.taskType)
      ) {
        return task
      }
      return null
    },
  },
]
