import React from "react"
import { TextArea } from "@flow/forms"
import { FontStyles } from "@flow/style"
import styled from "styled-components"
import PayoutDetails from "../performFinancialCheck/detailsComponents/PayoutDetails"
import InformationLine from "../performFinancialCheck/detailsComponents/InformationLine"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import formatWithLocale from "../../util/dateFormatter"

const LabelSpan = styled.div`
  ${FontStyles.Normal};
  margin-bottom: 5px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  justify-content: flex-start;
  margin-top: 1em;
  position: relative;
`
const TextWrapper = styled.div`
  margin-top: 0.5em;
`

const TextAreaBox = styled(TextArea)`
  width: 100%;
  height: 150px;
`

const BeginPayoutDetails = ({
  flow,
  t,
  stateData,
  setStateData,
  readonly,
  partialSave,
  errorMsg,
  deliveryId,
  currentDelivery,
  payoutRequestReceived,
  commitment,
  remainingAmount,
}) => {
  const karCheckMessage =
    currentDelivery?.currentPayout?.karCheck?.resultText || ""
  const flowId = flow?.flowId
  const account = commitment?.accountByAccountName
  const grantedAmount = currentDelivery?.amount
  return (
    <Content>
      <Row>
        <LabelSpan>{t("info-from-coreView")}</LabelSpan>
        <InformationLine
          t={t}
          data={[
            { title: t("acno-agno"), value: account?.accountName },
            { title: t("Status"), value: account?.coreViewStateName },
            {
              title: t("amount-available"),
              value: addThousandSeparator(account?.availableAmount) + " NOK",
            },
            {
              title: t("amount-approved"),
              value: addThousandSeparator(grantedAmount) + " NOK",
            },
            {
              title: t("amount-paid"),
              value:
                addThousandSeparator(Math.abs(account?.payoutAmount)) + " NOK",
            },
            {
              title: t("last-possible-payout-date"),
              value:
                account?.expiryDate &&
                formatWithLocale(account?.expiryDate, "dd.MM.yyyy"),
            },
          ]}
        />
      </Row>
      <Row>
        <LabelSpan>{t("details-payout")}</LabelSpan>
        <PayoutDetails
          currentDelivery={currentDelivery}
          karCheckMessage={karCheckMessage}
          flowId={flowId}
          payoutRequestReceived={payoutRequestReceived}
          commitment={commitment}
          remainingAmount={remainingAmount}
          readonly={readonly}
          stateData={stateData}
          t={t}
          partialSave={partialSave}
          setStateData={setStateData}
          errorMsg={errorMsg}
        />
      </Row>
      <Row>
        <LabelSpan>{t("internal-comments")}</LabelSpan>
        <TextWrapper>
          <TextAreaBox
            onChange={() => {}}
            disabled={readonly}
            label={t("comment")}
            help={t("internal-comment-help")}
            value={stateData.comment}
            name="internal-comment"
          />
        </TextWrapper>
      </Row>
    </Content>
  )
}
export default BeginPayoutDetails
