import { addThousandSeparator } from "./addThousandSeparator"
import { deliveryByAgreementId } from "../components/utils/mapAgreements"
import {
  collateralObjectTypes,
  collateralAgreementTypes,
} from "./collateralTypes"
import { formatCadastre } from "./formatCadastre"

const IN_ORGANIZATION_NUMBER = "986399445"

// Etterstående prioritet = det finnes en aktør med høyere prioritet enn IN.
// Sidestilt prioritet = det finnes en aktør med lik prioritet som IN.
// Sidestilt etterstående prioritet = det finnes en aktør med høyere prioritet enn IN OG en aktør med lik prioritet som IN.

/**
 * Creates text like "lån nr. 1234.1234 kr 123 456 og lån nr. 4321.4321 kr 456 123"
 * @param {string[]} accountNumbers Array of account numbers
 * @param {Object[]} loans All loans coming from coreview (engagements)
 * @returns {string} A string representing the already existing loans
 */
const createExistingLoansText = (accountNumbers, loans) =>
  accountNumbers
    .map((accountNumber) => {
      // Find the engagement representing this loan
      const loan = loans.find(
        ({ accountName }) => accountNumber === accountName
      )
      const { bookBalance, availableAmount, blockedAmount } = loan

      // Only bookBalance should be negative but might as well make sure all numbers are positive
      const loanSum =
        Math.abs(bookBalance) +
        Math.abs(availableAmount) +
        Math.abs(blockedAmount)

      // We've removed "løpende" from this text because we don't know the status of loan -
      // it isn't necessarily "løpende", it could be "aktiv" or similar. IN liked this change.
      return `lån nr. ${accountNumber} kr ${addThousandSeparator(loanSum)}`
    })
    .join(" og ")

const getTitle = (
  deliveries,
  deliveryId,
  collateralAgreement,
  agreementData,
  rawEngagements,
  isMaintenance = false
) => {
  if (isMaintenance) {
    // I tillegg til tidligere etablerte sikkerheter, skal følgende nye sikkerheter etableres til sikkerhet for løpende lån nr. [] til rest kr [] og løpende lån nr. [] til rest kr []:
    const coveredLoans =
      collateralAgreement?.basicCollateralAgreementInformation?.collateralCoverages?.map(
        ({ accountNumber }) => accountNumber
      ) ?? []

    // These constants are from insight's structure, not to be confused with IN's loan status.
    const validVersions = ["ACTIVE", "CHANGED"]
    const accountNumbers = agreementData
      ?.filter(
        (agreement) =>
          agreement.data?.type === "LOAN" &&
          validVersions.includes(
            agreement.data?.basicAccountInformation?.version
          ) &&
          coveredLoans.includes(
            agreement.data?.basicAccountInformation?.accountNumber
          )
      )
      ?.map(
        (agreement) => agreement.data?.basicAccountInformation?.accountNumber
      )

    const loansText = createExistingLoansText(accountNumbers, rawEngagements)

    return `I tillegg til tidligere etablerte sikkerheter, skal følgende nye sikkerheter etableres til sikkerhet for ${loansText}`
  }
  const collateralCoverages =
    collateralAgreement?.basicCollateralAgreementInformation
      ?.collateralCoverages ?? []

  if (collateralCoverages?.length === 1) {
    return "Lånet skal sikres som følger:"
  }

  // Hvis kategori er maintenance, så er det alltid snakk om løpende lån.
  // Hvis maintenance og nye avtaler, da skal disse på 04. Vedlikehold - utvidelse av sikkerhet
  // Bare tittelen bryr vi oss om her.
  // I fremtiden må vi også håndtere 03. Vedlikehold - debitorskifte

  const mappedCoverages = collateralCoverages.map((coverage) => ({
    coverage,
    version: agreementData.find((x) => x.internalId === coverage.internalId)
      ?.data?.basicAccountInformation?.version,
  }))

  const newLoans = mappedCoverages.filter((x) => x.version === "NEW")
  const activeLoans = mappedCoverages.filter((x) => x.version === "ACTIVE")

  const mapAgreementIdToDeliveryId = deliveryByAgreementId(deliveries)
  const hasOneNewAndExistingLoan =
    activeLoans?.length > 0 && newLoans?.length === 1

  const hasMultipleNewAndExistingLoans =
    activeLoans?.length > 0 && newLoans?.length > 1

  if (hasOneNewAndExistingLoan || hasMultipleNewAndExistingLoans) {
    const activeLoansText = createExistingLoansText(
      activeLoans.map(({ coverage }) => coverage.accountNumber),
      rawEngagements
    )
    if (hasOneNewAndExistingLoan) {
      return `Nytt lån kr ${addThousandSeparator(
        newLoans?.at(0)?.coverage?.amount
      )} og ${activeLoansText} skal sikres som følger:`
    }

    const otherLoansText = newLoans
      .filter(
        (x) => mapAgreementIdToDeliveryId[x.coverage.internalId] !== deliveryId
      )
      .map(({ coverage }) => {
        const internalId = coverage.internalId
        const deliveryId = mapAgreementIdToDeliveryId[internalId]
        const delivery = deliveries[deliveryId]
        return `${delivery?.productFullName} kr ${addThousandSeparator(
          delivery?.amount
        )}`
      })
      .join(", ")

    return `Lånet skal sammen med ${otherLoansText} og ${activeLoansText} sikres som følger:`
  }

  const otherLoansText = collateralCoverages
    .filter((x) => mapAgreementIdToDeliveryId[x.internalId] !== deliveryId)
    .map(({ internalId }) => {
      const deliveryId = mapAgreementIdToDeliveryId[internalId]
      const delivery = deliveries[deliveryId]
      return `${delivery?.productFullName} kr ${addThousandSeparator(
        delivery?.amount
      )}`
    })
    .join(", ")

  return `Lånet skal sammen med ${otherLoansText} sikres som følger:`
}

const getSubtitleType = (
  collateralAgreement,
  collateralObjects,
  stakeholders
) => {
  // if (Sikkerhet ved kausjon) return 4
  if (
    collateralAgreement?.collateralAgreementType ===
    collateralAgreementTypes.suretyship
  )
    return 4

  // if (Eier aktiva selv) return 2
  if (collateralObjects.some((obj) => ownsProperty(obj, stakeholders))) return 2

  return 3
}

const subtitleTypeToString = (type) => {
  const subtitles = {
    2: "Sikkerhet i aktiva tilhørende låntaker:",
    3: "Sikkerhet i aktiva tilhørende andre enn låntaker:",
    4: "Sikkerhet ved kausjon:",
  }
  return subtitles[type] ?? "error"
}

const getAgreementFaceValue = (agreement) => {
  // TODO: Add support for other types of agreements
  return (
    agreement?.specificCollateralAgreementInformation?.mortgageDeedDetails
      ?.mortgageDeedFaceValue ||
    agreement?.specificCollateralAgreementInformation?.mortgageDeedDetails
      ?.faceValue
  )
}

const ownsProperty = (object, stakeholders) => {
  return object?.basicCollateralObjectInformation?.owners?.some(
    ({ ownerId }) =>
      stakeholders?.find((stakeholder) => stakeholder.id === ownerId)
        ?.isApplicant
  )
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// Should be called per collateral agreement, each line can describe several collateral objects
const getSecurityLine = (
  deliveries,
  deliveryId,
  subtitleType,
  collateralAgreement,
  collateralObjects,
  stakeholders,
  collateral
) => {
  const textOrException = (
    text,
    errorMessage = "Mangler data eller klarer ikke å autogenerere tekst for denne avtalen."
  ) => {
    if (!text) {
      throw new Error(errorMessage)
    }

    return text()
  }

  // Make sure we actually get some collateral objects
  // subtitleType 4 is a special case, and does not have any collateral objects
  if (!collateralObjects.length && subtitleType !== 4) {
    throw new Error("Ingen sikkerhetsobjekter lagt til i avtalen.")
  }

  const {
    basicCollateralAgreementInformation,
    specificCollateralAgreementInformation,
  } = collateralAgreement
  const { mortgagors } = basicCollateralAgreementInformation

  // This only works because all security objects have the same type
  const objectType =
    collateralObjects.at(0)?.basicCollateralObjectInformation
      ?.collateralObjectType ||
    collateralObjects.at(0)?.basicCollateralObjectInformation?.type

  // Used to generate text like "Ola Nordmann, org.nr./fnr. 12345678901 og Kari Nordmann, org.nr./fnr. 12345678902"
  const formatEntities = (entities) => {
    return entities
      ?.map((stakeholder) => {
        const id =
          stakeholder?.type === "organization"
            ? stakeholder.organizationNumber
            : stakeholder.ssn
        if (!id) {
          return `${stakeholder.name}`
        }
        if (stakeholder?.type === "organization") {
          return `${stakeholder.name}, org.nr. ${id}`
        }
        if (stakeholder?.type === "person") {
          return `${stakeholder.name}, fnr. ${id}`
        }
        throw new Error("Unknown entity/stakeholder type")
      })
      ?.join(" og ")
  }

  const buildEntityList = (entityList) => {
    const entityIds = entityList?.map((x) => x.internalId)
    return formatEntities(
      stakeholders?.filter((x) => entityIds?.includes(x.id))
    )
  }

  // 2: Sikkerhet i aktiva tilhørende låntaker
  // 3: Sikkerhet i aktiva tilhørende andre enn låntaker
  // 4: Sikkerhet ved kausjon

  if (subtitleType === 4) {
    const { suretyLiabilityType, suretyAmount } =
      collateralAgreement.specificCollateralAgreementInformation
    const sureties = mortgagors ?? []
    const suretyList = buildEntityList(sureties)

    const coverageTotalAmount =
      basicCollateralAgreementInformation?.collateralCoverages?.reduce(
        (acc, { amount }) => acc + amount,
        0
      ) ?? 0

    // laveste av maksbeløp eller (kausjonsbeløp)avtalebeløp er større eller lik lånebeløp

    // Temporarily assume every surety has the same coverage, in the future we will
    // extend this to handle different coverage amounts for different sureties
    const coverageFactor = sureties.length ? 1 / sureties.length : 0
    const coveragePercent = `${(coverageFactor * 100).toFixed(2)}%`

    const mappedSureties = sureties.map((surety) => ({
      internalId: surety.internalId,
      name: stakeholders.find((x) => x.id === surety.internalId)?.name,
    }))

    // [] % på [kausjonist A] og med [] % på [kausjonist B]
    const suretyCoverageText = mappedSureties
      .map((x) => `${coveragePercent} på ${x.name}`)
      .join(" og med ")

    const suretiesArePeople =
      stakeholders?.find(
        (stakeholder) => stakeholder.id === sureties.at(0)?.internalId
      )?.type === "person"

    // Hvis kausjonisten er en forbruker, skal ordet "selvskyldnerkausjon" byttes ut med ordet "kausjon". Særvilkår om styre- eller generalforsamlingsbeslutning. JURI bør konsulteres
    const appropriateWord = suretiesArePeople
      ? "kausjon"
      : "selvskyldnerkausjon"

    // Nivå 1: Hvis det er kun en kausjonist for et lån, er du på 01. Hvis du har to eller flere kautionister på et lån, er du på 02 eller 03.
    // Hvis suretyLiabilityType = JOINT_AND_SEVERAL er du på 02, mens PRO_RATA gir 03.
    // Nivå 2: 04 er ikke støttet, 05 under 01 og 02 finnes ved å kombinere produktkoden for oppstartslån og antall kausjonister.

    // check if other agreements that are not suretyship
    const allDeliveryValues =
      Object.values(collateral?.collateralAgreementsForDelivery) || {}

    const hasOtherAgreementsThanSuretyship = allDeliveryValues?.some((item) =>
      item?.collateralAgreements
        ?.map(
          (x) =>
            x?.collateralAgreementType !== collateralAgreementTypes.suretyship
        )
        .some((item) => item)
    )

    const isPartialGuarantee =
      basicCollateralAgreementInformation?.collateralCoverages?.at(0)
        ?.collateralCoverage?.suretyshipDetails?.suretyResponsibiltyType ===
        "GUARANTEE_PARTIAL" || false

    let level = null
    if (suretyAmount >= coverageTotalAmount) {
      level = 1
    } else if (suretyAmount < coverageTotalAmount) {
      if (isPartialGuarantee) {
        level = 4
      } else {
        if (!hasOtherAgreementsThanSuretyship) {
          level = 5
        } else {
          level = 2
        }
      }
    }

    const loanWord =
      basicCollateralAgreementInformation?.collateralCoverages?.length > 1
        ? "lånene"
        : "lånet"

    const lineTextTable = {
      oneSurety: {
        1: () =>
          `${capitalizeFirstLetter(
            appropriateWord
          )} fra ${suretyList}. Kausjonen skal også dekke renter og omkostninger ved låntakers eventuelle mislighold.`,
        2: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}.`,
        4: () => `Kr ${addThousandSeparator(
          suretyAmount
        )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med
          selvskyldnerkausjon fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i
          sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}. Kausjonsbeløpet skal
          imidlertid reduseres med ethvert avdrag (ikke renter) som blir nedbetalt på ${loanWord}. Slik reduksjon
          av kausjonsbeløpet skal dog ikke skje ved nedreguleringer av ${loanWord} som kan relateres til salg av
          pantsatte aktiva, annet enn varelager, med mindre Innovasjon Norge har gitt skriftlig samtykke.`,
        5: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet.`,
      },
      jointSureties: {
        // Hver av kausjonistene er fullt ansvarlig for hele beløpet som omfattes av kausjonen (”en for alle –
        // alle for en”). Hver av kausjonistene kan kreves for hele kausjonsbeløpet inntil hele kausjonskravet er
        // dekket fullt ut. Långiver kan velge fritt å forholde seg til én eller flere av kausjonistene.
        1: () =>
          `Solidarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal også omfatte renter og omkostninger ved låntakers eventuelle mislighold.`,
        // Hver av kausjonistene er fullt ansvarlig for hele beløpet som omfattes av kausjonen (”en for alle –
        // alle for en”). Hver av kausjonistene kan kreves for hele kausjonsbeløpet inntil hele kausjonskravet er
        // dekket fullt ut. Långiver kan velge fritt å forholde seg til én eller flere av kausjonistene.
        2: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av ${loanWord}, med tillegg av renter og omkostningerved låntakers mislighold, skal sikres med solidarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}.`,
        4: () => `Kr ${addThousandSeparator(
          suretyAmount
        )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med
            selvskyldnerkausjon fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i
            sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}. Kausjonsbeløpet skal
            imidlertid reduseres med ethvert avdrag (ikke renter) som blir nedbetalt på ${loanWord}. Slik reduksjon
            av kausjonsbeløpet skal dog ikke skje ved nedreguleringer av ${loanWord} som kan relateres til salg av
            pantsatte aktiva, annet enn varelager, med mindre Innovasjon Norge har gitt skriftlig samtykke.`,
        5: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med solidarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet.`,
      },
      prorataSureties: {
        // Hver kausjonist kun er ansvarlig for sin angitte del av kausjonsbeløpet. (Eks: A = 50 %, B = 30 %
        // og C = 20 %). Långiver kan ikke kreve mer av hver kausjonist enn vedkommendes angitte
        // %-andel. Dersom en av kausjonistene ikke er i stand til å betale sin andel, kan långiver ikke
        // overføre dette ansvaret til de andre kausjonistene.
        1: () =>
          `Proratarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde for hele ${loanWord} med tillegg av renter og omkostninger ved låntakers eventuelle mislighold. Den felles forpliktelsen under kausjonen skal fordeles med ${suretyCoverageText}.`,
        // Hver kausjonist kun er ansvarlig for sin angitte del av kausjonsbeløpet. (Eks: A = 50 %, B = 30 % og C =
        // 20 %). Långiver kan ikke kreve mer av hver kausjonist enn vedkommendes angitte %‐andel. Dersom
        // en av kausjonistene ikke er i stand til å betale sin andel, kan långiver ikke overføre dette ansvaret til
        // de andre kausjonistene (slik som ved solidarisk ansvar).
        2: () =>
          `Kr ${addThousandSeparator(
            suretyAmount
          )} av lånet, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med proratarisk ${appropriateWord} fra ${suretyList}. Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet og skal gjelde for den dårligst pantesikrede delen av ${loanWord}. Den felles forpliktelsen under kausjonen skal fordeles med ${suretyCoverageText}.`,
        4: () => `Kr ${addThousandSeparator(
          suretyAmount
        )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, sikres med
          proratarisk ${appropriateWord} fra ${suretyList}.
          Kausjonen skal gjelde inntil ${loanWord} er innfridd i sin helhet og skal gjelde for den dårligst
          pantesikrede delen av ${loanWord}. Kausjonsbeløpet skal imidlertid reduseres med ethvert avdrag (ikke
          renter) som blir nedbetalt på ${loanWord}. Slik reduksjon av kausjonsbeløpet skal dog ikke skje ved
          avdragsbetalinger som kan relateres til salg av pantsatte aktiva, annet enn varelager, med mindre
          Innovasjon Norge har gitt skriftlig samtykke. Den felles forpliktelsen under kausjonen skal fordeles
          med ${suretyCoverageText}.`,
        5: () => `Kr ${addThousandSeparator(
          suretyAmount
        )} av ${loanWord}, med tillegg av renter og omkostninger ved låntakers mislighold, skal sikres med
        proratarisk ${appropriateWord} fra ${suretyList}. Kausjonen
        skal gjelde inntil ${loanWord} er innfridd i sin helhet.`,
      },
    }

    if (sureties.length === 1) {
      return textOrException(lineTextTable.oneSurety[level])
    }

    if (suretyLiabilityType === "JOINT_AND_SEVERAL") {
      return textOrException(lineTextTable.jointSureties[level])
    }

    if (suretyLiabilityType === "PRO_RATA") {
      return textOrException(lineTextTable.prorataSureties[level])
    }

    throw new Error("Kunne ikke finne sikkerhetsansvarstype")
  }

  const agreementFaceValue =
    getAgreementFaceValue(collateralAgreement) ?? "ukjent"

  const mortgagorList = buildEntityList(mortgagors)

  let INpriority = "[ikke definert]"
  const mapPriority = (liens = []) => {
    INpriority =
      liens
        .filter(
          (e) =>
            e.mortgageeId === IN_ORGANIZATION_NUMBER &&
            e.nominalAmount.amount === agreementFaceValue
        )
        .sort((a, b) => b.priority - a.priority)
        .shift()?.priority || "[ikke definert]"

    return liens
      .filter((e) => e.mortgageeId !== IN_ORGANIZATION_NUMBER)
      .reduce(
        (acc, curr) => {
          if (
            (INpriority !== "[ikke definert]"
              ? curr.priority < INpriority
              : true) &&
            !curr.toBeDeleted
          ) {
            acc.amount += curr.nominalAmount.amount
            const isOrganization = curr.mortgageeId.length === 9
            acc.mappedLiens.push({
              name: curr.mortgageeName,
              type: isOrganization ? "organization" : "person",
              ...(isOrganization
                ? { organizationNumber: curr.mortgageeId }
                : { ssn: curr.mortgageeId }),
            })
          }
          return acc
        },
        { mappedLiens: [], amount: 0 }
      )
  }

  let cadastreList = ""
  let allLiensWithHigherPriority = ""
  let allHigherPriorityAmount = ""
  if (
    objectType === collateralObjectTypes.realEstate ||
    objectType === collateralObjectTypes.vehicle ||
    objectType === collateralObjectTypes.movables ||
    objectType === collateralObjectTypes.shares ||
    objectType === collateralObjectTypes.aquaculture
  ) {
    const { liensList, totalAmount } = collateralObjects?.reduce(
      (acc, object) => {
        const { mappedLiens, amount = 0 } = mapPriority(
          object?.basicCollateralObjectInformation?.collateralObjectLiens
        )
        acc.liensList = [...acc.liensList, ...mappedLiens]
        acc.totalAmount += parseFloat(amount)
        return acc
      },
      { liensList: [], totalAmount: 0 }
    )

    // Remove duplicates
    const uniqueLiensList = liensList.filter(
      (lien, index, self) =>
        self.findIndex(
          (otherLien) =>
            (lien.type === "person" && lien.ssn === otherLien.ssn) ||
            (lien.type === "organization" &&
              lien.organizationNumber === otherLien.organizationNumber)
        ) === index
    )

    allLiensWithHigherPriority = formatEntities(uniqueLiensList)
    allHigherPriorityAmount = addThousandSeparator(totalAmount)
  }
  if (objectType === collateralObjectTypes.realEstate) {
    cadastreList = collateralObjects
      ?.map((object) => {
        const { cadastre, associatedProperties, ownershipType } =
          object.collateralObjectDetails
        const formattedMainCadastre = formatCadastre(cadastre)
        const allAssociatedProperties = associatedProperties
          ?.map((subCadastre) => formatCadastre(subCadastre.cadastre))
          .filter((subCadastre) => subCadastre !== formattedMainCadastre)

        const associatedPropertiesText =
          allAssociatedProperties?.length > 0
            ? ", " + allAssociatedProperties.join(", ")
            : ""
        return `${formattedMainCadastre} (${
          ownershipType === "EXCLUSIVE_OWNERSHIP" ? "eiendomsrett" : "festerett"
        })${associatedPropertiesText}`
      })
      ?.join(", ")
  }

  // This makes subtypes easier to work with
  let finalObjectType = objectType
  if (
    objectType === collateralObjectTypes.movables &&
    collateralObjects.at(0)?.collateralObjectDetails?.movablesType
  ) {
    finalObjectType =
      "MOVABLES::" +
      collateralObjects.at(0)?.collateralObjectDetails?.movablesType
  } else if (
    objectType === collateralObjectTypes.vehicle &&
    collateralObjects.at(0)?.collateralObjectDetails?.vehicleType
  ) {
    finalObjectType =
      "VEHICLE::" +
      collateralObjects.at(0)?.collateralObjectDetails?.vehicleType
  }

  const { registeredDate, registrationNumber } =
    specificCollateralAgreementInformation.mortgageDeedDetails

  const getLabelText = (textOrFunction) =>
    typeof textOrFunction === "function" ? textOrFunction() : textOrFunction

  // This function is used to generate the different levels of mortgage texts based on the collateral object type
  // shortenedLabel was needed for a case where a shortened label was used instead of the full one.
  // The label arguments can be strings or functions.

  const createMortgageTextsOwned = (label, shortenedLabel = null) => ({
    1: () =>
      `Pantedokument pålydende kr ${addThousandSeparator(
        agreementFaceValue
      )} i ${getLabelText(label)} med ${INpriority}. prioritet.`,
    3: () =>
      `Pantedokument pålydende kr ${addThousandSeparator(
        agreementFaceValue
      )} i ${getLabelText(
        label
      )} med prioritet og opptrinnsrett etter pantedokumenter pålydende til sammen kr ${allHigherPriorityAmount} til ${allLiensWithHigherPriority}.`,
    5: () =>
      `Eksisterende Pantedokument pålydende kr ${addThousandSeparator(
        agreementFaceValue
      )}, tgl. ${registeredDate} dbnr. ${registrationNumber}, i ${getLabelText(
        shortenedLabel || label
      )}.`,
  })

  const createMortgageTextsNotOwned = (label) => ({
    1: () =>
      `Pantedokument pålydende kr ${addThousandSeparator(
        agreementFaceValue
      )} i ${getLabelText(
        label
      )} tilhørende ${mortgagorList}, med ${INpriority}. prioritet.`,
    3: () =>
      `Pantedokument pålydende kr ${addThousandSeparator(
        agreementFaceValue
      )} i ${getLabelText(
        label
      )} tilhørende ${mortgagorList}, med prioritet og opptrinnsrett etter pantedokumenter pålydende til sammen kr ${allHigherPriorityAmount} til ${allLiensWithHigherPriority}.`,
    5: () =>
      `Eksisterende pantedokument pålydende kr ${addThousandSeparator(
        agreementFaceValue
      )}, tgl. ${registeredDate} dbnr. ${registrationNumber}, i ${getLabelText(
        label
      )} tilhørende ${mortgagorList}.`,
  })

  const getVehicleRegistrationText = () =>
    // This is the text that is used when taking security in *specific* vehicles, and not the whole fleet.
    // The mortgagors list may be in a different order than the collateral objects, but there should only be
    // mortgagor per collateral agreement, so the order doesn't matter.
    collateralObjects
      .map((object) => {
        const {
          dateFirstRegistered,
          registrationNumber,
          vehicleBrand,
          vehicleIdentificationNumber,
        } = object.collateralObjectDetails?.registrationData || {}

        if (registrationNumber) {
          return registrationNumber.toUpperCase()
        }

        if (
          !dateFirstRegistered ||
          !vehicleBrand ||
          !vehicleIdentificationNumber
        ) {
          throw new Error(
            "Mangler nødvendig informasjon om motorvogn: Førstegangsregistrerings, Merke eller Chassisnummer"
          )
        }

        const yearRegistered = new Date(dateFirstRegistered).getFullYear()
        return `${vehicleBrand}/${vehicleIdentificationNumber}/${yearRegistered}`
      })
      .join(", ")

  const getAquacultureText = () =>
    `følgende akvakulturtillatelser; ${collateralObjects
      .map((collateralObject) =>
        collateralObject.collateralObjectDetails.permissionNumbers
          .map(
            ({ regionNumber, municipalityNumber, serialNumber }) =>
              `${String(regionNumber).padEnd(2, " ")}${String(
                municipalityNumber
              ).padEnd(2, " ")}${String(serialNumber).padStart(4, "0")}`
          )
          .join(", ")
      )
      .join(" og ")}`

  const createSharesTexts = (owned) => {
    // This is not nice, but apparently the sum of shareOfTotalShareCapital in each entry is the total ownership percentage.
    const getOwnershipPercentage = ({ listOfShareSeries }) =>
      listOfShareSeries?.reduce(
        (acc, cur) => acc + (cur?.shareOfTotalShareCapital ?? 0),
        0
      )
    const getShareText = () =>
      collateralObjects
        .map((collateralObject) => {
          const { collateralObjectDetails } = collateralObject
          // If we do have share %, get the total ownership percentage
          if (collateralObjectDetails?.listOfShareSeries?.length > 0) {
            const ownershipPercentage = getOwnershipPercentage(
              collateralObjectDetails
            )
            return `${collateralObjectDetails.organizationName} (aksjeandel ${ownershipPercentage} %)`
          }
          return collateralObjectDetails.organizationName
        })
        .join(", ")

    const getIssuedAt = () =>
      collateralAgreement.meta.created.split("-").reverse().join(".")

    if (owned) {
      return {
        1: () =>
          `Pant i samtlige aksjer som låntaker eier i ${getShareText()} med 1. prioritet.`,
        // Kombineres ofte med særvilkår om "Pant i aksjer" og "Etterstående prioritet". JURI bør konsulteres
        3: () =>
          `Pant i samtlige aksjer som låntaker eier i ${getShareText()} med prioritet og opptrinnsrett etter pant til ${allLiensWithHigherPriority} til sikkerhet for lån til sammen kr ${allHigherPriorityAmount}.`,
        // Kombineres ofte med særvilkår om "Pant i aksjer". Pantobligasjoner i landbrukssaker fra før 01.01.1997 kan som regel ikke lånes opp igjen. Det samme gjelder for andre pantobligasjoner fra før 01.01.2005. Opplåningsforbud kan også være avtalt f.eks i en koordineringsavtale. DEPO bør kontaktes.
        5: () =>
          `Eksisterende pant utstedt ${getIssuedAt()} i samtlige aksjer som låntaker eier i ${getShareText()}.`,
      }
    }

    return {
      // Kombineres ofte med særvilkår om "Pant i aksjer" og "Kausjoner, herunder 3. manns pant". JURI bør konsulteres.
      1: () =>
        `Pant i samtlige aksjer som ${mortgagorList}, eier i ${getShareText()} med 1. prioritet.`,
      // Kombineres med særvilkår om "Kausjoner, herunder 3. manns pant", "Pant i aksjer" og "Etterstående prioritet". JURI bør konsulteres
      3: () =>
        `Pant i samtlige aksjer som ${mortgagorList}, eier i ${getShareText()} med prioritet og opptrinnsrett etter pant til ${allLiensWithHigherPriority} til sikkerhet for lån kr ${allHigherPriorityAmount}.`,
      5: () =>
        `Eksisterende pant utstedt ${getIssuedAt()} i samtlige aksjer som ${mortgagorList}, eier i ${getShareText()} .`,
    }
  }

  const lineTextTable = {
    // 2: Sikkerhet i aktiva tilhørende låntaker
    2: {
      [collateralObjectTypes.realEstate]:
        createMortgageTextsOwned(cadastreList),
      "VEHICLE::FLEET_VEHICLES": createMortgageTextsOwned(
        "samlede motorvogner og anleggsmaskiner ",
        "motorvogner"
      ),
      "VEHICLE::MOTOR_VEHICLE": createMortgageTextsOwned(
        getVehicleRegistrationText
      ),
      "MOVABLES::OPERATING_ACCESSORIES":
        createMortgageTextsOwned("driftstilbehør"),
      "MOVABLES::ACCOUNTS_RECEIVABLE": createMortgageTextsOwned(
        "samlede kundefordringer/factoring"
      ),
      "MOVABLES::INVENTORIES": createMortgageTextsOwned("varelager"),
      "MOVABLES::AGRICULTURAL_MOVABLES":
        createMortgageTextsOwned("landbruksløsøre"),
      "MOVABLES::FISHING_GEAR": createMortgageTextsOwned("fiskeredskaper"),
      SHARES: createSharesTexts(true),
      AQUACULTURE: createMortgageTextsOwned(getAquacultureText),
    },
    // 3: Sikkerhet i aktiva tilhørende andre enn låntaker
    3: {
      [collateralObjectTypes.realEstate]:
        createMortgageTextsNotOwned(cadastreList),
      "VEHICLE::FLEET_VEHICLES": createMortgageTextsNotOwned(
        "samlede motorvogner og anleggsmaskiner "
      ),
      "VEHICLE::MOTOR_VEHICLE": createMortgageTextsNotOwned(
        getVehicleRegistrationText
      ),
      "MOVABLES::OPERATING_ACCESSORIES":
        createMortgageTextsNotOwned("driftstilbehør"),
      "MOVABLES::ACCOUNTS_RECEIVABLE": createMortgageTextsNotOwned(
        "samlede kundefordringer/factoring"
      ),
      "MOVABLES::INVENTORIES": createMortgageTextsNotOwned("varelager"),
      "MOVABLES::AGRICULTURAL_MOVABLES":
        createMortgageTextsNotOwned("landbruksløsøre"),
      "MOVABLES::FISHING_GEAR": createMortgageTextsNotOwned("fiskeredskaper"),
      SHARES: createSharesTexts(false),
      AQUACULTURE: createMortgageTextsNotOwned(getAquacultureText),
    },
  }

  let priorityType = 1
  if (INpriority > 1) {
    priorityType = 3
  }
  // If eksisterende pantedokument, priorityType = 5

  // Invoke string table values as functions because each string requires formatting with
  // arbitrary values specific to each case, and we cannot do that in a static string table.
  return textOrException(
    lineTextTable[subtitleType]?.[finalObjectType]?.[priorityType]
  )
}

const getSecurityTextsForDelivery = (
  flowDeliveries,
  deliveryId,
  agreementsDataState,
  stakeholders,
  isMaintenance,
  rawEngagements
) => {
  const { collateral, agreementData } = agreementsDataState
  // We get one or more collateral agreements for each delivery
  const collateralData =
    collateral?.collateralAgreementsForDelivery?.[deliveryId] || {}
  const { collateralAgreements, collateralObjects } = collateralData

  if (!collateralAgreements || collateralAgreements?.length === 0) {
    return [
      {
        title: "Lånet gis uten krav om sikkerhet.",
        subtitle: "",
        text: "",
      },
    ]
  }

  // Only generate security texts for NEW collateral agreements
  const newAgreements = collateralAgreements.filter(
    (agreement) =>
      agreement?.basicCollateralAgreementInformation?.basicCollateralInformation
        ?.version === "NEW"
  )

  const getSecurityTextForAgreement = (agreement) => {
    // Find all collateral objects that belong to this agreement
    // We need to do this because there is no direct relation between the agreement and the objects
    const objects = collateralObjects.filter((x) =>
      agreement?.collateralObjectsReferences?.includes(x.internalId)
    )

    const subtitleType = getSubtitleType(agreement, objects, stakeholders)

    try {
      return {
        title: getTitle(
          flowDeliveries,
          deliveryId,
          agreement,
          agreementData,
          rawEngagements,
          isMaintenance
        ),
        subtitle: subtitleTypeToString(subtitleType),
        text: getSecurityLine(
          flowDeliveries,
          deliveryId,
          subtitleType,
          agreement,
          objects,
          stakeholders,
          collateral
        ),
        userGenerated: false,
      }
    } catch (e) {
      // An error occurred, but we still want to show the user something
      return {
        title: "En feil oppstod under generering av sikkerhetstekst:",
        subtitle: e.message,
        text: "",
        userGenerated: false,
        failed: true,
      }
    }
  }

  // Nå genererer vi en tittel osv. for hver avtale, og i getTitle skal vi sjekke om avtalen har sikkerhet.
  // Men, hvis vi har en avtale, vil ikke alltid lånet ha sikkerhet da?

  // For every collateral agreement we get a security text
  return newAgreements.map(getSecurityTextForAgreement)
}

const shouldDeliveryHaveSecurity = (delivery) => {
  const whitelisted = ["Loan", "Maintenance"]
  if (!delivery) return false
  return whitelisted.includes(delivery.productClass)
}

export { getSecurityTextsForDelivery, shouldDeliveryHaveSecurity }
