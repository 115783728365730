import React from "react"
import styled from "styled-components"
import StyledBox from "../../components/common/StyledBox"
import Notice from "../../components/common/Notice"
import { Colors } from "@flow/style"
import formatWithLocale from "../../util/dateFormatter"

const DisplayPayouts = ({ payouts, t }) => {
  if (payouts <= 0) {
    return (
      <Content>
        <StyledBox title={t("no-payouts")} />
      </Content>
    )
  }

  return (
    <Content>
      {payouts.map((x, i) => (
        <Row key={i}>
          <StyledBox
            title={t("payout-date")}
            data={formatWithLocale(x.payoutDate.Date, "dd.MM.yyyy")}
          />
          <StyledBox
            title={t("type")}
            data={t(x?.performFinancialCheck?.payoutType)}
          />
          <StyledBox
            title={t("amount")}
            data={x?.performFinancialCheck?.payoutAmount}
          />
        </Row>
      ))}
    </Content>
  )
}
export default DisplayPayouts

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const Content = styled(Notice)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
