const validatePhoneNumber = (number) => {
  // Not proper validation, allows one "+", one space and no other non-numeric characters
  // Refactor this!
  // (123) 456-7890
  const toTest = number
    .replace("+", "")
    .replaceAll(" ", "")
    .replaceAll("-", "")
    .replaceAll("(", "")
    .replaceAll(")", "")

  return number.length > 2 && !/\D/.test(toTest)
}
export default validatePhoneNumber
