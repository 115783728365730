import React from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"
import { ProjectColors } from "../common/style/ProjectColors"

const DisplaySigner = ({ t, signer, status }) => {
  return (
    <SignerWrapper hasSigned={status === "completed"}>
      <LineItemHeader>
        <SubItem>{signer?.navn || signer?.name}</SubItem>
      </LineItemHeader>
      <LineItem>
        <SubItem>{t("signing-status")}:</SubItem>
        <SubItem>{t(status)}</SubItem>
      </LineItem>
      {signer?.organization && (
        <LineItem>
          <SubItem>{t("organization")}</SubItem>
          <SubItem>{signer?.organization}</SubItem>
        </LineItem>
      )}
      <LineItem>
        <SubItem>{t("role")}</SubItem>
        <SubItem>{signer?.rolle?.tekstforklaring}</SubItem>
      </LineItem>
      <LineItem>
        <SubItem>{t("ssn")}</SubItem>
        <SubItem>{signer?.ssn}</SubItem>
      </LineItem>
      {signer?.fodselsdato && (
        <LineItem>
          <SubItem>{t("date-of-birth")}</SubItem>
          <SubItem>{signer?.fodselsdato}</SubItem>
        </LineItem>
      )}
      <LineItem>
        <SubItem>{t("email")}</SubItem>
        <SubItem>{signer?.email}</SubItem>
      </LineItem>
      <LineItem>
        <SubItem>{t("phone")}</SubItem>
        <SubItem>{signer?.phone}</SubItem>
      </LineItem>
    </SignerWrapper>
  )
}
export default DisplaySigner

const SignerWrapper = styled.div`
  p:first-letter {
    text-transform: capitalize;
  }

  border-radius: 5px;
  padding: 10px;
  margin: 4em;
  flex-direction: column;
  border: solid 2px
    ${(props) =>
      props.hasSigned ? `${Colors.GrassDark}` : `${Colors.FerrariDark}`};
  background-color: ${(props) =>
    props.header ? `${Colors.Snow}` : `${ProjectColors.Slate95}`};
`

const LineItem = styled.div`
  margin: 4px;
  align-items: center;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const LineItemHeader = styled(LineItem)`
  font-weight: bold;
  font-size: large;
  padding-bottom: 5px;
`
const SubItem = styled.div`
  margin: 0 4px 1px 4px;
  padding: 0 10px 1px 10px;
  display: grid;
`
