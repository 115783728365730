import React from "react"
import ReactDOM from "react-dom"

import { CaseManager, APPS, FEATURES } from "@flow/case-manager"
import * as serviceWorker from "./serviceWorker"
import * as customerSetup from "./customer"
import { Icons } from "@flow/icons"

import "./index.css"
import { customTranslates } from "./translate"
import AuditLog from "./customer/AuditLog"
import CustomRouteApplicationByCaseNumber from "./customer/CustomRouteApplicationByCaseNumber"
import { getCustomComponents } from "./customer"
import Settings, { SettingsIcon } from "./settings"
import { useLanguage } from "./translate/useLanguage"

const skin = {
  branding: customerSetup.Branding,
  mainLogo: customerSetup.MainLogo,
}
const loginConfiguration = {
  authenticationIcon: customerSetup.AuthenticationIcon,
}
const isUserAllowedToStart = (user) => {
  if (
    user.profile.role &&
    user.profile.role.some(
      (r) =>
        r === "role.inflow.test.reskontro" || r === "role.inflow.prod.reskontro"
    )
  ) {
    return true
  }
  return false
}

const getStartableProcesses = (user) => {
  if (isUserAllowedToStart(user)) {
    return customerSetup.StartableProcesses
  }
  return []
}

const FlowPortalWithApps = () => {
  const { language } = useLanguage()
  return (
    <CaseManager
      configuration={(user) => {
        const config = {
          homeUrl: "/inbox",
          apps: [APPS.Inbox, APPS.Search, APPS.Process, APPS.Decisions],
          skin: skin,
          inboxConfiguration: {
            searchFields: customerSetup.SearchFields,
            queuesPollingEnabled: true,
            startableProcesses: getStartableProcesses(user),
            features: [FEATURES.CaseAssignment, FEATURES.CaseNotes],
          },
          customComponents: customerSetup.CustomComponents,
          loginConfiguration: loginConfiguration,
          translates: customTranslates,
          language: language,
          searchConfiguration: {
            searchProcesses: [
              "application",
              "vekstgaranti",
              "payout-control",
              "maintenance",
              "depot-end-control",
            ],
            searchFields: customerSetup.searchFields,
            searchResultFields: customerSetup.searchResultFields,
            features: [FEATURES.CaseAssignment, FEATURES.CaseNotes],
          },
          caseAssignmentConfiguration: {
            caseownersTeamId: (flow) => {
              if (!flow) {
                return "CASEWORKERS"
              }
              return "CASEWORKERS"
            },
          },
          customRoutes: [
            {
              path: "/auditlog",
              component: AuditLog,
              navigation: {
                title: "Auditlog",
                icon: () => <Icons.Auditlog />,
              },
              translationNamespace: "Auditlog",
            },
            {
              path: "/case",
              component: () => <CustomRouteApplicationByCaseNumber />,
            },
            {
              path: "/settings",
              component: Settings,
              navigation: {
                title: "Settings",
                icon: SettingsIcon,
              },
            },
          ],
        }

        const role = user?.profile?.role || []
        const roles = role
          .filter((elem) => elem.startsWith("role"))
          .map((elem) => elem.split(".").pop())

        /* Did not remove this just in case they want to go back
      to the old solution. There is always a change with counties merging and IN

      const department = user?.profile?.department || []
      let counties = ""
      if (Array.isArray(department)) {
        department.forEach((element) => {
          counties += `${element.split(" ")[0]},`
        })
      } else {
        counties = department.split(" ")[0]
      }*/

        // //return queues based on what roles the user has
        config.customComponents = getCustomComponents(roles)
        return config
      }}
    />
  )
}

ReactDOM.render(<FlowPortalWithApps />, document.getElementById("root"))

serviceWorker.unregister()
