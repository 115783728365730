import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import React, { useCallback, useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import WarningInfoBox from "../components/common/WarningInfoBox"
import StyledBox from "../components/common/StyledBox"
import { Checkbox } from "@flow/forms"
import ErrorText from "../components/common/ErrorText"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { ProjectColors } from "../components/common/style/ProjectColors"

const extractValues = (values) => {
  return {
    assessmentComment: values?.assessmentComment,
    decision: values?.decision,
  }
}

const validate = (values) => {
  let errors = {}

  if (values?.decision === "OK") {
    if (!values?.descriptionOfActivities) {
      errors.descriptionOfActivities = "required"
    }
    if (!values?.descriptionOfProjectGoals) {
      errors.descriptionOfProjectGoals = "required"
    }
    if (!values?.assessmentOfUsefullnessOfProject) {
      errors.assessmentOfUsefullnessOfProject = "required"
    }
  }
  return errors
}

const AssessFinalReport = ({ task, flow, t, schema, save, complete, user }) => {
  const taskHasData = task?.data && Object.keys(task?.data).length > 0
  const [formData, setFormdata] = useState(
    taskHasData ? task?.data : task?.defaults
  )
  const [isProcessing, setProcessing] = useState(false)
  const [errors, setErrors] = useState({})

  const updateFormdata = useCallback(
    (key, value) => {
      const newData = { ...formData }
      newData[key] = value
      setFormdata(newData)
    },
    [formData]
  )

  const handleComplete = (values) => {
    setProcessing(true)

    const errors = validate(values)
    if (Object.keys(errors).length > 0) {
      setProcessing(false)
      setErrors(errors)
      return
    }

    complete(
      values,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = (values) => {
    setProcessing(true)

    save(
      values,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const warningInfoText = () => {
    return (
      <div>
        <b>{t(`assess-final-report-information-text-header`)}</b>
        <br />
        <br />
        <p>{t(`assess-final-report-information-text-body`)}</p>
      </div>
    )
  }

  return (
    <Layout forceHeight>
      <LeftContent>
        <StyledWarningInfo text={warningInfoText()} />
        <CheckboxContainer>
          <StyledBox
            title={t("assess-final-report-label")}
            titleSize={FontStyles.Normal}
          />
          <>
            <Checkbox
              id={t("assess-final-report-descriptionOfActivities")}
              name={t("assess-final-report-descriptionOfActivities")}
              label={t("assess-final-report-descriptionOfActivities")}
              checked={formData?.descriptionOfActivities || false}
              onChange={() =>
                updateFormdata(
                  "descriptionOfActivities",
                  !formData?.descriptionOfActivities
                )
              }
            />
            <ErrorText error={errors?.descriptionOfActivities} />
          </>
          <>
            <Checkbox
              id={t("assess-final-report-descriptionOfProjectGoals")}
              name={t("assess-final-report-descriptionOfProjectGoals")}
              label={t("assess-final-report-descriptionOfProjectGoals")}
              checked={formData?.descriptionOfProjectGoals || false}
              onChange={() =>
                updateFormdata(
                  "descriptionOfProjectGoals",
                  !formData?.descriptionOfProjectGoals
                )
              }
            />
            <ErrorText error={errors?.descriptionOfProjectGoals} />
          </>
          <>
            <Checkbox
              id={t("assess-final-report-assessmentOfUsefullnessOfProject")}
              name={t("assess-final-report-assessmentOfUsefullnessOfProject")}
              label={t("assess-final-report-assessmentOfUsefullnessOfProject")}
              checked={formData?.assessmentOfUsefullnessOfProject || false}
              onChange={() =>
                updateFormdata(
                  "assessmentOfUsefullnessOfProject",
                  !formData?.assessmentOfUsefullnessOfProject
                )
              }
            />
            <ErrorText error={errors?.assessmentOfUsefullnessOfProject} />
          </>
        </CheckboxContainer>
      </LeftContent>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <StyledGeneratedForm>
          <ReactForm
            schema={schema || {}}
            formData={formData}
            disabled={isProcessing}
            onChange={(values) =>
              setFormdata({ ...formData, ...extractValues(values) })
            }
            onSubmit={(values) =>
              handleComplete({ ...formData, ...extractValues(values) })
            }
          >
            <ButtonContainer>
              <PrimaryButton
                type="submit"
                onClick={() => setErrors(validate(formData))}
                isLoading={isProcessing}
              >
                {t("complete")}
              </PrimaryButton>
              <SecondaryButton
                type="button"
                disabled={isProcessing}
                onClick={() => handleSave(formData)}
              >
                {t("save")}
              </SecondaryButton>
            </ButtonContainer>
          </ReactForm>
        </StyledGeneratedForm>
      </Context>
    </Layout>
  )
}
export default AssessFinalReport

const CheckboxContainer = styled.div`
  padding-top: 2em;
  display: flex;
  flex-direction: column;

  label {
    padding: 1em 1em 1em 1em;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  width: 100%;

  label:nth-child(2) {
    background-color: ${ProjectColors.Slate90};
  }
  label:last-child {
    background-color: ${ProjectColors.Slate90};
  }
`

const StyledGeneratedForm = styled.div`
  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
  }

  label {
    label {
      span {
        padding-left: 10px;
        font: 12px "IBM Plex Sans";
        color: #6c6c6c;
      }
    }
  }

  input {
    max-width: 13.7em;
    margin: 0;
  }

  span:first-child {
    ${FontStyles.NormalStrong};
    padding-bottom: 0.4em;
  }
`

const ButtonContainer = styled.div`
  padding-top: 10px;

  button:last-of-type {
    margin-left: 1em;
    border: solid 1px ${Colors.SeaLight};
  }

  button {
    padding: 0;
  }
`

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  overflow-y: auto;
`

const StyledWarningInfo = styled(WarningInfoBox)`
  padding: 60px;
`
