import React, { useMemo } from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"
import { ProjectColors } from "../../common/style/ProjectColors"
import { addThousandSeparator } from "../../../util/addThousandSeparator"
import Amount from "./inputs/Product/Amount"
import Product from "./inputs/Product/Product"
import { useAgreement } from "../lineItems/useProductDraft"

const AdditionalProductLine = ({
  product,
  amount,
  fundingIntensity,
  productType,
  subsidyEffect,
  header,
  t,
  options,
  totalAppliedFor,
  name,
  readonly,
  className,
}) => {
  const { productConfiguration } = options || {}

  const dropdownValues = useMemo(
    () =>
      Object.keys(productConfiguration || {})?.reduce((acc, curr) => {
        const { displayName } = productConfiguration[curr]
        acc.push({ value: curr, name: displayName })
        return acc
      }, []),
    [productConfiguration]
  )

  const currentProduct = (productConfiguration || {})[product]
  const [agreement, setAgreement] = useAgreement(currentProduct)

  if (header) {
    return (
      <LineItem className={className} header={header}>
        <p>{product}</p>
        <p>{amount}</p>
        <p>{fundingIntensity}</p>
        <p>{productType}</p>
        <p>{subsidyEffect}</p>
      </LineItem>
    )
  }

  return (
    <>
      {readonly ? (
        <LineItem className={className} header={header}>
          <p>{product}</p>
          <p>{addThousandSeparator(amount)}</p>
          <p>{fundingIntensity}</p>
          <p>{productType}</p>
          <p>{addThousandSeparator(subsidyEffect)}</p>
        </LineItem>
      ) : (
        <LineItem className={className}>
          <Product dropdownValues={dropdownValues} name={name} t={t} />
          <Amount totalAppliedFor={totalAppliedFor} name={name} t={t} />
          <StyledCosts>
            <p>{fundingIntensity && fundingIntensity.toFixed(2)} </p>
            <p>{t("percentage-symbol")}</p>
          </StyledCosts>
          <p>{t(productType)}</p>
          <p>
            {subsidyEffect && addThousandSeparator(subsidyEffect?.toFixed(0))}{" "}
            NOK
          </p>
        </LineItem>
      )}
    </>
  )
}

export default AdditionalProductLine

const StyledCosts = styled.div`
  display: flex;
  flex-direction: row;

  p {
    :first-child {
      padding-right: 3px;
    }
  }
`

const LineItem = styled.li`
  border-radius: 5px;
  margin: 4px;
  padding: 10px;
  display: grid;
  background-color: ${(props) =>
    props?.header ? `${Colors.Snow}` : `${ProjectColors.Slate95}`};
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.1fr;
  grid-column-gap: 10px;
`
