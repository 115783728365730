import React from "react"
import styled from "styled-components"
import { DropDownValues } from "../../common/tempData/DropDownValues"
import { Controller, useFormContext } from "react-hook-form"
import Error from "../../common/Error"
import Dates from "../activity/Dates"
import { ProjectColors } from "../../common/style/ProjectColors"

const ActivityTypeLine = ({
  activityTypeText,
  supportRegimeText,
  index,
  t,
  isAdditionalProduct = false,
  isGrowthGuaranteeProduct = false,
  isAgriculture = false,
  isBioEnergy = false,
  isDtil = false,
  isShippingAndFishery = false,
  isExportPromotion,
  activityStart,
  activityEnd,
  supportRegimes,
  isForeignAccount,
}) => {
  const { control, errors, showErrors } = useFormContext()
  const activityTypeError = errors.activityType?.message
  const supportRegimeError = errors.supportRegime?.message

  let supportRegime =
    supportRegimes?.length > 0 ? supportRegimes : DropDownValues.SupportRegime
  if (isAdditionalProduct)
    supportRegime = DropDownValues.SupportRegimeExtraordinaryFinancing
  if (isAgriculture)
    supportRegime = [
      ...DropDownValues.SupportRegime,
      {
        name: "Omfattes ikke av statsstøtteregelverket",
        value: "exemptFromSupportBasisRules",
      },
    ]

  if (isBioEnergy) {
    supportRegime = DropDownValues.SupportRegimeBioEnergi
  }

  if (isDtil) {
    supportRegime = DropDownValues.SupportRegimeDtil
  }

  if (isExportPromotion) {
    supportRegime = DropDownValues.SupportRegimeExportPromotion
  }
  if (isShippingAndFishery) {
    supportRegime = DropDownValues.SupportRegimeDSF
  }

  return (
    <ActivityLineItem t={t}>
      <LeftSide isAdditionalProduct={isAdditionalProduct} t={t}>
        {!isAdditionalProduct && !isGrowthGuaranteeProduct && (
          <>
            <DropdownWrapper width="240px" t={t}>
              <Controller
                t={t}
                name={`activityType`}
                control={control}
                key={`${index}activityType`}
                render={({
                  field: { name, onBlur, onChange, value, defaultValue },
                }) => (
                  <>
                    <label htmlFor={`${name}${index}`}>
                      {activityTypeText}
                    </label>
                    <select
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      name={name}
                      id={`${name}${index}`}
                      key={`${name}${index}`}
                    >
                      <option value={DropDownValues.NoOption.value}>
                        {t(DropDownValues.NoOption.name)}
                      </option>
                      {DropDownValues.ActivityType?.map((optionItem) => {
                        return (
                          <option
                            key={`${index}${name}${optionItem?.value}`}
                            value={optionItem?.value}
                          >
                            {" "}
                            {t(optionItem?.name)}
                          </option>
                        )
                      })}
                    </select>
                  </>
                )}
              />
              {showErrors && activityTypeError && (
                <Error error={activityTypeError} t={t} />
              )}
            </DropdownWrapper>
          </>
        )}
        <DropdownWrapper extraordinaryFinancing={isAdditionalProduct} t={t}>
          <Controller
            t={t}
            name={`supportRegime`}
            control={control}
            key={`${index}supportRegime`}
            render={({
              field: { name, onBlur, onChange, value, defaultValue },
            }) => (
              <>
                <label htmlFor={`${name}${index}`}>{supportRegimeText}</label>
                <select
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  name={name}
                  id={`${name}${index}`}
                  key={`${name}${index}`}
                  t={t}
                >
                  {!isAdditionalProduct && (
                    <option value={DropDownValues.NoOption.value}>
                      {t(DropDownValues.NoOption.name)}
                    </option>
                  )}
                  {supportRegime.map((optionItem) => {
                    return (
                      <option
                        key={`${index}${name}${optionItem?.value}`}
                        value={optionItem?.value}
                      >
                        {" "}
                        {isForeignAccount
                          ? t(optionItem?.value)
                          : t(optionItem?.name)}
                      </option>
                    )
                  })}
                </select>
              </>
            )}
          />
          {showErrors && supportRegimeError && (
            <Error error={supportRegimeError} t={t} />
          )}
        </DropdownWrapper>
      </LeftSide>
      {!isGrowthGuaranteeProduct && (
        <Dates activityStart={activityStart} activityEnd={activityEnd} t={t} />
      )}
    </ActivityLineItem>
  )
}
export default ActivityTypeLine

const LeftSide = styled.div`
  border-radius: 5px;
  margin: 4px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 10px;
`

const ActivityLineItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const DropdownWrapper = styled.div`
  select {
    padding: 3px 5px 3px 5px;
    border-radius: 5px;
    border: solid 1px ${ProjectColors.InputBorder};
    color: inherit;
    width: ${({ width }) => width || "unset"};
    max-width: 300px;
  }
`
