import React from "react"
import EvaluateBasisForSupport from "./EvaluateBasisForSupport"
import SelectSigners from "./SelectSigners"
import IddCheck from "./IddCheck"
import AnalyzeApplication from "./AnalyzeApplication"
import UploadAccountingData from "./UploadAccountingData"
import CreateCreditMemo from "./CreateCreditMemo"
import FollowUpDecision from "./FollowUpDecision"
import BaseLayout from "./BaseLayout"
import AssessApplication from "./AssessApplication"
import PerformFinancialCheck from "./performFinancialCheck"
import AssessDecision from "./AssessDecision"
import ConsiderExtension from "./ConsiderExtension"
import FollowUpCustomer from "./FollowUpCustomer"
import DirectPayout from "./DirectPayout"
import PrintAndSendToSign from "./PrintAndSendToSign"
import RegisterBailDocuments from "./RegisterBailDocuments"
import UploadStatisticalData from "./UploadStatisticalData"
import EvaluateGuarantors from "./EvaluateGuarantors"
import HandleAnnulment from "./HandleAnnulment"
import AnnulmentCancellationFee from "./AnnulmentCancellationFee"
import ConsiderDelayingPayoutDueDate from "./ConsiderDelayingPayoutDueDate"
import CompanyBankruptcy from "./CompanyBanckruptcy"
import AnnulmentRequest from "./AnnulmentRequest"
import AssessFinalReport from "./AssessFinalReport"
import BeginPayout from "./BeginPayout"
import PerformIntroductoryEvaluation from "./PerformIntroductoryEvaluation"
import EvaluateBasisForSupportVekstgaranti from "./EvaluateBasisForSupportVekstgaranti"
import DecideCase from "./DecideCase"
import ApplicationAnnuled from "./ApplicationAnnuled"
import ConsiderExtensionOfPayment from "./ConsiderExtensionOfPayment"
import RegisterRecievedAdvance from "./RegisterReceivedAdvance"
import VerifyPayouts from "./VerifyPayouts"
import CancelAccountCoreView from "./CancelAccountCoreView"
import RegisterCollateralManual from "./RegisterCollateralManual"
import RegisterCollateralMoveablesManual from "./RegisterCollateralMoveablesManual"
import RegisterCollateralPropertyManual from "./RegisterCollateralPropertyManual"
import ControlPriority from "./ControlPriority"
import TermsAndSecurity from "./TermsAndSecurity"
import SelectSignersCollateral from "./SelectSignersCollateral"
import ErrorBoundary from "./ErrorBoundary"
import DepotControlCollateralMovablesManual from "./DepotControlCollateralMovablesManual"
import DepotControlCollateralPropertyManual from "./DepotControlCollateralPropertyManual"
import ControlTerms from "./ControlTerms"
import ManuallyUpdateCore from "./ManuallyUpdateCore"
import DoCustomerFollowUp from "./DoCustomerFollowUp"
import AdvicePanel from "./AdvicePanel"
import AssessAcceptance from "./AssessAcceptance"
import DepotAssessment from "./DepotAssessment"
import RefetchEngagement from "./RefetchEngagement"
import SelectCustomerRelatedCompany from "./SelectCustomerRelatedCompany"

const buildDynamicTaskName = (taskName, product) => {
  return `${taskName}-${product}`
}
const baseLayoutTasks = [
  //Application
  "customer-uploads-documentation",
  "fill-in-trivial-support-form",

  // signing
  "sign-document",

  //vekstgaranti
  "consider-extension-of-payment",
  "fill-in-self-declaration-form",
  "decide-case",
  "application-received",

  // maintenance
  "manually-update-core",
  "do-customer-followup",
]

const setDimensions = (width, height, maxWidth) => {
  return {
    customWidth: `min(${width}%, ${maxWidth || width + "%"})`,
    customHeight: `${height || width}%`,
  }
}

const dimensionsInsightComponents = {
  customWidth: "calc(100% - 40px)",
  customHeight: "calc(100% - 40px)",
}

const oldProductIds = [
  "vekstgaranti",
  "komtilRegional",
  "startUpLoan",
  "komtil",
  "matil",
  "matilRegional",
  "utInntBortfall",
  "mentor",
  "lalTr",
  "lalBolig",
  "brlTr",
  "lufBio",
  "buGenTr",
  "buIvtTr",
  "buGjLager",
  "A5F245D6-E47D-4AEF-A0B1-8FCEA6D915BA",
  "C0A5F1B0-F508-4972-B77B-F51C63CFCD6A",
  "43CAD91D-19AC-4ABA-BE2A-5D5553459C49",
  "9C1ECA95-D4F2-43F9-91DF-F38B025A0A93",
  "E2002819-FA53-46A4-B77E-50DFCB7D08B3",
  "8C308015-BFDD-4D27-BF3A-B061BC7E7417",
  "F6A5EE52-16DD-4FC6-AC59-B6E74EC73503",
  "2F7B6847-F167-4F2E-ACA6-D3B7CFA3ACD0",
  "9B77C451-B15A-4ECD-AF48-07D63D9A80C5",
  "6035BDEE-670B-4447-818F-2E907B33B251",
  "42ABD440-8063-4D4C-9225-74A19A8A7A41",
  "61A01F72-AE79-4571-B20A-08B504852F1A",
  "E5994C56-9B4E-4F23-BB88-D8C444B4546C",
  "83E500DA-C184-40F0-9D59-AA480B72603B",
]

const createOldProductTasks = (taskName, component) => {
  return oldProductIds.reduce((acc, curr) => {
    acc[buildDynamicTaskName(taskName, curr)] = {
      component,
      titleMap: (task) => taskName,
      ...setDimensions(80, 80, "1500px"),
    }
    return acc
  }, {})
}

const taskComponents = {
  ...baseLayoutTasks.reduce((acc, taskName) => {
    acc[taskName] = {
      component: BaseLayout,
      ...setDimensions(90),
    }
    return acc
  }, {}),
  "perform-introductory-evaluation": {
    component: PerformIntroductoryEvaluation,
    ...dimensionsInsightComponents,
  },
  "select-signers": {
    component: SelectSigners,
    ...setDimensions(90),
  },
  "select-signers-collateral": {
    component: SelectSignersCollateral,
    ...setDimensions(90),
  },
  "analyze-application": {
    component: AnalyzeApplication,
    ...dimensionsInsightComponents,
  },
  "assess-application-credit": {
    component: AssessApplication,
    ...dimensionsInsightComponents,
  },
  "assess-application-main-credit": {
    component: AssessApplication,
    ...dimensionsInsightComponents,
  },
  "assess-application-board": {
    component: AssessApplication,
    ...dimensionsInsightComponents,
  },
  "perform-idd-check-caseworker": {
    component: (props) => <IddCheck {...props} />,
    ...setDimensions(80),
  },
  "perform-idd-check-idd-team": {
    component: (props) => <IddCheck isTeam {...props} />,
    ...setDimensions(80),
  },
  "set-decision-basis": {
    component: EvaluateBasisForSupport,
    ...setDimensions(90, 90, "1800px"),
  },
  "upload-accounting-data-manually": {
    component: UploadAccountingData,
    ...dimensionsInsightComponents,
  },
  "create-credit-memo": {
    component: CreateCreditMemo,
    ...dimensionsInsightComponents,
  },
  "follow-up-decision": {
    component: FollowUpDecision,
    ...setDimensions(90),
  },
  "perform-financial-check": {
    component: PerformFinancialCheck,
    ...dimensionsInsightComponents,
  },
  "assess-decision": {
    component: AssessDecision,
    ...dimensionsInsightComponents,
  },
  "consider-extension": {
    component: ConsiderExtension,
    ...setDimensions(90),
  },
  "consider-delaying-payout-duedate": {
    component: ConsiderDelayingPayoutDueDate,
    ...setDimensions(90),
  },
  "signing-consider-delaying-payout-duedate": {
    component: ConsiderDelayingPayoutDueDate,
    ...setDimensions(90),
  },
  "follow-up-customer": {
    component: FollowUpCustomer,
    ...setDimensions(70),
  },
  "direct-payout": {
    component: DirectPayout,
    ...dimensionsInsightComponents,
  },
  "print-and-send-to-sign": {
    component: PrintAndSendToSign,
    ...setDimensions(90, 90, "1500px"),
  },
  "register-bail-documents": {
    component: RegisterBailDocuments,
    ...setDimensions(90, 90, "1500px"),
  },
  "upload-statistically-data": {
    component: UploadStatisticalData,
    ...setDimensions(90),
  },
  "assess-guarantor": {
    component: EvaluateGuarantors,
    ...setDimensions(90, 90, "1500px"),
  },
  "handle-annulment": {
    component: HandleAnnulment,
    ...setDimensions(80, 80, "1500px"),
  },
  "handle-cancellation-fee": {
    component: AnnulmentCancellationFee,
    ...setDimensions(80, 80, "1500px"),
  },
  "assess-final-report": {
    component: AssessFinalReport,
    ...setDimensions(80, 80, "1200px"),
  },
  "begin-payout": {
    component: BeginPayout,
    ...setDimensions(80, 80, "1500px"),
  },
  "perform-introductory-evaluation-vekstgaranti": {
    component: PerformIntroductoryEvaluation,
    ...dimensionsInsightComponents,
  },
  "set-decision-basis-vekstgaranti": {
    component: EvaluateBasisForSupportVekstgaranti,
    ...setDimensions(90, 90, "1800px"),
  },
  "decide-case": {
    component: DecideCase,
    ...dimensionsInsightComponents,
  },
  "application-annulled": {
    component: ApplicationAnnuled,
    ...setDimensions(80, 80, "1500px"),
  },
  "consider-extension-of-payment": {
    component: ConsiderExtensionOfPayment,
    ...setDimensions(90),
  },
  "register-received-advance": {
    component: RegisterRecievedAdvance,
    ...setDimensions(90),
  },
  "verify-payouts": {
    component: VerifyPayouts,
    ...setDimensions(90, 90, "1800px"),
  },
  "perform-depot-assessment": {
    component: DepotAssessment,
    ...setDimensions(90, 90, "1800px"),
  },
  "control-application-legal": {
    component: AssessApplication,
    ...setDimensions(90, 90, "1800px"),
  },
  "cancel-account-in-coreview-manual": {
    component: CancelAccountCoreView,
    ...setDimensions(70, 70, "1500px"),
  },
  "register-collateral-manual": {
    component: RegisterCollateralManual,
    ...setDimensions(90, 90, "1800px"),
  },
  "register-collateral-moveables-manual": {
    component: RegisterCollateralMoveablesManual,
    ...setDimensions(90, 90, "1500px"),
  },
  "register-collateral-property-manual": {
    component: RegisterCollateralPropertyManual,
    ...setDimensions(70, 70, "1500px"),
  },
  "control-priority": {
    component: ControlPriority,
    ...dimensionsInsightComponents,
  },
  "terms-and-security": {
    component: TermsAndSecurity,
    ...dimensionsInsightComponents,
  },

  "offer-denied": {
    component: BaseLayout,
    ...setDimensions(80, 80, "1500px"),
  },
  "change-signing": {
    component: BaseLayout,
    ...setDimensions(80, 80, "1500px"),
  },
  "payout-request-received": {
    component: BaseLayout,
    ...setDimensions(80, 80, "1500px"),
  },
  "company-bankruptcy": {
    component: CompanyBankruptcy,
    ...setDimensions(80, 80, "1500px"),
  },
  "annulment-requested": {
    component: AnnulmentRequest,
    ...setDimensions(80, 80, "1500px"),
  },
  "depot-control-collateral-movables-manual": {
    component: DepotControlCollateralMovablesManual,
    ...setDimensions(70, 70, "1500px"),
  },
  "depot-control-collateral-property-manual": {
    component: DepotControlCollateralPropertyManual,
    ...setDimensions(70, 70, "1500px"),
  },
  "control-terms": {
    component: ControlTerms,
    ...setDimensions(90, 90, "1500px"),
  },
  "manually-update-core": {
    component: ManuallyUpdateCore,
    ...setDimensions(90, 90, "1500px"),
  },
  "do-customer-followup": {
    component: DoCustomerFollowUp,
    ...setDimensions(90, 90, "1500px"),
  },
  "advice-panel": {
    component: AdvicePanel,
    ...setDimensions(70, 70, "1500px"),
  },
  "assess-acceptance": {
    component: AssessAcceptance,
    ...setDimensions(90, 90, "1500px"),
  },
  "start-extend-payout-duedate": {
    component: BaseLayout,
    ...setDimensions(80, 60, "1000px"),
  },
  "introductory-valuation-refetch-engagement": {
    component: RefetchEngagement,
    ...setDimensions(80, 80, "1000px"),
  },
  "start-refetch-engagements": {
    component: RefetchEngagement,
    ...setDimensions(80, 80, "1000px"),
  },
  "select-customer-related-company": {
    component: SelectCustomerRelatedCompany,
    ...setDimensions(80, 80, "1000px"),
  },
  // For backwards compatibility with old products so they show up
  ...createOldProductTasks("offer-denied", BaseLayout),
  ...createOldProductTasks("change-signing", BaseLayout),
  ...createOldProductTasks("payout-request-received", BaseLayout),
  ...createOldProductTasks("company-bankruptcy", CompanyBankruptcy),
  ...createOldProductTasks("annulment-requested", AnnulmentRequest),
}

const ErrorSafeComponents = Object.keys(taskComponents).reduce((acc, key) => {
  acc[key] = {
    ...taskComponents[key],
    component: (props) => {
      return (
        <ErrorBoundary {...props}>
          {taskComponents[key].component(props)}
        </ErrorBoundary>
      )
    },
  }
  return acc
}, {})

export default ErrorSafeComponents
