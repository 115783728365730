import axios from "axios"
import { useEffect, useState } from "react"
import capitalizedFirstLetter from "../../util/capitalizeFirstLetter"

/**
 * This function uses insight-lookup function against "Kartverket",
 * then uses the code (kommunenr) from the result as req-body to get dpvResult
 * from Flow decision-table municipality-dpv-results endpoint.
 * @param {string} searchString
 * @returns object with name, code and dpvResult
 */
const municipalityLookup = async (searchString) => {
  try {
    let municipality
    //Manuell håndtering av kommunenummer som IN ville ha
    const hardcodedMunicipalities = [
      { code: "2100", name: "Svalbard" },
      { code: "2111", name: "Spitsbergen" },
      { code: "2121", name: "Bjørnøya" },
      { code: "2131", name: "Hopen" },
      { code: "9900", name: "Utlandet" },
    ]

    const isExeption = hardcodedMunicipalities.some(
      (x) =>
        searchString === x.code ||
        capitalizedFirstLetter(searchString) === x.name
    )

    if (!isExeption) {
      const resultFromInsightMunicipalityLookup = await axios.get(
        `/si_server_proxy/location/${searchString}`
      )
      municipality = resultFromInsightMunicipalityLookup
    } else {
      municipality = {
        data: {
          municipalities: isExeption
            ? hardcodedMunicipalities.filter(
                (x) =>
                  searchString === x.code ||
                  capitalizedFirstLetter(searchString) === x.name
              )
            : [],
        },
      }
    }

    const dpvResultFromFlow = await Promise.all(
      municipality.data.municipalities.map(async (x) => {
        const dpvResult = (
          await axios.post(`/api/decision-tables/municipality-dpv-results`, {
            municipalityNumber: x.code,
          })
        ).data.result
        return { ...x, dpvResult }
      })
    )
    return dpvResultFromFlow
  } catch (error) {
    console.error(
      "municipalityLookup:: Could not get dpv, maybe the searchstring does not exist?, err: ",
      error.message
    )
    throw error
  }
}

const useMunicipalityLookup = (searchString) => {
  const [municipality, setMunicipality] = useState(null)
  //fetch municipality name
  useEffect(() => {
    const fetchData = async () => {
      const result = await municipalityLookup(searchString)
      const firstHit = result?.at(0)?.name
      setMunicipality(firstHit)
    }
    fetchData()
  }, [])

  return [municipality, municipalityLookup]
}

export default useMunicipalityLookup
