import React from "react"
import styled from "styled-components"
import StyledBox from "../../components/common/StyledBox"
import Notice from "../../components/common/Notice"
import { Colors, FontStyles } from "@flow/style"
import { format } from "date-fns"

const PayoutGrid = ({ titles, payouts, t, color, headline }) => {
  if (payouts.length === 0) {
    return (
      <Content color={color}>
        <TableTitle>{headline}</TableTitle>
        <GridWrapper>
          <GridItem>{t("no-payouts")}</GridItem>
        </GridWrapper>
      </Content>
    )
  }

  return (
    <Content color={color}>
      <TableTitle>{headline}</TableTitle>
      <GridWrapper>
        {titles.map((x) => (
          <GridItem fontsize={FontStyles.LargeStrong}>{x}</GridItem>
        ))}
        {payouts.map((data, i) =>
          data.map((x) => (
            <GridItem color={(i + 1) % 2 ? "white" : ""}>{x.data}</GridItem>
          ))
        )}
      </GridWrapper>
    </Content>
  )
}
export default PayoutGrid

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  overflow: auto;
`

const GridItem = styled.div`
  background-color: ${({ color }) => color};
  font-size: ${({ fontsize }) => fontsize || FontStyles.NormalStrong};
  padding: 20px 10px 20px 20px;
  white-space: nowrap;

  &:nth-child(8n-3):nth-child(n + 6) {
    text-align: end;
  }
`

const TableTitle = styled.p`
  ${FontStyles.LargeStrong};
  font-size: 20px;
  padding: 20px 0px 0px 20px;
`

const Content = styled(Notice)`
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1em;
`
