import { RadioButton } from "@flow/forms"
import Signer from "../assessAcceptance/Signer"
import AddSigner from "../assessAcceptance/AddSigner"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { ProjectColors } from "../common/style/ProjectColors"

const AssessAcceptanceComponent = ({
  needsAcceptance,
  setNeedsAcceptance,
  context,
  signers,
  handleAdd,
  handleDelete,
  handleEdit,
  t,
}) => {
  return (
    <AssessAcceptanceWrapper>
      <ConclusionContainer>
        <FlexColumn>
          <h4>Krever vedtaket aksept?</h4>
          <RadioButtonContainer>
            <RadioButton
              id="needsAcceptanceYes"
              name="needsAcceptanceYes"
              checked={needsAcceptance}
              onChange={() => setNeedsAcceptance(true)}
              label="Ja"
            />
            <RadioButton
              id="needsAcceptanceNo"
              name="needsAcceptanceNo"
              checked={!needsAcceptance}
              onChange={() => setNeedsAcceptance(false)}
              label="Nei"
            />
          </RadioButtonContainer>
        </FlexColumn>
        {needsAcceptance !== context.stateData.needsAcceptance && (
          <p>(Satt manuelt av saksbehandler)</p>
        )}
      </ConclusionContainer>
      <p>Følgende personer/selskaper må signere:</p>
      {signers.map((signer) => (
        <Signer
          handleEdit={handleEdit}
          handleAdd={handleAdd}
          handleDelete={handleDelete}
          signer={signer}
          key={signer.id}
          t={t}
        />
      ))}
      <AddSigner
        initialGuarantors={context.stateData?.initialGuarantors}
        handleAdd={handleAdd}
        t={t}
      />
    </AssessAcceptanceWrapper>
  )
}

export default withTranslation("maintenance")(AssessAcceptanceComponent)

const ConclusionContainer = styled.div`
  display flex;
  background-color ${ProjectColors.Sea97};
  padding: 15px;
  gap: 10px;
  justify-content: space-between;
  align-items: end;
  border-radius: 8px;
  margin-bottom: 1em;

  & h4 {
    margin: 0 0 5px 0;
  }
`

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
const AssessAcceptanceWrapper = styled.div`
  padding: 15px;
`

const RadioButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
`
