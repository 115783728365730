import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { CenteredSpinner, Icons } from "@flow/icons"

const defaultIconSize = 20

const ClickablePanel = styled.div`
  display: flex;
  padding-left: 10px;
  align-items: center;
  cursor: pointer;
`

const Collapsible = ({
  header,
  children,
  isOpen,
  isLoading,
  iconSize,
  color,
  className,
}) => {
  const [_isOpen, setOpen] = useState(isOpen)
  iconSize = iconSize || defaultIconSize
  return (
    <div className={className}>
      <ClickablePanel onClick={() => setOpen(!_isOpen)}>
        {_isOpen ? (
          <Icons.ChevronDown size={iconSize} color={color} />
        ) : (
          <Icons.ChevronRight size={iconSize} color={color} />
        )}
        {header}
      </ClickablePanel>
      {_isOpen && isLoading && <CenteredSpinner />}
      {_isOpen && !isLoading && <>{children}</>}
    </div>
  )
}

Collapsible.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.node.isRequired,
  children: PropTypes.node,
}

Collapsible.defaultProps = {
  children: null,
  isOpen: false,
}

export default Collapsible
