export const calculateSubsidyEffect = (amount) => {
  return amount * 0.215552259 - 1354.41641368147
}

const counterGuaranteeFactors = [
  0.027, 0.053, 0.08, 0.107, 0.133, 0.16, 0.187, 0.213, 0.24, 0.267,
]

export const calculateSubsidyEffectCounterGuarantee = (
  amount,
  runtimeYears
) => {
  return amount * counterGuaranteeFactors[runtimeYears - 1]
}
