import React from "react"
import { Colors } from "@flow/style"
import { withTranslation } from "react-i18next"
import styled from "styled-components"

const ErrorText = withTranslation()(({ error, t, children }) => {
  if (!error || error.length === 0) return <>{children}</>
  return (
    <ErrorStyle>
      {children}
      <div className="error-message">{t(error)}</div>
    </ErrorStyle>
  )
})

const ErrorStyle = styled.div`
  color: ${Colors.Ferrari};
  .error-message {
    margin-top: 4px;
  }
  input,
  select {
    border-color: ${Colors.Ferrari} !important;
  }
`

export default ErrorText
