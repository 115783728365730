import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import React, { useState } from "react"
import Heading from "../components/common/Heading"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import GuarantorCard from "../components/bail/GuarantorCard"
import styled from "styled-components"
import GuarantorForm from "../components/bail/GuarantorForm"
import { personFirstPred } from "../util/guarantorUtils"

const validateGuarantor = (id, guarantor) => {
  const errors = {}
  if (!guarantor.signedBailDocument) {
    errors[`signedBailDocument_${id}`] = "required"
  }

  if (guarantor.type === "person" && !guarantor.signDate) {
    errors[`signDate_${id}`] = "INVALID_SIGNDATE"
  }
  if (guarantor.type === "organization") {
    if (!guarantor.signedBoardDecisionDocument) {
      errors[`signedBoardDecisionDocument_${id}`] = "required"
    }
  }
  return errors
}

const validateSchema = (schema) => {
  let id = 0
  return schema.guarantors.reduce((acc, x) => {
    acc = { ...acc, ...validateGuarantor(id, x) }
    id++
    return acc
  }, {})
}

const RegisterBailDocuments = ({ task, t, flow, schema, save, complete }) => {
  const deliveryId = task?.variables?.delivery?.id
  const delivery = flow.data?.deliveries[deliveryId]
  const guarantors =
    (delivery?.guarantors || []).map((x) => ({
      ...x,
      ...x.info,
    })) || []

  if (!Array.isArray(task?.defaults)) task.defaults = []
  const mortgagors =
    task?.defaults?.map((x) => ({
      ...x,
      ...x.info,
      isSigningWithCollateral: true,
    })) || []

  const initialData = task.data || {
    guarantors: [...mortgagors, ...guarantors],
  }

  const [taskData, setTaskData] = useState(initialData)
  const [isProcessing, setProcessing] = useState(false)
  const [errors, setErrors] = useState({})

  const handleSave = (values) => {
    setProcessing(true)
    save(
      taskData,
      () => {},
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setProcessing(true)
    const errors = validateSchema(taskData)
    if (Object.keys(errors).length > 0) {
      setProcessing(false)
      setErrors(errors)
      return
    }
    complete(
      taskData,
      () => {},
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const onChangeGuarantor = (id, data) => {
    const newTaskdata = { ...taskData }
    newTaskdata.guarantors[id] = data
    setTaskData(newTaskdata)
  }

  const isSigningWithCollateral = taskData.guarantors?.some(
    (x) => x.isSigningWithCollateral
  )

  const newSchema = { ...schema }
  // Note: Manually handled outside of reactForm, but we want it in the schema for validation purposes
  delete newSchema.properties.guarantors

  return (
    <Layout forceHeight>
      <MainContainer>
        <GuarantorContainer>
          {taskData.guarantors?.sort(personFirstPred).map((x, i) => (
            <GuarantorCard
              t={t}
              guarantor={x}
              key={i}
              readonly
              isSigningWithCollateral={isSigningWithCollateral}
            >
              <GuarantorForm
                t={t}
                id={i}
                guarantor={x}
                onChange={(values) => onChangeGuarantor(i, values)}
                errors={errors}
              />
            </GuarantorCard>
          ))}
        </GuarantorContainer>
      </MainContainer>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <ReactForm
          schema={newSchema}
          formData={taskData}
          disabled={isProcessing}
          onChange={(values) => setTaskData(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton type="submit" isLoading={isProcessing}>
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave(taskData)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}

const MainContainer = styled.div`
  padding: 30px;
  width: 100%;
  overflow-y: auto;
`

const GuarantorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

export default withTranslation()(RegisterBailDocuments)
