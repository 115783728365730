import React, { useState, useCallback } from "react"
import lodash from "lodash"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Tabs from "../components/common/Tabs"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { returnFirstArgWithValue } from "../util/returnValue"
import InsightModule from "../components/insight/InsightModule"
import { useInsightAppInitialized } from "../components/insight/useInsightAppInitialized"
import SecurityTexts from "../components/TermsAndSecurity/SecurityTexts"
import Stakeholders from "../components/TermsAndSecurity/stakeholders/Stakeholders"
import SpecialTermsTab from "../components/AnalyzeApplication/SpecialTermsTab"
import { mapCollateral } from "../components/utils/mapAgreements"
import {
  mapChanges,
  mapEngagementToAgreementRegister,
} from "../components/utils/mapMaintenance"
import { mapStakeholderForInsight } from "../components/TermsAndSecurity/stakeholders/mapStakeholderForInsight"
import { getFile } from "../components/common/services/files"
import { mapAgreementData } from "../components/utils/mapAgreements"
import {
  getSecurityTextsForDelivery,
  shouldDeliveryHaveSecurity,
} from "../util/securityText"
import {
  getHistoricRealtyData,
  realtyLookup,
} from "../components/TermsAndSecurity/cadastreLookup"
import SecurityRiskAssessment from "../components/TermsAndSecurity/SecurityRiskAssessment"
import ErrorText from "../components/common/ErrorText"
import { formatStakeholderFromState } from "../components/TermsAndSecurity/stakeholders/formatStakeholderFromState"
import DecisionTexts from "../components/TermsAndSecurity/decisionTexts/DecisionTexts"
import { FLOW_NS_DEV_OR_TEST } from "../components/ONLY_RENDER_IN_DEVELOPMENT"
import {
  validateAgreementHasObject,
  validateAgreementWithoutDelivery,
} from "../util/validateAgreementsAndDeliveries"

const TermsAndSecurity = (props) => {
  const { task, t, schema, flow, onSave, updateCase } = props
  const flowId = flow.flowId
  const { data } = task
  const {
    applicationSummary,
    insightComponentData,
    stateData,
    isMaintenance,
    rawEngagements,
    existingCollateralFromCoreview,
  } = task?.context || {}
  const { agreementData, stakeholders } = insightComponentData || {}
  const [creditChecking, setCreditChecking] = useState(false)
  const [validations, setValidations] = useState([])
  const [formData, setFormData] = useState(
    returnFirstArgWithValue(data, stateData) || {}
  )
  const [editing, setEditing] = useState(
    formData.guarantors?.some((stakeholder) => !!stakeholder.editing)
  )
  const [maintenanceAgreements, setMaintenanceAgreements] = useState([])

  const [insightMappedStakeholder, setInsightMappedStakeholder] = useState(
    returnFirstArgWithValue(
      data?.insightStakeholder,
      stakeholders,
      mapStakeholderForInsight(formData?.guarantors || [])
    )
  )

  const partialSave = useCallback(
    (data) => {
      setProcessing(true)
      onSave(
        task.taskId,
        data,
        () => {
          updateCase()
          setProcessing(false)
        },
        () => {
          console.error("Could not save task")
          setProcessing(false)
        }
      )
    },
    [onSave, task.taskId, updateCase]
  )

  const updateFormDataStakeholdersWithRealtyCheckData = (stakeholders = {}) => {
    const formattedStakeholders = Object.keys(stakeholders).map((id) =>
      formatStakeholderFromState(stakeholders[id], id, true, true)
    )
    const existingStakeholders = [...formData.guarantors]

    //add data from state to existing stakeholders in frontend
    const updatedExistingStakeholders = existingStakeholders.reduce(
      (acc, curr) => {
        let found = false
        formattedStakeholders.forEach((newStakeholder) => {
          if (newStakeholder.id === curr.id) {
            curr.ownedCadastres = newStakeholder.ownedCadastres || {}
            //only used in dev
            if (newStakeholder.hashedSsn) {
              curr.hashedSsn = newStakeholder.hashedSsn
            }
            found = true
            acc.push(curr)
          }
        })
        !found && acc.push(curr)
        return acc
      },
      []
    )
    //Filter the stakeholders from state so only the ones not in frontend remains
    const newStakeholders =
      formattedStakeholders
        .filter((a) => !!a.id)
        .filter(
          (e) =>
            !existingStakeholders
              .filter((a) => !!a.id)
              .some((a) => a.id === e.id)
        ) || []

    const localFormData = { ...formData }
    const mergedStakeholders = [
      ...updatedExistingStakeholders,
      ...newStakeholders,
    ]
    if (mergedStakeholders?.some((stakeholder) => !!stakeholder.editing))
      setEditing(true)

    localFormData.guarantors = mergedStakeholders

    setInsightMappedStakeholder(mapStakeholderForInsight(mergedStakeholders))
    setFormData(localFormData)
    partialSave(localFormData)
  }

  const getLookupCadastre = async (cadastreKey, historicData = false) => {
    let lookupRes = {}
    if (historicData)
      lookupRes = await getHistoricRealtyData(cadastreKey, "cadastre", flowId)
    else {
      lookupRes = await realtyLookup(
        "cadastre",
        cadastreKey,
        flowId,
        formData.guarantors.map((stakeholder) => ({
          id: stakeholder.id,
          ssn: stakeholder.ssn,
          organizationNumber: stakeholder.organizationNumber,
          //only used in dev
          ...(stakeholder.hashedSsn
            ? { hashedSsn: stakeholder.hashedSsn }
            : {}),
        }))
      )
      updateFormDataStakeholdersWithRealtyCheckData(lookupRes.stakeholders)
      delete lookupRes.stakeholders
    }
    return lookupRes
  }
  const getLookupCadastrePdf = async (cadastreKey, historicData = false) => {
    let lookupRes = {}
    if (historicData)
      lookupRes = await getHistoricRealtyData(
        cadastreKey,
        "cadastrepdf",
        flowId
      )
    else lookupRes = await realtyLookup("cadastrepdf", cadastreKey, flowId)
    if (lookupRes.status === 200) {
      return {
        ...lookupRes,
        data: await getFile(lookupRes.data.storeID, "blob"),
      }
    }
    return lookupRes
  }

  const setAgreements = (agreementData, agreementsState) => {
    return {
      ...agreementData,
      ...agreementsState,
    }
  }
  const [agreementsDataState, setAgreementsDataState] = useState(
    setAgreements(agreementData, data?.agreementData)
  )

  const createDefaultSecurityTextsForDelivery = (deliveries) =>
    Object.keys(deliveries).map((deliveryId) => {
      return {
        deliveryId,
        securityTexts: [],
      }
    })

  const sortDeliveries = (deliveries) => {
    return deliveries.sort((a, b) => {
      return a.deliveryId.localeCompare(b.deliveryId)
    })
  }
  const getDefaultSecurityTextsForDelivery = () => {
    // Data from older task completions
    const previousSecurityTexts =
      flow.data.analysis?.termsAndSecurity?.securityTextsForDelivery
    // Data saved on the form (from the current task)
    const savedSecurityTexts = formData?.securityTextsForDelivery
    // Data from the (new) deliveries
    const newDeliveries = createDefaultSecurityTextsForDelivery(
      flow.data.deliveries
    )

    // Filter out deliveries that are not in the flow data anymore
    // savedSecurityTexts takes precedence over previousSecurityTexts
    const olderTexts = (
      savedSecurityTexts ||
      previousSecurityTexts ||
      []
    ).filter((delivery) => !!flow.data.deliveries[delivery.deliveryId])

    // Merge new and old (but valid) deliveries
    // Both are on the form {deliveryId, securityTexts, ...}
    const mergedDeliveries = [
      ...olderTexts,
      ...newDeliveries.filter(
        (delivery) =>
          !olderTexts.some((dt) => dt.deliveryId === delivery.deliveryId)
      ),
    ]

    return sortDeliveries(mergedDeliveries)
  }

  const [mappedSecurityTexts, setMappedSecurityTexts] = useState(
    getDefaultSecurityTextsForDelivery()
  )

  const [isProcessing, setProcessing] = useState(false)

  const onFormChange = (values) => {
    setFormData(values)
  }

  const getSecurityTexts = (deliveryId) =>
    getSecurityTextsForDelivery(
      lodash.cloneDeep(flow.data.deliveries),
      deliveryId,
      agreementsDataState,
      formData?.guarantors,
      isMaintenance,
      rawEngagements
    )
  const handleSave = () => {
    let agreementData = agreementsDataState
    let tempDeliveries = deliveries
    if (activeTab === "agreementRegister") {
      const insightState = activeApp.getCurrentState() || {}
      const collateral = mapCollateral(
        insightState?.agreementregister?.agreementData || [],
        flow.data.deliveries,
        isMaintenance
      )
      const tempAgreementDataState = {
        ...agreementsDataState,
        ...insightState?.agreementregister,
        collateral,
      }
      setAgreementsDataState(tempAgreementDataState)
      agreementData = tempAgreementDataState

      if (isMaintenance) {
        const mappedAgreementRegister = mapEngagementToAgreementRegister(
          insightState.agreementregister,
          insightComponentData.engagement.loans
        )

        const updatedDelivery = mapChanges(
          mappedAgreementRegister,
          existingCollateralFromCoreview,
          deliveries.vedlikehold
        )
        updateDeliveries({ deliveryId: "vedlikehold", data: updatedDelivery })
        tempDeliveries.vedlikehold = updatedDelivery
      }
    }
    setProcessing(true)
    props.save(
      {
        ...formData,
        agreementData: mapAgreementData(
          agreementData,
          flow,
          formData?.guarantors,
          securityRiskData
        ),
        securityTextsForDelivery: mappedSecurityTexts,
        deliveries: tempDeliveries,
        insightStakeholder: insightMappedStakeholder,
        securityRiskData,
      },
      () => setProcessing(false),
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const mapStakeholdersForComplete = (formData) => {
    return formData.guarantors.map((tStakeholder) => {
      const stakeholder = JSON.parse(JSON.stringify(tStakeholder))
      //data already on state, avoid big patch/duplicates
      delete stakeholder.nationalPopulationRegister
      delete stakeholder.ownedCadastres
      delete stakeholder.creditChecks
      //data only used in frontend for lookups and validation
      delete stakeholder.creditCheck
      delete stakeholder.creditChecking
      delete stakeholder.fetchDataFromAmbita
      delete stakeholder.fetchDataFromBisnode
      delete stakeholder.fetchDataFromPopulationRegistry

      return stakeholder
    })
  }

  const spouseValidationInGuarantorsList = (guarantors) => {
    const statuses = []
    guarantors.forEach((guarantor) => {
      if (guarantor.isMarried) {
        const spouseSsn = guarantor.spouseSsn
        const spouseExists = guarantors.find(
          (guarantor) => guarantor.ssn === spouseSsn
        )

        if (spouseExists !== undefined) {
          const isMatchedSpouse =
            spouseExists.isMarried && spouseExists.spouseSsn === guarantor.ssn
          statuses.push(isMatchedSpouse ? true : false)
        } else {
          statuses.push(false)
        }
      }
    })

    return statuses.some((status) => status === false)
  }

  const realkausjonValidation = (agreements, guarantors, updateCoDebtors) => {
    /**
     * This validation is to check if there is realkausjonister on agreement and user have not ticked off "Realkausjon" in GUI
     */

    //mappedCodebtors returnerer en array av id's som kan være ssn eller organizationNumber
    const mappedCodebtors = Object.keys(updateCoDebtors)
      .reduce((acc, deliveryId) => {
        const current = (updateCoDebtors[deliveryId] || [])?.map((x) => x.id)
        current.forEach((elem) => {
          if (!acc.includes(elem)) {
            acc.push(elem)
          }
        })
        return acc
      }, [])
      .flat()

    //Her henter vi ut alle interessenter som ikke eksisterer i mappedCodebtors listen
    //Og filtrerer ut søker
    const guarantorsOnly = guarantors
      .filter(
        (guarantor) =>
          !mappedCodebtors.find(
            (x) => x === (guarantor?.ssn || guarantor?.organizationNumber)
          )
      )
      .filter((gurantor) => !gurantor?.isApplicant)

    const statuses = []
    agreements.forEach((agreement) => {
      // This validation should only run on MORTGAGE_DEED and non existing agreements that have not been retrieved from CoreView.

      if (
        (agreement.collateralAgreementType !== "MORTGAGE_DEED" &&
          agreement.collateralAgreementType !== "MORTGAGE_DEED_VEHICLE" &&
          agreement.collateralAgreementType !== "MORTGAGE_DEED_MOVABLES" &&
          agreement.collateralAgreementType !== "MORTGAGE_DEED_REAL_ESTATE") ||
        (agreement.externalSource === "CoreView" &&
          agreement.basicCollateralAgreementInformation
            .basicCollateralInformation.version === "ACTIVE")
      )
        return

      const mortgagors =
        agreement.basicCollateralAgreementInformation.mortgagors
      const realkausjonIsChecked =
        agreement.specificCollateralAgreementInformation.suretyship

      let realkausjonister = []

      mortgagors.forEach((mortgagor) => {
        guarantorsOnly.forEach((guarantor) => {
          if (mortgagor.internalId === guarantor.id) {
            realkausjonister.push(guarantor)
          }
        })
      })

      if (realkausjonister.length > 0) {
        statuses.push(realkausjonIsChecked ? true : false)
      }
    })

    return statuses.some((status) => status === false)
  }

  const handleComplete = (values) => {
    // If disabled, don't do anything
    if (isErrored || isLoading || isProcessing || creditChecking) return

    if (editing) {
      let newValidations = [...validations]

      const translatedError = t("complete-editing-error")
      if (!newValidations.includes(translatedError))
        newValidations.push(translatedError)

      setValidations(newValidations)
      return
    }

    const currentState =
      activeTab === "agreementRegister"
        ? activeApp.getCurrentState()?.agreementregister?.agreementData || []
        : agreementsDataState?.agreementData || []

    const {
      collateralAgreements,
      collateralObjects,
      collateralAgreementsForDelivery,
    } = mapCollateral(currentState, flow.data.deliveries, isMaintenance)

    let agreementsForDeliveryMaintenance = {}

    //Dette er for å håndtere at vedlikehold ikke inkluderer avtaler som kommer fra CoreView
    if (isMaintenance) {
      agreementsForDeliveryMaintenance = Object.keys(
        collateralAgreementsForDelivery
      ).reduce((acc, curr) => {
        const vedlikehold = collateralAgreementsForDelivery.vedlikehold
        const collateralAgreements = vedlikehold.collateralAgreements.filter(
          (x) =>
            x.basicCollateralAgreementInformation.basicCollateralInformation
              .version === "NEW"
        )
        acc.vedlikehold = {
          ...vedlikehold,
          collateralAgreements,
        }
        return acc
      }, {})
    }

    const guarantors = mapStakeholdersForComplete(formData)
    const deliveryIds = Object.keys(flow.data.deliveries)
    let updateCoDebtors
    if (isMaintenance) {
      updateCoDebtors = currentState
        .filter((x) => x.type === "LOAN")
        .reduce((agreements, agreement) => {
          const agreementParsed = JSON.parse(JSON.stringify(agreement))
          if (!deliveryIds.includes("vedlikehold")) return agreements
          agreements.vedlikehold =
            agreementParsed.data.basicAccountInformation.accountRoles
          return agreements
        }, {})
    } else {
      updateCoDebtors = currentState
        .filter((x) => x.type === "LOAN")
        .reduce((agreements, agreement) => {
          const deliveryId = deliveryIds.find(
            (deliveryId) =>
              flow.data.deliveries[deliveryId].agreement.data.internalId ===
              agreement.data.internalId
          )
          if (!deliveryId) return agreements
          agreements[deliveryId] =
            agreement.data.basicAccountInformation.accountRoles
          return agreements
        }, {})
    }
    let tempDeliveries = deliveries
    if (isMaintenance && activeTab === "agreementRegister") {
      const insightState = activeApp.getCurrentState() || {}

      const mappedAgreementRegister = mapEngagementToAgreementRegister(
        insightState.agreementregister,
        insightComponentData.engagement.loans
      )

      const updatedDelivery = mapChanges(
        mappedAgreementRegister,
        existingCollateralFromCoreview,
        deliveries.vedlikehold
      )

      updateDeliveries({ deliveryId: "vedlikehold", data: updatedDelivery })
      tempDeliveries.vedlikehold = updatedDelivery
    }

    let newValidations = []

    const hasInvalidInputOnSpouse = spouseValidationInGuarantorsList(guarantors)
    const hasRealkausjonNotChecked = realkausjonValidation(
      collateralAgreements,
      guarantors,
      updateCoDebtors
    )

    if (
      isMaintenance &&
      tempDeliveries.vedlikehold.changes.length > 0 &&
      tempDeliveries.vedlikehold.changes.some(
        (change) =>
          change.decisionTexts.generated.length === 0 &&
          change.decisionTexts.userAdded.length === 0
      )
    ) {
      newValidations.push(t("missingDecisionTextsError"))
    }

    if (hasInvalidInputOnSpouse) {
      newValidations.push(t("spouseInteressentError"))
    }
    if (hasRealkausjonNotChecked) {
      newValidations.push(t("realkausjonValidationError"))
    }

    if (
      !isMaintenance &&
      securityRiskData.some((x) => x.safetyRisk === "default")
    ) {
      newValidations.push(t("securityRiskError"))
    }

    if (!isMaintenance) {
      const deliveriesMissingSecurityTexts = mappedSecurityTexts.filter(
        (x) =>
          shouldDeliveryHaveSecurity(flow.data.deliveries[x.deliveryId]) &&
          x.securityTexts.length === 0
      )

      if (deliveriesMissingSecurityTexts.length > 0) {
        const missingText = deliveriesMissingSecurityTexts
          .map(({ deliveryId }) => flow.data.deliveries[deliveryId].productName)
          .join(", ")

        newValidations.push(t("missingSecurityTextsError") + missingText)
      }
    }

    const securityTextsThatFailed = mappedSecurityTexts.filter(
      (x) =>
        shouldDeliveryHaveSecurity(flow.data.deliveries[x.deliveryId]) &&
        x.securityTexts.some((y) => y.failed)
    )

    if (securityTextsThatFailed.length > 0) {
      const failedText = securityTextsThatFailed
        .map(({ deliveryId }) => flow.data.deliveries[deliveryId].productName)
        .join(", ")

      newValidations.push(
        t("failedSecurityTextsError").replace("{failedText}", failedText)
      )
    }

    // Check that all agreements have connection to a delivery on state
    if (
      !isMaintenance &&
      validateAgreementWithoutDelivery(
        flow.data.deliveries,
        collateralAgreements
      )
    )
      newValidations.push(t("agreement-does-not-have-delivery-error"))

    if (validateAgreementHasObject(collateralAgreements))
      newValidations.push(
        t("mortgage-deed-agreement-does-not-have-object-error")
      )

    for (const [deliveryId, { collateralAgreements }] of Object.entries(
      collateralAgreementsForDelivery
    )) {
      const securityTextsForDelivery =
        (
          mappedSecurityTexts
            .filter((e) => e.deliveryId === deliveryId)
            ?.at(0) || {}
        ).securityTexts || []

      // Ideally we should check that each agreement has a connection to an existing text,
      // but we don't have a way to connect these, so instead just check that we have at least
      // as many new texts as agreements.
      const newAgreements = collateralAgreements.filter(
        ({ basicCollateralAgreementInformation }) =>
          basicCollateralAgreementInformation?.basicCollateralInformation
            ?.version === "NEW"
      )

      if (securityTextsForDelivery.length < newAgreements.length) {
        newValidations.push(t("not-enough-security-texts"))
      }
    }

    for (const collateralObject of collateralObjects) {
      const { collateralValuation, collateralObjectInsurance, type } =
        collateralObject?.basicCollateralObjectInformation
      const marketValue = collateralValuation?.marketValuation?.amount || 0
      const insuranceCompanyName = collateralObjectInsurance?.companyName

      if (
        // If real estate
        type === "REAL_ESTATE" &&
        // and marketvalue exists
        marketValue &&
        // and value is less than or equal to 12 MNOK
        marketValue <= 12_000_000 &&
        // and we don't have an insurance company name
        !insuranceCompanyName?.length
      ) {
        // Then error
        newValidations.push(t("missing-insurance-company"))
      }
    }

    let agreementData = agreementsDataState

    if (activeTab === "agreementRegister") {
      const insightState = activeApp.getCurrentState() || {}
      const collateral = mapCollateral(
        insightState?.agreementregister?.agreementData || [],
        flow.data.deliveries,
        isMaintenance
      )

      // Clean up deleted agreements from insightState

      const tempAgreementDataState = {
        ...agreementsDataState,
        ...insightState?.agreementregister,
        collateral,
      }
      setAgreementsDataState(tempAgreementDataState)
      agreementData = tempAgreementDataState
    }

    setValidations(newValidations)
    if (newValidations.length <= 0) {
      setProcessing(true)
      props.complete(
        {
          ...formData,
          securityTextsForDelivery: mappedSecurityTexts,
          securityRiskData,
          deliveries: tempDeliveries,
          updateCoDebtors,
          collateral: {
            collateralAgreements,
            collateralObjects,
          },
          collateralAgreementsForDelivery: isMaintenance
            ? agreementsForDeliveryMaintenance
            : collateralAgreementsForDelivery,
          guarantors,
          insightStakeholder: insightMappedStakeholder,
          agreementData: mapAgreementData(
            agreementData,
            flow,
            formData?.guarantors,
            securityRiskData
          ),
        },
        () => {
          setProcessing(false)
        },
        () => {
          setProcessing(false)
          console.error("Could not complete task")
        }
      )
    }
  }

  const createDefaultSecurityRiskData = () => {
    return Object.keys(flow.data.deliveries)
      .filter((deliveryId) =>
        shouldDeliveryHaveSecurity(flow.data.deliveries[deliveryId])
      )
      .map((deliveryId) => {
        return {
          deliveryId,
          safetyRisk: "default",
        }
      })
  }

  const getDefaultSecurityRiskData = () => {
    const oldData = formData?.securityRiskData || stateData?.securityRiskData
    const newData = createDefaultSecurityRiskData()

    const deliveries = Object.keys(flow.data.deliveries)
      .filter((deliveryId) =>
        shouldDeliveryHaveSecurity(flow.data.deliveries[deliveryId])
      )
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: flow.data.deliveries[cur],
        }),
        {}
      )

    const previousData =
      oldData
        ?.map((curData, index) => {
          // Check if data is using correct format, otherwise convert it
          if (curData.deliveryId) {
            return curData
          }

          // Convert it - this is necessary for backwards compatibility
          const matchedDeliveryId = Object.keys(deliveries).find(
            (deliveryId) => deliveries[deliveryId].productName === curData.name
          )

          if (!matchedDeliveryId) {
            return null
          }

          return {
            deliveryId: matchedDeliveryId,
            safetyRisk: curData.safetyRisk,
          }
        })
        ?.filter((x) => x !== null)
        ?.filter((x) => !!deliveries[x.deliveryId]) ?? []

    return [
      ...previousData,
      ...newData.filter(
        (newItem) =>
          !previousData.some(
            (oldItem) => oldItem.deliveryId === newItem.deliveryId
          )
      ),
    ]
  }

  const [activeApp, setActiveApp] = useState(null)
  const [activeTab, setActiveTab] = useState("agreementRegister")
  const [isLoading, isErrored] = useInsightAppInitialized(activeApp)
  const [deliveries, setDeliveries] = useState(formData.deliveries)
  const [securityRiskData, setSecurityRiskData] = useState(
    getDefaultSecurityRiskData()
  )

  const onAppChange = useCallback(
    (app) => {
      setActiveApp(app)
    },
    [setActiveApp]
  )

  // todo: fix when insight app exposes state
  const handleTabChange = (tab) => {
    // Check if we're tabbing away from agreementRegister, if so, update the state
    if (activeTab === "agreementRegister") {
      const insightState = activeApp.getCurrentState() || {}

      const collateral = mapCollateral(
        insightState?.agreementregister?.agreementData || [],
        flow.data.deliveries,
        isMaintenance
      )
      const tempAgreementDataState = {
        ...agreementsDataState,
        ...insightState?.agreementregister,
        collateral,
      }
      setMaintenanceAgreements(
        tempAgreementDataState.agreementData.filter(
          (agreement) =>
            !agreement.data.hasOwnProperty("basicCollateralObjectInformation")
        )
      )
      setAgreementsDataState(tempAgreementDataState)

      if (isMaintenance) {
        const mappedAgreementRegister = mapEngagementToAgreementRegister(
          insightState.agreementregister,
          insightComponentData.engagement.loans
        )

        const updatedDelivery = mapChanges(
          mappedAgreementRegister,
          existingCollateralFromCoreview,
          deliveries.vedlikehold
        )

        updateDeliveries({ deliveryId: "vedlikehold", data: updatedDelivery })
      }
    }

    setActiveTab(tab)
  }
  const updateDeliveries = ({ deliveryId, data }) => {
    const delivs = lodash.cloneDeep(deliveries)
    delivs[deliveryId] = data
    setDeliveries(delivs)
  }

  const options = [
    {
      id: "agreementRegister",
      title: "Sikkerheter",
      component: (
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          onAppChange={onAppChange}
          commonData={insightComponentData?.commonData}
          onChange={(e) => handleTabChange(e)}
          data={{
            ...insightComponentData,
            stakeholders: insightMappedStakeholder,
            lookupCadastre: getLookupCadastre,
            lookupLandRegister: getLookupCadastrePdf,
            collateralObjectTypes: [
              "MOVABLES",
              "REAL_ESTATE",
              "VEHICLE",
              "SHARES",
              "AQUACULTURE",
            ],
            collateralAgreementTypes: [
              "MORTGAGE_DEED_REAL_ESTATE",
              "MORTGAGE_DEED_VEHICLE",
              "MORTGAGE_DEED_MOVABLES",
              "MORTGAGE_DEED_SHARES",
              "MORTGAGE_DEED_AQUACULTURE",
              "SURETYSHIP",
            ],
            mortgagees: [
              {
                internalId: "986399445",
                mortgageeId: "986399445",
                mortgageeName: "Innovasjon Norge",
              },
            ],
            featureChangesEnabled: isMaintenance ? true : false,
            changeTypes: {
              ...(FLOW_NS_DEV_OR_TEST()
                ? {
                    LOAN: [
                      "GRACEPERIOD",
                      "INTERESTFREEPERIOD",
                      "REFINANCING",
                      "ACCUMULATEINTEREST",
                      "PAYMENTDEFERRAL",
                    ],
                    MORTGAGE_DEED: [
                      "COLLATERALCOVERAGES",
                      "REDUCEDNOMINALAMOUNT",
                      "DELETEELEMENT",
                      "PRIORITYCHANGES",
                    ],
                    SURETYSHIP: ["COLLATERALCOVERAGES", "DELETEELEMENT"],
                    REAL_ESTATE: [],
                    SHARE_IN_HOUSING_COOPERATION: [],
                  }
                : {
                    LOAN: [
                      "GRACEPERIOD",
                      "INTERESTFREEPERIOD",
                      "ACCUMULATEINTEREST",
                      "REFINANCING",
                      "PAYMENTDEFERRAL",
                    ],
                    MORTGAGE_DEED: [
                      "REDUCEDNOMINALAMOUNT",
                      "DELETEELEMENT",
                      "PRIORITYCHANGES",
                    ],
                    SURETYSHIP: ["DELETEELEMENT"],
                    REAL_ESTATE: [],
                    SHARE_IN_HOUSING_COOPERATION: [],
                  }),
            },
          }}
          options={{
            context: "agreementregister",
          }}
        />
      ),
    },
    {
      id: "securityTexts",
      title: "Sikkerhetstekster",
      component: (
        <SecurityTexts
          t={t}
          deliveries={flow.data.deliveries}
          formData={formData}
          getSecurityTexts={getSecurityTexts}
          mappedSecurityTexts={mappedSecurityTexts}
          setMappedSecurityTexts={setMappedSecurityTexts}
          setProcessing={setProcessing}
          isMaintenance={isMaintenance}
        />
      ),
    },
    {
      id: "specialconditions",
      title: "Særvilkår",
      component: (
        <SpecialTermsTab
          deliveries={deliveries}
          onChange={(values) => updateDeliveries(values)}
          t={t}
        />
      ),
    },
    {
      id: "stakeholders",
      title: "Interessenter",
      component: (
        <Stakeholders
          partialSave={partialSave}
          t={t}
          flow={flow}
          setEditing={setEditing}
          setCreditChecking={setCreditChecking}
          formData={formData}
          setFormData={setFormData}
          setInsightStakeholder={setInsightMappedStakeholder}
          isCreditChecking={creditChecking}
          agreementsDataState={agreementsDataState}
        />
      ),
    },
    ...(isMaintenance
      ? []
      : [
          {
            id: "riskAssessments",
            title: "Sikkerhetsvurderinger",
            component: (
              <SecurityRiskAssessment
                t={t}
                deliveries={flow.data.deliveries}
                securityRiskData={securityRiskData}
                setSecurityRiskData={setSecurityRiskData}
                isMaintenance={isMaintenance}
              />
            ),
          },
        ]),
    {
      id: "agreementRegisterSummary",
      title: "Oppsummering",
      component: (
        <InsightModule
          name={"@stacc/vue-corporateanalysis"}
          onAppChange={onAppChange}
          commonData={insightComponentData?.commonData}
          data={{
            agreementData: mapAgreementData(
              agreementsDataState,
              flow,
              formData?.guarantors,
              securityRiskData,
              true
            ),
          }}
          options={{
            context: "agreementregistersummary",
          }}
        />
      ),
    },
  ]

  // Add Vedtakstekster as a tab if it's a maintenance case.
  if (isMaintenance) {
    const deliveryId = "vedlikehold"
    const maintenanceDelivery = deliveries[deliveryId] // Should exist if maintenance
    options.splice(1, 0, {
      id: "decisionTexts",
      title: "Vedtakstekster",
      component: (
        <DecisionTexts
          t={t}
          delivery={maintenanceDelivery}
          deliveryId={deliveryId}
          context={task.context}
          onChange={(values) => updateDeliveries(values)}
          setProcessing={setProcessing}
          agreements={maintenanceAgreements}
          insightMappedStakeholder={insightMappedStakeholder}
        />
      ),
    })
  }

  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <Tabs
          defaultTab={"agreementRegister"}
          loading={isLoading}
          options={options}
          onChange={(e) => handleTabChange(e)}
        />
      </BMAnalyzeContent>

      <Context flow={flow} context={applicationSummary}>
        <ReactForm
          schema={schema}
          formData={formData}
          disabled={isProcessing}
          onChange={(values) => onFormChange(values)}
        >
          <></>
        </ReactForm>

        {validations &&
          validations.map((x, i) => (
            <ErrorTextStyle key={i}>
              <ErrorText error={x} t={t}></ErrorText>
            </ErrorTextStyle>
          ))}
        <ButtonContainer>
          <PrimaryButton
            type="submit"
            onClick={handleComplete}
            isLoading={isProcessing || editing || creditChecking}
            disabled={isErrored || isLoading || isProcessing || creditChecking}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButtonModified
            type="button"
            disabled={isProcessing || creditChecking}
            onClick={() => handleSave()}
          >
            {t("save")}
          </SecondaryButtonModified>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

const BMAnalyzeContent = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
`

const ErrorTextStyle = styled.div`
  margin-bottom: 1.5em;
`

export default withTranslation()(TermsAndSecurity)
