import styled from "styled-components"
import { Collapsible } from "@flow/components"
import { Colors } from "@flow/style"
import formatWithLocale from "../util/dateFormatter"
import { withTranslation } from "react-i18next"
import DownloadFileButton from "../components/common/DownloadFileButton"

const FileTab = ({ flow, t }) => {
  const files = flow.data.files ?? []

  const groupedFiles = files.reduce((acc, file) => {
    const documentType = file?.documentGroup
    ;(acc[documentType] = acc[documentType] || []).push(file)
    return acc
  }, {})

  return (
    <Container>
      {Object.entries(groupedFiles).map(([documentType, files]) => {
        return (
          <FileGroup key={documentType}>
            <FileHeader>
              <StyledCollapsible header={t(documentType + "Document")}>
                <File>
                  <b>{t("filename")}</b>
                  <b>{t("upload-date")}</b>
                </File>
                {files
                  .filter((x) => !!x)
                  .map((file, i) => {
                    const deliveryId = file?.metadata?.deliveryId
                    let productName = ""
                    if (deliveryId) {
                      const delivery = flow.data?.deliveries[deliveryId]
                      productName = t(delivery.productName) + ": "
                    }
                    return (
                      <File key={i}>
                        <FileName>
                          {productName}
                          {t(file?.filename) || t(file?.documentType)}
                        </FileName>
                        <div>{formatWithLocale(file?.uploadTime)}</div>
                        <DownloadFileWrapper>
                          <DownloadFileButton
                            fileId={file?.storeID}
                            title={t("download")}
                          />
                        </DownloadFileWrapper>
                      </File>
                    )
                  })}
              </StyledCollapsible>
            </FileHeader>
          </FileGroup>
        )
      })}
    </Container>
  )
}

const DownloadFileWrapper = styled.div`
  button {
    &:hover {
      background-color: ${Colors.Grey3};
    }
  }
`

const FileHeader = styled.div`
  padding: 15px 10px;
  div {
    font-weight: 500;
    font-size: 16px;
    color: #030303;
    text-transform: capitalize;
  }
  &:hover {
    background-color: ${Colors.Grey4};
  }
`

const FileName = styled.div`
  font-size: 16px;
`

const FileGroup = styled.div`
  border-bottom: 1px solid ${Colors.Grey3};
`

const StyledCollapsible = styled(Collapsible)`
  background-color: red;
`

const File = styled.div`
  margin: 10px 0;
  div {
    font-weight: 400 !important;
  }
  display: grid;
  grid-template-columns: 50% 25% 25%;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`

const Container = styled.div`
  border-top: 1px solid ${Colors.Grey3};
`

export default withTranslation()(FileTab)
