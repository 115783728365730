import { Input, Label } from "@staccx/bento"
import { Controller, useFormContext } from "react-hook-form"
import styled from "styled-components"
import ErrorText from "../../common/ErrorText"

const InputField = ({ name, id, t }) => {
  const { control, errors } = useFormContext()
  return (
    <Container>
      <Label>{t(name)}</Label>
      <Controller
        name={name}
        control={control}
        key={id}
        render={({ field: { name, onChange, onBlur, ...rest } }) => {
          return (
            <ErrorText error={errors[name]?.message}>
              <StyledInput
                name={name}
                id={name}
                type="text"
                onChange={onChange}
                value={rest.value}
                {...rest}
              />
            </ErrorText>
          )
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInput = styled(Input)`
  input {
    border: 1px solid #b8b8b8;
    border-radius: 0.4em;
    padding: 0.5em;
    font-size: 14px;
    line-height: 1.4em;
  }
  margin-right: 50px;
  margin-bottom: 10px;
`

export default InputField
