import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { Colors, FontStyles } from "@flow/style"
import { addThousandSeparator } from "./util/addThousandSeparator"
import formatWithLocale from "./util/dateFormatter"

const QueueItem = ({ path, flow, t }) => {
  const { flowDefinitionId, flowId, data, assignee } = flow
  const customerAccount = data.raw?.accountMaster?.customerAccount || {}

  const businessStatus = data?.businessStatus
  const applicationDate = formatWithLocale(
    data?.application?.submittedOn || new Date().toISOString(),
    "dd.MM.yyyy"
  )
  const applicantName = customerAccount?.name
  const applicationName =
    data?.raw?.applicationMaster?.currentApplication?.projectName

  const category = data?.application?.category
  const caseworker = data?.caseWorker?.name
  const hasSubcategories = data?.application?.subcategories !== undefined

  const isCounterGuarantee = category === "counterguarantee"
  if (isCounterGuarantee) {
    const {
      applyingBank,
      applyingCustomer,
      caseNumber,
      isPrequalification,
      projectName,
    } = data.application
    const activationFee = `${t("activationFee")} NOK ${
      addThousandSeparator(
        data?.mapped?.application?.startUpCostAndGuaranteeProvisionTotal
      ) || "-"
    }`
    return (
      <ItemNavLink
        activeClassName="active"
        to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      >
        <Left>
          <p>{caseNumber}</p>
          <Title>
            {!isPrequalification ? projectName : "Prekvalifisering"}
          </Title>
          <p>{applyingBank?.name || "-"}</p>
          <p>{applyingCustomer?.name || "-"}</p>
        </Left>
        <Right>
          <p>{applicationDate}</p>
          {!isPrequalification && <p>{activationFee}</p>}
        </Right>
      </ItemNavLink>
    )
  }

  const isMaintenance = category === "maintenance"
  if (isMaintenance) {
    return (
      <ItemNavLinkMaintenance
        activeClassName="active"
        to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      >
        <FlexRow>
          <p>{applicantName}</p>
          <p>{applicationDate}</p>
        </FlexRow>
        {hasSubcategories && (
          <FlexRow>
            <Title>{t(data.application.subcategories[0])}</Title>
          </FlexRow>
        )}
        <FlexRow>
          <p>{t(businessStatus)}</p>
          <p>{t(category)}</p>
        </FlexRow>
        <FlexRow>
          <p></p>
          <p>{caseworker}</p>
        </FlexRow>
      </ItemNavLinkMaintenance>
    )
  }

  const isDepotEndControl = flowDefinitionId === "depot-end-control"
  if (isDepotEndControl) {
    const caseNumber = data.input.caseNumber
    const followUpDate = data.input.followUpDate
    const formattedFollowUpDate = formatWithLocale(followUpDate, "dd.MM.yyyy")
    const responsibleCaseWorker = assignee?.name ?? "-"

    return (
      <ItemNavLink
        activeClassName="active"
        to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
      >
        <Left>
          <Title>{caseNumber}</Title>
        </Left>
        <Right>
          <p>{`${t("followUpDate")}: ${formattedFollowUpDate}`}</p>
          <p>{responsibleCaseWorker}</p>
        </Right>
      </ItemNavLink>
    )
  }

  return (
    <ItemNavLink
      activeClassName="active"
      to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}
    >
      <Left>
        <p>{applicantName}</p>
        <Title>{applicationName}</Title>
        <p>{t(businessStatus)}</p>
      </Left>
      <Right>
        <p>{applicationDate}</p>
        <p>{t(category)}</p>
        <p>{caseworker}</p>
      </Right>
    </ItemNavLink>
  )
}

QueueItem.propTypes = {
  path: PropTypes.string.isRequired,
  flow: PropTypes.shape({
    flowDefinitionId: PropTypes.string.isRequired,
    flowId: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    referenceId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }),
  t: PropTypes.func.isRequired,
}

QueueItem.defaultProps = {
  flow: {},
}

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ItemNavLink = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  background-color: rgb(229, 242, 255, 0.3);
  border-bottom: 1px solid ${Colors.Grey3};
  ${FontStyles.Small};
  padding: 5px 10px 10px 10px;

  &.active {
    background-color: ${Colors.SeaLighter};
    pointer-events: none;
  }
`

const ItemNavLinkMaintenance = styled(ItemNavLink)`
  flex-direction: column;
  padding: 15px 10px;
`

const Title = styled.p`
  ${FontStyles.LargeStrong};
`

const Left = styled.div`
  padding-top: 10px;
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  justify-content: space-between;
  text-align: right;
`

export default QueueItem
