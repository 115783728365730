import styled from "styled-components"
import Notice from "../common/Notice"
import { RadioButtons, Checkbox } from "@flow/forms"
import EditGuarantor from "./EditGuarantor"
import { Icons } from "../common/style/Icons"
import { useState } from "react"

const fieldSchemas = {
  person: [
    {
      key: "ssn",
      label: "ssn",
    },
    {
      key: "email",
      label: "email",
    },
    {
      key: "mobile",
      label: "phone",
    },
    {
      key: "amount",
      label: "denomination-in-nok",
      type: "currency",
    },
    {
      key: "",
      label: "credit-checked-when-added",
      type: "label",
    },
  ],
  organizationDomestic: [
    {
      key: "organizationNumber",
      label: "organizationNumber",
    },
    {
      key: "contactPersonName",
      label: "contactPerson",
    },
    {
      key: "contactPersonMobile",
      label: "phone",
    },
    {
      key: "contactPersonEmail",
      label: "email",
    },
    {
      key: "amount",
      label: "denomination-in-nok",
      type: "currency",
    },
    {
      key: "",
      label: "credit-checked-when-added",
      type: "label",
    },
  ],
  organizationInternational: [
    {
      key: "organizationCountry",
      label: "country",
    },
    {
      key: "name",
      label: "name of organization",
    },
    {
      key: "contactPersonName",
      label: "contactPerson",
    },
    {
      key: "contactPersonMobile",
      label: "phone",
    },
    {
      key: "contactPersonEmail",
      label: "email",
    },
    {
      key: "amount",
      label: "denomination-in-nok",
      type: "currency",
    },
    {
      key: "additionalInfo",
      label: "additional info",
      type: "textarea",
    },
    {
      key: "",
      label: "credit-checked-when-added",
      type: "label",
    },
  ],
}

const bailTypes = [
  {
    label: "Forbrukerkausjon",
    value: "person",
  },
  {
    label: "Næringskausjon",
    value: "organization",
  },
]

const GuarantorIcon = ({ type }) => {
  switch (type) {
    case "person":
      return <Icons.GuarantorPerson size={36} />
    case "organization":
      return <Icons.GuarantorOrganization size={36} />
    default:
      return <></>
  }
}

const NewGuarantor = ({ key, guarantor, t, onChange, onCancel, onAdd }) => {
  const [editGuarantor, setEditGuarantor] = useState(guarantor)

  const updateValue = (key, value) => {
    const updatedGuarantor = { ...editGuarantor }
    updatedGuarantor[key] = value

    if (key === "type" && !updatedGuarantor.scope) {
      updatedGuarantor.scope = "domestic"
    }
    setEditGuarantor(updatedGuarantor)
  }

  return (
    <Notice>
      <Content>
        <TopSection>
          <div className="icon">
            <GuarantorIcon type={editGuarantor.type} />
          </div>
          <div>
            <TypeSelection>
              <RadioButtons
                name={`bail-type-${key}`}
                label={t("bail-type")}
                horizontal
                checkedValue={editGuarantor.type}
                onChange={(e, value) => {
                  updateValue("type", value)
                }}
                options={bailTypes}
              />
              {editGuarantor.type === "organization" && (
                <CheckboxPadding>
                  <Checkbox
                    name={`bail-abroad-${key}`}
                    label={t("bail-abroad")}
                    value={editGuarantor.scope === "international"}
                    onChange={() => {
                      updateValue(
                        "scope",
                        editGuarantor.scope === "international"
                          ? "domestic"
                          : "international"
                      )
                    }}
                  />
                </CheckboxPadding>
              )}
            </TypeSelection>
            <FormContainer>
              <EditGuarantor
                schema={fieldSchemas}
                guarantor={editGuarantor}
                t={t}
                onChange={(editGuarantor) =>
                  setEditGuarantor({ ...editGuarantor })
                }
                onCancel={(editGuarantor) => onCancel()}
                onSave={(editGuarantor) => onAdd(editGuarantor)}
              />
            </FormContainer>
          </div>
        </TopSection>
      </Content>
    </Notice>
  )
}

const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;

  .icon {
    margin-top: 10px;
  }
`

const FormContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
`

const TypeSelection = styled.div``

const Content = styled.div`
  padding: 10px;
`
const CheckboxPadding = styled.div`
  margin-top: 5px;
  margin-left: 154px;
`

export default NewGuarantor
