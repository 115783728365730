import React from "react"
import styled from "styled-components"
import { Colors } from "@flow/style"
import { ProjectColors } from "../../common/style/ProjectColors"
import TextButton from "../../common/TextButton"
import { Icons } from "@flow/icons"

const SidebarItem = ({
  onClick,
  active,
  index,
  total,
  currency,
  t,
  name,
  canDelete,
  onDelete,
}) => {
  return (
    <StyledLi>
      <StyledButton active={active} onClick={() => onClick(index)}>
        <StyledColumn>
          <p>{index !== "overview" ? name : t(index)}</p>
          <p>
            {total} {currency}
          </p>
        </StyledColumn>
        {canDelete && (
          <StyledColumn>
            <TextButton onClick={onDelete} padding={false}>
              <Icons.Trashcan />
            </TextButton>
          </StyledColumn>
        )}
      </StyledButton>
    </StyledLi>
  )
}
export default SidebarItem

const StyledLi = styled.li`
  display: flex;
  flex-direction: row;
`
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const StyledButton = styled.button`
  background-color: ${(props) =>
    props.active ? `${Colors.Snow}` : `${ProjectColors.Slate90}`};
  border: ${(props) => (props.active ? `solid 1px ${Colors.Sea}` : `none`)};
  border-radius: 5px;
  margin: 5px 5px 0px 5px;
  padding: 10px 0px 10px 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  width: 100%;
  cursor: pointer;

  p {
    color: ${(props) => (props.active ? `${Colors.Sea}` : `inherit`)};
    font-weight: bold;
    font-size: smaller;
    text-align: left;

    :last-child {
      color: ${(props) =>
        props.active ? `${Colors.Coal}` : `${Colors.Grey1}`};
      font-weight: normal;
      font-size: x-small;
      padding-top: 4px;
    }
  }
`
