import React, { useState } from "react"
import styled from "styled-components"
import { TransparentButton } from "@flow/buttons"
import { Icons } from "@flow/icons"
import { Colors, FontStyles } from "@flow/style"
import EditAccount from "./EditAccount"

const RegularBox = ({ title, data, setMode, readonly }) => {
  return (
    <div>
      <Line>
        <p>{title}</p>
      </Line>
      <FlexBox>
        <CustomStyledBox>
          <CustomLine>
            <p>{data}</p>
          </CustomLine>
        </CustomStyledBox>
        {!readonly && (
          <ButtonContainer>
            <TransparentButton type="button" onClick={() => setMode("edit")}>
              <Icons.Edit size="18" />
            </TransparentButton>
          </ButtonContainer>
        )}
      </FlexBox>
    </div>
  )
}

const AccountForPayout = ({
  flowId,
  readonly,
  data,
  partialSave,
  stateData,
  setStateData,
  setErrorMessage,
  deliveryId,
  t,
  payment,
  index,
  mode,
  setMode,
}) => {
  const [editData, setEditData] = useState(data)

  const [loading, setLoading] = useState(false)
  return (
    <div>
      {mode === "none" && (
        <RegularBox
          readonly={readonly || loading}
          title={t("account-for-payout")}
          data={editData}
          setMode={setMode}
        />
      )}
      {mode === "edit" && !loading && !readonly && (
        <EditAccount
          title={t("account-for-payout")}
          partialSave={partialSave}
          data={editData}
          setMode={setMode}
          setEditData={setEditData}
          flowId={flowId}
          stateData={stateData}
          setStateData={setStateData}
          setLoading={setLoading}
          setErrorMessage={setErrorMessage}
          deliveryId={deliveryId}
          t={t}
          index={index}
          payment={payment}
        />
      )}
    </div>
  )
}

export default AccountForPayout

const ButtonContainer = styled.div`
  display: flex;
`

const CustomStyledBox = styled.div`
  display: flex;
  background-color: white;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 15px;
  height: 2rem;
  width: 25rem;
  :first-child {
    margin: 0;
  }
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;

  p {
    ${FontStyles.Small}
    padding-right: 5px;
  }

  &:nth-child(2) {
    p {
      ${FontStyles.Normal}
      color: black;
    }
  }
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 0.2rem;
`
const CustomLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-left: 0.5rem;

  p {
    ${FontStyles.Small}
    padding-right: 5px;
  }

  &:nth-child(2) {
    p {
      ${FontStyles.Normal}
      color: black;
    }
  }
`
