import { Controller, useFormContext } from "react-hook-form"
import ErrorText from "../../common/ErrorText"
import { Text, TextArea } from "@flow/forms"
import React, { useState, useEffect } from "react"
import styled from "styled-components"

export const InputComponent = ({ fieldName, t, id, disabled }) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      name={fieldName}
      control={control}
      key={`${id}${fieldName}`}
      render={({ field: { name, key, onChange, value, onBlur, ...rest } }) => (
        <InputField>
          <ErrorText error={errors[name]?.message}>
            <Text
              disabled={disabled}
              label={t(name)}
              name={name}
              key={key}
              id={key}
              onChange={onChange}
              defaultValue={value}
            />
          </ErrorText>
        </InputField>
      )}
    />
  )
}

export const TextAreaComponent = ({ fieldName, t, id, fullLine }) => {
  const { control, errors } = useFormContext()
  return (
    <Controller
      name={fieldName}
      control={control}
      key={`${id}${fieldName}`}
      render={({ field: { name, key, onChange, value, onBlur, ...rest } }) => (
        <TextAreaWrapper fullLine={fullLine}>
          <InputField>
            <ErrorText error={errors[name]?.message}>
              <TextArea
                label={t(name)}
                name={name}
                key={key}
                id={key}
                onChange={onChange}
                value={value}
              />
            </ErrorText>
          </InputField>
        </TextAreaWrapper>
      )}
    />
  )
}

export const RadioComponent = ({ fieldName, t, id, fullLine, watch }) => {
  const { control, getValues, errors } = useFormContext()
  const [current, currentSet] = useState(getValues(fieldName))

  const fieldValues = watch(fieldName)

  useEffect(() => {
    currentSet(fieldValues)
  }, [fieldValues])

  const handleChange = (value, onChange) => {
    currentSet(value.target.value)
    onChange(value)
  }
  return (
    <Controller
      name={fieldName}
      control={control}
      key={`${id}${fieldName}`}
      render={({ field: { name, onChange, value, key, onBlur, ...rest } }) => (
        <InputField fullLine={fullLine}>
          <ErrorText error={errors[name]?.message}>
            <label>Velg type interessent</label>
            <RadioGroup
              onChange={(e) => handleChange(e, onChange)}
              name={name}
              value={value}
            >
              <StyledRadio key={`person${current}`}>
                <input
                  type={"radio"}
                  value={"person"}
                  checked={current === "person"}
                  onChange={(e) => handleChange(e, onChange)}
                />
                <p> {t("person")}</p>
              </StyledRadio>
              <StyledRadio key={`organization${current}`}>
                <input
                  type={"radio"}
                  value={"organization"}
                  checked={current === "organization"}
                  onChange={(e) => handleChange(e, onChange)}
                />
                <p>{t("organization")}</p>
              </StyledRadio>
            </RadioGroup>
          </ErrorText>
        </InputField>
      )}
    />
  )
}

export const CheckboxComponent = ({ fieldName, t, id, fullLine, disabled }) => {
  const { control, errors } = useFormContext()

  return (
    <Controller
      name={fieldName}
      control={control}
      key={`${id}${fieldName}`}
      render={({ field: { name, key, onChange, value, onBlur, ...rest } }) => {
        let isChecked = value
        if (fieldName === "scope") isChecked = value === "international"
        const toggleValue = (val) =>
          val === "international" ? "domestic" : "international"

        return (
          <CheckboxWrapper fullLine={fullLine}>
            <ErrorText error={errors[name]?.message}>
              <StyledCheckBox>
                <input
                  name={name}
                  id={key}
                  onChange={(val) =>
                    fieldName === "scope"
                      ? onChange(toggleValue(value))
                      : onChange(val)
                  }
                  key={Math.random()}
                  type={"checkbox"}
                  checked={isChecked}
                  disabled={disabled}
                />
                <label>{t(name)}</label>
              </StyledCheckBox>
            </ErrorText>
          </CheckboxWrapper>
        )
      }}
    />
  )
}

const StyledCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  label {
    padding-left: 0.5em;
  }
`

const CheckboxWrapper = styled.div`
  display: grid;
  grid-column: ${(props) => props.fullLine && "1 / span 2"};
  padding: ${(props) => props.fullLine && "0 0 1em 0"};
`

const TextAreaWrapper = styled.div`
  display: grid;
  grid-column: ${(props) => props.fullLine && "1 / span 2"};
  padding: ${(props) => props.fullLine && "0 0 1em 0"};
  TextArea {
    width: 100%;
  }
`

const InputField = styled.div`
  grid-column: ${(props) => props.fullLine && "1 / span 2"};
  padding: ${(props) => props.fullLine && "0 0 1em 0"};
  margin-right: 50px;
  margin-bottom: 10px;
`

const StyledRadio = styled.label`
  display: flex;
  flex-direction: row;

  p {
    padding-left: 5px;
  }

  &:last-child {
    padding-left: 10px;
  }
`

const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;
`
