import styled from "styled-components"
import React from "react"
import { Colors } from "@flow/style"
import formatWithLocale from "../../util/dateFormatter"
import CommentColumnComponent from "./CaseComment"

const sortComments = (comments) => {
  return comments.sort((a, b) => (a.date > b.date ? 1 : -1))
}

const manualTranslationOfCreateCreditMemoDecision = (decision) => {
  switch (decision) {
    case "OK":
      return "Innstill på godkjenning"
    case "REJECT":
      return "Innstill på avslag"
    case "BACK_TO_SUPPORT":
      return "Tilbake til støttegrunnlag"
    default:
      break
  }
}

const manualTranslationOfControlApplication = (decision) => {
  switch (decision) {
    case "finishedAssessments":
      return "Juridisk vedtakskontroll er utført"
    case "needClarifications":
      return "Send tilbake til saksbehandler"
    default:
      break
  }
}

const manualTranslationOfPerformFinancialCheck = (decision) => {
  switch (decision) {
    case "OK":
      return "Godkjenn utbetaling"
    case "REJECT":
      return "Avvis utbetaling"
    default:
      break
  }
}

const translateTrueFalse = (data) => {
  return data ? "Ja" : "Nei"
}

const renderManualTranslationsBasedOnTask = (task, decision) => {
  switch (task) {
    case "Utfør økonomisk kontroll":
      return manualTranslationOfPerformFinancialCheck(decision)
    case "Lag innstilling":
      return manualTranslationOfCreateCreditMemoDecision(decision)
    case "Utfør juridisk vedtakskontroll":
      return manualTranslationOfControlApplication(decision)
    default:
      break
  }
}

const AssessmentComments = ({ t, comments = [] }, assignee) => {
  return (
    <ContentWrapper>
      {sortComments(
        comments.map((caseInfo, index) => {
          return (
            <CommentWrapper key={index}>
              <CommentColumn>
                {caseInfo.user ? (
                  <p>
                    {t("user")}: {caseInfo.user.name}
                  </p>
                ) : (
                  <p>{t("user")}</p>
                )}
                {/* {caseInfo.completedBy?.roles && (<p>Roller: {caseInfo.completedBy.roles.join(", ")}</p>)} */}
                <p>
                  {t("task")}: {caseInfo?.task}
                </p>
                {caseInfo?.level ? (
                  <p>
                    {t("level")}: {t(caseInfo?.level)}
                  </p>
                ) : (
                  ""
                )}
                {caseInfo.isCaseworker ? (
                  <>
                    <p>
                      {t("caseworker-decision")}:{" "}
                      {renderManualTranslationsBasedOnTask(
                        caseInfo.task,
                        caseInfo.decision
                      ) || t(caseInfo.decision)}
                    </p>
                    {caseInfo.needsJuridicalInspection != null && (
                      <p>
                        {t("needsJuridicalInspectionTab")}
                        {translateTrueFalse(caseInfo.needsJuridicalInspection)}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p>
                      {t("outcome")}:{" "}
                      {renderManualTranslationsBasedOnTask(
                        caseInfo.task,
                        caseInfo.decision
                      ) || t(caseInfo.decision)}
                    </p>
                  </>
                )}
              </CommentColumn>
              <CommentColumn>
                <p>{formatWithLocale(caseInfo?.date)}</p>
              </CommentColumn>

              {caseInfo?.comment || caseInfo?.needsJuridicalInspection ? (
                <>
                  <CaseComment>
                    {caseInfo?.comment && (
                      <CommentColumnComponent
                        title={t("case-comment")}
                        data={caseInfo.comment}
                      />
                    )}
                    {caseInfo.legalComment && (
                      <CommentColumnComponent
                        title={t("case-comment-legal")}
                        data={caseInfo.legalComment}
                      />
                    )}
                  </CaseComment>
                </>
              ) : (
                <div />
              )}
            </CommentWrapper>
          )
        })
      )}
      {comments?.length === 0 && (
        <NoCommentContainer>{t("no-decisions")}</NoCommentContainer>
      )}
    </ContentWrapper>
  )
}

const NoCommentContainer = styled.div`
  margin: 10px;
`

const CaseComment = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 2;
  grid-row-end: 2;
  margin-top: 1em;
`

const ContentWrapper = styled.div`
  color: ${Colors.Grey1};
  margin: 1em;
`

const CommentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: [row1-start] 80px 1fr;
  background-color: ${Colors.SeaLighter};
  border: 1px solid ${Colors.Grey4};
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 10px;
  align-items: start;

  div:nth-child(2) {
    text-align: start;
  }
`
const CommentColumn = styled.div`
  display: flex;
  flex-direction: column;

  &:first-child {
    p {
      &:first-child {
        font-weight: bold;
      }
    }
  }

  &:nth-child(2) {
    p {
      text-align: end;
      color: ${Colors.Sea};
    }
  }
`
export default AssessmentComments
