import styled from "styled-components"
import React, { useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { withTranslation } from "react-i18next"

import { TextArea } from "@flow/forms"
import IddTabs from "../components/Idd/IddTabs"
import IddRadioButtons from "../components/Idd/IddRadioButtons"
import { Colors } from "@flow/style"
import Layout, { Context } from "../components/common/Layout"

const isValidCheck = (taskData, isTeam) => {
  if (taskData.sendToIddTeam)
    return taskData.caseComment && !isTeam && taskData.assessment
  if (isTeam) return taskData.caseComment
  if (taskData.completedByCaseworker) return taskData.assessment

  return false
}

const IddCheckCaseworker = ({
  isTeam,
  flow,
  task,
  user,
  t,
  save,
  complete,
}) => {
  const defaults = task.data || {}
  if (!defaults.assessment) {
    defaults.assessment = flow.data.integrityDueDilligence?.decision?.assessment
  }

  const [taskData, setTaskData] = useState(defaults)
  const [isLoading, setLoading] = useState(false)
  const [isValid, setValid] = useState(true)

  const loadingFalse = () => setLoading(false)
  const onComplete = (e) => {
    const { assessment, sendToIddTeam, completedByCaseworker, caseComment } =
      taskData
    e.preventDefault()

    const checkValid = isValidCheck(taskData, isTeam)
    if (!checkValid) {
      setValid(false)
      return
    }

    let data = { assessment, sendToIddTeam, completedByCaseworker }
    if (sendToIddTeam) {
      data = { ...data, caseComment }
    }
    if (isTeam) {
      data = { caseComment }
    }

    setLoading(true)
    complete(data, loadingFalse, loadingFalse)
  }

  const onSave = (e) => {
    e.preventDefault()
    setLoading(true)
    save(taskData, loadingFalse, loadingFalse)
  }

  const updateText = (text) => {
    const clone = { ...taskData }
    clone.caseComment = text
    setTaskData(clone)
  }

  const checkValid = isValidCheck(taskData, isTeam)
  if (checkValid && !isValid) {
    setValid(true)
    return
  }

  let iddError = ""
  if (taskData.sendToIddTeam && !taskData.caseComment) {
    iddError = t("send-to-idd-team-validation")
  } else if (isTeam) {
    iddError = t("send-to-caseworker-validation")
  }

  return (
    <Layout forceHeight>
      <MainContent>
        <IddTabs
          taskData={taskData}
          isValid={isValid}
          isTeam={isTeam}
          setTaskData={setTaskData}
          flow={flow}
          t={t}
        />
      </MainContent>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <IddRadioButtons
          isTeam={isTeam}
          taskData={taskData}
          setTaskData={setTaskData}
          t={t}
          isValid={isValid}
        />
        {(taskData.sendToIddTeam || isTeam) && (
          <>
            <DecisionText>
              {isTeam ? t("comment-to-caseworker") : t("comment-to-idd")}
            </DecisionText>
            <StyledTextArea
              name={"case-comment"}
              defaultValue={taskData.caseComment}
              required
              placeholder={t("write-placeholder")}
              onChange={(e, text) => updateText(text)}
            />
            <ValidationError>{!isValid && iddError}</ValidationError>
          </>
        )}
        <BottomBar>
          <PrimaryButton
            disabled={isLoading || !isValid}
            onClick={(e) => onComplete(e)}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButton disabled={isLoading} onClick={(e) => onSave(e)}>
            {t("save")}
          </SecondaryButton>
        </BottomBar>
      </Context>
    </Layout>
  )
}

export default withTranslation()(IddCheckCaseworker)

const ValidationError = styled.span`
  color: ${Colors.Ferrari};
`

const StyledTextArea = styled(TextArea)`
  width: calc(100% - 10px);
  height: 100px;
  resize: none;
`

const BottomBar = styled.div`
  height: 40px;
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  gap: 15px;
`

const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  overflow-y: auto;
`

const DecisionText = styled.div`
  text-transform: capitalize;
  font-style: normal;
  font-weight: normal;
  font-family: "IBM Plex Sans";
  color: #6c6c6c;
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 500;
  color: #2b2b2b;
  padding-bottom: 0.4em;
  margin-top: 1.2em;
`
