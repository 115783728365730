import React from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { Colors, FontStyles } from "@flow/style"
import Notice from "../common/Notice"
import GridContent from "../performFinancialCheck/detailsComponents/GridContent"
import { MiniTable } from "../controlPriority/TableForCheckList"

const SpecialTermsMaintenance = ({
  data,
  padding = "1.5em",
  margin = "1em",
  gridColumnGap = "20em",
  min = "12em",
  max = "1fr",
  backgroundColor,
  t,
  currentUser,
  readOnly = false,
}) => {
  return (
    <>
      {data.length > 0 ? (
        data.map((x, i) => {
          return (
            <>
              <Content
                padding={padding}
                margin={margin}
                gridColumnGap={gridColumnGap}
                min={min}
                max={max}
                backgroundColor={backgroundColor}
              >
                {Object.keys(x).map((key, i) => {
                  const value = x[key].toString()
                  return (
                    <>
                      <GridContent
                        key={i}
                        title={t(key)}
                        data={t(value)}
                        contentSize={FontStyles.Normal}
                      />
                    </>
                  )
                })}
                {!readOnly ? (
                  <StyledMiniTable>
                    <MiniTable
                      t={t}
                      isMaintenance={true}
                      currentUser={currentUser}
                      agreementId={i}
                    />
                  </StyledMiniTable>
                ) : null}
              </Content>
            </>
          )
        })
      ) : (
        <p>{t("no-special-terms")}</p>
      )}
    </>
  )
}

export default withTranslation("maintenance")(SpecialTermsMaintenance)
const Content = styled(Notice)`
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};

  display: grid;
  grid-template-columns: ${({ min, max }) =>
    `repeat(auto-fill, minmax(${min}, ${max}))`};
  grid-gap: 1em;
  grid-column-gap: ${({ gridColumnGap }) => gridColumnGap || "1em"};

  background-color: ${(props) => props.backgroundColor || Colors.SeaLighter};
`
const StyledMiniTable = styled.div`
  display: flex;
  justify-content: "flex-start";
`
