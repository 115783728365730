import { trimOperationalRisk } from "./trimOperationalRisk"

// This function calculates the subsidy effect based on the passed in loan.
/**
 * Calculates the subsidyeffect for the loan.
 *
 * @param {object} loan - The loan.
 * @param {object} loan.subsidyEffectParams - The subsidy effect params.
 * @param {number} loan.loanAmount - The loan amount.
 *
 *
 * @returns {object} - The calculated `subsidyEffect`.
 * @property {number} subsidyEffect - The calculated subsidy effect.
 * @property {number} netPresentValue - The NPV of the loan.
 * @property {string} deliveryId - The id of the product / loan / delivery.
 */

function calculateSubsidyEffect(loan) {
  try {
    let loanAmount = 0,
      subsidyEffectParams = {}
    if (loan) {
      loanAmount = loan.loanAmount
      subsidyEffectParams = loan.subsidyEffectParams
    }
    const {
      terminerPerAar,
      terminGebyr,
      antallAar,
      utbetalingsKurs,
      referanseRente,
      antallAvdragsFrieAar,
      antallRenteFrieAar = 0,
      nominellRente,
      sikkerhetsRisiko,
      driftsRisiko,
      laaneType,
      frekvens,
      deliveryId,
    } = subsidyEffectParams

    const { tableDiff } = createSubsidyEffectTable(loanAmount, {
      terminerPerAar,
      terminGebyr,
      antallAar,
      antallAvdragsFrieAar,
      antallRenteFrieAar,
      nominellRente,
      sikkerhetsRisiko,
      driftsRisiko,
      referanseRente,
      risikopaaslag: 0, // 0.01 - 0.1
      utbetalingsKurs,
      laaneType,
      frekvens,
    })

    const cashFlows =
      tableDiff?.map((loanTerm) => loanTerm.differanseKontantStrøm) ?? []

    const referanseRentePlusOnePercent = (referanseRente * 100 + 1) / 100
    const terminRenteSubsidieEffekt =
      Math.pow(1 + referanseRentePlusOnePercent, 1 / terminerPerAar) - 1

    const netPresentValue = calculateNPV(terminRenteSubsidieEffekt, cashFlows)

    const differanseKontantStrømUtbetaling =
      -loanAmount + loanAmount * utbetalingsKurs

    const subsidyEffectAmount =
      netPresentValue + differanseKontantStrømUtbetaling

    return {
      netPresentValue: netPresentValue,
      subsidyEffect: subsidyEffectAmount,
      deliveryId,
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

export default function calculateMultipleSubsidyEffects(loans) {
  const result = loans?.map((loan) => calculateSubsidyEffect(loan)) || []
  return result
}

function createSubsidyEffectTable(loanAmount, subsidieEffectParams) {
  const {
    terminerPerAar,
    terminGebyr,
    antallAar,
    antallAvdragsFrieAar,
    antallRenteFrieAar,
    nominellRente,
    referanseRente,
    risikopaaslag,
    utbetalingsKurs,
    laaneType,
    frekvens,
    sikkerhetsRisiko,
    driftsRisiko,
  } = subsidieEffectParams

  const frekvensInMonths = {
    AARLIG: 12,
    HALVAARLIG: 6,
    KVARTALVIS: 3,
    MAANEDLIG: 1,
  }
  const antallTerminer = terminerPerAar * (antallAar + antallAvdragsFrieAar)
  const frekvensInNumber = frekvensInMonths[frekvens]
  const risikoPaaslagCalculated = calculateRisikoPaaslag(
    sikkerhetsRisiko,
    driftsRisiko,
    risikopaaslag
  )

  const referanseRenteMedRisikoTillegg =
    referanseRente + risikoPaaslagCalculated

  // Limit to 15 decimals, to avoid floating point errors compared to Excel sheet
  const terminRenteReferanseLaan = (
    Math.pow(1 + referanseRenteMedRisikoTillegg, 1 / terminerPerAar) - 1
  ).toFixed(16)

  let avdragBeregnet = 0
  if (laaneType === "SERIELAAN") {
    avdragBeregnet = calculateAvdragSerielaan(
      loanAmount,
      antallTerminer,
      antallAvdragsFrieAar,
      terminerPerAar
    )
  } else {
    avdragBeregnet = calculateAnnuity(
      loanAmount,
      nominellRente,
      terminerPerAar,
      antallAar
    )
  }

  const tableLoan = []
  let currentSaldo = loanAmount

  const firstUtbetaltSum = loanAmount * utbetalingsKurs
  const saldoUtbetaling = {
    termin: 0,
    saldo: currentSaldo,
    utbetalt: -firstUtbetaltSum,
    rente: 0,
    avdrag: 0,
    kontantstrøm: -firstUtbetaltSum,
    terminBeløp: 0,
    gebyr: 0,
    terminRente: 0,
  }
  tableLoan.push(saldoUtbetaling)

  for (let i = 0; i < antallTerminer; i++) {
    const isAvdragsfriPeriode = i < terminerPerAar * antallAvdragsFrieAar
    const isRenteFriPeriode = i < terminerPerAar * antallRenteFrieAar
    const avdragTermin = isAvdragsfriPeriode ? 0 : avdragBeregnet
    let terminGebyrAmount = terminGebyr
    let renteSumLoan = (currentSaldo * nominellRente) / terminerPerAar
    if (!isAvdragsfriPeriode) {
      currentSaldo = currentSaldo - avdragBeregnet
    }
    if (isRenteFriPeriode) {
      renteSumLoan = 0
      terminGebyrAmount = 0
    }
    tableLoan.push({
      termin: i + 1,
      rente: renteSumLoan,
      avdrag: avdragTermin,
      saldo: currentSaldo,
      kontantstrøm: avdragTermin + renteSumLoan + terminGebyrAmount,
      terminBeløp: avdragTermin + renteSumLoan + terminGebyrAmount,
      gebyr: terminGebyrAmount,
      utbetalt: 0,
      terminRente: terminRenteReferanseLaan,
    })
  }

  let currentSaldoReferanseLaan = loanAmount
  const tableReferenceLoan = []
  saldoUtbetaling.utbetalt = -loanAmount
  saldoUtbetaling.kontantstrøm = -loanAmount
  tableReferenceLoan.push(saldoUtbetaling)

  for (let i = 0; i < antallTerminer; i++) {
    const isAvdragsfriPeriode = i < terminerPerAar * antallAvdragsFrieAar
    const avdragTermin = isAvdragsfriPeriode ? 0 : avdragBeregnet
    const renterReferanseLoan =
      terminRenteReferanseLaan * currentSaldoReferanseLaan

    if (isAvdragsfriPeriode) {
      // Hvis det er avdragsfri periode, så skal det ikke betales avdrag
    } else {
      currentSaldoReferanseLaan = currentSaldoReferanseLaan - avdragBeregnet
    }

    tableReferenceLoan.push({
      saldo: currentSaldoReferanseLaan,
      termin: i + 1,
      rente: renterReferanseLoan,
      avdrag: avdragTermin,
      kontantstrøm: avdragTermin + renterReferanseLoan,
      terminBeløp: 0,
      gebyr: 0,
      utbetalt: 0,
      terminRente: terminRenteReferanseLaan,
    })
  }

  const tableDiff = []
  for (let i = 1; i <= antallTerminer; i++) {
    const differanseKontantStrøm =
      tableReferenceLoan[i].kontantstrøm - tableLoan[i].kontantstrøm
    tableDiff.push({
      saldo: tableLoan[i].saldo,
      termin: i,
      rente: tableLoan[i].rente,
      avdrag: tableLoan[i].avdrag,
      kontantstrøm:
        tableReferenceLoan[i].kontantstrøm - tableLoan[i].kontantstrøm,
      terminBeløp: tableLoan[i].terminBeløp,
      gebyr: 0,
      utbetalt: 0,
      terminRente: terminRenteReferanseLaan,
      differanseKontantStrøm: differanseKontantStrøm,
      referanseKontantStrøm: tableReferenceLoan[i].kontantstrøm,
      låneKontantStrøm: tableLoan[i].kontantstrøm,
      låneRenter: tableLoan[i].rente,
      referanseRenter: tableReferenceLoan[i].rente,
    })
  }
  return {
    tableLoan: tableLoan,
    tableReferenceLoan: tableReferenceLoan,
    tableDiff,
    terminRenteReferanseLaan: +terminRenteReferanseLaan,
  }
}

/**
 * This function calculates the net present value of the cashflows.
 * then returns the calculated result.
 * @param {number | float} rate - The rate.
 * @param {array} cashFlows - The cashflows.
 * @returns {number} - The calculated NPV.
 */

function calculateNPV(rate = -1, cashFlows) {
  let npv = 0
  // Start on term 1
  for (let i = 0; i < cashFlows.length; i++) {
    const cashFlow = cashFlows[i]
    npv += cashFlow / Math.pow(1 + rate, i + 1)
  }
  return npv
}

// Private function
function calculateAnnuity(
  loanAmount,
  rate,
  numberOfPaymentsPerYear,
  loanTermYears
) {
  const numberOfPayments = numberOfPaymentsPerYear * loanTermYears
  const monthlyRate = rate / (12 / numberOfPaymentsPerYear)
  const annuity =
    (loanAmount * (monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments))) /
    (Math.pow(1 + monthlyRate, numberOfPayments) - 1)
  return annuity
}

// Private function
function calculateAvdragSerielaan(
  loanAmount,
  antallTerminer,
  antallAvdragsFrieAar,
  terminerPerAar
) {
  return loanAmount / (antallTerminer - terminerPerAar * antallAvdragsFrieAar)
}

function calculateRisikoPaaslag(
  sikkerhetsRisiko = 1,
  driftsRisiko = "A", // "A" | "B" | "C" | "D"
  risikopaaslag = 0
) {
  const driftsRisikoArray = ["A", "B", "C", "D"]
  const tapsProsent = [
    [0.75, 1.0, 2.2, 4.0],
    [0.75, 1.0, 2.2, 4.0],
    [0.75, 1.0, 2.2, 4.0],
    [1.0, 2.2, 4.0, 6.5],
    [2.2, 4.0, 6.5, 10.0],
    [2.2, 4.0, 6.5, 10.0],
    [2.2, 4.0, 6.5, 10.0],
  ]
  // Get index of driftsRisiko
  if (risikopaaslag > 0) {
    return risikopaaslag
  }
  const indexOfDriftsRisiko = driftsRisikoArray.indexOf(driftsRisiko)
  return tapsProsent[sikkerhetsRisiko - 1][indexOfDriftsRisiko] / 100
}

export const convertFrequencyToNumber = (frequency) => {
  switch (frequency) {
    case "AARLIG":
      return 1
    case "HALVAARLIG":
      return 2
    case "KVARTALVIS":
      return 4
    case "TRE_GANGER_I_AARET":
      return 3
    case "BIMAANEDLIG":
      return 6
    case "MAANEDLIG":
      return 12
    default:
      return 1
  }
}

export const convertFrequencyFromAgreementNameToNorwegian = (frequency) => {
  switch (frequency) {
    case "YEARLY":
      return "AARLIG"
    case "BIANNUALLY":
      return "HALVAARLIG"
    case "QUARTERLY":
      return "KVARTALVIS"
    case "TRIANNUALLY":
      return "TRE_GANGER_I_AARET"
    case "BIMONTHLY":
      return "BIMAANEDLIG"
    case "MONTHLY":
      return "MAANEDLIG"
    default:
      return "KVARTALVIS"
  }
}

// This function parses the subsidy effect data from api-gateway
// to the format needed for mappedStateAid in caseMemo
/**
 * Calculates the sum of two numbers.
 *
 * @param {object} caseMemo - The caseMemo object which contains mappedStateAid.
 * @param {[
 *  {
 *   deliveryId: string,
 *   subsidyEffect: number,
 *   netPresentValue: number
 *  }
 * ]} deliverySubsidyEffects - The calculated subsidy effects containing deliveryId, subsidyEffect and netPresentValue.
 * @returns {object} - The new `mappedStateAid` with newly calculated `subsidyEffects`.
 * @property {array} activities - The updated activities with new calculated subsidy effect.
 * @property {array} products - The updated products with new calculated subsidy effect.
 * @property {object} ...rest - The old values that are not overwritten.
 */
export const parseSubsidyEffectForDelivery = (
  caseMemo,
  deliverySubsidyEffects // updated mappedStateAid
) => {
  const products = caseMemo?.mappedStateAid?.products

  // Map subsidy effect to correct product in activities
  const updatedActivitiesProducts = caseMemo?.mappedStateAid?.activities?.map(
    (activity) => {
      // Check if current activity has products, and that product matches delivery
      const productsInActivity = activity?.products?.map((product) => {
        const newCalculation = deliverySubsidyEffects?.find(
          (delivery) => delivery?.deliveryId === product?.product
        )
        return {
          ...product,
          subsidyEffect:
            newCalculation?.subsidyEffect > 0
              ? newCalculation?.subsidyEffect
              : 0,
        }
      })
      return {
        ...activity,
        products: productsInActivity,
      }
    }
  )
  const newProducts = products?.map((product) => {
    const newCalculation = deliverySubsidyEffects?.find(
      (delivery) => delivery?.deliveryId === product?.productId
    )
    if (!newCalculation) return product

    const { calculatedFields } = product
    const { subsidyEffect, fundingIntensity, supportBasis } = calculatedFields

    let newCalculatedSubsidyEffect = 0

    if (
      typeof newCalculation?.subsidyEffect === "number" &&
      newCalculation?.subsidyEffect > 0
    ) {
      newCalculatedSubsidyEffect = newCalculation?.subsidyEffect
    } else if (newCalculation?.subsidyEffect <= 0) {
      newCalculatedSubsidyEffect = 0
    } else if (subsidyEffect) {
      newCalculatedSubsidyEffect = subsidyEffect
    }

    const newCalculatedFields = {
      fundingIntensity:
        newCalculation?.subsidyEffect?.fundingIntensity ?? fundingIntensity,
      subsidyEffect: newCalculatedSubsidyEffect,
      supportBasis: newCalculation?.subsidyEffect?.supportBasis ?? supportBasis,
    }

    const productRows = product?.rows?.map((row) => {
      return {
        ...row,
        subsidyEffect: newCalculatedSubsidyEffect,
      }
    })

    return {
      ...product,
      rows: productRows,
      calculatedFields: {
        ...newCalculatedFields,
      },
    }
  })
  const newProductsSubsidyCalculated = newProducts?.map((stateAidProduct) => ({
    ...stateAidProduct,
    calculatedFields: {
      fundingIntensity: stateAidProduct.rows.reduce(
        (acc, { fundingIntensity }) => acc + +fundingIntensity,
        0
      ),
      subsidyEffect: stateAidProduct.rows.reduce(
        (acc, { subsidyEffect }) => acc + +subsidyEffect,
        0
      ),
      supportBasis: stateAidProduct.rows.reduce(
        (acc, { supportBasis }) => acc + +supportBasis,
        0
      ),
    },
  }))
  return {
    ...caseMemo?.mappedStateAid,
    activities: updatedActivitiesProducts,
    products: newProductsSubsidyCalculated,
  }
}

export const getSubsidyEffectParamsForDelivery = (
  delivery,
  caseOverview,
  subsidyEffectData = {},
  securityRiskData,
  selectedOperationalRisk
) => {
  const { terminGebyr, referanseRente, establishmentFeePercentage } =
    subsidyEffectData || {}

  const {
    gracePeriod,
    interestFreePeriod,
    maturity,
    loanType,
    paymentInformation,
    interestProfile,
  } = delivery?.agreement?.data?.specificAccountTypeInfo || {}
  const lopetid = maturity?.numberOfMonths / 12
  const avdragsFriPeriode = gracePeriod?.numberOfMonths / 12
  const antallRenteFrieAar = interestFreePeriod?.numberOfMonths / 12
  const avdragsPeriode = lopetid - avdragsFriPeriode
  const frekvensFromAgreement = convertFrequencyFromAgreementNameToNorwegian(
    paymentInformation?.paymentFrequency
  )
  const { baseRate, marginRate = {} } =
    interestProfile?.interestLine?.interestRates
  const nominellRenteFromAgreement =
    (baseRate?.value + (marginRate?.value ?? 0)) / 100

  const terminerPerAar = convertFrequencyToNumber(frekvensFromAgreement)
  const driftsRisiko = trimOperationalRisk(
    selectedOperationalRisk || caseOverview?.selectedOperationalRisk?.title
  )

  const sikkerhetsRisiko = +securityRiskData?.find(
    (riskItem) => riskItem?.deliveryId === delivery?.deliveryId
  )?.safetyRisk
  const subsidyEffectParams = {
    terminerPerAar: terminerPerAar,
    terminGebyr,
    antallAar: avdragsPeriode,
    antallAvdragsFrieAar: avdragsFriPeriode,
    antallRenteFrieAar,
    nominellRente: nominellRenteFromAgreement,
    referanseRente: referanseRente / 100,
    sikkerhetsRisiko: sikkerhetsRisiko || -1,
    driftsRisiko,
    utbetalingsKurs: (100 - establishmentFeePercentage) / 100,
    laaneType: loanType === "SERIAL" ? "SERIELAAN" : "ANNUITETSLAAN",
    frekvens: frekvensFromAgreement,
    risikopaaslag: 0,
    deliveryId: delivery?.deliveryId,
  }
  return subsidyEffectParams
}
