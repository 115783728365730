import { withTranslation } from "react-i18next"
import styled from "styled-components"
import Layout, { Context } from "../components/common/Layout"
import React, { useState } from "react"
import Heading from "../components/common/Heading"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import DisplaySecurityText from "../components/TermsAndSecurity/DisplaySecurityText"
import SpecialTermsTab from "../components/AnalyzeApplication/SpecialTermsTab"
import { Tabs } from "@flow/components"
import FetchCadastrePdf from "../components/RegisterCollateralManual/FetchCadastrePdf"
import { realtyLookup } from "../components/TermsAndSecurity/cadastreLookup"
import _ from "lodash"
import isTesting from "../util/isTesting"
import DepotControl from "../components/DepotControl/DepotControl"
const DepotControlCollateralPropertyManual = ({
  task,
  t,
  flow,
  schema,
  save,
  complete,
}) => {
  const { data, defaults, variables, context } = task
  const { oldCadastres = {}, newCadastres = {} } = context
  const [taskData, setTaskData] = useState(task.data || task.default)
  const [activeTab, setActiveTab] = useState("depotControl")
  const [isProcessing, setProcessing] = useState(false)
  const currentAgreementId = variables?.collateralElement
  const currentAgreement = task.context?.agreementData?.find(
    (x) => x.agreementId === currentAgreementId
  )
  const flowId = flow.flowId
  const pathCurrentCollateralElement = `deliveries.${variables.delivery.id}.collateral.servicesAmbitaEtinglysing.${variables.collateralElement}`
  const handleSave = (values) => {
    setProcessing(true)

    save(
      taskData,
      () => {},
      () => {
        console.error("Could not save task")
        setProcessing(false)
      }
    )
  }

  const handleComplete = (values) => {
    setProcessing(true)
    complete(
      taskData,
      () => {},
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const getLookupCadastrePdf = async (cadastreKey) => {
    try {
      if (isTesting()) return null
      const lookedUp = await realtyLookup(
        "cadastrepdf",
        cadastreKey,
        flowId,
        [],
        pathCurrentCollateralElement + ".realty",
        pathCurrentCollateralElement,
        "start-lookup-cadastrepdf",
        currentAgreementId,
        "servicesAmbitaEtinglysing",
        "depot"
      )
      const file = {
        storedFile: lookedUp.data,
        timestamp: lookedUp.timestamp,
      }
      const copy = _.cloneDeep(updatedNewCadastres)
      if (lookedUp.cadastre in copy) {
        copy[lookedUp.cadastre].cadastrepdf.push(file)
      } else {
        copy[lookedUp.cadastre] = {
          cadastrepdf: [file],
          cadastre: [],
        }
      }
      setUpdatedNewCadastres(copy)
    } catch (error) {
      console.error("Failed to lookupcadastre.", error)
      throw error
    }
  }
  const [isLooking, setIsLooking] = useState([])
  const [updatedNewCadastres, setUpdatedNewCadastres] = useState(newCadastres)
  const relevantDocuments = flow.data.files.filter(
    (x) =>
      x.documentType === "Tilbudsbrev" &&
      x.documentGroup === "Archive" &&
      x?.metadata?.deliveryId === variables.delivery.id
  )
  const decisionPdf = flow.data.files.filter(
    (x) => x.documentType === "Innstilling" && x.documentGroup === "Søknad"
  )
  const insurancePdf = flow.data.files.filter(
    (x) =>
      x.documentType === "Forsikringsavtale for fast eiendom.pdf" &&
      x.documentGroup === "Archive"
  )
  const options = [
    {
      id: "depotControl",
      title: t("depotControl"),
      component: (
        <MainContainer>
          <DepotControl
            t={t}
            currentAgreement={currentAgreement}
            relevantDocuments={relevantDocuments}
            decisionPdf={decisionPdf}
            insurancePdf={insurancePdf}
          />
          <div>
            <Heading>{t("ground-book")}</Heading>
            {currentAgreement?.collateralObject?.map((collateralObject, i) => (
              <>
                <FetchCadastrePdf
                  heading={t("main-cadastre")}
                  oldCadastres={oldCadastres}
                  newCadastres={updatedNewCadastres}
                  cadastreKey={collateralObject?.collateralObjectCadastre}
                  getLookupCadastrePdf={getLookupCadastrePdf}
                  isLooking={isLooking}
                  setIsLooking={setIsLooking}
                  t={t}
                  readOnly={false}
                />
                <AssociatedCadastreWrapper className={"borderAbove"}>
                  {collateralObject?.collateralAssosciatedProperties?.length >
                  0 ? (
                    <>
                      {collateralObject?.collateralAssosciatedProperties?.map(
                        (associatedCadastre, i) => (
                          <FetchCadastrePdf
                            heading={i < 1 ? t("associated-properties") : ""}
                            oldCadastres={oldCadastres}
                            newCadastres={updatedNewCadastres}
                            cadastreKey={associatedCadastre}
                            getLookupCadastrePdf={getLookupCadastrePdf}
                            isLooking={isLooking}
                            setIsLooking={setIsLooking}
                            t={t}
                            key={i}
                            readOnly={false}
                          />
                        )
                      )}
                    </>
                  ) : (
                    <p>{t("no-associated-properties")}</p>
                  )}
                </AssociatedCadastreWrapper>
              </>
            ))}
          </div>
        </MainContainer>
      ),
    },
    {
      id: "securityTexts",
      title: "Sikkerhetstekster",
      component: (
        <>
          {flow?.data?.deliveries[variables?.delivery?.id]?.securityTexts?.map(
            (securityText, securityTextIndex) => {
              return (
                <DisplaySecurityText
                  t={t}
                  key={securityTextIndex}
                  isEmpty={!securityText?.subtitle?.length}
                  isFirst={securityTextIndex === 0}
                  securityText={securityText}
                  userGenerated={securityText?.userGenerated}
                  isReadOnly={true}
                />
              )
            }
          )}
        </>
      ),
    },
    {
      id: "specialconditions",
      title: "Særvilkår",
      component: (
        <SpecialTermsTab
          deliveries={flow?.data?.deliveries}
          onChange={() => {}}
          t={t}
          readOnly={true}
        />
      ),
    },
  ]

  return (
    <Layout forceHeight>
      <MainContent>
        <Tabs options={options} onChange={(e) => setActiveTab(e)} />
        <TabContainer>
          {options?.map((x, i) => (
            <div
              key={i}
              style={{ display: x?.id === activeTab ? "block" : "none" }}
            >
              {x?.component}
            </div>
          ))}
        </TabContainer>
      </MainContent>
      <Context context={task.context?.applicationSummary} flow={flow}>
        <ReactForm
          schema={schema}
          formData={taskData}
          disabled={isProcessing}
          onChange={(values) => setTaskData(values)}
          onSubmit={(values) => handleComplete(values)}
        >
          <ButtonContainer>
            <PrimaryButton type="submit" isLoading={isProcessing}>
              {t("complete")}
            </PrimaryButton>
            <SecondaryButton
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave(taskData)}
            >
              {t("save")}
            </SecondaryButton>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  width: 100%;
  overflow-y: auto;
  gap: 1rem;
`

const BoldTd = styled.td`
  ${"" /*   font-weight: bold; */}
`

const Row = styled.div`
  display: flex;
  gap: 15px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 1em;
  height: 30px;
`

const Content = styled.div`
  display: flex;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`

const ContentTr = styled.tr`
  display: flex;
  padding: 10px;
  background-color: #f5f9ff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
`
const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  padding: 15px;
  overflow: scroll;
`
const TabContainer = styled.div`
  margin: 20px 20px 20px 0;
  flex: 1;
`
const AssociatedCadastreWrapper = styled.div`
  padding: 1em 0 0 0;
`
export default withTranslation()(DepotControlCollateralPropertyManual)
