import { APPS } from "@flow/case-manager"
import translatesNO from "./no"
import translatesEN from "./en"

function createTranslates(language, collection) {
  return [
    {
      language,
      translates: {
        ...collection.inbox,
        ...collection.tasks,
        ...collection.details,
        ...collection.queues,
        ...collection.taskComponents,
        ...collection.tabs,
        ...collection.vekstgaranti,
        ...collection.search,
        ...collection.oldTaskTranslations,
        ...collection.maintenance,
      },
    },
    {
      app: "Auditlog",
      language,
      translates: { ...collection.auditlog },
    },
    {
      app: "settings",
      language,
      translates: { ...collection.settings },
    },
    {
      app: "EvaluateBasisForSupport",
      language,
      translates: {
        ...collection.evaluateBasisForSupport,
        ...collection.taskComponents,
        ...collection.oldTaskTranslations,
      },
    },
  ]
}

export const customTranslates = [
  ...createTranslates("no", translatesNO),
  ...createTranslates("en", translatesEN),
]
