import styled from "styled-components"
import { Colors } from "@flow/style"
import React, { useEffect, useState } from "react"
import DeliveryComponent from "./DeliveryComponent"
import axios from "axios"
import { Spinner, Icons } from "@flow/icons"
import isTesting from "../../util/isTesting"

/**
 * The special term tab
 * @param {*} deliveries The deliveries in the application
 * @param {*} onChange Function that adds the changes to state
 * @param {*} t Translation
 * @returns The Special Term tab
 */
const SpecialTermsTab = ({ deliveries, onChange, t, readOnly = false }) => {
  useEffect(() => {
    if (isTesting()) return undefined
    const caller = async () => {
      const specialTermTagsRequest = async () => {
        try {
          setGettingSpecialTerms(true)
          return await axios.get("/api/special-term-tags")
        } catch (error) {
          console.error("Failed to get special term tags")
          setCheckSpecialTermsError(true)
        }
      }
      // Initializing
      const getSpecialTermTags = await specialTermTagsRequest()
      setGettingSpecialTerms(false)

      setSpecialTermTags(
        getSpecialTermTags.data.map((tag) => {
          return {
            value: tag.slice(0, 2),
            label: tag,
          }
        })
      )
    }
    caller()
  }, [])

  const [gettingSpecialTerms, setGettingSpecialTerms] = useState(false)
  const [checkSpecialTermsError, setCheckSpecialTermsError] = useState(false)
  const [specialTermsTags, setSpecialTermTags] = useState(false)
  const [editingId, setEditingId] = useState(null)
  const isMaintenance = deliveries?.vedlikehold ?? false
  if (checkSpecialTermsError) {
    return (
      <LoadingPage>
        <Icons.ErrorCircle />
        <div>
          {t("could-not-fetch-specialterms")} <br /> {t("close-and-try-again")}
        </div>
      </LoadingPage>
    )
  }

  return (
    <ContentWrapper>
      {gettingSpecialTerms ? (
        <LoadingPage>
          <Spinner size={50} />
          <div>{t("wait-specialterms")}</div>
        </LoadingPage>
      ) : (
        <>
          <Title>{t("PRODUCT")}</Title>
          {Object.keys(deliveries)?.map((deliveryId, i) => (
            <React.Fragment key={i}>
              <DeliveryComponent
                delivery={deliveries[deliveryId]}
                deliveryId={deliveryId}
                t={t}
                onChange={onChange}
                specialTermTags={specialTermsTags}
                readOnly={readOnly}
                editingId={editingId}
                setEditingId={setEditingId}
                isMaintenance={isMaintenance}
              />
              <Divider />
            </React.Fragment>
          ))}
        </>
      )}
    </ContentWrapper>
  )
}

export default SpecialTermsTab

const ContentWrapper = styled.div`
  margin: 15px;
`
const Title = styled.div`
  color: ${Colors.Grey1};
  margin: 0 0 10px 5px;
`
const Divider = styled.div`
  margin: 15px;
`
const LoadingPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin-left: 10px;
  }
`
