import { withTranslation } from "react-i18next"
import React, { useMemo, useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import styled from "styled-components"
import { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import Layout from "../components/common/Layout"
import formatWithLocal from "../util/dateFormatter"
import { addThousandSeparator } from "../util/addThousandSeparator"
import PayoutGrid from "../components/payoutControl/PayoutGrid"
import { Colors } from "@flow/style"

//This returns the oldest first
const sortByDate = (a, b) => {
  if (a.payoutDate < b.payoutDate) return -1
  if (a.payoutDate > b.payoutDate) return 1
  return 0
}

const VerifyPayouts = ({ task, flow, t, schema, save, complete }) => {
  const sortedNornet = flow.data?.payouts?.nornett.sort(sortByDate)
  const nordetDisplayList = sortedNornet?.map((x) => [
    {
      data: x.accountName,
    },
    {
      data: x.creditAccountName,
    },
    {
      data: formatWithLocal(x.payoutDate, "dd.MM.yyyy"),
    },

    {
      data: x.customerName,
    },
    {
      data: addThousandSeparator(x.payoutAmount),
    },
    {
      data: x.referenceNumber,
    },
    {
      data: x.user,
    },
    {
      data: formatWithLocal(x.decisionDate, "dd.MM.yyyy"),
    },
  ])
  const sortedKonsern = flow.data?.payouts?.konsern?.sort(sortByDate)
  const konsernDisplayList = sortedKonsern?.map((x) => [
    {
      data: x.accountName,
    },
    {
      data: x.creditAccountName,
    },
    {
      data: formatWithLocal(x.payoutDate, "dd.MM.yyyy"),
    },

    {
      data: x.customerName,
    },
    {
      data: addThousandSeparator(x.payoutAmount),
    },
    {
      data: x.referenceNumber,
    },
    {
      data: x.user,
    },
    {
      data: formatWithLocal(x.decisionDate, "dd.MM.yyyy"),
    },
  ])

  const titles = [
    "Kontonr i coreView",
    "Kontonr for utbetaling",
    "Utført utbetaling",
    "Mottakers navn",
    "Beløp",
    "Saksnummer",
    "Utført av",
    "Tilsagnsdato",
  ]

  const [formData, setFormdata] = useState(task.data)
  const [isProcessing, setProcessing] = useState(false)

  const handleComplete = () => {
    setProcessing(false)
    complete(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const handleSave = () => {
    setProcessing(true)
    save(
      formData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  return (
    <Layout forceHeight>
      <MainContent>
        <PayoutGrid
          titles={titles}
          payouts={nordetDisplayList}
          t={t}
          color={Colors.OrangeLighter}
          headline="Nornett"
        />
        <PayoutGrid
          titles={titles}
          payouts={konsernDisplayList}
          t={t}
          color={Colors.GrassLighter}
          headline="Konsern"
        />
      </MainContent>
      <Context flow={flow} context={task.contexts}>
        <ReactForm
          schema={schema}
          formData={formData}
          onChange={(values) => setFormdata(values)}
        >
          <></>
        </ReactForm>

        <ButtonContainer>
          <PrimaryButton
            type="submit"
            isLoading={isProcessing}
            onClick={() => handleComplete()}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            disabled={isProcessing}
            onClick={() => handleSave()}
          >
            {t("save")}
          </SecondaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px 30px;
  overflow: auto;
`
const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`

export default withTranslation()(VerifyPayouts)
